/* eslint-disable @typescript-eslint/no-unused-expressions */
import InfiniteScroll from "react-infinite-scroll-component";
import { createFullName, hasAddressData, renderAddressDetail } from "../../utils";
import { labels, placeholder } from '../../utils/constants';
import Logo from "../../components/commonComponent/ProfileImage";
import ViewCrewDetails from '../crewMembers/ViewCrewDetails';
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ROLE_TYPE, USER_PRESS_ACTION_TYPE } from '../../types';
import queryString from 'query-string';
import { makePostCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { makeGetCall } from '../../effects/requests';
import { ApiResponseType } from '../../types';
import InputField from '../../lib/input';
import SearchWithHeading from "../../global/ui/SearchWithHeading";
import { EMAIL_VALIDATION, SUPPLIER_NAME_VALIDATION } from '../../lib/input/validation-rules';
import { BUTTON_TYPE } from '../../types';
import { sendInvite } from '../../data/formData';
import "./RelevantCrew.css";
import { apiConstants } from '../../utils/apiConstants';
import Button from '../../global/ui/Button';
import { generateFormData } from '../../utils';
import { showToast } from '../../utils';
import Loader from "../../global/ui/Loader";

interface RelevantCrewProps {
    data: Array<any>;
    selectedCrew?: Array<any>;
    onChange?: (value: any) => void
    hideCheckbox?: boolean,
    nextUrl: any,
    fetchMoreDetails: () => void
    type?: any
    setSearchRelevant?: any
    searchRelevant?: any
    closeMainPopup?: any
    isLoading?: any;
}

const RelevantCrew = (props: RelevantCrewProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { ...methods } = useForm({
        mode: 'all',
        defaultValues: { ...sendInvite() },
        reValidateMode: 'onBlur'
    });

    const onSubmit: SubmitHandler<any> = (data: any) => {
        handleInvite(data);
    };


    const [crewMemDetails, setCrewMemDetails] = useState<any>(null);
    const parsed = queryString.parse(location.search);

    const fetchDetails = (id: any) => {
        const urls = `${endPoints.USER}${id}`
        makeGetCall(urls).then((res: ApiResponseType<any>) => {
            setCrewMemDetails(res?.data)
        })
    }

    const updateCrewMemberDetails = (item: any) => {
        setCrewMemDetails(item);
    }

    const handleInvite = (data: any) => {
        const apiPayload = {
            ...data,
        }
        const fd = generateFormData(apiPayload);
        makePostCall(`${endPoints.INVITE_CREW}?email=${data.email}`, fd, true).then((res: ApiResponseType<any>) => {
            if (res.status.code === 200) {
                showToast('Invite sent successfully', 'success');
                props.closeMainPopup();
                methods.reset();
            }
        })
    }

    const isEmptyRelevent = (props?.type === 'Relevent' && props?.data?.length == 0) ? true : false;

    return (
        <>
            <div className={props?.type === 'Relevent' ? (props.data?.length === 0 ? "inviteScrollScreen jobs-selct-popup" : "scrollScreen jobs-selct-popup") : " jobs-selct-popup"}>
                {(props?.type === 'Relevent' && props.data?.length > 0 && !props?.hideCheckbox) &&
                    <SearchWithHeading
                        placeholder="Search Crew"
                        onSearch={(value) => props?.setSearchRelevant(value)}
                        value={props?.searchRelevant || ''}
                        heading='Suppliers'
                        typeView={true}
                    />
                }
                {isEmptyRelevent &&
                    <>
                        <div style={{ marginTop: '20px' }}>
                            <FormProvider {...methods}>
                                <div className='col-md-12'>
                                    <InputField
                                        label={labels.full_name}
                                        placeholder={placeholder.full_name}
                                        name={apiConstants.first_name}
                                        type='text'
                                        rules={SUPPLIER_NAME_VALIDATION(labels.full_name)}
                                        className="black-input mb-4"
                                    />
                                </div>
                                <div className='col-md-12'>
                                    <InputField
                                        label={labels.email}
                                        placeholder={'Invite crew not on Sixth Degree by email'}
                                        name={apiConstants.email}
                                        type='email'
                                        rules={EMAIL_VALIDATION}
                                        className="black-input mb-4"
                                    />
                                </div>
                            </FormProvider>
                        </div>
                        <div className="inviteButton">
                            <Button
                                type={BUTTON_TYPE.TYPE_BUTTON}
                                btnTxt='Invite'
                                style={{ marginTop: "0px", width: "auto" }}
                                className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
                                onClick={methods.handleSubmit(onSubmit)}
                                isLoading={false}
                            />
                        </div>
                    </>
                }
                {props.data?.length > 0 ?
                    <div
                        className={props?.type != 'Relevent' ? '' : 'job-selct-scroll-part'}
                        id="scrollableDivList"
                        style={{ paddingBottom: '80px' }}
                    >
                        <InfiniteScroll
                            dataLength={props?.data?.length}
                            next={props?.fetchMoreDetails}
                            hasMore={props?.nextUrl ? true : false}
                            loader={null}
                            scrollableTarget="scrollableDivList"
                        >
                            {props.data?.map((item: any, idx) => {
                                return (
                                    <div key={idx} className='jobs-selct-popup-list d-flex py-3 px-3 align-items-center gap-3 mt-3'>
                                        {item?.profile_image ? <img src={item?.profile_image} className={`profile-img rounded-circle ${item?.profile_image ? '' : 'without-logo'}`} alt="" />
                                            : <Logo style={{ width: '54px', height: '54px', padding: '20px' }} data={item?.first_name?.[0]} />}
                                        <div className='jobs-selct-popup-list-img'>
                                            <h3
                                                style={{ textTransform: 'capitalize', cursor: 'pointer', width: 'fit-content' }}
                                                onClick={() => updateCrewMemberDetails(item)}
                                            >
                                                {createFullName(item?.first_name, item?.last_name)}
                                                {item?.verified && <img style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-3px' }} src={"/assets/images/yellow_check_mark.png"} alt="" />}
                                            </h3>
                                            {hasAddressData(item) &&
                                                <ul className='list-unstyled pl-0 d-flex flex-wrap mb-0'>
                                                    <li><img src={"/assets/images/Location-icon-perple.svg"} alt="" />{renderAddressDetail(item)}</li>
                                                </ul>
                                            }
                                        </div>
                                        {!props.hideCheckbox &&
                                            <div className='poupcheck'>
                                                <div className="input-check-box">
                                                    <input
                                                        onChange={() => props?.onChange?.(item)}
                                                        checked={props.selectedCrew?.includes(item?.id as never)}
                                                        type="checkbox"
                                                        name=""
                                                        id={item?.id}
                                                        className="d-none"
                                                    />
                                                    <label htmlFor={item?.id}></label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                        {props?.isLoading &&
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loader imgStyle={{ width: '40px', height: '40px' }} /></div>
                        }
                    </div>
                    :
                    isEmptyRelevent ? null : <div className="no-relevant-crew"><p>No results to show</p></div>
                }
            </div>
            {crewMemDetails &&
                <ViewCrewDetails
                    visible={crewMemDetails ? true : false}
                    onClose={() => setCrewMemDetails(null)}
                    crewDetails={crewMemDetails}
                    onAction={(type) => {
                        if (type == USER_PRESS_ACTION_TYPE.TYPE_FLAG) {
                            alert("To be implement")
                        }
                    }}
                    type={parsed.type}
                    onRefView={(item: any) => {
                        if (item?.user_type === ROLE_TYPE.TYPE_CREW) {
                            fetchDetails(item?.reference_id)
                        } else {
                            navigate(`/suppliers/?detail_id=${item?.reference_id}`)
                        }
                    }}
                    getCrewMem={() => { }}
                    hideBtn
                />
            }
        </>
    )
}

export default RelevantCrew