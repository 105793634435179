import React from 'react'
import { Table } from 'react-bootstrap';
import Logo from '../../components/commonComponent/ProfileImage';
import { createFullName, getMaskInputFormat, hasAddressData, renderAddressDetail, setDropdownSelectedValue } from '../../utils';
import { eventTypeList } from '../../data/data';
import { DateUtils } from '../../utils/dateUtils';
import NoData from '../../global/ui/NoData';

interface IItems {
    data: Array<any>;
    onClick?: (data: any) => void;
}

const Items: React.FC<IItems> = (props) => {
    return (
        <div className="common-table ovflow-height">
            <Table responsive>
                <thead>
                    <tr>
                        <th style={{ minWidth: '80px', width: '5%' }}></th>
                        <th style={{ minWidth: '120px', width: '10%' }}>Name</th>
                        <th style={{ minWidth: '200px', width: '10%' }}>Location</th>
                        <th style={{ minWidth: '120px', width: '10%' }}>Phone</th>
                        <th style={{ minWidth: '120px', width: '10%' }}>Contracted hours</th>
                        <th style={{ minWidth: '150px', width: '15%' }}>Department</th>
                        <th style={{ minWidth: '120px', width: '10%' }}>Event Type</th>
                        <th style={{ minWidth: '120px', width: '10%' }}>Scheduled Hours</th>
                        <th style={{ minWidth: '120px', width: '10%' }}>Hours</th>
                    </tr>
                </thead>
                {props?.data?.length > 0
                    ?
                    <tbody>
                        {props?.data?.map((item: any, idx: any) => {

                            let dept = '-', eventType = '-';
                            const evts = item?.event_type || [], departments = item?.crew_member_profile?.company_department || []
                            if (departments?.length > 0) {
                                dept = `${departments?.[0]?.dep || ''} ${departments?.length - 1 > 0 ? ` + ${departments?.length - 1} more` : ''}`;
                            }

                            if (evts?.length > 0) {
                                const firstItem = setDropdownSelectedValue(eventTypeList, evts?.[0]) || {};
                                eventType = `${firstItem?.label || ''} ${evts?.length - 1 > 0 ? ` + ${evts?.length - 1} more` : ''}`;
                            }

                            const isOverTime = item?.scheduled_hours > item?.crew_member_profile?.overtime_hours ? true : false;

                            const startLocalTime = DateUtils.removeUTCString(item?.starting_hour);
                            const endLocalTime = DateUtils.removeUTCString(item?.ending_hour);

                            return (
                                <tr key={idx}>
                                    <td style={{ minWidth: '80px', width: '5%' }}>
                                        <div className="d-flex align-items-center gap-4">
                                            {item?.profile_image ?
                                                <img style={{ width: '33px', marginLeft: '20px' }} src={item?.profile_image} alt="User" className={`rounded-circle ${item?.profile_image ? '' : 'table-icon-img'}`} />
                                                :
                                                <Logo style={{ width: '33px', height: '33px', marginLeft: '20px' }} data={item?.first_name[0]} />
                                            }
                                        </div>
                                    </td>
                                    <td className='text-break' style={{ minWidth: '120px', width: '10%', cursor: 'pointer' }}><p style={{ textTransform: 'capitalize' }} className="mb-0">{createFullName(item?.first_name || '', item?.last_name || "")}</p></td>
                                    <td className='text-break' style={{ minWidth: '200px', width: '10%' }}>{hasAddressData(item) ? renderAddressDetail(item) : '-'}</td>
                                    <td style={{ minWidth: '120px', width: '10%' }}>{item?.mobile_number ? getMaskInputFormat(item?.mobile_number) : '-'}</td>
                                    <td className='text-break' style={{ minWidth: '120px', width: '10%', cursor: 'pointer' }}>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <div className='contract-hours-wrap d-flex align-items-center justify-content-center' style={{ width: '80px' }}>
                                                <span style={{ fontWeight: '500' }}>{`${item?.crew_member_profile?.overtime_hours || 0} hours`}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '150px', width: '15%', cursor: 'pointer' }}>{dept}</td>
                                    <td style={{ minWidth: '120px', width: '10%', cursor: 'pointer' }}>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <div style={{ backgroundColor: item?.flat_day ? '#6FCF97' : 'rgba(122, 51, 255, 0.2)', width: '80px' }} className='contract-hours-wrap d-flex align-items-center justify-content-center'>
                                                <span style={{ color: item?.flat_day ? '#000000' : 'rgba(122, 51, 255, 1)', fontWeight: '500' }}>{item?.flat_day ? 'Flat Day' : eventType}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', width: '10%' }}>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <div style={{ backgroundColor: item?.flat_day ? '#6FCF97' : isOverTime ? '#FF3F3F' : '#FFD800', width: '80px' }} className='contract-hours-wrap d-flex align-items-center justify-content-center' >
                                                <span style={{ fontWeight: '500' }}>{item?.flat_day ? 'Flat Day' : `${item?.scheduled_hours} hours`}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', width: '10%' }}>{item?.flat_day ? '  •  • ' : `${DateUtils.formatDateTime(startLocalTime, 'HH:mm')} - ${DateUtils.formatDateTime(endLocalTime, 'HH:mm')}`}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                    :
                    <NoData />
                }
            </Table>
        </div>
    )
}

export default Items