import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import Button from '../../global/ui/Button'
import { BUTTON_TYPE } from '../../types'
import { DateUtils } from '../../utils/dateUtils';
import { emptyFunction } from '../../utils';

interface IDragEventInfo {
    show: boolean;
    onClose: () => void;
    onPublish: () => void;
    data: any;
}

const DragEventInfo: React.FC<IDragEventInfo> = (props) => {
    return (
        <Modal
            centered
            scrollable
            show={props.show}
            onHide={emptyFunction}>
            <ModalHeader className="p-0 border-0 text-center mb-3">
                <ModalTitle className="fw-bold mx-auto">
                    <p><img src={"/assets/images/add-evt.svg"} alt="" /></p>Update Event
                </ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-center mb-5 pb-3">
                <p style={{ lineHeight: '24px' }} className="mb-0">Are you sure you want to adjust the event date from <b>{`${DateUtils.formatDateTime(props?.data?.event_source_date, 'DD-MM-YYYY')}`}</b> to <b>{`${DateUtils.formatDateTime(props?.data?.event_destination_date, 'DD-MM-YYYY')}`}</b>?</p>
            </ModalBody>
            <ModalFooter className="p-0 border-0">
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Update'
                    style={{ marginTop: "0px", width: "auto" }}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                    onClick={props.onPublish}
                />
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Exit without updating'
                    style={{ marginTop: "0px", width: "auto", textDecoration: 'underline' }}
                    onClick={props.onClose}
                    className={'btn btn-light w-100 with-after-shadow shadow-none fw-normal bg-transparent m-0 border-0 mt-4 p-0'}
                />
            </ModalFooter>
        </Modal>
    )
}

export default DragEventInfo