import { renderAddressDetail } from '../../utils';
import ActionModal from './ActionModal';

interface ISupplierMoreAddresses {
    isShow: boolean;
    onClose: () => void;
    locations: Array<any>;
    showFullAddress?: boolean;
}

const SupplierMoreAddresses = (props: ISupplierMoreAddresses) => {

    return (
        <ActionModal
            isShow={props.isShow}
            onClose={props.onClose}
            onSubmit={props.onClose}
            title='Supplier additional locations'
            children={
                <div style={{ maxHeight: '330px', overflowY: 'scroll' }}>
                    <ul className="profile-information-list">
                        {props?.locations?.map((item: any, idx: any) => {
                            return (
                                <li key={idx} className='align-items-center'>
                                    <img src={"/assets/images/Location-icon-perple.svg"} alt="image" />
                                    {renderAddressDetail(item, true, props?.showFullAddress)}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
            btnTxt='Ok'
        />
    )
}

export default SupplierMoreAddresses