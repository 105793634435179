import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import Button from '../../global/ui/Button';
import { BUTTON_TYPE } from '../../types';
import { emptyFunction } from '../../utils';

interface IMessageLimitExceed {
    show: boolean;
    onClose: () => void;
}

const MessageLimitExceed: React.FC<IMessageLimitExceed> = (props) => {
    return (
        <Modal
            centered
            scrollable
            show={props?.show}
            onHide={emptyFunction}>
            <ModalHeader className="p-0 border-0 text-center mb-3">
                <ModalTitle className="fw-bold mx-auto">
                    <p><img src="/assets/images/exceed_message.svg" alt="" /></p>
                    Message Limit Reached
                </ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-center mb-5 pb-3">
                <p style={{ lineHeight: '24px' }} className="mb-0">You have reached your message limit for this month, you can message an additional 10 crew members next month</p>
            </ModalBody>
            <ModalFooter className="p-0 border-0">
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Dismiss'
                    style={{ marginTop: "0px", width: "auto" }}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                    onClick={props?.onClose}
                />
            </ModalFooter>
        </Modal>
    )
}

export default MessageLimitExceed