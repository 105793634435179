import React, { useState } from 'react'
import Button from '../../global/ui/Button';
import { ApiResponseType, BUTTON_TYPE } from '../../types';
import { createFullName, emptyFunction, generateFormData, showToast } from '../../utils';
import { DateUtils } from '../../utils/dateUtils';
import SendGroupMessage from './SendGroupMessage';
import AssignedCrews from './AssignedCrews';
import { apiConstants } from '../../utils/apiConstants';
import { makePostCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { errorMessage } from '../../utils/constants';

interface IViewCrewGroup {
    isShow: boolean;
    onClose: () => void;
    crewGroupDetails: any;
    onEdit: () => void;
}

const ViewCrewGroup: React.FC<IViewCrewGroup> = (props) => {

    const { crewGroupDetails } = props;

    const [isGroupChat, setIsGroupChat] = useState(false);

    const memberData = crewGroupDetails?.member_data?.length > 0 ? crewGroupDetails?.member_data : [];

    const handleSend = (msg?: string, url?: string, fileType?: string, fileName?: any) => {
        if (!msg && !url) {
            showToast(errorMessage.fieldRequired('Message'), 'error');
            return
        }
        const fd = generateFormData({
            message: msg,
            attachment_url: url || null,
            attachment_type: fileType || null,
            attachment_name: fileName || null,
        });
        const ids: any = memberData?.map((item: any) => item?.member?.id)
        fd.append(apiConstants.user_ids, JSON.stringify(ids))
        makePostCall(endPoints.BROADCAST, fd).then((res: ApiResponseType<any>) => {
            if (res.status?.code === 200) {
                if (res?.data) {
                    showToast(res?.data, 'success');
                }
                setIsGroupChat(false)
            }
        }).catch(emptyFunction)
    }

    return (
        <div className={`right-side-popup-background ${props?.isShow ? "" : "d-none"}`}>
            <div className="right-side-popup-fixed d-grid align-content-between gap-4">
                <div className='crew-group-details justify-content-start align-items-center m-4'>
                    <div onClick={props.onClose} className='noti-back-btn mb-2'>
                        <img src={"/assets/images/dismiss-cross-black.svg"} alt="" />
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-4'>
                        <span className='w-75' style={{ fontSize: '28px', lineHeight: '30px' }}>{crewGroupDetails?.name || ''}</span>
                        <div onClick={props?.onEdit} className='d-flex justify-content-start align-items-center update-btn cursor-pointer'>
                            <img style={{ marginRight: '5px' }} src="/assets/images/edit-pencil-black.svg" />
                            <span>Update</span>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <p className='w-75'>{crewGroupDetails?.description || ''}</p>
                        <p className='mb-0'><img className='crew-group-icons' src={"/assets/images/user-icon-perple.svg"} alt="" />{`Created by ${createFullName(crewGroupDetails?.created_by?.first_name || '', crewGroupDetails?.created_by?.last_name || '')}`}</p>
                        <p className='mb-0'><img className='crew-group-icons' src={"/assets/images/Location-icon-perple.svg"} alt="" />{`Last Edited ${DateUtils.formatDateTime(crewGroupDetails?.modified_at, 'DD/MM/YYYY HH:mm')}`}</p>
                    </div>
                    <AssignedCrews memberData={memberData} />
                </div>
                {crewGroupDetails?.member_data?.length > 0
                    ?
                    <div className='ms-3 me-3 mb-4'>
                        <Button
                            type={BUTTON_TYPE.TYPE_BUTTON}
                            btnTxt='Send Message To Group '
                            className={'btn btn-warning w-100 with-after-shadow shadow-none m-0 mt-2'}
                            onClick={() => {
                                if (!crewGroupDetails?.published) {
                                    showToast('Please publish the group first.', 'error', crewGroupDetails?.id);
                                    return;
                                }
                                setIsGroupChat(true)
                            }}
                        />
                    </div>
                    :
                    null
                }
            </div>
            <SendGroupMessage
                isShow={isGroupChat}
                onClose={() => setIsGroupChat(false)}
                onSubmit={handleSend}
                memberData={memberData}
            />
        </div>
    )
}

export default ViewCrewGroup;