import React from 'react'
import { createFullName, getMaskInputFormat, hasAddressData, renderAddressDetail } from '../../utils';
import { DateUtils } from '../../utils/dateUtils';
import { ROLE_TYPE } from '../../types';

interface AboutSupplierProps {
    data: any;
    selectedIdx?: number;
    onMoreClick?: () => void;
}

const AboutSupplier: React.FC<AboutSupplierProps> = (props) => {

    const { data } = props;

    const departments: Array<any> = props.data?.supplier_profile?.departments

    return (
        <div className={`tab-pane ${props?.selectedIdx === 0 && 'active show'}`} id="about">
            <ul className="profile-information-list">
                {(data?.first_name | data?.last_name || data?.email || data?.mobile_number) &&
                    <li className="align-items-center">
                        <img src={"/assets/images/Mail-icon-perple.svg"} alt="" />
                        <div>
                            <b>{createFullName(data?.first_name, data?.last_name)}</b>{(data?.first_name || data?.last_name) ? <br /> : ''}{data?.email}{data?.email ? <br /> : ''}{data?.mobile_number ? getMaskInputFormat(data?.mobile_number) : ''}
                        </div>
                    </li>
                }
                {hasAddressData(data) &&
                    <li className="align-items-center">
                        <img src={"/assets/images/Location-icon-perple.svg"} alt="" />
                        {renderAddressDetail(data)}<br />{data?.postcode || ''}
                        {data?.user_type === ROLE_TYPE.TYPE_SUPPLIER && data?.other_location?.length > 0 &&
                            <span
                                className="cursor-pointer address-label"
                                onClick={props?.onMoreClick}
                            >
                                {`+${data?.other_location?.length} More`}
                            </span>
                        }
                    </li>
                }
                {/* not in current scope */}
                {/* <li className="align-items-center">
                    <img src={"/assets/images/dimand-icon-perpel.svg"} alt="" />
                    8 Years Experience
                </li> */}
                <li className="align-items-center">
                    <img src={"/assets/images/Calendar-empty-icon-perple.svg"} alt="" />
                    {` Member since ${DateUtils.formatDateTime(data?.date_joined, 'DD MMMM YYYY')}`}
                </li>
                {data?.supplier_profile?.bio &&
                    <li>
                        <img src={"/assets/images/user-icon-perple.svg"} alt="" />
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: data?.supplier_profile?.bio.replace(/\n/g, "<br/>") || '' }}>
                            </p>
                        </div>
                    </li>
                }
                {departments?.length > 0 &&
                    <li className="align-items-center">
                        <img src={"/assets/images/Briefcase-perple-icon.svg"} alt="" />
                        <div className="like-togl">
                            {departments?.join(',')?.split(',')?.map((item, idx) => <span key={idx}>{item}</span>)}
                        </div>
                    </li>
                }
            </ul>
        </div>
    )
}

export default AboutSupplier