import React, { useEffect, useState } from 'react'

interface TabsProps {
    data: Array<any>;
    onClick?: (value: any, idx?:any) => void;
    selectedIdx?: number
}

const Tabs: React.FC<TabsProps> = (props) => {

    const [selectedIdx, setSelectedIdx] = useState(props?.selectedIdx || 0);
    const [section, setSection] = useState(false)
    useEffect(() => {
        setSelectedIdx(props?.selectedIdx || 0)
    }, [props?.selectedIdx])
    
    return (
        <ul className="nav">
            {props.data.map((item, idx) => {
      
                return (
                    <li key={idx}
                        onClick={() => {
                            setSelectedIdx(idx);
                            props?.onClick?.(item, idx)
                        }}
                        className="nav-item">
                        <a style={{ textTransform: 'capitalize' }} className={selectedIdx === idx ? "nav-link active" : "nav-link"} data-bs-toggle="pill" href={`#${item?.toLowerCase()}`}>{item}</a>
                    </li>
                )
            })}
        </ul>
    )
}

export default Tabs