import React from 'react'

interface ILoadingDots {
    style?: React.CSSProperties;
}

const LoadingDots: React.FC<ILoadingDots> = (props) => {
    return (
        <div style={props?.style} className="loading-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}

export default LoadingDots