import Select from 'react-select';
import { countryList } from '../../data/data'
import InputField, { InputFieldsProps } from '../../lib/input';
interface mobileNumberProps extends InputFieldsProps {
  classNameFlag?: string | null;
  selectLabel?: string | null;
  onSelect: (data: string) => void;
  selectedOption: any;
  mobileContainerStyle?: React.CSSProperties;
  isTransparent?: any
  isDisabled?: any
}
const MobileNumber = (props: mobileNumberProps) => {

  const customStyles = () => {
    if (!props?.isTransparent) return
    return {
      control: (base: any, state: any) => ({
        ...base,
        background: "transparent",
      }),
    }
  };

  return (
    <div style={props?.mobileContainerStyle} className='row'>
      <div className='col-md-3' style={{ marginTop: '25px', }}>
        <div className={`form_selcet w-100 mb-4 arrow-fill ${props?.classNameFlag}`}>
          <div className="input_box">
            {props?.selectLabel && <label htmlFor="country">Country</label>}
            <Select
              name="country"
              className="basic-multi-select shadow-none"
              classNamePrefix="select"
              value={props?.selectedOption}
              onChange={(options: any) => props.onSelect(options)}
              getOptionLabel={option => `${option?.flag}`}
              getOptionValue={option => `${option.flag}`}
              options={countryList}
              placeholder={' '}
              isSearchable={false}
              styles={customStyles()}
              isDisabled={props?.isDisabled}
            />
          </div>
        </div>
      </div>
      <div className='col-md-9'>
        <InputField {...props} isDisabled={props?.isDisabled} />
      </div>
    </div>
  )
}

export default MobileNumber;

// usage

{/* <MobileNumber
onSelect={(data) => { console.log(data, "selected data") }}
name={apiConstants.phone_number}
label={labels.contactNumber}
placeholder={placeholder.contactNumber}
type='number'
rules={MOBILE_VALIDATION}
mobileContainerStyle={{ marginTop: '25px', }}
/> */}