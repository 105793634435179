import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { ApiResponseType, BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";
import DropDown from '../../global/ui/dropDown';
import { useContext, useEffect, useState } from 'react';
import { MyContext } from '../../context';
import { emptyFunction, showToast } from '../../utils';
import { endPoints } from '../../utils/endpoints';
import { makePostCall } from '../../effects/requests';

const ArchiveAndDuplicateProd = ({ isShow, onClose, onFinalDone }: any) => {

    const { companies: companiesData } = useContext(MyContext);

    const [selectedEl, setSelectedEl] = useState<any>(null);

    const onDone = (type: any) => {
        if (!selectedEl) {
            showToast('Please select production.', 'error');
            return
        }
        let url = endPoints.ARCHIVE_PRODUCTION;
        const fd = {
            production_id: selectedEl?.id || selectedEl?.value
        }
        if (type === 'duplicate') {
            url = endPoints.DUPLICATE_PRODUCTION
        }
        makePostCall(url, fd).then((res: ApiResponseType) => {
            if (res?.status?.code === 200 && res?.status?.message) showToast(res?.status?.message, 'success')
            onFinalDone();
        }).catch(emptyFunction)
    }

    useEffect(() => {
        if (isShow && companiesData?.length > 0) {
            companiesData.map((item: any) => {
                item.label = item?.production_company?.company_name || '';
                item.value = item?.production_company?.id || '';
                return item
            })
        }
    }, [isShow])

    return (
        <Modal
            centered
            scrollable
            className='new_broadcast'
            show={isShow}
        >
            <ModalHeader className="p-0 border-0 align-items-start">
                <ModalTitle className="fw-bold me-auto text-start">
                    <h4 className='h4 text-start mb-0 mt-0'>Archive/Duplicate Production</h4>
                </ModalTitle>
                <button className="close-btn-de shadow-none" onClick={onClose}>
                    <img src={"/assets/images/cancel-button.svg"} alt="" />
                </button>
            </ModalHeader>
            <ModalBody style={{ minHeight: '180px' }} className="p-25 text-start pb-4">
                <DropDown
                    className="arrow-fill new-brodcast"
                    isMultiSelect={false}
                    data={companiesData}
                    placeholder='Select production to archive / duplicate'
                    label='Archive / Duplicate'
                    onSelect={(el) => setSelectedEl(el)}
                    selectedItems={selectedEl}
                />
            </ModalBody>
            <ModalFooter className="p-0 border-0 row align-items-center justify-content-center" style={{ marginTop: '45px', }}>
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt={'Archive Production'}
                    style={{ marginTop: "0px", width: "auto", alignSelf: 'center' }}
                    className={'btn btn-warning w-75 with-after-shadow shadow-none m-0 center'}
                    onClick={() => onDone('archive')}
                />
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt={'Duplicate Production'}
                    style={{ marginTop: "0px", width: "auto", alignSelf: 'center' }}
                    className={'btn btn-warning w-75 with-after-shadow shadow-none mt-3 center'}
                    onClick={() => onDone('duplicate')}
                />
            </ModalFooter>
        </Modal>
    )
}

export default ArchiveAndDuplicateProd