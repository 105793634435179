import React, { useContext, useState } from 'react'
import ActionModal from '../../components/modal/ActionModal'
import AsyncSelect from 'react-select/async';
import { makeGetCall } from '../../effects/requests';
import { ApiResponseType } from '../../types';
import { showToast } from '../../utils';
import { DateUtils } from '../../utils/dateUtils';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';

interface AssignJobProps {
    isShow: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const AssignJob: React.FC<AssignJobProps> = (props) => {

    const [selectedItem, setSelectedItem] = useState<any>(null)
    const { userData } = useContext(MyContext)
    const promiseOptions = (query: any) => {
        return makeGetCall(endPoints.JOBLIST, { search: query, job_company__company_name: userData?.production_company_name || '' }).then((res: ApiResponseType<any>) => {
            return res?.results?.map((item: any) => {
                return { ...item, label: item.title, value: item?.id }
            })
        })
    }

    return (
        <ActionModal
            isShow={props.isShow}
            onClose={props.onClose}
            onSubmit={() => {
                if (!selectedItem) {
                    showToast('Please select job', 'error');
                    return
                }
                props.onSubmit(selectedItem)
            }}
            showCloseIcon
            title='Assign Job'
        >
            <div className='assign-job-modal'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className={`form_selcet w-100 mb-4 col-md-12`}>
                            <div className="input_box">
                                <label htmlFor="country">Jobs</label>
                                <AsyncSelect
                                    className="basic-multi-select shadow-none remove-arrows-select"
                                    classNamePrefix="select"
                                    loadOptions={promiseOptions}
                                    placeholder='Search Jobs'
                                    value={selectedItem}
                                    onChange={(item) => setSelectedItem(item)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form_group w-100 mb-4 black-input remove-arrows-select">
                            <div className="input_box">
                                <label htmlFor="country">Booking Start Date</label>
                                <select disabled name="country" id="" className="">
                                    <option value="">{selectedItem?.start_date ? `${DateUtils.formatDateTime(selectedItem?.start_date, 'DD/MM/YYYY')}` : 'Start Date'}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form_group w-100 mb-4 black-input remove-arrows-select">
                            <div className="input_box">
                                <label htmlFor="country"> Booking End Date</label>
                                <select disabled name="country" id="" className="">
                                    <option value="">{selectedItem?.end_date ? `${DateUtils.formatDateTime(selectedItem?.end_date, 'DD/MM/YYYY')}` : 'End Date'}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ActionModal>
    )
}

export default AssignJob