import React, { useCallback, useContext, useEffect, useState } from 'react'
import { isProdUser, isSuperUser } from '../../utils';
import { MyContext } from '../../context';
import { ApiResponseType, ROLE_TYPE, USER_TYPE } from '../../types';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import NewBroadCast from './newBroadCast';
import { DateUtils } from '../../utils/dateUtils';
import Logo from '../../components/commonComponent/ProfileImage';
import { makeGetCall, makeNextPageCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { debounce } from 'lodash';
import LoadingDots from '../../global/ui/LoadingDots';
import NoData from '../../global/ui/NoData';

const MessagingUsers: React.FC<any> = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const parsed: any = queryString.parse(location?.search);
    const { userData } = useContext(MyContext);

    const [isNewBroadcast, setIsNewBroadcast] = useState(false);
    const [allData, setAllData] = useState<Array<any>>([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const updateUrls = (key: any, value: any) => {
        const params = { ...parsed };
        params[key] = value;
        if (key === 'user_type') {
            params['room'] = '';
        }
        navigate(`/messages/?${createSearchParams(params as never)}`);
    }

    const fetchMessagingUsers = () => {
        makeGetCall(endPoints.ROOM_LIST, parsed, false, isLoading)
            .then((res) => {
                setIsLoading(false)
                setAllData(res?.results);
                setNextUrl(res?.next);
            })
            .catch(() => setIsLoading(false))
    }

    const fetchMoreData = () => {
        if (nextUrl) {
            makeNextPageCall(nextUrl).then((res: ApiResponseType<any>) => {
                const data = [...allData, ...res.results];
                setAllData(data)
                setNextUrl(res?.next);
                setIsLoading(false)
            }).catch(() => setIsLoading(false));
        }
    }

    useEffect(() => {
        fetchMessagingUsers();
        if (parsed?.search) {
            setSearchValue(parsed.search);
        }
    }, [parsed?.user_type, parsed?.search]);

    const onChangeTextDebounce = useCallback(debounce((q) => updateUrls('search', q), 800), []);

    return (
        <div className={`messages-left-side`}>
            <div className='messages-top-side d-flex justify-content-between align-items-center'>
                <h5>Messages</h5>
                {(isProdUser(userData) || isSuperUser(userData)) &&
                    <p
                        className='d-flex align-items-center gap-2'
                        onClick={() => setIsNewBroadcast(true)}
                    >
                        New Broadcast
                        <img src="/assets/images/broadcast-icon-perple.svg" alt="" />
                    </p>
                }
            </div>
            <div className='messages-middle-side'>
                <div className="search-button d-flex align-items-center justify-content-between gap-3">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder="Search messages"
                            aria-label="Amount (to the nearest dollar)"
                            onChange={(evt) => {
                                setIsLoading(true);
                                setSearchValue(evt.target.value);
                                onChangeTextDebounce(evt.target.value);
                            }}
                            value={searchValue || ''}
                        />
                        <span className="input-group-text bg-transparent border-0">
                            {isLoading
                                ?
                                <LoadingDots />
                                :
                                <img
                                    src={searchValue ? "/assets/images/cross-cercle-fill.svg" : "/assets/images/search-black.svg"}
                                    alt="search"
                                    onClick={() => {
                                        if (!searchValue) return
                                        setSearchValue('');
                                        onChangeTextDebounce('');
                                    }}
                                />
                            }
                        </span>
                    </div>
                </div>
            </div>
            <div className='messages-bottom-side'>
                <div className="bottom-tabs-wo">
                    <ul className="nav">
                        <li
                            className="nav-item"
                            onClick={() => updateUrls('user_type', USER_TYPE.TYPE_SUPPLIER)}
                        >
                            <a
                                className={`nav-link ${parsed?.user_type === USER_TYPE.TYPE_SUPPLIER ? 'active' : ''}`}
                                data-bs-toggle="pill" href="#profile_information"
                            >
                                Supplier
                            </a>
                        </li>
                        <li
                            className="nav-item"
                            onClick={() => updateUrls('user_type', USER_TYPE.TYPE_CREW)}
                        >
                            <a
                                className={`nav-link ${parsed?.user_type === USER_TYPE.TYPE_CREW ? 'active' : ''}`}
                                data-bs-toggle="pill" href="#profile_information"
                            >
                                Crew
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="profile_information">
                            <div className='messages-chat-list' id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={allData?.length}
                                    next={fetchMoreData}
                                    hasMore={nextUrl ? true : false}
                                    loader={null}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {allData?.length > 0
                                        ?
                                        <>
                                            {allData?.map((item: any, idx: any) => {
                                                return (
                                                    <div
                                                        style={{ backgroundColor: parsed?.room == item?.id ? 'rgba(122, 51, 255, 0.2)' : '' }}
                                                        className='message-chat-box'
                                                        key={idx}
                                                        onClick={() => updateUrls('room', item?.id)}
                                                    >
                                                        <p className='mb-0 text-end time-date'>{item?.last_message_on ? DateUtils.getTime(item?.last_message_on) : item?.created_at ? DateUtils.getTime(item?.created_at) : ''}</p>
                                                        <div className='d-flex align-items-center justify-content-between mt-n1'>
                                                            <div className='message-box-left align-items-center d-flex gap-3'>
                                                                <div className='message-box-logo'>
                                                                    {(item?.other_user?.supplier_profile?.company_logo || item?.other_user?.owner_profile?.profile_image || item?.other_user?.profile_image)
                                                                        ?
                                                                        <img
                                                                            src={item?.other_user?.supplier_profile?.company_logo || item?.other_user?.owner_profile?.profile_image || item?.other_user?.profile_image}
                                                                            alt="user"
                                                                        />
                                                                        :
                                                                        <Logo
                                                                            style={{ width: '49px', height: '49px' }}
                                                                            data={item?.other_user?.supplier_profile?.company_name?.[0] || item?.other_user?.first_name?.[0] || item?.other_user?.last_name?.[0]}
                                                                        />
                                                                    }
                                                                </div>
                                                                <div className='heading-data'>
                                                                    <h3>
                                                                        {item?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER
                                                                            ?
                                                                            item?.other_user?.supplier_profile?.company_name
                                                                            :
                                                                            item?.other_user?.first_name + " " + item?.other_user?.last_name
                                                                        }
                                                                        {(item?.other_user?.verified && item?.other_user?.user_type === ROLE_TYPE.TYPE_CREW) &&
                                                                            <img
                                                                                style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-3px' }}
                                                                                src={"/assets/images/yellow_check_mark.png"} alt="verify user"
                                                                            />
                                                                        }
                                                                    </h3>
                                                                    <p className='last-message'>{item?.attachment_type ? `Sent ${item?.attachment_type}` : item?.last_message}</p>
                                                                </div>
                                                            </div>
                                                            {item?.unread_messages > 0
                                                                ?
                                                                <p className='mb-0 unread-message-number'>{item?.unread_messages}</p>
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <NoData
                                            imgStyle={{ width: '100px' }}
                                            textStyle={{ fontSize: '20px' }}
                                        />
                                    }
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NewBroadCast
                isShow={isNewBroadcast}
                onClose={() => setIsNewBroadcast(false)}
            />
        </div>
    )
}

export default MessagingUsers