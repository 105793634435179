import React from 'react';
import DropDown from './dropDown';

interface IDeptWithRoles {
    depts: Array<any>;
    onDeptSelect: (data: any, idx: any) => void;
    onRoleSelect?: (data: any, idx: any) => void;
    selectedDept: Array<any>;
}

const DeptWithRoles = (props: IDeptWithRoles) => {

    return (
        <>
            {props?.selectedDept?.map((item, idx) => {
                return (
                    <React.Fragment key={idx}>
                        <DropDown
                            className="arrow-fill new-brodcast"
                            data={props.depts}
                            onSelect={(data) => props.onDeptSelect(data, idx)}
                            placeholder='Select Department'
                            label='Department'
                            selectedItems={item || null}
                        />
                        <DropDown
                            className="arrow-fill new-brodcast"
                            data={item?.roles?.length > 0 ? item?.roles : []}
                            onSelect={(data) => props.onRoleSelect?.(data, idx)}
                            placeholder='Select Roles'
                            label='Roles'
                            selectedItems={item?.selectedRole?.length > 0 ? item?.selectedRole : []}
                            isMultiSelect
                        />
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default DeptWithRoles