import React from 'react'
import { Table } from 'react-bootstrap'
import DayItems from './DayItems'
import { DateUtils } from '../../../utils/dateUtils'
interface IEventScheduleDay {
    allData: Array<any>;
    shootEvents: Array<any>;
    onView: (event: any) => void;
    onEdit: (event: any) => void
}

const EventScheduleDay: React.FC<IEventScheduleDay> = (props) => {

    return (
        <div className="main-collender" >
            <Table responsive borderless className="" cellSpacing={"0"}>
                <thead>
                    <tr>
                        <td style={{ width: '70px', minWidth: '20px' }}></td>
                        {props.shootEvents?.length > 0 &&
                            <td>
                                {props.shootEvents?.map((shootEvtItem, idx) => {
                                    return (
                                        <div key={idx} className='imp-event'>
                                            <div className='outer'>
                                                <div onClick={() => props?.onView(shootEvtItem)} className='event-name'>{shootEvtItem?.name || ''}</div>
                                                <img onClick={() => props.onEdit(shootEvtItem)} src="/assets/images/schedule-white.svg" alt="" />
                                            </div>
                                            <div className='event-address'>{shootEvtItem?.address || ''}</div>
                                        </div>
                                    )
                                })}
                            </td>
                        }
                    </tr>
                </thead>
                <tbody id="scrollableDiv">
                    <tr>{props.allData?.map((item, idx) => {
                        return (
                            <div key={idx}>
                                <td style={{ width: '70px' }}>{DateUtils.formatDateTime(item?.dateString, 'hh a') as never}</td>
                                {item?.hasEvent &&
                                    <td>
                                        <div style={{ display: 'flex' }}>
                                            {item?.events?.map((eventItem: any, index: any) => {
                                                return <DayItems key={index} data={eventItem} onView={() => props?.onView(eventItem)} />
                                            })}
                                        </div>
                                    </td>
                                }
                            </div>
                        )
                    })}
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default EventScheduleDay