import React from 'react'
import DropDown from '../../global/ui/dropDown'
import { eventTypeList, preConfigDeptData } from '../../data/data'
import { setDropdownSelectedValue } from '../../utils'
import { labels, placeholder } from '../../utils/constants';

interface IBackPageFilter {
    count?: any;
    updateValue?: (key: any, value: any) => void;
    data: any;
    showing?: any;
    onClear: () => void;
}

const BackPageFilter: React.FC<IBackPageFilter> = (props) => {

    const dept = [...preConfigDeptData];

    const roles = setDropdownSelectedValue(dept, props?.data?.department)?.roles?.length > 0 ? setDropdownSelectedValue(dept, props?.data?.department)?.roles : []

    return (
        <div className="table-filter-setup mb-3 mb-lg-0">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 order-2 order-sm-3 order-xxl-2">
                <div className="select-fileter d-grid d-sm-flex align-items-center gap-4 mb-4">
                    <DropDown
                        className="arrow-fill w-100 mb-0 mb-sm-4 drop-down-over-vis"
                        data={dept}
                        placeholder='All departments'
                        label='Filter by department'
                        selectedItems={setDropdownSelectedValue(dept, props?.data?.department)}
                        onSelect={(data) => props?.updateValue?.('department', data?.label)}
                        style={{ width: '221px' }}
                    />
                    <DropDown
                        className="arrow-fill w-100 mb-0 mb-sm-4 drop-down-over-vis"
                        data={roles}
                        onSelect={(data) => props?.updateValue?.('role', data?.label)}
                        placeholder={placeholder.allRole}
                        selectedItems={setDropdownSelectedValue(roles, props?.data?.role)}
                        label={labels.filterByRole}
                        style={{ width: '221px' }}
                    />
                    <DropDown
                        className="arrow-fill w-100 mb-0 mb-sm-4 drop-down-over-vis"
                        data={eventTypeList}
                        onSelect={(data) => props?.updateValue?.('event_type', data?.value)}
                        placeholder='Select Event Type'
                        selectedItems={setDropdownSelectedValue(eventTypeList, props?.data?.event_type)}
                        label='Event Type'
                        style={{ width: '221px' }}
                    />
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-5 order-3 order-sm-2 order-xxl-5">
                        <div className="right-side-showing d-flex align-items-center justify-content-center justify-content-lg-end gap-3 mt-3 mt-sm-0">
                            <div className="clear-all" onClick={props?.onClear}>Clear all</div>
                            <p className="mb-0 fw-bold">{`Showing ${props?.showing} of ${props?.count || 0} results`}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackPageFilter