import React, { useContext, useState } from 'react'
import DropDown from '../../global/ui/dropDown';
import { setDropdownSelectedValue } from '../../utils';
import Search from '../../global/ui/Search';
import { preConfigDeptData } from '../../data/data';
import { MyContext } from '../../context';
import CustomGooglePlaceSearch from '../../components/customGooglePlaceSearch';

interface SuppliersFilterProps {
  count?: any;
  updateValue?: (key: any, value: any) => void;
  data: any;
  showing?: any;
  onClear: () => void
  onLocationSearch?: (data: any) => void
}

const SuppliersFilter: React.FC<SuppliersFilterProps> = (props) => {

  const { userData } = useContext(MyContext);

  const [allDept, setAllDept] = useState(preConfigDeptData)

  // useEffect(() => {
  //   if (userData?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER && userData?.crew_member_profile?.company_department?.length > 0) {
  //     if(Array.isArray(userData?.crew_member_profile?.company_department)){
  //       userData?.crew_member_profile?.company_department?.map((item: any) => {
  //         item.label = item?.dep;
  //         item.value = item?.dep;
  //       })
  //       setAllDept(userData?.crew_member_profile?.company_department)
  //     }
  //   }
  // }, [userData])

  return (
    <div className="table-filter-setup mb-3 mb-lg-0">
      <div className="row align-items-center justify-content-between">
        <div className="col-md-12 col-lg-7 col-xl-6 col-xxl-5">
          <div className="select-fileter d-grid d-sm-flex align-items-center gap-4">
            {/* <div className="form_group w-100 mb-0 mb-sm-4 black-input" style={{ maxWidth: '230px' }}>
              <div className="input_box">
                <label htmlFor="country">Location</label>
                <div className="search-button d-flex align-items-center gap-3">
                  <Search
                    id='country'
                    placeholder={'City or Postcode'}
                    value={props?.data?.location || ''}
                    onChange={(value: any) => props?.updateValue?.('location', value)}
                    imgStyle={{ backgroundColor: 'transparent' }}
                  />
                </div>
              </div>
            </div> */}
            <CustomGooglePlaceSearch
              onApply={props.onLocationSearch}
              isSupplier
              data={props.data}
            />
            <DropDown
              className="arrow-fill w-100 drop-down-over-vis"
              data={allDept}
              placeholder='All departments'
              label='Filter by department'
              selectedItems={setDropdownSelectedValue(allDept, props?.data?.department)}
              onSelect={(data) => props?.updateValue?.('department', data?.label)}
            />
          </div>
        </div>

        <div className="col-md-12 col-lg-4 col-xl-6 coll-xxl-5">
          <div className="right-side-showing d-flex align-items-center justify-content-center justify-content-lg-end gap-3 mb-4 mt-3 mt-sm-0">
            <div className="clear-all" onClick={props?.onClear}>Clear all</div>
            <p className="mb-0 fw-bold">{`Showing ${props?.showing} of ${props?.count || 0} results`}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliersFilter