import { Table } from "react-bootstrap";
import Button from "../global/ui/Button";
import DropDown from "../global/ui/dropDown";
import { BUTTON_TYPE } from "../types";
import { placeholder } from "../utils/constants";
import { useState } from "react";
import InputField from "../lib/input";
// import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { preConfigDeptData } from "../data/data";
const Test = (props: any) => {
  const [isShow, setIsShow] = useState(false)
  const [isDropDownDots, setIsDropDownDots] = useState(false)

  const [searchValue, setSearchValue] = useState("")
  const timeValue: Date = new Date("01/01/2021 08:30 AM");
  const minTime: Date = new Date("01/02/2021 01:00 AM");
  const maxTime: Date = new Date("01/02/2021 05:00 AM");
  return (
    // <div className="main-section">
    //   <div className='container py-5'>
    //     <div className='row'>
    //       <div className='col-md-4'>
    //         <div className="card bg-transparent border border-white mb-3">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">input radio button without button design</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className='input-radio-select-without-radio-button'>
    //               <div className="form-check text-start">
    //                 <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioDisabled1" />
    //                 <label className="form-check-label mb-0" htmlFor="flexRadioDisabled1">
    //                   <h5>Production Company</h5>
    //                   <p>Lorem ipsum dolor sit amet consectetur. Lectus accumsan cursus penatibus pellentesque morbi aliquam imperdiet ut.</p>
    //                 </label>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="card bg-transparent border border-white mb-3">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Top bar with dropdonw dots</h6>
    //           </div>
    //           <div className="card-body bg-white">
    //             <div className='cross-and-dropdonw-selct d-flex justify-content-between align-items-center mb-0'>
    //               <img src={"/assets/images/dismiss-cross-black.svg"} alt="" className="img-cross-icon" />
    //               <div className='filter-select'>
    //                 <img src={"/assets/images/more-three-dots.svg"} className="main-drop-donwe" onClick={() => setIsDropDownDots((prvState) => !prvState)} alt="" />
    //                 {
    //                   <div className={`drop-down-dtost  ${isDropDownDots ? "d-block" : "d-none"} `}>
    //                     <ul className="d-grid list-unstyled pl-0 mb-0">
    //                       <li><img src={"/assets/images/deactivate-person.svg"} alt="" /> Deactivate</li>
    //                       <li><img src={"/assets/images/update-role.svg"} alt="" /> Update Role</li>
    //                       <li><img src={"/assets/images/Flag-red.svg"} alt="" /> Flag User</li>
    //                     </ul>
    //                   </div>
    //                 }
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="card bg-transparent border border-white mb-3">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Right side popup</h6>
    //           </div>
    //           <div className="card-body bg-white">
    //             <button className="common_btn btn btn-warning w-100 with-after-shadow shadow-none " onClick={() => setIsShow(true)}>Right side popup</button>
    //             {
    //               <div className={`right-side-popup-background  ${isShow ? "" : "d-none"} `}>
    //                 <div className="right-side-popup-fixed d-grid align-content-between gap-4">
    //                   <div className="right-side-popup-main-top ">
    //                     <div className="popup-right-side-top">
    //                       <div className='cross-and-dropdonw-selct d-flex justify-content-between align-items-center mb-0'>
    //                         <img src={"/assets/images/dismiss-cross-black.svg"} alt="" className="img-cross-icon" onClick={() => setIsShow(false)} />
    //                         <div className='filter-select'>
    //                           <img src={"/assets/images/more-three-dots.svg"} className="main-drop-donwe" onClick={() => setIsDropDownDots((prvState) => !prvState)} alt="" />
    //                           {
    //                             <div className={`drop-down-dtost  ${isDropDownDots ? "d-block" : "d-none"} `}>
    //                               <ul className="d-grid list-unstyled pl-0 mb-0">
    //                                 <li><img src={"/assets/images/activat-user-black.svg"} alt="" /> Activate User</li>
    //                                 <li><img src={"/assets/images/deactivate-person.svg"} alt="" /> Deactivate</li>
    //                                 <li><img src={"/assets/images/update-role.svg"} alt="" /> Update Role</li>
    //                                 <li><img src={"/assets/images/Flag-red.svg"} alt="" /> Flag User</li>
    //                               </ul>
    //                             </div>
    //                           }
    //                         </div>
    //                       </div>
    //                       <div className="profile-show-part text-center">
    //                         <img className="profile-img" src={"/assets/images/show-profile-on-table.png"} alt="" />
    //                         <h5>Abby Jones</h5>
    //                         <button className="perple-button mb-3">Head of Department</button>
    //                         <ul className="list-unstyled d-flex align-items-center justify-content-center">
    //                           <li><button className="btn btn-light-with-icon shadow-none"><img src={"/assets/images/link-icon.svg"} alt="" /> IMDb Profile</button></li>
    //                           <li><button className="btn btn-dark-button shadow-none"><img src={"/assets/images/chat-message-icon.svg"} alt="" /> IMDb Profile</button></li>
    //                         </ul>
    //                       </div>
    //                     </div>
    //                     <div className="bottom-tabs-wo">
    //                       <ul className="nav">
    //                         <li className="nav-item">
    //                           <a className="nav-link active" data-bs-toggle="pill" href="#about">About</a>
    //                         </li>
    //                         <li className="nav-item">
    //                           <a className="nav-link" data-bs-toggle="pill" href="#work">Work</a>
    //                         </li>
    //                         <li className="nav-item">
    //                           <a className="nav-link" data-bs-toggle="pill" href="#references">References</a>
    //                         </li>
    //                       </ul>
    //                       <div className="tab-content pt-4">

    //                         <div className="tab-pane active" id="about">
    //                           <ul className="profile-information-list">
    //                             <li className="align-items-center">
    //                               <img src={"/assets/images/dimand-icon-perpel.svg"} alt="" />
    //                               12 Years Experience
    //                             </li>
    //                             <li className="align-items-center">
    //                               <img src={"/assets/images/Location-icon-perple.svg"} alt="" />
    //                               Teddington  <br />United Kingdom TW11
    //                             </li>
    //                             <li>
    //                               <img src={"/assets/images/user-icon-perple.svg"} alt="" />
    //                               I have worked in the Hair and Make Up department for over 10 years. I have experience running crowd teams, designing, prosthetics, and working with key cast. I have just finished working on a large scale period drama and am looking for my next role.
    //                             </li>
    //                             <li className="align-items-center">
    //                               <img src={"/assets/images/Briefcase-perple-icon.svg"} alt="" />
    //                               <div className="like-togl">
    //                                 <span>Hair & Make Up</span>
    //                                 <span>Prosthetics</span>
    //                                 <span>Hair & Make Up</span>
    //                               </div>
    //                             </li>
    //                           </ul>
    //                         </div>

    //                         <div className="tab-pane fade" id="work">
    //                           <div className="work-tab-desi">
    //                             <h5>Abby’s Past Jobs</h5>
    //                             <div className="card work-card">
    //                               <div className="card-body">
    //                                 <h4>Romancing the Throne</h4>
    //                                 <ul className="profile-information-list">
    //                                   <li className="align-items-center">
    //                                     <img src={"/assets/images/user-icon-perple.svg"} alt="" />
    //                                     Crowd Supervisor
    //                                   </li>
    //                                   <li className="align-items-center">
    //                                     <img src={"/assets/images/Calendar-empty-icon-perple.svg"} alt="" />
    //                                     March 2022 - November 2022
    //                                   </li>
    //                                   <li className="align-items-center">
    //                                     <img src={"/assets/images/Briefcase-perple-icon.svg"} alt="" />
    //                                     <div className="like-togl">
    //                                       <span>Hair & Make Up</span>
    //                                       <span>Prosthetics</span>
    //                                     </div>
    //                                   </li>
    //                                 </ul>
    //                               </div>
    //                             </div>
    //                             <div className="card work-card">
    //                               <div className="card-body">
    //                                 <h4>Spirits In Time</h4>
    //                                 <ul className="profile-information-list">
    //                                   <li className="align-items-center">
    //                                     <img src={"/assets/images/user-icon-perple.svg"} alt="" />
    //                                     Crowd Supervisor
    //                                   </li>
    //                                   <li className="align-items-center">
    //                                     <img src={"/assets/images/Calendar-empty-icon-perple.svg"} alt="" />
    //                                     March 2022 - November 2022
    //                                   </li>
    //                                   <li className="align-items-center">
    //                                     <img src={"/assets/images/Briefcase-perple-icon.svg"} alt="" />
    //                                     <div className="like-togl">
    //                                       <span>Hair & Make Up</span>
    //                                       <span>Prosthetics</span>
    //                                     </div>
    //                                   </li>
    //                                 </ul>
    //                               </div>
    //                             </div>
    //                           </div>
    //                         </div>
    //                         <div className="tab-pane fade" id="references">
    //                           <div className="references-tabs">
    //                             <div className="references-tabs-card d-flex align-items-center justify-content-between mb-4">
    //                               <div className="left-side-references d-flex align-items-center">
    //                                 <img src={"/assets/images/profile-img.png"} className="rounded-circle" alt="" />
    //                                 <div className="contne-tab">
    //                                   <h6>Sandra Davey</h6>
    //                                   <p className="mb-0 perple-button">Exec Producer</p>
    //                                 </div>
    //                               </div>
    //                               <button className="btn btn-light-with-icon shadow-none">View Profile <img src={"/assets/images/link-icon.svg"} alt="" /></button>
    //                             </div>
    //                             <div className="references-tabs-card d-flex align-items-center justify-content-between mb-4">
    //                               <div className="left-side-references d-flex align-items-center">
    //                                 <img src={"/assets/images/profile-img.png"} className="rounded-circle" alt="" />
    //                                 <div className="contne-tab">
    //                                   <h6>Sandra Davey</h6>
    //                                   <p className="mb-0 perple-button">Exec Producer</p>
    //                                 </div>
    //                               </div>
    //                               <button className="btn btn-light-with-icon shadow-none">View Profile <img src={"/assets/images/link-icon.svg"} alt="" /></button>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="right-side-popup-button">
    //                     <ul className="d-flex list-unstyled gap-3 justify-content-between align-item-center w-100 mb-0">
    //                       <li className="w-100">
    //                         <button className="btn btn-white w-100">View Schedule</button>
    //                       </li>
    //                       <li className="w-100">
    //                         <button className="common_btn btn btn-warning w-100 with-after-shadow shadow-none">Assign Job</button>
    //                       </li>
    //                     </ul>
    //                   </div>
    //                 </div>

    //               </div>
    //             }
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-4'>
    //         <div className="card bg-transparent border border-white">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">input radio button with button design</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className='input-radio-select-with-radio-button'>
    //               <div className="form-check text-start">
    //                 <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioDisabled3" />
    //                 <label className="form-check-label mb-0 d-flex justify-content-between" htmlFor="flexRadioDisabled3">
    //                   <div className='tesxt-work'>
    //                     <h5>Silver Monthly </h5>
    //                     <p>Feature list one</p>
    //                     <p>Feature list two</p>
    //                   </div>
    //                   <h3>£59.95/mo.</h3>
    //                 </label>
    //               </div>
    //             </div>
    //             <div className='input-radio-select-with-radio-button'>
    //               <div className="form-check text-start">
    //                 <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioDisabled2" />
    //                 <label className="form-check-label mb-0 d-flex justify-content-between" htmlFor="flexRadioDisabled2">
    //                   <div className='tesxt-work'>
    //                     <h5>Silver Monthly </h5>
    //                     <p>Feature list one</p>
    //                     <p>Feature list two</p>
    //                   </div>
    //                   <h3>£59.95/mo.</h3>
    //                 </label>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-4'>
    //         <div className="card bg-transparent border border-white">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Button desing active</h6>
    //           </div>
    //           <div className="card-body">
    //             <p className="text-white">with shadow</p>
    //             <Button
    //               type={BUTTON_TYPE.TYPE_BUTTON}
    //               btnTxt={"Login"}
    //               //isLoading={isLoading}
    //               // disabled={isLoading}
    //               style={{ marginTop: "0px", width: "auto" }}
    //               onClick={() => alert('hi')}
    //               className={'btn btn-warning w-100 with-after-shadow shadow-none'}
    //             />
    //             <br />
    //             <br />
    //             <p className="text-white mb-2">without shadow</p>
    //             <Button
    //               type={BUTTON_TYPE.TYPE_BUTTON}
    //               btnTxt={"Login"}
    //               //isLoading={isLoading}
    //               // disabled={isLoading}
    //               style={{ marginTop: "0px", width: "auto" }}
    //               onClick={() => alert('hi')}
    //               className={'btn btn-warning w-100 shadow-none'}
    //             />
    //             <br />
    //             <br />
    //             <p className="text-white mb-2">disable button shadow</p>
    //             <Button
    //               type={BUTTON_TYPE.TYPE_BUTTON}
    //               btnTxt={"Login"}
    //               //isLoading={isLoading}
    //               // disabled={isLoading}
    //               style={{ marginTop: "0px", width: "auto" }}
    //               onClick={() => alert('hi')}
    //               className={'btn btn-warning w-100 shadow-none'}
    //               disabled
    //             />
    //             <div className="bg-white mt-4 p-4">
    //               <p className=" mb-2">Othter button</p>
    //               <button className="perple-button mb-3">Head of Department</button>
    //               <button className="btn btn-danger rounded-pill mb-3">Inactive</button>
    //               <button className="btn danger-button rounded-pill mb-3">Inactive</button>
    //               <button className="btn btn-success rounded-pill">Active</button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='col-md-4'>
    //         <div className="card bg-transparent border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Input design</h6>
    //           </div>
    //           <div className="card-body">
    //             <p className="text-white">without icon</p>
    //             <InputField type={'text'} name={'fullname'} label={'Full Name'} className="mb-4" placeholder={'Enter your email'} />
    //             <p className="text-white">with icon</p>
    //             <InputField type={'text'} name={'emailaddress'} label={'Email Address'} placeholder={'Enter email'} className="mb-4" />
    //             <div className="form_group  mb-4">
    //               <div className="input_box">
    //                 <label htmlFor="supplier_bio">Supplier bio</label>
    //                 <textarea name="supplier_bio" id="supplier_bio" placeholder="Enter short bio"></textarea>
    //               </div>
    //             </div>
    //             <div className="form_group w-100 mb-4">
    //               <div className="input_box">
    //                 <label htmlFor="country">Filter by department </label>
    //                 <select name="country" id="">
    //                   <option value="">All departments</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                 </select>
    //               </div>
    //             </div>
    //             <div className="form_group w-100 mb-4">
    //               <div className="input_box">
    //                 <label htmlFor="country">Filter by department </label>
    //                 <select name="country" id="" className="seend-arrow">
    //                   <option value="">All departments</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                 </select>
    //               </div>
    //             </div>
    //             <p className="text-white">Filter select</p>
    //             <DropDown className="dark-select" isMultiSelect={true} isClearable={false} data={preConfigDeptData}
    //               placeholder={placeholder.departmentSelect}
    //             />
    //             <DropDown className="dark-select arrow-fill" isMultiSelect={true} isClearable={false} data={preConfigDeptData}
    //               placeholder={placeholder.departmentSelect}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Input dark design</h6>
    //           </div>
    //           <div className="card-body">
    //             <p className="text-dark">without icon</p>
    //             <InputField type={'text'} name={'fullname'} label={'Production Company Name'} className="mb-4 black-input" placeholder={'Enter company name'} />
    //             <p className="text-dark">with icon</p>
    //             <InputField type={'text'} name={'emailaddress'} label={'Email Address'} placeholder={'Enter email'} className="mb-4 black-input" />
    //             <div className="form_group  mb-4 black-input">
    //               <div className="input_box">
    //                 <label htmlFor="supplier_bio">Supplier bio</label>
    //                 <textarea name="supplier_bio" id="supplier_bio" placeholder="Enter short bio"></textarea>
    //               </div>
    //             </div>
    //             <div className="form_group w-100 mb-4 black-input">
    //               <div className="input_box">
    //                 <label htmlFor="country">Filter by department </label>
    //                 <select name="country" id="">
    //                   <option value="">All departments</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                 </select>
    //               </div>
    //             </div>
    //             <div className="form_group w-100 mb-4 black-input">
    //               <div className="input_box">
    //                 <label htmlFor="country">Filter by department </label>
    //                 <select name="country" id="" className="seend-arrow">
    //                   <option value="">All departments</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                   <option value="">Option</option>
    //                 </select>
    //               </div>
    //             </div>
    //             <p className="text-dark">Filter select</p>
    //             <DropDown isMultiSelect={true} isClearable={false} data={preConfigDeptData}
    //               placeholder={placeholder.departmentSelect}
    //             />
    //             <DropDown className="arrow-fill" isMultiSelect={true} isClearable={false} data={preConfigDeptData}
    //               placeholder={placeholder.departmentSelect}
    //             />
    //             <p className="text-dark">input radio button simple</p>
    //             <div className="">
    //               <div className="form-check form-check-inline radio-design-simple radio-dark">
    //                 <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
    //                 <label className="form-check-label" htmlFor="inlineRadio1">PDF File</label>
    //               </div>
    //               <div className="form-check form-check-inline radio-design-simple radio-dark">
    //                 <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
    //                 <label className="form-check-label" htmlFor="inlineRadio2">CSV  File</label>
    //               </div>
    //             </div>

    //           </div>
    //         </div>
    //       </div>
    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">form-steps</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className="form-steps">
    //               <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center justify-content-center">
    //                 <li className="active"><span>1</span> Information</li>
    //                 <li><span>2</span> Location</li>
    //                 <li><span>3</span> Rates</li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Select currency</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className='slect-radio-like-tab'>
    //               <div className="form-check text-start mb-0">
    //                 <input className="form-check-input" type="radio" name="select_currency" id="gbp" />
    //                 <label className="form-check-label" htmlFor="gbp">
    //                   GBP
    //                 </label>
    //               </div>
    //               <div className="form-check text-start mb-0">
    //                 <input className="form-check-input" type="radio" name="select_currency" id="eur" />
    //                 <label className="form-check-label" htmlFor="eur">
    //                   EUR
    //                 </label>
    //               </div>
    //               <div className="form-check text-start mb-0">
    //                 <input className="form-check-input" type="radio" name="select_currency" id="usd" />
    //                 <label className="form-check-label" htmlFor="usd">
    //                   USD
    //                 </label>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="card-body bg-dark">
    //             <div className='slect-radio-like-tab dark-mode ms-auto'>
    //               <div className="form-check text-start mb-0">
    //                 <input className="form-check-input" type="radio" name="select_currency" id="gbp1" />
    //                 <label className="form-check-label" htmlFor="gbp1">
    //                   GBP
    //                 </label>
    //               </div>
    //               <div className="form-check text-start mb-0">
    //                 <input className="form-check-input" type="radio" name="select_currency" id="eur1" />
    //                 <label className="form-check-label" htmlFor="eur1">
    //                   EUR
    //                 </label>
    //               </div>
    //               <div className="form-check text-start mb-0">
    //                 <input className="form-check-input" type="radio" name="select_currency" id="usd1" />
    //                 <label className="form-check-label" htmlFor="usd1">
    //                   USD
    //                 </label>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>


    //       <div className='col-md-12'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Tabs</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className="bottom-tabs-wo">
    //               <ul className="nav">
    //                 <li className="nav-item">
    //                   <a className="nav-link active" data-bs-toggle="pill" href="#profile_information">Profile Information</a>
    //                 </li>
    //                 <li className="nav-item">
    //                   <a className="nav-link" data-bs-toggle="pill" href="#overtime_rates">Overtime Rates</a>
    //                 </li>
    //                 <li className="nav-item">
    //                   <a className="nav-link" data-bs-toggle="pill" href="#settings">Settings</a>
    //                 </li>
    //                 <li className="nav-item">
    //                   <a className="nav-link" data-bs-toggle="pill" href="#payment_info">Payment Info</a>
    //                 </li>
    //               </ul>
    //               <div className="tab-content">
    //                 <div className="tab-pane active" id="profile_information">
    //                   Profile Information
    //                 </div>
    //                 <div className="tab-pane fade" id="overtime_rates">
    //                   Overtime Rates
    //                 </div>
    //                 <div className="tab-pane fade" id="settings">
    //                   Settings
    //                 </div>
    //                 <div className="tab-pane fade" id="payment_info">
    //                   Payment Info
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">List type</h6>
    //           </div>
    //           <div className="card-body">
    //             <ul className="profile-information-list">
    //               <li>
    //                 <img src={"/assets/images/user-icon-perple.svg"} alt="" />
    //                 Barry Jones
    //               </li>
    //               <li>
    //                 <img src={"/assets/images/Mail-icon-perple.svg"} alt="" />
    //                 abbyjones@example.com <br />0456576788
    //               </li>
    //               <li>
    //                 <img src={"/assets/images/Location-icon-perple.svg"} alt="" />
    //                 12 Example St United Kingdom 2000
    //               </li>
    //               <li>
    //                 <img src={"/assets/images/Calendar-empty-icon-perple.svg"} alt="" />
    //                 Member since 19 March 2022
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">File upload</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className='input-type-file-multiple'>
    //               <div className="form_group file-upload black-input">
    //                 <div className="input_box">
    //                   <label htmlFor="fileuplaod"><img src={"/assets/images/circle-black-plus-icon.svg"} alt="" /></label>
    //                   <input type="file" name="fileuplaod" id="fileuplaod" placeholder="Enter short bio" />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Rate Section</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className='rate-section mb-4 text-center text-sm-start'>
    //               <h3>6th Day Rate</h3>
    //               <div className='d-grid d-sm-flex align-items-center justify-content-center justify-content-sm-between gap-3'>
    //                 <p className='perple-button mb-0'>1.5 times daily rate</p>
    //                 <button className='danger-button'><img src={"/assets/images/edit-pencil-edit.svg"} alt="" /> Update</button>
    //               </div>
    //             </div>
    //             <div className='rate-section mb-4 text-center text-sm-start'>
    //               <h3>6th Day Rate</h3>
    //               <div className='d-grid d-sm-flex align-items-center justify-content-center justify-content-sm-between gap-3'>
    //                 <p className='perple-button mb-0'>1.5 times daily rate</p>
    //                 <button className='danger-button'><img src={"/assets/images/edit-pencil-edit.svg"} alt="" /> Update</button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>


    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Rate Section</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className='payments-card-design d-flex gap-4'>
    //               <div className='payments-card text-white'>
    //                 <div className='payments-card-header d-flex align-items-center justify-content-between gap-3'>
    //                   <p className='mb-0'><span></span> Default Card</p>
    //                   <button className='btn-edit-top'><img src={"/assets/images/edit.svg"} alt="" /></button>
    //                   <button className='btn-delete-only-icon'><img src={"/assets/images/delete-btn-red.svg"} alt="" /></button>
    //                 </div>
    //                 <div className='payments-card-number d-flex align-items-center gap-3'>
    //                   <span>****</span>
    //                   <span>****</span>
    //                   <span>****</span>
    //                   <span>2847</span>
    //                 </div>
    //                 <div className='payments-card-footer d-flex align-items-center'>
    //                   <div className='card-holder'>
    //                     <p>Card holder</p>
    //                     <h6>Mike Smith</h6>
    //                   </div>
    //                   <div className='card-holder'>
    //                     <p>Expire date</p>
    //                     <h6>06/21</h6>
    //                   </div>
    //                 </div>
    //               </div>

    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='col-md-5'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Rate Section</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className="search-button d-flex align-items-center gap-3">
    //               <div className="input-group">
    //                 <input type="text" className="form-control border-0 shadow-none" placeholder="Search by name email or phone" aria-label="Amount (to the nearest dollar)" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
    //                 <span className="input-group-text bg-transparent border-0">
    //                   {searchValue ?
    //                     <img src="/assets/images/cross-cercle-fill.svg" onClick={() => setSearchValue('')} alt="" />
    //                     :
    //                     <img src={"/assets/images/search-black.svg"} alt="" />
    //                   }
    //                 </span>
    //               </div>
    //               <button className="btn btn-warning btn-sm shadow-none"><img src={"/assets/images/plus-icon.svg"} alt="" /> Add Crew</button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-12'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Table design</h6>
    //           </div>
    //           <div className="card-body">
    //             <Table responsive className="">
    //               <thead>
    //                 <tr>
    //                   <th>
    //                     <div className="input-check-box">
    //                       <input type="checkbox" name="" id="select_table" className="d-none" />
    //                       <label htmlFor="select_table"></label>
    //                     </div>
    //                   </th>
    //                   <th></th>
    //                   <th>Name</th>
    //                   <th>Email</th>
    //                   <th>Phone</th>
    //                   <th>Address</th>
    //                   <th>Department</th>
    //                   <th>Role</th>
    //                   <th>Status</th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 <tr>
    //                   <td>
    //                     <div className="input-check-box">
    //                       <input type="checkbox" name="" id="select_table" className="d-none" />
    //                       <label htmlFor="select_table"></label>
    //                     </div>
    //                   </td>
    //                   <td>
    //                     <div className="d-flex align-items-center gap-4">
    //                       <img src={"/assets/images/table-img.png"} alt="" />

    //                     </div>
    //                   </td>
    //                   <td><p className="mb-0">Abby jones</p></td>
    //                   <td>jackson.graham@example.com</td>
    //                   <td>308.555.0121</td>
    //                   <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
    //                   <td>Hair & Make Up</td>
    //                   <td><button className="perple-button">Head of Department</button></td>
    //                   <td><button className="btn btn-success rounded-pill">Active</button></td>
    //                 </tr>
    //                 <tr>
    //                   <td>
    //                     <div className="input-check-box">
    //                       <input type="checkbox" name="" id="select_table" className="d-none" />
    //                       <label htmlFor="select_table"></label>
    //                     </div>
    //                   </td>
    //                   <td>
    //                     <div className="d-flex align-items-center gap-4">
    //                       <img src={"/assets/images/table-img.png"} alt="" />

    //                     </div>
    //                   </td>
    //                   <td><p className="mb-0">Abby jones</p></td>
    //                   <td>jackson.graham@example.com</td>
    //                   <td>308.555.0121</td>
    //                   <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
    //                   <td>Hair & Make Up</td>
    //                   <td><button className="perple-button">Head of Department</button></td>
    //                   <td><button className="btn btn-success rounded-pill">Active</button></td>
    //                 </tr>
    //               </tbody>
    //             </Table>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-12'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Table Filter section</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className="table-filter-setup">
    //               <div className="row">
    //                 <div className="col-md-12 col-lg-6">
    //                   <div className="select-fileter d-flex align-items-center gap-4">
    //                     <div className="form_group w-100 mb-4 black-input">
    //                       <div className="input_box">
    //                         <label htmlFor="country">Filter by department </label>
    //                         <select name="country" id="" className="seend-arrow">
    //                           <option value="">All departments</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                         </select>
    //                       </div>
    //                     </div>
    //                     <div className="form_group w-100 mb-4 black-input">
    //                       <div className="input_box">
    //                         <label htmlFor="country">Filter by role</label>
    //                         <select name="country" id="" className="seend-arrow">
    //                           <option value="">All roles</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                         </select>
    //                       </div>
    //                     </div>
    //                     <div className="form_group w-100 mb-4 black-input">
    //                       <div className="input_box">
    //                         <label htmlFor="country">Filter by status</label>
    //                         <select name="country" id="" className="seend-arrow">
    //                           <option value="">All statuses</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                           <option value="">Option</option>
    //                         </select>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-12 col-lg-6">
    //                   <div className="right-side-showing d-flex align-items-center justify-content-end gap-3">
    //                     <p className="mb-0 fw-bold">Showing</p>
    //                     <div className="form_group w-100 black-input ">
    //                       <div className="input_box">
    //                         <select name="country" id="" className="seend-arrow w-100 p-3">
    //                           <option value="">10</option>
    //                           <option value="">20</option>
    //                           <option value="">30</option>
    //                         </select>
    //                       </div>
    //                     </div>
    //                     <p className="mb-0 fw-bold">of 24 results</p>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-12'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">after login top section</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className="main-top-bar-heading px-4 d-flex align-items-center justify-content-between gap-3">
    //               <h3 className="fw-bold">Production Crew </h3>
    //               <div className="search-button d-flex align-items-center gap-3">
    //                 <div className="input-group">
    //                   <input type="text" className="form-control border-0 shadow-none" placeholder="Search by name email or phone" aria-label="Amount (to the nearest dollar)" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
    //                   <span className="input-group-text bg-transparent border-0">
    //                     {searchValue ?
    //                       <img src="/assets/images/cross-cercle-fill.svg" onClick={() => setSearchValue('')} alt="" />
    //                       :
    //                       <img src={"/assets/images/search-black.svg"} alt="" />
    //                     }
    //                   </span>
    //                 </div>
    //                 <button className="btn btn-warning btn-sm shadow-none"><img src={"/assets/images/plus-icon.svg"} alt="" /> Add Crew</button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>




    //       <div className='col-md-4'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Selft tabs</h6>
    //           </div>
    //           <div className="card-body">
    //             <div className="liket-active-tab mb-4">
    //               <ul className="list-unstyled d-flex align-item-center mb-0">
    //                 <li className="active">My Production</li>
    //                 <li>Explore Crew</li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='col-md-8'>
    //         <div className="card border border-white mb-4">
    //           <div className="card-header text-dardk bg-white">
    //             <h6 className="mb-0">Alert</h6>
    //           </div>
    //           <div className="card-body bg-dark">
    //             <div className="custom-alert d-flex align-items-center mb-4">
    //               <img src={"/assets/images/yellow-alert.svg"} alt="" />
    //               <div className="content-alert">
    //                 <h6 className="fw-bold">User Already Registered</h6>
    //                 <p>This user is already part of the production. Please try again.</p>
    //               </div>
    //             </div>
    //             <div className="custom-alert custom-danger-alert d-flex align-items-center mb-4">
    //               <img src={"/assets/images/red-alert.svg"} alt="" />
    //               <div className="content-alert">
    //                 <h6 className="fw-bold">Email ID Invalid</h6>
    //                 <p>Email ID is invalid please enter a valid email and try again.</p>
    //               </div>
    //             </div>
    //             <div className="custom-alert custom-succsess-alert d-flex align-items-center mb-4">
    //               <img src={"/assets/images/green-alert.svg"} alt="" />
    //               <div className="content-alert">
    //                 <h6 className="fw-bold">Email ID Invalid</h6>
    //                 <p>Email ID is invalid please enter a valid email and try again.</p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>


    //     </div>
    //   </div>


    // </div >
    <div>
      {/* <TimePickerComponent placeholder="Select a time"
        value={timeValue}
        min={minTime}
        max={maxTime}
        format="HH:mm"
        step={60}></TimePickerComponent> */}
    </div>
  )
}
export default Test;