import React, { useContext, useEffect, useState } from 'react'
import useWebSocket from '../../hooks/useWebSocket';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { MyContext } from '../../context';
import ChatWindowHeader from './ChatWindowHeader';
import { createFullName, emptyFunction, getFileExtension, showToast, urlify } from '../../utils';
import UserMiniProfile from './UserMiniProfile';
import ViewSupplierDetails from '../suppliers/ViewSupplierDetails';
import { endPoints } from '../../utils/endpoints';
import { ApiResponseType, MESSAGE_STATUS, MESSAGE_TEXT, PERMISSION_TYPE, REQ_STATUS, ROLE_TYPE, USER_TYPE } from '../../types';
import { makeDeleteCall, makeGetCall, makeNextPageCall, makePostCall } from '../../effects/requests';
import ViewCrewDetails from '../crewMembers/ViewCrewDetails';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DateUtils } from '../../utils/dateUtils';
import Logo from '../../components/commonComponent/ProfileImage';
import moment from 'moment';
import ChatInput from '../messages/ChatInput';
import FullViewImage from '../../components/modal/fullViewImage';
import ViewPdf from '../../components/modal/ViewPdf';

interface IChatWindow {
    chatData: any;
    acceptRejectRequest: (value: any) => void;
}

const ChatWindow: React.FC<IChatWindow> = (props) => {

    const { chatData } = props;

    const location = useLocation();
    const parsed: any = queryString.parse(location?.search);
    const { userData } = useContext(MyContext);

    const [showUserProfile, setShowUserProfile] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [allData, setAllData] = useState<Array<any>>([]);
    const [nextUrl, setNextUrl] = useState<any>(null);
    const [showDeleteOption, setShowDeleteOption] = useState<boolean>(false);
    const [selectedPdf, setSelectedPdf] = useState<any>(null)
    const [enlargeImage, setEnlargeImage] = useState<any>(null);
    const [selectedFileName, setSelectedFileName] = useState<any>('');
    const [fullView, setFullView] = useState<boolean>(false);
    const [showDownload, setShowDownload] = useState(true);

    const [isRoomOpen, errorMessage, onSendMessage, onClose] = useWebSocket({
        roomID: parsed?.room || '',
        userID: userData?.id || '',
        initializeWebSocket: true,
        onMessage: (data) => {
            if (data?.message || data?.attachment_url) {
                setAllData((prevState) => [data, ...prevState]);
            } else if (data?.status && parsed?.room) {
                fetchUserMessages();
            }
        }
    });

    const fetchUserDetail = (userId: any) => {
        const urls = `${endPoints.USER}${userId}/`
        makeGetCall(urls, null, false, true).then((res: ApiResponseType<any>) => {
            setUserProfile(res?.data)
        }).catch(emptyFunction)
    }

    const fetchUserMessages = (query?: any) => {
        const params = {
            room_id: parsed?.room,
            search: query || ''
        }
        makeGetCall(endPoints.chat_messages_url, params, false, true)
            .then((res: ApiResponseType<any>) => {
                setAllData(res?.results)
                setNextUrl(res.next)
            })
            .catch(emptyFunction)
    }

    const fetchMoreData = () => {
        if (nextUrl) {
            makeNextPageCall(nextUrl).then((res: ApiResponseType<any>) => {
                const data = [...allData, ...res.results];
                setAllData(data)
                setNextUrl(res?.next);
            }).catch(emptyFunction);
        }
    }

    const onMessageDelete = (messageId: string) => {
        const param = {
            message_id: messageId
        }
        makeDeleteCall(endPoints.DELETE_MESSAGE, param)
            .then((res: ApiResponseType<any>) => {
                if (res?.status?.code === 200) {
                    fetchUserMessages();
                    showToast(res?.status?.message, "success")
                }
            })
            .catch(emptyFunction)
    }

    const setImg = (item: any) => {
        if (item?.sent_by_id !== chatData?.other_user?.id) {
            if (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
                return userData?.profile_image
            } else {
                return userData?.profile_image
            }
        } else {
            if (chatData?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
                return chatData?.other_user?.profile_image
            } else if (chatData?.other_user?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
                return chatData?.other_user?.profile_image
            } else if (chatData?.other_user?.user_type === ROLE_TYPE.TYPE_CREW) {
                return chatData?.other_user?.profile_image
            }
        }
        return null
    }

    const handleDownload = (fileURL: any, filename: any) => {
        const pdfAcceptType = ["pdf", 'PDF'];
        const imgAcceptType = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
        const common = [...imgAcceptType, ...pdfAcceptType];
        const extension = getFileExtension(fileURL);

        if (common.includes(extension)) {
            const name = createFullName(userData?.first_name, userData?.last_name);
            const aws_url = fileURL;
            const url = pdfAcceptType.includes(extension) ? endPoints.WATER_MARK : endPoints.WATER_MARK_IMAGE;
            const payload = { name, aws_url };

            makePostCall(url, payload).then((res: ApiResponseType<any>) => {
                const watermarkedURL = res?.data?.watermarked_url || fileURL;
                downloadFile(watermarkedURL, filename);
            });
        } else {
            downloadFile(fileURL, filename);
        }
    };

    const downloadFile = (fileURL: any, filename: any) => {
        // Create a virtual anchor element
        const anchor = document.createElement('a');
        anchor.href = fileURL;

        // Set the download attribute to specify the file name
        anchor.download = filename;

        // Append the anchor to the body
        document.body.appendChild(anchor);

        // Trigger a click on the anchor to initiate the download
        anchor.click();

        // Remove the anchor from the body
        document.body.removeChild(anchor);
    }

    const handleSendMessage = (msg: string, attachment_url?: string, attachment_type?: string, fileName?: any) => {
        if (userData && isRoomOpen) {
            onSendMessage({
                message: msg || "",
                attachment_url: attachment_url,
                attachment_type: attachment_type,
                receiver_id: chatData?.other_user?.id || '',
                attachment_name: fileName || ''
            })
        }
    }

    useEffect(() => {
        if (parsed?.room) {
            setShowUserProfile(true);
            fetchUserMessages();
        }
        return () => onClose()
    }, [parsed?.room])

    useEffect(() => {
        if (chatData?.other_user?.id) {
            fetchUserDetail(chatData?.other_user?.id)
        }
    }, [chatData?.other_user?.id]);

    const isCurrentUser = userData?.id === chatData?.user_two;

    return (
        <>
            <div className={showUserProfile ? 'message-right-side sidebar' : 'message-right-side'}>
                <ChatWindowHeader
                    onClose={onClose}
                    onSearched={(query) => fetchUserMessages(query)}
                    chatData={chatData}
                    acceptRejectRequest={props.acceptRejectRequest}
                    isCurrentUser={isCurrentUser}
                />
                <div className='message-right-side-chat-start'>
                    <div className='message-setup d-grid gap-5'>
                        <div
                            className={`all-chents-inner gap-5 scrollAsd`}
                            id="scrollableID"
                        >
                            <InfiniteScroll
                                dataLength={allData?.length}
                                next={fetchMoreData}
                                style={{ display: 'flex', flexDirection: 'column-reverse', overflowAnchor: 'none', contentVisibility: "auto" }} //To put endMessage and loader to the top.
                                inverse={true}
                                hasMore={nextUrl ? true : false}
                                loader={null}
                                scrollableTarget="scrollableID"
                            >
                                {allData?.map((message, idx: any) => {
                                    return (
                                        <>
                                            {DateUtils.getDateWithTime(message?.created_at, 'll') !== DateUtils.getDateWithTime(allData?.[idx - 1]?.created_at, 'll') && idx !== 0 &&
                                                <p className='date-time-top text-center'>{DateUtils.getDateWithTime(allData?.[idx - 1]?.created_at, 'll')}</p>
                                            }
                                            <div className={`left-side-message d-flex position-relative gap-3 ${message?.sent_by_id !== chatData?.other_user?.id ? "resive-other-message ms-auto" : "me-auto"}`} key={idx}>
                                                {message?.sent_by_id !== chatData?.other_user?.id &&
                                                    <div className="mt-3 pt-4">
                                                        <div className='filter-select message-delete'>
                                                            <img
                                                                src={"/assets/images/three-dots-more.svg"}
                                                                id={idx}
                                                                className="main-drop-donwe"
                                                                alt=""
                                                                onClick={() => setShowDeleteOption((prevState) => !prevState)}
                                                            />
                                                            {showDeleteOption &&
                                                                <div style={showUserProfile ? { left: '10px' } : {}} className={`drop-down-dtost pt-3 pb-3`}>
                                                                    <ul className="d-grid list-unstyled pl-0 mb-0">
                                                                        <li onClick={() => onMessageDelete(message?.id)}>
                                                                            <img src={"/assets/images/Delete-icon-red.svg"} alt="" />Delete
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {/* sent delivered read need to be added ahead for status in above class */}
                                                <div className="w-100">
                                                    <div className={`profile-name-time d-flex justify-content-between align-items-center ${message?.sent_by_id !== chatData?.other_user?.id ? "flex-row-reverse" : ""}`}>
                                                        <div className='message-name-logo  d-flex gap-3 align-items-center'>
                                                            {setImg(message)
                                                                ?
                                                                <img src={setImg(message)} alt="user" />
                                                                :
                                                                <Logo
                                                                    style={{ width: '30px', height: '30px' }}
                                                                    data={message?.sent_by_id !== chatData?.other_user?.id
                                                                        ?
                                                                        userData?.first_name?.[0] || userData?.last_name?.[0]
                                                                        :
                                                                        chatData?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER
                                                                            ?
                                                                            chatData?.other_user?.supplier_profile?.company_name?.[0]
                                                                            :
                                                                            chatData?.other_user?.first_name?.[0]
                                                                            ||
                                                                            chatData?.other_user?.last_name?.[0]}
                                                                />
                                                            }
                                                            <p className='mb-0'>
                                                                {message?.sent_by_id !== chatData?.other_user?.id
                                                                    ?
                                                                    "You"
                                                                    :
                                                                    chatData?.other_user?.first_name + " " + chatData?.other_user?.last_name
                                                                }
                                                                {(chatData?.other_user?.verified && chatData?.other_user?.user_type === ROLE_TYPE.TYPE_CREW && message?.sent_by_id === chatData?.other_user?.id) &&
                                                                    <img style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-2px' }} src={"/assets/images/yellow_check_mark.png"} alt="" />
                                                                }
                                                            </p>
                                                        </div>
                                                        <p className='mb-0 date-tme'>{moment(message?.created_at).format('LLL')}</p>
                                                    </div>
                                                    <div className={`left-side-message-text ${message?.attachment_url ? "" : ""} ${message.message && message?.sent_by_id !== chatData?.other_user?.id ? "ms-0" : ""} `}>
                                                        <p>
                                                            {message?.message?.length > 0 &&
                                                                <p className={message?.sent_by_id !== chatData?.other_user?.id ? "" : "text-white"} dangerouslySetInnerHTML={{ __html: urlify(message?.message?.replace(/\n/g, "<br/>")) || '' }}></p>
                                                            }
                                                            {message?.attachment_url && message?.attachment_type === 'image' && (
                                                                <img
                                                                    src={message.attachment_url}
                                                                    alt="sended"
                                                                    id={idx}
                                                                    onClick={() => {
                                                                        setFullView(true);
                                                                        setEnlargeImage(message.attachment_url);
                                                                        setSelectedFileName(message?.attachment_name)
                                                                    }}
                                                                />
                                                            )}
                                                            {message?.attachment_url && message?.attachment_type === 'video' &&
                                                                <>
                                                                    <video width="100%" height="240" controls={true} controlsList="download">
                                                                        <source src={message.attachment_url}></source>
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                    <p
                                                                        className={message?.sent_by_id !== chatData?.other_user?.id ? "text-start fw-bold" : "fw-bold"}
                                                                    >
                                                                        <a
                                                                            href="#"
                                                                            onClick={() => handleDownload(message.attachment_url, message?.attachment_name || message?.attachment_url.substring(message.attachment_url.lastIndexOf('/') + 1))}
                                                                        >
                                                                            {message.attachment_url.substring(message.attachment_url.lastIndexOf('/') + 1)}
                                                                            <img
                                                                                src={message?.sent_by_id !== chatData?.other_user?.id ? "/assets/images/arrow-download-black.svg" : "/assets/images/arrow-download-white.svg"}
                                                                                className="donwnload-arrow"
                                                                                alt=""
                                                                            />
                                                                        </a>
                                                                    </p>
                                                                </>
                                                            }
                                                            {message?.attachment_url && (message?.attachment_type === 'application' || message?.attachment_type === 'pdf') &&
                                                                (<div className='left-side-message-text file-send ms-0'>
                                                                    <div className='file-send-div d-flex justify-content-between align-items-center gap-3'>
                                                                        <div
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedPdf(message.attachment_url)
                                                                                setSelectedFileName(message?.attachment_name)
                                                                            }}
                                                                            className='file-name d-flex align-items-center gap-3 text-dark fw-bold'
                                                                        >
                                                                            <img src="/assets/images/document-black.svg" width='16px' alt="" />
                                                                            {message?.attachment_name || message?.attachment_url.substring(message.attachment_url.lastIndexOf('/') + 1)}
                                                                        </div>
                                                                        <a href="#" onClick={() => handleDownload(message.attachment_url, message?.attachment_name || message?.attachment_url.substring(message.attachment_url.lastIndexOf('/') + 1))}> <img src="/assets/images/arrow-download-black.svg" width='20px' alt="" /></a>
                                                                    </div>
                                                                </div>)}
                                                        </p>
                                                    </div>
                                                    {message?.sent_by_id !== chatData?.other_user?.id &&
                                                        <div>
                                                            {message?.status === MESSAGE_STATUS.TYPE_READ
                                                                ?
                                                                MESSAGE_TEXT.TYPE_READ
                                                                :
                                                                message?.status === MESSAGE_STATUS.TYPE_SENT
                                                                    ?
                                                                    MESSAGE_TEXT.TYPE_SENT
                                                                    :
                                                                    MESSAGE_TEXT.TYPE_DELIEVRED
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </InfiniteScroll>
                        </div>
                        <div className='all-text-write-here'>
                            {(chatData?.status === REQ_STATUS.PENDING) &&
                                <div className="h-100 d-flex align-items-center justify-content-center text-danger">
                                    <img style={{ marginRight: '10px', }} src="/assets/images/error-icon.svg" />
                                    Message request is pending
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="chatBar-wrap">
                    {chatData?.status !== REQ_STATUS.PENDING &&
                        <ChatInput
                            handleSendMessage={handleSendMessage}
                        />
                    }
                </div>
            </div>
            {!showUserProfile &&
                <UserMiniProfile
                    chatData={chatData}
                    onToggle={() => setShowUserProfile((prevState) => !prevState)}
                />
            }
            {(showUserProfile && chatData?.other_user?.user_type === USER_TYPE.TYPE_SUPPLIER) &&
                <ViewSupplierDetails
                    visible={showUserProfile}
                    onClose={() => setShowUserProfile(false)}
                    supplierDetails={userProfile}
                    onFlag={() => alert('To be implemented')}
                    sideBarCss
                    hideMessage
                />
            }
            {(showUserProfile && chatData?.other_user?.user_type === USER_TYPE.TYPE_CREW) &&
                <ViewCrewDetails
                    visible={showUserProfile}
                    onClose={() => setShowUserProfile(false)}
                    crewDetails={userProfile}
                    onAction={emptyFunction}
                    type={parsed?.user_type}
                    getCrewMem={() => { }}
                    sideBarCss
                    hideMessage
                    hideBtn
                />
            }
            {fullView &&
                <FullViewImage
                    show={fullView}
                    closePopup={() => {
                        setFullView(false);
                        setShowDownload(true);
                        setSelectedFileName('')
                    }}
                    enlargeImage={enlargeImage}
                    showDownload={showDownload}
                    onDownload={() => handleDownload(enlargeImage, selectedFileName)}
                />
            }
            {selectedPdf &&
                <ViewPdf
                    onClose={() => {
                        setSelectedPdf(null);
                        setSelectedFileName('');
                    }}
                    file={selectedPdf}
                    onDownload={() => handleDownload(selectedPdf, selectedFileName)}
                />
            }
        </>
    )
}

export default ChatWindow