import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../../components/modal/changePassword";
import DeleteProfile from "../../components/modal/DeleteProfile";
import Faq from "../../components/modal/Faq";
import LegalsModal from "../../components/modal/LegalsModal";
import { ownerSettingData, settingsData, suppSettingData } from "../../data/data";
import { makeDeleteCall, makeGetCall, makePatchCall, makePutCall } from "../../effects/requests";
import { cookieService } from "../../services";
import { ApiResponseType, PERMISSION_TYPE, ROLE_TYPE, SETTINGS_ITEMS_TYPE } from "../../types";
import { defaultAdminMail } from "../../utils/constants";
import { endPoints } from "../../utils/endpoints";
import { createFullName, emptyFunction, showToast } from "../../utils";
import UpdateRole from "./UpdateRole";
import { MyContext } from "../../context";
import ChangeOwnerShipModal from "../../components/modal/ChangeOwnerShipModal";
import ArchiveAndDuplicateProd from "../../components/modal/ArchiveAndDuplicateProd";

const Setting = (props: any) => {

  const navigate = useNavigate();

  const { userData, updateUserData, updateCompanyData } = useContext(MyContext)
  const isProductionOwner = (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? true : false

  const [actionType, setActionType] = useState<any>(null)
  const [adminInfo, setAdminInfo] = useState<any>(null)
  const [selectedRole, setSelectedRole] = useState<any>(null)
  const [roleList, setRoleList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleUpdatePass = (data: any) => {
    setActionType(null)
    makePutCall(endPoints.PASSWORD, data).then((res: ApiResponseType<any>) => {
      if (res?.status?.code == 200) {
        showToast(res?.status?.message, 'success')
      }
      else {
        showToast(res?.status?.message, 'error')
      }
    })
  }

  //This method allow us to delete user porfile
  const handleDeleteAcc = () => {
    setActionType(null)
    makeDeleteCall(endPoints.PROFILE).then(clearAll).catch(clearAll)
  }

  const handleCancelSubs = () => {
    setActionType(null)
    makeDeleteCall(endPoints.SUBSCRIPTION).then((res: ApiResponseType<any>) => {
      if (res?.status?.code == 200) {
        showToast("Subscription cancelled successfully", 'success')
      }
      else {
        showToast(res?.status?.message, 'error')
      }
    })
  }

  const fetchAdminInfo = () => {
    makeGetCall(endPoints.ADMIN_CONTACT).then((res: ApiResponseType<any>) => {
      setAdminInfo(res.data)
    })
  }

  const updateRole = () => {
    setShowConfirmModal(false);
    const payload = {
      user_id: selectedRole?.id || '',
      production_company_id: userData?.production_id || ''
    }
    makePatchCall(endPoints.OWNER_SHIP, payload).then((response) => {
      makeDeleteCall(endPoints.LOGOUT, { device_type: 'WEB' }).then(clearAll).catch(clearAll)
    })
  }

  const clearAll = () => {
    updateUserData(null)
    cookieService.removeAllCookies();
    navigate('/sign-in');
  }

  const getProductionUser = () => {
    const params = {
      production_company_name: userData?.production_company_name || '',
      user_type: ROLE_TYPE.TYPE_CREW
    }
    makeGetCall(endPoints.USERLIST, params).then((res: ApiResponseType<any>) => {
      if (res?.results?.length > 0) {
        res?.results?.map((item: any) => {
          item.label = createFullName(item?.first_name || '', item?.last_name || '');
          item.value = item.id;
        })
        setRoleList(res.results);
      }
    })
  }

  useEffect(() => {
    if (isProductionOwner) getProductionUser()
  }, [userData?.id])

  useEffect(fetchAdminInfo, []);

  const getCompanyNames = () => {
    makeGetCall(endPoints.USER_PRODUCTION, null, false, true).then((res) => {
      if (res?.results?.length > 0) {
        const data = res?.results?.map((item: any) => {
          if (item?.company_department && typeof item?.company_department == 'string') {
            item.company_department = JSON.parse(item?.company_department)
          }
          return item
        })
        updateCompanyData(data);
      }
    }).catch(emptyFunction)
  }

  const allData = userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? [...settingsData, ...suppSettingData] : [...settingsData, ...ownerSettingData];

  return (
    <div className="tab-pane active" id="settings">
      <div className="setting-policy-list d-grid gap-3 pb-4 mb-3">
        {allData.map((item, idx) => {
          if (item?.type === SETTINGS_ITEMS_TYPE.CANCEL_SUBSCRIPTION && !props?.userData?.active_subscription) {
            return null
          }
          return (
            <div key={idx} className="setting-policy-design d-flex align-items-center justify-content-between" onClick={() => setActionType(item.type)}>
              <div className="d-flex gap-4 align-items-center">
                <img style={{ width: '20px', height: '20px' }} src={item.icon} alt={item.label} />
                {item?.type === SETTINGS_ITEMS_TYPE.TYPE_CONTACT_ADMIN
                  ?
                  <a className="a_tag_style" href={`mailto:${adminInfo?.email || defaultAdminMail}`}>{item.label}</a>
                  :
                  <p className='mb-0' style={(item.type === SETTINGS_ITEMS_TYPE.TYPE_DELETE_PROFILE || item.type === SETTINGS_ITEMS_TYPE.TYPE_CHANGE_OWNERSHIP || item.type === SETTINGS_ITEMS_TYPE.TYPE_ARCHIVE_AND_DELETE) ? { color: '#FF3F3F' } : { color: '#000000' }}>{item.label}</p>
                }
              </div>
              {(item.type !== SETTINGS_ITEMS_TYPE.TYPE_DELETE_PROFILE && item.type !== SETTINGS_ITEMS_TYPE.CANCEL_SUBSCRIPTION) && <img src={"/assets/images/play-icon-dark.svg"} alt="" />}
            </div>
          )
        })}
      </div>
      <ChangePassword
        isShow={actionType === SETTINGS_ITEMS_TYPE.TYPE_CHANGE_PASS}
        onClose={() => setActionType(null)}
        onSubmit={handleUpdatePass}
      />
      <LegalsModal
        isShow={(actionType === SETTINGS_ITEMS_TYPE.TYPE_PP || actionType === SETTINGS_ITEMS_TYPE.TYPE_TNC) ? true : false}
        type={actionType}
        onClose={() => setActionType(null)}
      />
      <Faq
        show={actionType === SETTINGS_ITEMS_TYPE.TYPE_FAQ}
        closePopup={() => setActionType(null)}
      />
      <DeleteProfile
        show={actionType === SETTINGS_ITEMS_TYPE.TYPE_DELETE_PROFILE || actionType === SETTINGS_ITEMS_TYPE.CANCEL_SUBSCRIPTION}
        closePopup={() => setActionType(null)}
        type={actionType}
        onDone={actionType === SETTINGS_ITEMS_TYPE.CANCEL_SUBSCRIPTION ? handleCancelSubs : handleDeleteAcc}
      />
      {actionType === SETTINGS_ITEMS_TYPE.TYPE_CHANGE_OWNERSHIP &&
        <UpdateRole
          isShow={actionType === SETTINGS_ITEMS_TYPE.TYPE_CHANGE_OWNERSHIP ? true : false}
          onClose={() => {
            setActionType(null);
            setSelectedRole(null)
          }}
          onUpdate={() => {
            if (!selectedRole) {
              showToast('Please select person to shift ownership.', 'error', 'ownership')
              return
            }
            setActionType(null);
            setShowConfirmModal(true);
          }}
          data={roleList}
          selectedEl={selectedRole}
          onSelect={(el) => setSelectedRole(el)}
        />
      }
      {actionType === SETTINGS_ITEMS_TYPE.TYPE_ARCHIVE_AND_DELETE &&
        <ArchiveAndDuplicateProd
          isShow={actionType === SETTINGS_ITEMS_TYPE.TYPE_ARCHIVE_AND_DELETE ? true : false}
          onClose={() => {
            setActionType(null);
          }}
          onFinalDone={() => {
            getCompanyNames();
            setActionType(null);
          }}
        />
      }
      {showConfirmModal &&
        <ChangeOwnerShipModal
          show={showConfirmModal}
          closePopup={() => {
            setShowConfirmModal(false);
            setSelectedRole(null)
          }}
          onDone={updateRole}
        />
      }
    </div>
  )
}
export default Setting;