import React, { useState } from 'react'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { OpenDirectionShape, SingleDatePicker, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';

interface DatePickerProps {
    date: any
    onDateChange: (date: any) => void;
    label?: any;
    displayFormat?: string;
    errorMessage?: any;
    allowPreviousDate?: boolean;
    allowPastDate?: boolean;
    openDirection?: OpenDirectionShape;
    placeholder?: any;
    style?: React.CSSProperties;
    className?: string;
    isEndDate?: boolean
}

const DatePicker: React.FC<DatePickerProps> = (props) => {

    const [focused, setFocused] = useState(false);
    return (
        <div className={`form_group w-100  ${props?.className} ${props?.errorMessage ? 'error' : ''}`}>
            <div style={props?.style} className="input_box">
                {props?.label && <label htmlFor="country">{props?.label}</label>}
                <SingleDatePicker
                    date={props.date || null} // momentPropTypes.momentObj or null
                    onDateChange={props.onDateChange} // PropTypes.func.isRequired
                    focused={focused} // PropTypes.bool
                    onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                    id={props?.label || 'date_id'} // PropTypes.string.isRequired,
                    numberOfMonths={1}
                    small
                    displayFormat={props.displayFormat || 'DD/MM/YYYY'}
                    openDirection={props.openDirection || 'up'}
                    isOutsideRange={(date) => props?.allowPastDate ? false : props?.isEndDate ? !props?.date?.isBefore(date, 'day') : !isInclusivelyAfterDay(date, moment())}
                    placeholder={props?.placeholder || 'Date'}
                    firstDayOfWeek={1}
                />
            </div>
            {props.errorMessage && <span style={{ color: 'red' }}>{props.errorMessage}</span>}
        </div>
    )
}

export default DatePicker