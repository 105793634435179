import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import Button from '../../global/ui/Button';
import { BUTTON_TYPE } from '../../types';

interface ConfirmationModalProps {
    visible: boolean;
    onClose: () => void;
    title?: string | any
    btnTxt?: string | any
    description?: string | any;
    hideClose?: boolean;
    onDone?: () => void;
    descriptionComp?: any
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
    return (
        <Modal
            centered
            scrollable
            show={props.visible}
            onHide={props.onClose}>
            <ModalHeader className="p-0 border-0 text-center mb-3">
                {props?.title ? <ModalTitle className="fw-bold mx-auto pt-3">{props?.title}</ModalTitle> : null}
                {!props.hideClose && <button className="close-btn-de shadow-none mb-1" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>}
            </ModalHeader>
            <ModalBody className="p-0 text-center mb-5 pb-3">
                {props?.descriptionComp ? props?.descriptionComp : <p className="mb-0">{props.description}</p>}
            </ModalBody>
            <ModalFooter className="p-0 border-0">
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt={props?.btnTxt}
                    style={{ marginTop: "0px", width: "auto" }}
                    onClick={props.onDone}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                />
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Cancel'
                    style={{ marginTop: "0px", width: "auto" }}
                    onClick={props?.onClose}
                    className={'btn btn-light w-100 with-after-shadow shadow-none fw-normal bg-transparent m-0 border-0 mt-4 p-0'}
                />
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal