import React, { useContext, useEffect, useState } from 'react'
import SideBarLayout from '../../global/layout/SideBarLayout'
import SearchWithHeading from '../../global/ui/SearchWithHeading'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import CrewGroupFilter from './CrewGroupFilter';
import NoData from '../../global/ui/NoData';
import CrewGroupItem from './CrewGroupItem';
import ViewCrewGroup from './ViewCrewGroup';
import CreateCrewGroup from './CreateCrewGroup';
import { MyContext } from '../../context';
import { ApiResponseType, CREW_GROUP_STATUS } from '../../types';
import { makeGetCall, makeNextPageCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../global/ui/Loader';
import { emptyFunction } from '../../utils';

const CrewGroups: React.FC<any> = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const parsed = queryString.parse(location.search);
    const { userData, } = useContext(MyContext)

    const [crewGroupDetails, setCrewGroupDetails] = useState(null)
    const [createCrewGroup, setCreateCrewGroup] = useState(false)
    const [allData, setAllData] = useState<Array<any>>([])
    const [nextUrl, setNextUrl] = useState<string | null>(null);

    const updateUrls = (key: any, value: any) => {
        const params = { ...parsed };
        params[key] = value;
        navigate(`/crew-groups/?${createSearchParams(params as never)}`);
    }

    const fetchCrewGroups = () => {
        const params: any = {
            production_company_name: userData?.production_company_name || '',
            search: parsed?.search || '',
        }
        if (parsed?.status === CREW_GROUP_STATUS.PUBLISHED_GROUP) {
            params.published = true
        }
        makeGetCall(endPoints.CREW_GROUP, params).then((res: ApiResponseType<any>) => {
            setAllData(res?.results);
            setNextUrl(res?.next || null);
        }).catch(emptyFunction)
    }

    const fetchMoreData = () => {
        if (!nextUrl) return;
        makeNextPageCall(nextUrl, false, true).then((res: ApiResponseType<any>) => {
            const data = [...allData, ...res.results];
            setAllData(data);
            setNextUrl(res?.next);
        })
    }

    useEffect(() => {
        if (userData?.production_company_name) {
            fetchCrewGroups()
        }
    }, [userData?.production_company_name, JSON.stringify(parsed)]);

    return (
        <SideBarLayout>
            <div className="right-content-full-screen">
                <SearchWithHeading
                    placeholder="Search by name, email or phone"
                    onSearch={(value) => updateUrls('search', value)}
                    value={parsed?.search || ''}
                    heading='Crew Groups'
                    onAdd={() => setCreateCrewGroup(true)}
                    addText='Create Group'
                />
                <div className="bottom-content-full-screen">
                    <CrewGroupFilter
                        selectedType={parsed?.status}
                        onSelect={(value) => updateUrls('status', value)}
                    />
                    <div style={{ maxHeight: 'calc(100vh - 205px)' }} id='scrollableDiv' className="production-crow-exploree">
                        <InfiniteScroll
                            dataLength={allData?.length}
                            next={fetchMoreData}
                            hasMore={nextUrl ? true : false}
                            loader={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loader imgStyle={{ width: '40px', height: '40px' }} /></div>}
                            scrollableTarget="scrollableDiv"
                        >
                            {allData?.length > 0
                                ?
                                <div className="row gx-4">
                                    {allData?.map((item, idx) => {
                                        return (
                                            <CrewGroupItem
                                                item={item}
                                                key={idx}
                                                onClick={() => setCrewGroupDetails(item)}
                                            />
                                        )
                                    })}
                                </div>
                                :
                                <NoData />
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
            <ViewCrewGroup
                isShow={crewGroupDetails ? true : false}
                onClose={() => setCrewGroupDetails(null)}
                crewGroupDetails={crewGroupDetails}
                onEdit={() => {
                    setCreateCrewGroup(true);
                }}
            />
            <CreateCrewGroup
                isShow={createCrewGroup}
                onClose={() => {
                    setCreateCrewGroup(false);
                    setCrewGroupDetails(null);
                }}
                type={crewGroupDetails ? 'EDIT' : 'ADD'}
                onDone={() => {
                    setCreateCrewGroup(false);
                    setCrewGroupDetails(null)
                    fetchCrewGroups();
                }}
                crewGroupDetails={crewGroupDetails}
            />
        </SideBarLayout>
    )
}

export default CrewGroups