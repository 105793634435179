import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LegalsModal from '../../components/modal/LegalsModal'
import { makeGetCall, makeNextPageCall, } from '../../effects/requests'
import SideBarLayout from '../../global/layout/SideBarLayout'
import NoData from '../../global/ui/NoData'
import { ApiResponseType, NOTIFICATION_TYPE, PERMISSION_TYPE, ROLE_TYPE, SETTINGS_ITEMS_TYPE, USER_TYPE } from '../../types'
import { DateUtils } from '../../utils/dateUtils'
import { endPoints } from '../../utils/endpoints'
import InfiniteScroll from 'react-infinite-scroll-component';
import { MyContext } from '../../context'
import Loader from '../../global/ui/Loader'
import Logo from '../../components/commonComponent/ProfileImage'
import { emptyFunction } from '../../utils'

const Notifications: React.FC<any> = (props) => {

    const [notificationData, setNotificationData] = useState([])
    const [nextUrl, setNextUrl] = useState(null)
    const [legalType, setLegalType] = useState<any>(null)
    const { userData } = useContext(MyContext);

    const getNotification = () => {
        makeGetCall(endPoints.USER_NOTIFICATION).then((res: ApiResponseType<any>) => {
            setNotificationData(res?.results);
            setNextUrl(res?.next || null);
        })
    }

    useEffect(getNotification, [userData]);

    const navigate = useNavigate();

    const createRoom = (id: string) => {
        makeGetCall(endPoints.ROOM_LIST + id + '/')
            .then((response) => {
                navigate(`/messages/?search=&user_type=${response?.data?.other_user?.user_type}&room=${response?.data?.id}`)
            })
            .catch(emptyFunction)
    }

    const handleNavigate = (item: any) => {
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_PP_UPDATED || item?.notification_type === NOTIFICATION_TYPE.TYPE_TNC_UPDATED) {
            setLegalType(item?.notification_type === NOTIFICATION_TYPE.TYPE_PP_UPDATED ? SETTINGS_ITEMS_TYPE.TYPE_PP : SETTINGS_ITEMS_TYPE.TYPE_TNC);
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_JOB_COMPLETED || item?.notification_type === NOTIFICATION_TYPE.TYPE_JOB_ASSIGNED) {
            navigate(`/jobs/?detail_id=${item?.data_id}`);
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_CHAT_INVITE) {
            createRoom(item?.data_id)
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_CHAT) {
            createRoom(item?.data_id)
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_JOB_STATUS || item?.notification_type === NOTIFICATION_TYPE.TYPE_JOB_ASSIGNED || item?.notification_type === NOTIFICATION_TYPE.TYPE_JOB_COMPLETED) {
            navigate(`/jobs`, { state: { jobId: item?.data_id } });
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_REQUEST_UPDATED || item?.notification_type === NOTIFICATION_TYPE.TYPE_COMPANY_INVITE) {
            navigate(`/explore/?type=production`);
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_PROFILE_UPDATED) {
            navigate(`/view-profile`);
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_EVENT_ASSIGNED) {
            navigate(`/schedule`, { state: { eventId: item?.data_id } });
            return
        }
        if (item?.notification_type === NOTIFICATION_TYPE.TYPE_ADMIN) {
            navigate(`/schedule`);
            return
        }
        // if (item?.notification_type === NOTIFICATION_TYPE.TYPE_REQUEST_UPDATED || item?.notification_type === NOTIFICATION_TYPE.TYPE_COMPANY_INVITE) {
        //     navigate(`/explore/?type=${EXPLORE_TYPE.MY_PROD}`);
        //     return
        // }
    }

    const fetchMoreData = () => {
        if (!nextUrl) return;
        makeNextPageCall(nextUrl, false, true).then((res: ApiResponseType<any>) => {
            const data = [...notificationData, ...res.results];
            setNextUrl(res?.next);
            setNotificationData(data as never)
        })
    }

    const viewText = (type: any) => {
        if (type == NOTIFICATION_TYPE.TYPE_COMPANY_INVITE || type == NOTIFICATION_TYPE.TYPE_REQUEST_UPDATED) {
            return 'View My Production'
        }
        else if (type == NOTIFICATION_TYPE.TYPE_JOB_COMPLETED || type == NOTIFICATION_TYPE.TYPE_JOB_ASSIGNED) {
            return 'View Job Detail'
        }
        else if (type == NOTIFICATION_TYPE.TYPE_EVENT_ASSIGNED) {
            return 'View Event'
        }
        else if (type == NOTIFICATION_TYPE.TYPE_EVENT_REMOVED) {
            return ''
        }
        else if (type == NOTIFICATION_TYPE.TYPE_TNC_UPDATED) {
            return 'View T&C'
        }
        else if (type == NOTIFICATION_TYPE.TYPE_PP_UPDATED) {
            return 'View Privacy Policy'
        }
        else if (type == NOTIFICATION_TYPE.TYPE_ADMIN) {
            return 'View Calendar'
        }
        else {
            return 'View Message'
        }
    }

    return (
        <SideBarLayout>
            <div className='notifications-part-all'>
                <div className='notifications-part-left-side bg-white'>
                    <div style={{ marginTop: '15px' }} className='notifications-part-left-side-top'>
                        {/* <div className='cross-and-dropdonw-selct d-flex justify-content-between align-items-center'>
                            <img onClick={() => navigate(-1)} src={"/assets/images/dismiss-cross-black.svg"} className="img-cross-icon" alt="" />
                            <div className='filter-select'>
                                <img src={"/assets/images/more-three-dots.svg"} className="main-drop-donwe" alt="" />
                            </div>
                        </div> */}
                        <h3>Notifications</h3>
                    </div>
                    <div id='scrollableDiv' className='notifications-part-left-side-bottom'>
                        {notificationData?.length > 0
                            ?
                            <InfiniteScroll
                                dataLength={notificationData?.length}
                                next={fetchMoreData}
                                hasMore={nextUrl ? true : false}
                                loader={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loader imgStyle={{ width: '40px', height: '40px' }} /></div>}
                                scrollableTarget="scrollableDiv"
                            >
                                {notificationData.map((item: any, idx) => {
                                    return (
                                        <div onClick={() => handleNavigate(item)} style={{ cursor: 'pointer' }} key={idx} className={`media-object d-flex align-items-center ${!item?.is_read ? 'not-opened' : ''}`}>
                                            {item?.extra_data?.user_image || item?.notification_type === NOTIFICATION_TYPE.TYPE_PP_UPDATED || item?.notification_type === NOTIFICATION_TYPE.TYPE_TNC_UPDATED ?
                                                <img src={item?.notification_type === NOTIFICATION_TYPE.TYPE_PP_UPDATED || item?.notification_type === NOTIFICATION_TYPE.TYPE_TNC_UPDATED ? '/assets/images/yellow-6th.png' : item?.extra_data?.user_image} alt="" />
                                                : <Logo style={{ width: '50px', height: '50px' }} data={item?.extra_data?.full_name?.[0]} />}
                                            <div className='media-cont'>
                                                {item?.notification_type !== NOTIFICATION_TYPE.TYPE_CHAT && <h5>{item?.title || ''}</h5>}
                                                <p>{item?.notification_type === NOTIFICATION_TYPE.TYPE_CHAT ? item?.title : item?.body || ''} <b>{viewText(item?.notification_type)}</b></p>
                                            </div>
                                            <p className='time-date'>{`${DateUtils.formatDateTime(item?.created_at, 'DD MMM')} . ${DateUtils.formatDateTime(item?.created_at, 'hh:mm A')}`}</p>
                                        </div>
                                    )
                                })}
                            </InfiniteScroll>
                            :
                            <NoData />
                        }
                    </div>
                </div>
                <LegalsModal
                    isShow={legalType ? true : false}
                    type={legalType}
                    onClose={() => setLegalType(null)}
                />
            </div>
        </SideBarLayout>
    )
}

export default Notifications