import moment from "moment";
import { DateUtils } from "./dateUtils";
import { EVENT_TYPE, ROLE_TYPE } from "../types";

export const UtilityFunctions = {
  getImageURL,
  validateEmail,
  validatePassword,
  validatePhone,
  validateOtp,
  isEmptyOrNullOrUndefined,
  API_URL,
  capitalize,
  //checkSpaceInPassword
};

function getImageURL() {
  return process.env.REACT_APP_LIVE_IMAGEURL;
}
function API_URL() {
  return process.env.REACT_APP_LIVE_APIURL;
}

function validateEmail(value: any) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/.test(
    value
  );
}
function validatePassword(value: any) {
  return /^(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?]).{7,}$/.test(value); // special character (?=.*\W)
}

function validatePhone(value: any) {
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value); //9999999999
}

// function checkSpaceInPassword(str:any) {
//   return /\s/.test(str);
// }

export const blobToFile = (theBlob: Blob, name = "filename.png"): File => {
  return new File([theBlob], name, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
};

export const dataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
function validateOtp(value: any) {
  if (value !== 6) {
    return true;
  } else {
    return false;
  }
}

function isEmptyOrNullOrUndefined(str: string) {
  str =
    str === undefined || str == null || str === "0"
      ? ""
      : str.toString().trim();
  if (str === "") return true;
  return false;
}
function capitalize(str: string) {
  var firstLetter = str[0];
  var restOfWord = str.slice(1); // start at the 1 index

  return firstLetter?.toUpperCase() + restOfWord;
}

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const stripeStyle = {
  base: {
    minHeight: "48px",
    fontFamily: "Greycliff CF",
    borderRadius: "6px",
    width: "100%",
    color: "#000000",
    letterSpacing: "0.025em",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    boxShadow: "none",
    outline: "0px",
    "::placeholder": {
      color: "#000000",
      fontSize: "14px",
      weight: "400",
    },
  },
  invalid: {
    color: "#9e2146",
  },
};

export const manipulatedDayData = (
  data: Array<any>,
  eventRes: Array<any>,
  totalMembers: any
) => {
  const tempNoOfHoursInDay: Array<any> = [];
  const shootEvents: Array<any> = [];
  data.map((timeItem) => {
    let dateObj = {
      ...timeItem,
      events: [],
      shoot_events: [],
      hasEvent: false,
    };
    eventRes.map((el) => {
      const tempEl = { ...el };
      tempEl.start_datetime = DateUtils.removeUTCString(tempEl?.start_datetime);
      tempEl.end_datetime = DateUtils.removeUTCString(tempEl?.end_datetime);
      if (
        moment(timeItem?.dateString).isSame(tempEl?.start_datetime, "hours")
      ) {
        const eventObj = { ...tempEl, total_members: totalMembers };
        dateObj.hasEvent = true;
        if (eventObj?.type === EVENT_TYPE.SHOOT) {
          shootEvents.push(eventObj);
          dateObj.shoot_events.push(eventObj);
        } else {
          dateObj.events.push(eventObj);
        }
      }
    });
    tempNoOfHoursInDay.push(dateObj);
  });
  return [tempNoOfHoursInDay, shootEvents];
};

export const manipulatedMonthData = (
  data: Array<any>,
  eventRes: Array<any>,
  totalMembersData: Array<any>
) => {
  const tempWeekDatesInMonth: Array<any> = [];
  data.map((monthItem) => {
    const tempWeekArray: Array<any> = [];
    monthItem?.weeks?.map((weekItem: any) => {
      let dateObj = {
        ...weekItem,
        events: [],
        shoot_events: [],
        hasEvent: false,
      };
      eventRes?.map((evtItem) => {
        const tempItem = { ...evtItem };
        tempItem.start_datetime = DateUtils.removeUTCString(
          tempItem?.start_datetime
        );
        tempItem.end_datetime = DateUtils.removeUTCString(
          tempItem?.end_datetime
        );
        if (
          moment(weekItem?.dateString).isSame(tempItem?.start_datetime, "date")
        ) {
          const eventObj = { ...evtItem };
          dateObj.hasEvent = true;
          if (eventObj?.type === EVENT_TYPE.SHOOT) {
            dateObj.shoot_events.push(eventObj);
          } else {
            dateObj.events.push(eventObj);
          }
        }
      });
      tempWeekArray.push(dateObj);
    });
    tempWeekDatesInMonth.push({ weeks: tempWeekArray });
  });
  return tempWeekDatesInMonth;
};

export const getWeekHeaderData = (selectedDate: Date) => {
  const dates = DateUtils.getDatesOfDays(selectedDate);
  return dates;
};

export const manipulatedWeekData = (
  data: Array<any>,
  eventRes: any,
  userData: any
) => {
  const isSupplier =
    userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? true : false;
  const eventData = isSupplier ? eventRes?.event : eventRes?.data;
  const tempWeekDates: Array<any> = [];
  data?.map((days) => {
    let dateObj: any = {
      dateString: days,
      events: [],
      shoot_events: [],
      hasEvent: false,
    };
    eventData?.map((evtItem: any) => {
      const eventObj = isSupplier ? { ...evtItem?.event } : { ...evtItem };
      eventObj.start_datetime = DateUtils.removeUTCString(
        eventObj?.start_datetime
      );
      eventObj.end_datetime = DateUtils.removeUTCString(eventObj?.end_datetime);
      if (moment(days).isSame(eventObj?.start_datetime, "date")) {
        dateObj.hasEvent = true;
        const tempData = isSupplier
          ? { ...evtItem, event: eventObj }
          : eventObj;
        if (eventObj?.type === EVENT_TYPE.SHOOT) {
          dateObj.shoot_events.push(tempData);
        } else {
          dateObj.events.push(tempData);
        }
      }
    });
    tempWeekDates.push(dateObj);
  });
  return [tempWeekDates, eventRes?.date_data];
};
