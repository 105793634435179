import { Table } from "react-bootstrap";
import WeekViewFooter from "./WeekViewFooter";
import Header from "./Header";
import { MyContext } from "../../../context";
import { useContext } from "react";
import CustomTd from "./CustomTd";

interface IWeekView {
    data: any;
    onAddPrepDiary: (data: any) => void;
    onViewPrepDiary: (data: any) => void;
    isProdSuperUser?: any
    onAddEvent: () => void;
    onEditEvt: (id: any) => void;
    onEventView: (id: any, eventStatus: any, supplierID?: any) => void;
    onDragEnd: (evtData: any) => void;
}

const WeekView = (props: IWeekView) => {

    const { userData } = useContext(MyContext);

    return (
        <div className="main-collender event-scadule">
            <div className={`event-scadule-mintabsl ${!props?.isProdSuperUser ? 'mh-100' : null}`} >
                <Table responsive borderless className="fix-height mb-0" cellSpacing={"0"} cellPadding={"0"} >
                    <Header data={props?.data?.evtData} />
                    <tbody>
                        <tr>
                            {props?.data?.evtData?.map((item: any, idx: any) => {
                                return (
                                    <CustomTd
                                        {...props}
                                        item={item}
                                        index={idx}
                                        userData={userData}
                                    />
                                )
                            })}
                        </tr>
                    </tbody>
                </Table>
            </div>
            {props?.isProdSuperUser &&
                <WeekViewFooter
                    data={props?.data}
                    isProdSuperUser={props?.isProdSuperUser}
                    showPrep={props?.isProdSuperUser}
                    onAddPrepDiary={props?.onAddPrepDiary}
                    onAddEvent={props?.onAddEvent}
                />
            }
        </div>
    )
}

export default WeekView