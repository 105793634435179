import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { makeDeleteCall, makeGetCall } from '../effects/requests';
import { cookieService } from '../services';
import { emptyFunction, saveUserData } from '../utils';
import { cookieServiceParam } from '../utils/constants';
import { endPoints } from '../utils/endpoints';

export const MyContext = React.createContext({
  userData: null as any,
  updateUserData: (res: any) => { },
  onLogOut: () => { },
  chatStatus: false,
  updateChatStatus: (value: boolean) => { },
  companies: [],
  updateCompanyData: (companies: any) => { },
});

const Context = (props: any) => {

  const navigate = useNavigate();

  const [userData, setUserData] = useState<any>(null);
  const [chatStatus, setChatStatus] = useState(false);
  const [companies, setCompanies] = useState([]);

  const onLogOut = () => makeDeleteCall(endPoints.LOGOUT, { device_type: 'WEB' }).then(clearAll).catch(clearAll)

  useEffect(() => {
    const token = cookieService.getCookie(cookieServiceParam.token) || null
    if (!token) return
    fetchUser();
  }, []);

  const fetchUser = () => {
    makeGetCall(endPoints.PROFILE, null, false, true)
      .then((res) => {
        setUserData(res?.data)
      })
      .catch(emptyFunction)
  }

  const clearAll = () => {
    cookieService.removeAllCookies();
    navigate('/sign-in');
  }

  const updateData = (res: any) => {
    setUserData(res?.data);
    saveUserData(res || null);
  }

  return (
    <MyContext.Provider
      value={{
        userData: userData,
        updateUserData: updateData,
        onLogOut: onLogOut,
        chatStatus: chatStatus,
        updateChatStatus: (status) => setChatStatus(status),
        companies: companies,
        updateCompanyData: (data: any) => setCompanies(data),
      }}
    >
      {props?.children}
    </MyContext.Provider>
  )
}

export default Context