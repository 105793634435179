import React, { useRef, useState } from "react";
import Picker, { EmojiClickData, Theme } from "emoji-picker-react";
import { BsEmojiSmileFill } from "react-icons/bs";
import { IoMdSend } from "react-icons/io";
import { makePostCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import WebcamModal from "../../components/modal/webcamModal";
import { emptyFunction, handleConvertToImageFile } from "../../utils";
interface ChatInputProps {
  handleSendMessage: (msg: string, attachment_url: string, attachment_type: string, fileName?: any) => void;
  chatReq?: boolean;
  hideExtraOptions?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ handleSendMessage, chatReq, hideExtraOptions }) => {
  const [msg, setMsg] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const webcamRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [showCamera, setShowCamera] = useState(false);
  const [attachement, setAttachement] = useState({
    attachement_type: '',
    attachement_url: ''
  })
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setShowCamera(false);
    handleFileChange('', imageSrc);
  }

  const handleFileTypeIcon = (filename: any) => {
    const fileType = filename.substring(filename.lastIndexOf('.') + 1);
    switch (fileType) {
      case 'png':
      case 'jpg':
        return "/assets/images/image-example.svg";
      case 'mp4':
        return "/assets/images/mp4-example.svg";
      case 'pdf':
        return "/assets/images/pdf-example.svg";
      case 'xls':
        return "/assets/images/xls-example.svg";
      default:
        return "/assets/images/image-example.svg";
    }
  }

  const renderAttachedFiles = () => {
    const src = handleFileTypeIcon(selectedFile?.name);
    return <div className="add-file-inchat d-flex justify-content-between border px-3 mb-3">
      <div className="d-flex gap-2 align-items-center w-100">
        <img src={src} alt='' />
        <h6 className="mb-0 fw-bold">{selectedFile?.name}</h6>
      </div>
      <img src={"/assets/images/delete-fill-inner-red.svg"} alt='' onClick={() => {
        setSelectedFile(null);
        setAttachement({
          attachement_type: '',
          attachement_url: ''
        })
      }} />
    </div>
  }

  const handleFileChange = (e?: any, image?: any) => {
    if (e?.target?.files || image !== null) {
      const selectedFile = e.target.files[0];
      setSelectedFile(selectedFile)
      let formData = new FormData();
      let img = image ? handleConvertToImageFile(image, 'my-image.png', 'image/png') : undefined;
      let attachementUrl = e?.target?.files ? e?.target?.files[0] : img;
      let attachementType = e?.target?.files ? e?.target?.files[0].type.split('/')[0] : img?.type?.split('/')[0];
      formData.append('file', attachementUrl);
      formData.append('file_type', attachementType)
      makePostCall(endPoints.ATTACHEMENT_URL, formData)
        .then((res) => {
          setAttachement({ attachement_type: res?.data?.file_type, attachement_url: res?.data?.file_url })
          setImgSrc('');
        })
        .catch(emptyFunction)
    }
  };

  const handleEmojiClick = (emojiObject: EmojiClickData, event: MouseEvent) => {
    let message = msg;
    message += emojiObject.emoji;
    setMsg(message);
  };

  const sendChat = (event: any) => {
    event.preventDefault();
    if (attachement.attachement_url.length > 0) {
      handleSendMessage(msg, attachement.attachement_url, attachement.attachement_type, selectedFile?.name)
      setAttachement({
        attachement_type: '',
        attachement_url: ''
      })
      setImgSrc('');
      setMsg('');
      document.getElementById('file_id')!!.innerHTML = ''
    }
    else if (msg.length > 0) {
      handleSendMessage(msg, "", "");
      setMsg("");
      document.getElementById('file_id')!!.innerHTML = ''
    }
    else {
      if (chatReq) {
        handleSendMessage("", "", "");
        setMsg("");
        document.getElementById('file_id')!!.innerHTML = ''
      }
    }
    setSelectedFile(null)
    document.getElementById('file_id')!!.innerHTML = ''
  };

  return (
    <div>{selectedFile && renderAttachedFiles()}
      <div className="bottom-chat-input">
        <div className="button-container">
          <div className="emoji">
            <BsEmojiSmileFill onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
            {showEmojiPicker && (
              <div className="emoji-picker-react">
                <Picker onEmojiClick={handleEmojiClick} theme={Theme.DARK} />
              </div>
            )}
          </div>
        </div>
        <form className="input-container d-flex" onSubmit={(event) => sendChat(event)}>
          <input
            type="text"
            placeholder="Type message.."
            onChange={(e) => setMsg(e.target.value)}
            value={msg}
          />
          <div className="image-send">
            <button type="button" onClick={() => inputRef.current?.click()} className={attachement?.attachement_url ? "selected" : ""}>
              <img src={'/assets/images/attach-files-dark.svg'} alt="" />
            </button>
          </div>
          <WebcamModal
            show={showCamera}
            onClose={() => setShowCamera(false)}
            capture={capture}
            webcamRef={webcamRef}
            imgSrc={imgSrc}
          />
          {!hideExtraOptions &&
            <div className="image-send">
              <button type="button" onClick={() => setShowCamera((prev) => !prev)}>
                <img src={'/assets/images/camera.png'} alt="" width="20px" height="20px" />
              </button>
            </div>
          }
          <button type="submit">
            <IoMdSend />
          </button>
        </form>
        <div className="image-send">
          {/* 👇 Notice the `display: hidden` on the input */}
          <input
            type="file"
            accept="image/png, image/jpeg, image/heic, video/mp4,video/x-m4v,video/*, application/pdf"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            multiple={true}
            id="file_id"
          />
        </div>
      </div></div>

  );
};

export default ChatInput;