import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import ChatInput from '../../pages/messages/ChatInput';

interface ChatRequestProps {
    show: boolean;
    closePopup: () => void;
    onSubmit: (msg?: string, attachement_url?: string, attachement_type?: string) => void;
}
const ChatRequest: React.FC<ChatRequestProps> = (props) => {

    return (
        <Modal
            centered
            scrollable
            className='exportdata updaterole'
            show={props.show}
            onHide={props.closePopup}>
            <ModalHeader className="p-0 border-0 mb-3">
                <button className="close-btn-de shadow-none" onClick={props.closePopup}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3">Enter Message</ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start overflow-hidden">
                <div className='poup-chat-design'>
                    <p className="mb-5 text-start">Enter message for chat request</p>
                    <div className='ms-5 ps-4 all-chat-desing' style={{ paddingBottom: '20px' }}>
                        <ChatInput handleSendMessage={props.onSubmit} chatReq={true} />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ChatRequest;
