import React, { useState } from 'react'
import SubscriptionUpgrade from './SubscriptionUpgrade'
import Stripe from '../../components/stripe';
import ConfirmSubscription from '../../components/modal/confirmSubscription';

export const SubscriptionExpire = () => {

  const [showSubsModal, setShowSubsModal] = useState(true);
  const [isShowCardModal, setIsShowCardModal] = useState(false);
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <SubscriptionUpgrade
        isShow={showSubsModal}
        onDone={() => {
          setShowSubsModal(false);
          setIsShowCardModal(true);
        }}
      />
      {isShowCardModal &&
        <Stripe
          isShow={isShowCardModal}
          onClose={() => setIsShowCardModal(false)}
          onSubscribe={() => setIsShow(true)}
          redirectType='subs'
        />
      }
      <ConfirmSubscription isShow={isShow} onClose={() => setIsShow(false)} />
    </>
  )
}
