import React from 'react'
import { Table } from 'react-bootstrap'
import { DateUtils } from '../../utils/dateUtils'

interface JobsDataProps {
    data: Array<any>;
    onClick: (item: any) => void
}

const JobsData: React.FC<JobsDataProps> = (props) => {
    return (
        <div className="common-table ovflow-height">
            <Table responsive className="">
                <thead>
                    <tr>
                        <th style={{ minWidth: '180px', width: '10%' }}>Job Title</th>
                        <th style={{ minWidth: '190px', width: '10%' }}>Date</th>
                        {/* <th style={{ minWidth: '150px', width: '10%' }}>Daily Rate ($)</th> */}
                        <th style={{ minWidth: '150px', width: '10%' }}>Location</th>
                        <th style={{ minWidth: '200px', width: '10%' }}>Department</th>
                        <th style={{ minWidth: '80px', width: '10%' }}>Positions</th>
                        <th style={{ minWidth: '80px', width: '10%' }}>Shared</th>
                        <th style={{ minWidth: '90px', width: '10%' }}>Filled</th>
                        {/* <th style={{ minWidth: '120px', width: '10%' }}>Status</th> */}
                    </tr>
                </thead>
                <tbody>
                    {props.data?.map((item: any, idx: number) => (
                        <tr style={{ cursor: 'pointer' }} onClick={() => props.onClick(item)} key={idx}>
                            <td className='text-break' style={{ minWidth: '180px', width: '10%' }}>{item?.title}</td>
                            <td style={{ minWidth: '190px', width: '10%' }}><p className="mb-0">{`${DateUtils.formatDateTime(item?.start_date, 'DD/MM/YYYY')} to ${DateUtils.formatDateTime(item?.end_date, "DD/MM/YYYY")}`}</p></td>
                            {/* <td style={{ minWidth: '150px', width: '10%' }}>{item?.daily_rate}</td> */}
                            <td className='text-break' style={{ minWidth: '150px', width: '10%' }}>{item?.location}</td>
                            <td className='text-break' style={{ minWidth: '200px', width: '10%' }}>{item.department}</td>
                            <td style={{ minWidth: '80px', width: '10%' }}>{item?.number_of_positions}</td>
                            <td style={{ minWidth: '80px', width: '10%' }}>{item?.total_invites | 0}</td>
                            <td style={{ minWidth: '90px', width: '10%' }}>{item?.total_invites_accepted | 0}</td>
                            {/* <td style={{ minWidth: '120px', width: '10%' }}><button className={`btn  rounded-pill shadow-none ${item?.completed ? 'btn-success' : 'btn-warning-table'}`}>{item?.completed ? 'Completed' : 'Posted'}</button></td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default JobsData