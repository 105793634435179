import moment from "moment";
// import { StringOrNull } from "../network/collections"

const seconds = "seconds";
const minutes = "minutes";
const weeks = "weeks";

export enum TimeType {
  TWELVE_HOUR = "hh:mm a",
  TWENTY_FOUR_HOUR = "HH:mm",
  TWENTY_FOUR_HOUR_WITH_SEC = "HH:mm:ss",
  TWELVE_HOUR_HUMAN = "h:mm a",
  TIME_DURATION = "d hh:mm:ss",
}

export enum DateTimeType {
  BOOKING_DISPLAY_FORMAT = " Do MMM h:mma",
}

export enum DateType {
  CALENDAR_MARKING_TYPE = "yyyy-MM-DD",
  DASHBOARD_FILTERED_WITH_MONTH = "Do MMM",
  DASHBOARD_FILTERED_WITH_MONTH_YEAR = "Do MMM, yy",
  DASHBOARD_FILTERED_WITHOUT_MONTH = "Do",
  NOTIFICATION_DATE = "DD",
  NEWEVENT = "DD-MM-YYYY",
}

export enum StartEndConstants {
  START_TIME = "12:00 am",
  END_TIME = "11:59 pm",
}

export type DateTypes =
  | "MM/DD/YYYY"
  | "YYYY-MM-DD HH:mm"
  | "YYYY-MM-DD"
  | "DD/MM/YYYY"
  | "YYYY-MM-DD HH:mm:ss"
  | "dddd"
  | "DD MMMM"
  | "YYYY"
  | "Do MMM"
  | "ddd, M MMMM"
  | "dddd, Mo MMMM"
  | "dddd, M MMMM"
  | "dddd, Do MMMM"
  | "MMMM YYYY"
  | "MMMM"
  | "DD"
  | "ddd"
  | "DD MMM YYYY"
  | "DD MMMM YYYY"
  | "DD MMM"
  | "DD MMM, YY"
  | "DD-MM-YYYY"
  | "DD MMMM YYYY"
  | "dddd, Do MMMM YYYY"
  | "DD/MM/YYYY HH:mm";

export type TimeTypes =
  | "HH:mm"
  | "hh:mm A"
  | "h:mm"
  | "hh:mm a"
  | "hh a"
  | "HH:mm:ss";

export class DateUtils {
  static getDateDisplayFromTimestamp = (timestamp: string) => {
    return moment(timestamp).format(DateType.DASHBOARD_FILTERED_WITH_MONTH);
  };

  static getDateOnly = (timestamp: string) => {
    return moment(timestamp).format(TimeType.TWELVE_HOUR_HUMAN);
  };

  static getDate = (timestamp: string) => {
    return moment(timestamp).format(DateType.NOTIFICATION_DATE);
  };

  /** Considers the time provided is local to user */
  static getDisplayTime = (date: Date): string => {
    return moment(date).format(TimeType.TWELVE_HOUR);
  };

  /**Returns UTC time */
  static getISOTime = (): string => {
    return moment().toISOString();
  };

  static getUnixDateTime = () => {
    return moment().utc().valueOf();
  };

  /** Accepts UTC iso time string
   * returns local time
   */
  // static getDisplayTimeFromISOString = (isoString?: StringOrNull, shouldAddExtraSecond?: boolean): string => {
  //     if (isoString) {
  //         let tempDate = moment(isoString)
  //         if (shouldAddExtraSecond)
  //             tempDate.add(1, 'seconds')
  //         return tempDate.format(TimeType.TWELVE_HOUR_HUMAN)
  //     }
  //     else
  //         return ""
  // }

  static convertUtcToLocal = (date: string): moment.Moment => {
    let tempDate = moment(date).utc().local();
    return tempDate;
  };

  static getCurrentLocalDateTime = () => {
    return moment().utc().local();
  };

  /**
   *
   * @param month The month we need to get first day for
   * @returns UTC ISO string
   */

  static getStartDayOfMonth = (month?: number, year?: number): string => {
    let date = moment();
    if (month !== undefined) {
      date.set("month", month);
    }
    if (year !== undefined) {
      date.set("year", year);
    }
    let dateString = date.startOf("months").toISOString();

    return dateString;
  };

  /**
   *
   * @param month The month we need to get last day for
   * @returns UTC ISO string
   */
  static getEndDayOfMonth = (month?: number, year?: number) => {
    let date = moment();
    if (month !== undefined) {
      date.set("month", month);
    }
    if (year !== undefined) {
      date.set("year", year);
    }
    let dateString = date.endOf("months").toISOString();
    return dateString;
  };

  static getDayOfWeek = (date: string): number => {
    return moment(date, "YYYY-MM-DD").day();
  };

  static formatDateTime = (
    timeStamp: any,
    formatTo?: DateTypes | TimeTypes,
    timeType = null
  ) => {
    if (!timeStamp) {
      return;
    }

    if (!formatTo) {
      return moment(timeStamp);
    }

    if (timeType) {
      return moment(timeStamp, timeType).format(formatTo);
    } else {
      return timeStamp
        ? moment(timeStamp).format(formatTo)
        : moment().format(formatTo);
    }
  };

  static removeUTCString = (str: any) => {
    return str?.substring(0, str?.length - 1);
  };

  static getMinDiff = (startDate: Date, endDate: Date) =>
    moment(endDate).diff(startDate, "minutes");

  //Determine if end date is an before the start date
  static isDateTimeIsBefore = (
    startDate: any,
    endDate: any,
    format: DateTypes | TimeTypes
  ) => {
    return moment(startDate, format).isBefore(moment(endDate, format));
  };

  //Determine if end date is an same the start date
  static isDateTimeIsSame = (
    startDate: any,
    endDate: any,
    format: DateTypes | TimeTypes
  ) => {
    return moment(startDate, format).isSame(moment(endDate, format));
  };

  //Determine if end date is an before the start date
  static isDateTimeIsBeforeOrSame = (
    startDate: any,
    endDate: any,
    format: DateTypes | TimeTypes
  ) => {
    return moment(startDate, format).isSameOrBefore(moment(endDate, format));
  };

  static getCurrentDate = () => moment();

  static startOfWeek = (date: Date, format?: DateTypes) =>
    moment(date).startOf("isoWeeks").format(format);

  static endOfWeek = (date: Date, format?: DateTypes) =>
    moment(date).endOf("isoWeeks").format(format);

  static startOfMonth = (date: Date, format?: DateTypes) =>
    moment(date).startOf("month").format(format);

  static endOfMonth = (date: Date, format?: DateTypes) =>
    moment(date).endOf("month").format(format);

  static getNextWeek = (date: Date, count?: any) =>
    moment(date)
      .add(count || 1, "isoWeeks")
      .toDate();

  static getPreviousWeek = (date: Date, count?: any) =>
    moment(date)
      .subtract(count || 1, "isoWeeks")
      .toDate();

  static startdate = (date: Date, format: DateTypes) =>
    moment(date).format(format);
  static getNextDay = (date: Date, count?: any) =>
    moment(date)
      .add(count || 1, "day")
      .toDate();
  static getPreviousDay = (date: Date, count?: any) =>
    moment(date)
      .subtract(count || 1, "day")
      .toDate();

  static currentMonth = (date: Date, format: DateTypes) =>
    moment(date).format(format);
  static getNextMonth = (date: Date, count?: any) =>
    moment(date)
      .add(count || 1, "month")
      .toDate();
  static getPreviousMonth = (date: Date, count?: any) =>
    moment(date)
      .subtract(count || 1, "month")
      .toDate();

  static getDateWithTime = (date: any, format: any) =>
    moment(date).format(format);

  static getDatesOfDays = (date: Date, isObjFormat?: boolean) => {
    let startDate = moment(date).startOf("isoWeeks").clone(),
      endDate = moment(date).endOf("isoWeeks").clone(),
      dates: any = [];
    while (startDate.isSameOrBefore(endDate)) {
      if (isObjFormat) {
        dates.push({ dateString: startDate.toDate() });
      } else {
        dates.push(startDate.toDate());
      }
      startDate.add(1, "days");
    }
    return dates;
  };

  static getNoOfHoursInDay = (date: Date) => {
    const dateData = Array(24)
      .fill(null)
      .map((_, hoursIdx) => {
        return {
          [minutes]: Array(60)
            .fill(null)
            .map((_, minutesIdx) => {
              return {
                [seconds]: Array(60)
                  .fill(null)
                  .map((_, secondsIdx) => {
                    return {
                      dateString: moment({
                        date: date.getDate(),
                        month: date.getMonth(),
                        year: date.getFullYear(),
                        hour: hoursIdx,
                        minute: minutesIdx,
                        second: secondsIdx,
                      }).toDate(),
                    };
                  }),
                dateString: moment({
                  date: date.getDate(),
                  month: date.getMonth(),
                  year: date.getFullYear(),
                  hour: hoursIdx,
                  minute: minutesIdx,
                }).toDate(),
              };
            }),
          dateString: moment({
            date: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
            hour: hoursIdx,
          }).toDate(),
        };
      });
    return dateData;
  };
  static getTime = (date: Date) => {
    return moment(date).format("LT");
  };

  static getWeekDatesInMonth = (date: Date) => {
    const firstDayOfMonth = moment(date).startOf("month");
    const nextMonth = moment(date).add(1, "month");
    const totalWeeks = nextMonth.diff(firstDayOfMonth, "weeks");
    const weekDatesInMonth = [];
    for (let i = 0; i <= totalWeeks; i++) {
      const startOfWeek = firstDayOfMonth.clone().add(i, "weeks").toDate();
      const firstDayOfWeek = moment(startOfWeek).startOf("isoWeeks").toDate();
      const datesInWeek = DateUtils.getDatesOfDays(startOfWeek, true);
      if (firstDayOfWeek.getMonth() <= firstDayOfMonth.toDate().getMonth()) {
        weekDatesInMonth.push({ [weeks]: datesInWeek });
      }
    }
    return weekDatesInMonth;
  };
}
