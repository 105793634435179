import React, { useState } from 'react'
import { EXPLORE_TYPE } from '../../types';
import { DateUtils } from '../../utils/dateUtils';
import { hasAddressData, renderAddressDetail } from '../../utils';

interface AboutCrewProps {
    data: any;
    type: any;
    dept: Array<any>;
    selectedIdx: number
    selectedDeptIdx: any;
    setSelectedDeptIdx: (e: any) => void;
}

const AboutCrew: React.FC<AboutCrewProps> = (props) => {

    const { data } = props;
    const [clicked, setClicked] = useState(false);
    // const [selectedIdx, setSelectedIdx] = useState(null);

    return (
        <div className={`tab-pane fade ${props?.selectedIdx === 0 && 'active show'}`} id="about">
            <ul className="profile-information-list">
                {props.type === EXPLORE_TYPE.EX_CREW && data?.date_joined &&
                    <li className="align-items-center">
                        <img src={"/assets/images/dimand-icon-perpel.svg"} alt="" />
                        {`Member since ${DateUtils.formatDateTime(data?.date_joined, 'DD MMMM YYYY')}`}
                    </li>
                }
                {hasAddressData(data) &&
                    <li className="align-items-center">
                        <img src={"/assets/images/Location-icon-perple.svg"} alt="" />
                        {renderAddressDetail(data)}<br />{data?.postcode || ''}
                    </li>
                }
                {data?.crew_member_profile?.bio &&
                    <li style={{ wordBreak: 'break-word' }}>
                        <img src={"/assets/images/user-icon-perple.svg"} alt="" />
                        <p dangerouslySetInnerHTML={{ __html: data?.crew_member_profile?.bio.replace(/\n/g, "<br/>") || '' }}>
                        </p>
                    </li>
                }
                {props.dept?.length > 0 &&
                    <li className="">
                        <img src={"/assets/images/Briefcase-perple-icon.svg"} alt="" />
                        <div className="like-togl">
                            <div className="accordion accordion-colleps w-100" id="accordionExample">
                                {props.dept?.map((deptItem: any, idx: any) => {
                                    return (
                                        <>
                                            {/* <div onClick={() => props?.setSelectedDeptIdx(idx)} key={idx} className='accordion-colleps w-100' id={`accordion_${idx}`}>
                                        <div className="card border-0">
                                            <div className="card-header bg-transparent p-0 border-bottom-0">
                                                <a className="btn bg-transparent shadow-none w-100 d-flex justify-content-between align-items-center p-0 border-0" data-bs-toggle="collapse" aria-expanded="false" href={`#collapse1_${idx}`}>
                                                    {deptItem?.dep || ''}
                                                    <img src="/assets/images/play-icon-dark.svg" className='bg-transparent' alt="" />
                                                </a>
                                            </div>
                                            <div id={`collapse1_${idx}`} className={`collapse w-100 ${props?.selectedDeptIdx === idx ? 'show' : ''}`} data-bs-parent={`#collapse1_${idx}`}>
                                                <div className="card-body mt-0 border-0 p-0 w-100 d-flex flex-wrap align-item-center gap-3">
                                                    {Array.isArray(deptItem?.role) ? deptItem?.role?.map((role: any, index: any) => <span key={index}>{role}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div onClick={() => { props?.setSelectedDeptIdx(idx); setClicked(!clicked) }} className="accordion-item card border-0">
                                                <div className="card-header bg-transparent p-0 border-bottom-0">
                                                    <a className="btn bg-transparent shadow-none w-100 d-flex justify-content-between align-items-center p-0 border-0" data-bs-toggle="collapse" aria-expanded="false" data-bs-target={`#collapse1_${idx}`} aria-controls={`collapse1_${idx}`}>
                                                        {deptItem?.dep || ''}
                                                        <img src="/assets/images/play-icon-dark.svg" className={`bg-transparent ${props?.selectedDeptIdx === idx && clicked ? 'rotate-arrow' : ''}`} alt="" />
                                                    </a>
                                                </div>
                                                <div id={`collapse1_${idx}`} className={`accordion-collapse collapse ${props?.selectedDeptIdx === idx && clicked ? 'show' : ''}`} aria-labelledby={`collapse1_${idx}`} data-bs-parent="#accordionExample">
                                                    <div className="accordion-body card-body mt-0 border-0 p-0 w-100 d-flex flex-wrap align-item-center gap-3">
                                                        {Array.isArray(deptItem?.role) ? deptItem?.role?.map((role: any, index: any) => <span key={index}>{role}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </li>
                }
            </ul>
        </div>
    )
}

export default AboutCrew