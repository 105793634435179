import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import ChatInput from '../messages/ChatInput';
import { emptyFunction } from '../../utils';
import AssignedCrews from './AssignedCrews';


interface ISendGroupMessage {
    isShow: boolean;
    onClose: () => void;
    onSubmit: (msg?: string, attachement_url?: string, attachement_type?: string) => void;
    memberData: Array<any>
}

const SendGroupMessage: React.FC<ISendGroupMessage> = (props) => {
    return (
        <Modal
            centered
            scrollable
            className='exportdata'
            show={props?.isShow}
            onHide={emptyFunction}
        >
            <ModalHeader className="p-0 border-0 mb-3">
                <button
                    className="close-btn-de shadow-none"
                    onClick={props?.onClose}
                >
                    <img src={"/assets/images/dismiss-cross-black.svg"} alt="close" />
                </button>
                <ModalTitle className="fw-bold me-auto ps-3">Send Message</ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start overflow-hidden">
                <div className='poup-chat-design'>
                    <AssignedCrews
                        memberData={props.memberData}
                        containerStyle={{ maxHeight: '250px', minHeight: '250px', overflowY: 'scroll', paddingBottom: '20px' }}
                    />
                    <ChatInput
                        handleSendMessage={props.onSubmit}
                        chatReq
                        hideExtraOptions
                    />
                </div>
            </ModalBody>
        </Modal>
    )
}

export default SendGroupMessage