import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { createFullName } from "../../utils";
import { DateUtils } from "../../utils/dateUtils";
import Logo from "../../components/commonComponent/ProfileImage";

const MultipleEvent = (props: any) => {

  return (
    <Modal
      centered
      scrollable
      show={props.isShow}
      onHide={props.onClose}
    >
      <ModalHeader className="p-0 border-0 mb-4 align-items-start">
        <button className="close-btn-de shadow-none" onClick={props.onClose}>
          <img src={"/assets/images/black-arrow-left-side.svg"} alt="" />
        </button>
        <ModalTitle className="fw-bold me-auto ps-3 text-start">
          <h6 className="h4 text-start mb-0 mt-0" style={{ fontSize: '20px', padding: '4px' }}>{`${DateUtils.formatDateTime(props?.eventData[0]?.event_start_date, "dddd, Do MMMM")}`}</h6>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between">
          <div className=" d-flex gap-2 ">
            {props?.nameData?.profile_image ? <img className="align-self-baseline user-profile-img"
              src={props.nameData?.profile_image} alt="Image" />
              :
              <Logo style={{ width: '70px', height: '70px' }} data={props?.nameData?.first_name?.[0]} />}
            <div>
              {/* {createFullName(props.item?.first_name, props.item?.last_name) || ''} */}
              <p className="mutiple-event-name">{createFullName(props.nameData?.first_name, props.nameData?.last_name) || ''}</p>
              <p className="mutiple-event-job-title">{props?.nameData?.job_title ? props?.nameData?.job_title : <p style={{ color: '#BDBDBD', fontSize: '12px' }}>No Job Assigned</p>}</p>
            </div>
          </div>
          <div>
            {props?.eventData?.map((event: any) => {
              return <div className="mutiple-meeting" key={event?.event_id} onClick={() => props.onEventView(event?.event_id)}>
                <div className="d-flex justify-content-between align-items-center mb-2" >
                  <h6 className="mb-0 fw-bold">{event?.event_title}</h6>
                  <img style={{ cursor: "pointer" }}
                    // onClick={() => setSelectedDate(DateUtils.getNextWeek(selectedDate))}
                    src="/assets/images/play-icon-dark.svg"
                    className="right-side-mo" alt="" />
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0" style={{ textAlign: 'initial', fontSize: '12px' }}>{event?.event_start_date.slice(11, 16)} - {event?.event_end_date.slice(11, 16)}</p>
                  {event?.is_seventh_day ? <img src="/assets/images/7th-icon.svg" alt="" className='icon-6th-7th' /> :
                    event?.is_sixth_day && <img src="/assets/images/6th-icon.svg" alt="" className='icon-6th-7th' />}
                </div>
              </div>
            })}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MultipleEvent;
