import React, { useState } from 'react'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';

const CustomTimePicker = (props: any) => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div onClick={() => setIsOpen(!isOpen)} className={`time-picker-outer mb-4 corsur-pointer`}>
            <div style={props?.style} className={`time-picker-inner ${props?.errorMessage ? 'error' : ''}`}>
                <label htmlFor="time">{props?.label} </label>
                <TimePicker
                    className={`time-picker ${props?.errorMessage ? 'error' : ''}`}
                    showSecond={false}
                    onChange={props?.onChange}
                    format='hh:mm A'
                    placeholder={props?.placeholder || ''}
                    use12Hours
                    minuteStep={15}
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    clearIcon={<div />}
                    clearText=''
                    value={props?.value || null}
                />
            </div>
            {props.errorMessage && <span style={{ marginLeft: '13px' }}>{props.errorMessage}</span>}
        </div>
    )
}

export default CustomTimePicker