import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react'
import { REQ_STATUS, ROLE_TYPE } from '../../types';
import Logo from '../../components/commonComponent/ProfileImage';

interface IChatWindowHeader {
    onClose: () => void;
    onSearched: (value: any) => void;
    chatData: any;
    acceptRejectRequest: (value: any) => void;
    isCurrentUser?: boolean
}

const ChatWindowHeader: React.FC<IChatWindowHeader> = (props) => {

    const [showSearch, setShowSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const onChangeTextDebounce = useCallback(debounce(props?.onSearched, 800), []);

    const { chatData } = props;

    const requestButton = (buttonText: string, type: string, image: string) => {
        return (
            <div className="d-flex align-items-center gap-2" onClick={() => props?.acceptRejectRequest(type)}>
                <img src={image} alt="" />
                {buttonText}
            </div>
        )
    }

    return (
        <div className='message-rigth-side-top'>
            <div className='message-heading text-center'>
                <div className='message-top-partsetup'>
                    <div className='back-btn-top' onClick={props?.onClose}>
                        <img src="/assets/images/black-arrow-left-side.svg" alt="" />
                    </div>
                    <div className={`input-group ${showSearch ? "mw-100 setuptop" : ""}`}>
                        <span className={`input-group-text bg-transparent`} onClick={() => setShowSearch(true)}>
                            <img className='mb-0' src="/assets/images/search-thin-blank.svg" alt="" />
                        </span>
                        <input
                            type="text"
                            className="form-control border-start-0 border-end-0 py-2 px-0 shadow-none"
                            onChange={(evt) => {
                                setSearchValue(evt.target.value);
                                onChangeTextDebounce(evt.target.value);
                            }}
                            value={searchValue || ''}
                            placeholder='Search in messages'
                        />
                        <span className="input-group-text bg-transparent">
                            <img
                                className='mb-0'
                                src="/assets/images/cross-thin-icon-black.svg"
                                alt=""
                                onClick={() => {
                                    setShowSearch(false);
                                    setSearchValue("");
                                    onChangeTextDebounce("");
                                }}
                            />
                        </span>
                    </div>
                </div>
                {(chatData?.other_user?.supplier_profile?.company_logo || chatData?.other_user?.owner_profile?.profile_image || chatData?.other_user?.profile_image)
                    ?
                    <img
                        src={chatData?.other_user?.supplier_profile?.company_logo || chatData?.other_user?.owner_profile?.profile_image || chatData?.other_user?.profile_image}
                        onClick={() => {

                        }}
                        alt="User"
                        className="cursor-pointer"
                    />
                    :
                    <Logo
                        style={{ width: '50px', height: '50px' }}
                        data={chatData?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? chatData?.other_user?.supplier_profile?.company_name?.[0] : chatData?.other_user?.first_name?.[0] || chatData?.other_user?.last_name?.[0]}
                    />
                }
                <h3
                    // onClick={() => setViewProfile(true)}
                    className="cursor-pointer"
                >
                    {chatData?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? chatData?.other_user?.supplier_profile?.company_name : chatData?.other_user?.first_name + " " + chatData?.other_user?.last_name}
                    {(chatData?.other_user?.verified && chatData?.other_user?.user_type === ROLE_TYPE.TYPE_CREW) &&
                        <img
                            style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '5px' }}
                            src={"/assets/images/yellow_check_mark.png"}
                            alt="Verified User"
                        />
                    }
                </h3>
                {(chatData?.status === REQ_STATUS.PENDING && !props?.isCurrentUser) &&
                    <div className="accept-reject-des mt-3 pb-4 mb-2 d-flex align-items-center justify-content-center gap-3">
                        {requestButton('Reject', 'R', "/assets/images/cross-cercle-fill-red.svg")}
                        {requestButton('Accept', 'A', "/assets/images/checkmark-fill-green.svg")}
                    </div>
                }
            </div>
        </div>
    )
}

export default ChatWindowHeader