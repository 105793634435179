import Button from '../../../global/ui/Button';
import { BUTTON_TYPE, ROLE_TYPE, USER_ACTION_TYPE } from '../../../types';
import { buttonsText, errorMessage, } from '../../../utils/constants';
import { useEffect, useState } from 'react';
import { showToast } from '../../../utils';
import CustomGooglePlaceSearch from '../../../components/customGooglePlaceSearch';
import { makePostCall } from '../../../effects/requests';
import { endPoints } from '../../../utils/endpoints';
interface AddressProps {
  userData: any;
  onSubmit: (value: any) => void;
  actionType?: any
}

const DEFAULT_ADD_OBJ = {
  location: '',
  lat: '',
  lng: '',
  radius: ''
}

const Address = (props: AddressProps) => {

  const [locationData, setLocationData] = useState<any>({
    ...DEFAULT_ADD_OBJ,
    location: props.userData?.address,
  });

  const [secondaryAddress, setSecondaryAddress] = useState<Array<any>>([]);

  const isSupplier = props?.userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER;

  const onSubmit = (evt: any) => {
    evt.preventDefault()
    const apiPayload = { ...locationData };
    let coordinates: any = [];

    if (!apiPayload?.location) {
      showToast(errorMessage.country_error, 'error', 'country');
      return
    }

    if (secondaryAddress?.length > 0 && isSupplier) {
      const isFillAllAddress = secondaryAddress.some((item: any) => !item?.location);
      if (isFillAllAddress) {
        showToast(errorMessage.country_error, 'error', 'country')
        return
      }
    }

    // Iterate through the keys and check if the value is falsy
    for (const key in apiPayload) {
      if (!apiPayload[key]) {
        delete apiPayload[key];
      }
    }

    if (apiPayload?.lat && apiPayload?.lng) {
      coordinates = [apiPayload?.lat, apiPayload?.lng];
    }

    const fd = new FormData();
    fd.append('address', apiPayload?.location || '');
    fd.append('city', apiPayload?.city || '');
    fd.append('country', apiPayload?.country || '');
    fd.append('post_code', apiPayload?.post_code || '');
    if (coordinates?.length > 0) fd.append('coordinates', JSON.stringify({ "type": "Point", "coordinates": [apiPayload?.lat, apiPayload?.lng] }));
    if (secondaryAddress?.length > 0 && isSupplier) {
      updateOtherLoc();
    }
    props.onSubmit(fd)
  }

  const updateOtherLoc = () => {
    const tempAddress = [...secondaryAddress];
    tempAddress?.map((item: any) => {
      if (item?.isAdd) {
        const fd = new FormData();
        fd.append('address', item?.location);
        fd.append('city', item?.city || '');
        fd.append('country', item?.country || '');
        fd.append('post_code', item?.post_code || '');
        fd.append('coordinates', JSON.stringify({ "type": "Point", "coordinates": [item?.lat, item?.lng] }));
        makePostCall(endPoints.OTHER_LOC, fd).then((res) => {
          item.isEdit = true;
          item.isAdd = false
        })
      }
    })
    setSecondaryAddress(tempAddress);
  }

  useEffect(() => {
    setLocationData((prevState: any) => ({
      ...prevState,
      location: props.userData?.address,
    }))
  }, [props?.userData?.address]);

  useEffect(() => {
    if (isSupplier && props?.userData?.other_location?.length > 0) {
      const temp: any = [];
      props?.userData?.other_location?.map((item: any) => {
        const obj = {
          ...DEFAULT_ADD_OBJ,
          location: item?.address,
          lat: item?.location?.coordinates[0],
          lng: item?.location?.coordinates[1],
          isEdit: true
        }
        temp.push(obj);
      })
      setSecondaryAddress(temp)
    }
  }, [JSON.stringify(props?.userData?.other_location)])

  return (
    <form action="" onSubmit={onSubmit} noValidate>
      <div className="all-form" style={{ marginTop: '14px' }}>
        <span className='address-label'>Primary Address</span>
        <CustomGooglePlaceSearch
          onApply={(data: any) => setLocationData(data)}
          data={locationData}
          hideRadius
          placeholder='Select Location'
          heading='Select Location'
          scrollwheel={false}
          needExtraData
        />
        {(secondaryAddress?.length > 0 && isSupplier) &&
          <div style={{}}>
            {secondaryAddress?.map((item: any, idx: any) => {
              return (
                <div className='mb-2 mt-2'>
                  {idx == 0 && <span className='address-label'>Secondary Address</span>}
                  <CustomGooglePlaceSearch
                    onApply={(data: any) => {
                      const tempAdd = [...secondaryAddress];
                      tempAdd[idx] = { ...data, isAdd: true };
                      setSecondaryAddress(tempAdd)
                    }}
                    data={item}
                    hideRadius
                    placeholder='Select Location'
                    heading='Select Location'
                    showDelete={!item?.isEdit}
                    onDelete={() => {
                      const tempAdd = [...secondaryAddress];
                      tempAdd.splice(idx, 1)
                      setSecondaryAddress(tempAdd)
                    }}
                    scrollwheel={false}
                    needExtraData
                  />
                </div>
              )
            })}
          </div>
        }
      </div>
      <div className="main-button">
        {(secondaryAddress?.length < 10 && isSupplier) &&
          <div className='d-flex align-items-center justify-content-center mt-2 mb-3'>
            <button
              className="btn btn-warning btn-sm shadow-none"
              type='button'
              style={{ padding: '12px 14px', alignSelf: 'center' }}
              onClick={() => {
                const address = [...secondaryAddress];
                address.push(DEFAULT_ADD_OBJ);
                setSecondaryAddress(address)
              }}
            >
              <img style={{ marginRight: '20px' }} src={"/assets/images/plus-icon.svg"} alt="" />
              Add Additional Address
            </button>
          </div>
        }
        <Button
          type={BUTTON_TYPE.TYPE_SUBMIT}
          btnTxt={props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE ? buttonsText.saveAndUpdate : buttonsText.continue}
          className={"btn btn-warning w-100 with-after-shadow shadow-none"}
        />
      </div>
    </form>
  )
}
export default Address;