import { ErrorMessage } from '@hookform/error-message';
import React, { forwardRef, Ref, useState } from 'react'
import { HTMLAttributes } from 'react';
import { UseControllerProps, useController, useFormContext } from 'react-hook-form';
import { InputType } from '../../types';

export interface InputFieldsProps extends UseControllerProps {
    inputProps?: HTMLAttributes<HTMLInputElement>
    label?: any
    placeholder?: string;
    rightIcon?: any;
    rightIconPress?: () => void;
    containerStyle?: React.CSSProperties;
    textAreaStyle?: React.CSSProperties;
    type: InputType;
    className?: string;
    isTextArea?: boolean;
    hideErrorColor?: boolean;
    maxLength?: number
    isDisabled?: boolean
}

const InputField = forwardRef((props: InputFieldsProps, ref: Ref<any>) => {

    const {
        name,
        label,
        rules,
        defaultValue,
        rightIcon,
        rightIconPress,
        className,
        isTextArea,
        maxLength
    } = props;

    const formContext = useFormContext();
    const { formState, setValue } = formContext;
    const { field } = useController({ name, rules, defaultValue });
    const hasError = Boolean(formState?.errors[name]);

    const [isFocused, setIsFocused] = useState(false);

    const getClass = () => {
        let className = ''
        if (isFocused) {
            className = 'focused'
        } else if (hasError) {
            className = 'error'
        }
        return className
    }

    return (
        <div style={{ ...props?.containerStyle }} className={`form_group ${getClass()} ${className}`}>
            <div className="input_box">
                {label && <label style={{ backgroundColor: hasError && !isFocused && isTextArea && !props?.hideErrorColor ? 'rgba(255,63,63,0.1)' : undefined }} htmlFor={props?.name}>{label}</label>}
                {isTextArea
                    ?
                    <textarea
                        ref={ref}
                        id={props?.name}
                        placeholder={props?.placeholder}
                        style={{ minHeight: "186px", ...props?.textAreaStyle }}
                        value={field.value}
                        onBlur={() => {
                            setIsFocused(false);
                            field?.onBlur?.()
                        }}
                        onFocus={() => setIsFocused(true)}
                        onChange={field.onChange}
                        className="no-resize"
                    />
                    :
                    <input
                        id={props?.name}
                        // className={className}
                        placeholder={props?.placeholder}
                        ref={ref}
                        value={field.value}
                        onBlur={() => {
                            setIsFocused(false);
                            field?.onBlur?.()
                        }}
                        onFocus={() => setIsFocused(true)}
                        onChange={field.onChange}
                        type={props?.type}
                        {...props?.inputProps}
                        maxLength={props?.maxLength}
                        disabled={props?.isDisabled}
                    />
                }
                {rightIcon &&
                    <img
                        src={rightIcon}
                        onClick={rightIconPress}
                        alt="Image"
                    // style={{ marginRight: ((hasError && !isFocused) || (!isFocused && !hasError && field.value)) ? "33px" : '0px' }}
                    />
                }
                {hasError && !isFocused && !isTextArea && (
                    <img
                        src={'/assets/images/error.svg'}
                        alt='Error message'
                        style={{ marginRight: rightIcon ? '33px' : '0px' }}
                    />
                )}
                {!isFocused && !hasError && field.value && !isTextArea && (
                    <img
                        src={'/assets/images/cross.svg'}
                        alt="Cross Image"
                        onClick={() => {
                            if (props?.isDisabled) return
                            setValue(name, '');
                            field.onBlur?.();
                        }}
                        style={{ marginRight: rightIcon ? '33px' : '0px' }}
                    />
                )}
            </div>
            <ErrorMessage
                errors={formState.errors}
                name={props?.name}
                render={({ message }) => <span>{message}</span>}
            />
        </div>
    )
});

export default InputField