import React from 'react'
import Select from 'react-select';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  isMultiSelect?: boolean;
  isClearable?: boolean;
  data: any;
  placeholder?: string;
  label?: string;
  onSelect?: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  selectedItems?: any;
  name?: any;
  errorMessage?: any;
  Option?: any;
  hideSelectedOptions?: boolean;
  isSearchable?: boolean
}

const DropDown = (props: Props) => {

  return (
    <div className={`form_selcet mb-4 ${props?.className} ${props?.errorMessage ? 'error' : ''}`}>
      <div style={props?.style} className="input_box">
        <label htmlFor="country">{props?.label} </label>
        <Select
          placeholder={props?.placeholder}
          isMulti={props?.isMultiSelect}
          isClearable={props?.isClearable}
          name={props?.name}
          options={props?.data}
          className="basic-multi-select shadow-none"
          classNamePrefix="select"
          onChange={props?.onSelect}
          value={props?.selectedItems}
          closeMenuOnSelect={props?.isMultiSelect ? false : true}
          onFocus={props?.onFocus}
          onBlur={props?.onBlur}
          components={props?.Option}
          hideSelectedOptions={props?.hideSelectedOptions ? false : true}
          isSearchable={props?.isSearchable}
        // menuIsOpen={props?.menuIsOpen || false}
        />
      </div>
      {props.errorMessage && <span>{props.errorMessage}</span>}
    </div>
  )
}

export default DropDown;