import { DateUtils } from "../../../utils/dateUtils";

interface IHeader {
    data: Array<any>
}

const Header = (props: IHeader) => {
    return (
        <thead>
            <tr>
                {props.data?.map((item: any, idx: any) => {
                    const headerDate = `${DateUtils.formatDateTime(item?.dateString, "DD")}`;
                    const headerDay = `${DateUtils.formatDateTime(item?.dateString, "ddd")}`
                    return (
                        <th className="tablehead-week" key={idx} style={{ width: "12%", minWidth: "162px", padding: '18px 10px' }}>
                            <div className="top-part-collender d-flex justify-content-between align-items-center">
                                <div className="d-grid ">
                                    <p className="mb-0">{headerDay}</p>
                                    <h6 className="mb-0">{headerDate} </h6>
                                </div>
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    )
}

export default Header