import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import Button from '../../global/ui/Button'
import { BUTTON_TYPE } from '../../types'

interface IDraftEvtModal {
    show: boolean;
    onClose: () => void;
    onPublish: () => void
}

const DraftEvtModal: React.FC<IDraftEvtModal> = (props) => {
    return (
        <Modal
            centered
            scrollable
            show={props.show}
            onHide={props.onClose}>
            <ModalHeader className="p-0 border-0 text-center mb-3">
                <ModalTitle className="fw-bold mx-auto">
                    <p><img src="/assets/images/delete-user-red.svg" alt="" /></p>
                    Changes have not been saved
                </ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-center mb-5 pb-3">
                <p className="mb-0">Are you sure you want to leave this page, any changes you have made will be lost, you can save changes now by clicking publish</p>
            </ModalBody>
            <ModalFooter className="p-0 border-0">
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Publish Changes'
                    style={{ marginTop: "0px", width: "auto" }}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                    onClick={props.onPublish}
                />
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Exit without saving'
                    style={{ marginTop: "0px", width: "auto", textDecoration: 'underline' }}
                    onClick={props.onClose}
                    className={'btn btn-light w-100 with-after-shadow shadow-none fw-normal bg-transparent m-0 border-0 mt-4 p-0'}
                />
            </ModalFooter>
        </Modal>
    )
}

export default DraftEvtModal