import { AxiosResponseHeaders, AxiosRequestConfig } from "axios";
import { string } from "yup";

export type CookieType = {
  accessToken?: string;
};

export type CookiesKeysType =
  | "user"
  | "access_token"
  | "refresh_token"
  | "data"
  | "user_data"
  | "user_type"
  | "email";

export type ApiResponseType<T = any, D = any> = {
  data: T;
  results: T;
  previous: any;
  next: any;
  status: StatusType;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
  count?: any;
  token?: any;
  shoot_data?: T;
};

export type StatusType = {
  code: number;
  message: string | any;
};

export enum BUTTON_TYPE {
  TYPE_SUBMIT = "submit",
  TYPE_BUTTON = "button",
  TYPE_RESET = "reset",
}

export enum VARIANT_TYPE {
  TYPE_PRIMARY = "primary",
  TYPE_SECONDARY = "secondary",
}

export enum USER_TYPE {
  TYPE_USER = "user",
  TYPE_MANAGER = "manager",
}

export enum REQ_STATUS {
  ACCEPTED = "A",
  PENDING = "P",
  DELETED = "D",
  REJECTED = "R",
}

export enum USER_ACTION_TYPE {
  TYPE_CREATE = "create",
  TYPE_UPDATE = "update",
  TYPE_DELETE = "delete",
  TYPE_ADD = "add",
}

export enum NUMBER_STATUS {
  STATUS_0 = 0,
  STATUS_1 = 1,
  STATUS_2 = 2,
  STATUS_3 = 3,
  STATUS_4 = 4,
  STATUS_5 = 5,
  STATUS_6 = 6,
  STATUS_7 = 7,
  STATUS_8 = 8,
  STATUS_9 = 9,
  STATUS_10 = 10,
}

//Responses Type

export interface ResponseType<T> {
  status?: any;
  data: T;
}

export type LoginResType = {
  Token: {
    AUTH_HEADER_TYPES: Array<string>;
    refresh: string;
    access: string;
  };
};

export type EmpDetailResType = {
  boide_id: string | any;
  branch: string;
  company: string;
  email: string;
  face_image: null;
  first_name: string;
  id: string;
  last_name: string;
  middle_name: string;
  phone_number: any;
  unique_id?: any;
};

export type SuccessResType = {
  Success?: any;
  message?: string | any;
};

export type UserParams = {
  search?: string | any;
};

export type DateTypes =
  | "MM/DD/YYYY"
  | "YYYY-MM-DD HH:mm"
  | "YYYY-MM-DD"
  | "DD/MM/YYYY"
  | "YYYY-MM-DD HH:mm:ss"
  | "dddd"
  | "DD MMMM"
  | "YYYY"
  | "Do MMM"
  | "ddd, M MMMM"
  | "ddd"
  | "ddd, D MMMM"
  | "Do MMMM YYYY"
  | "Do MMMM";

export type TimeTypes = "HH:mm" | "hh:mm A" | "h:mm" | "HH:mm:ss";

export type GenerateFormKeysType =
  | "start_date"
  | "start_time"
  | "end_date"
  | "end_time";

export type GenerateFormDataType = {
  start_date: string | any;
  start_time: string | any;
  end_date: string | any;
  end_time: string | any;
};

export type AddEmpResType = {
  Success?: SuccessResType;
  data?: EmpDetailResType;
  message?: string | any;
};

export type InputType =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week"
  | (string & {});

export type LegalsType = {
  document?: any;
  text: string | null;
};

export type LoginType = {
  email: string;
  password?: string;
};

export enum CURRENCY_TYPE {
  TYPE_GBP = "gbp",
  TYPE_USD = "usd",
  TYPE_EUR = "eur",
}

export enum ROLE_TYPE {
  TYPE_OWNER = "O",
  TYPE_SUPPLIER = "S",
  TYPE_CREW = "C",
}
export enum USER_TYPE {
  TYPE_PRIMARY = "P",
  TYPE_SUPPLIER = "S",
  TYPE_CREW = "C",
  TYPE_REQUEST = "R",
}
export enum USER_TYPE_NAME {
  TYPE_SUPPLIER = "Supplier",
  TYPE_CREW = "Crew",
}
export enum MESSAGE_STATUS {
  TYPE_READ = "R",
  TYPE_DELIVERED = "D",
  TYPE_SENT = "S",
}
export enum MESSAGE_TEXT {
  TYPE_READ = "Read",
  TYPE_DELIEVRED = "Delivered",
  TYPE_SENT = "Sent",
}

export enum INTERVAL_TYPE {
  TYPE_MONTH = "month",
  TYPE_YEAR = "year",
  TYPE_WEEK = "week",
}

export type UserDetailsType = {
  current_payment_method_token: string | null;
  date_joined: string | null;
  email: string | null;
  first_name: string | null;
  id: string | null;
  is_email_verified: boolean | false;
  is_mobile_verified: boolean | false;
  is_superuser: boolean | false;
  last_login: string | null;
  last_name: string | null;
  mobile_number: string | null;
  notification_setting: boolean | true;
  stripe_customer_id: string | null;
  unread_notification_count: Number | null;
  profile_image?: null;
  city?: string | null;
  postcode?: string | null;
  country?: string | null;
  imdb_link?: string | null;
  bio?: string | null;
  crew_member_profile?: CrewMemberProfileType;
  supplier_profile?: SupplierProfileType;
  supplier_media: Array<SupplierMediaType>;
  user_type?: UserType;
  account_completed?: boolean | null;
  total_reference?: number | 0;
  subscription_id?: any;
  permission_type?: any;
  owner_profile?: any;
  address?: any;
};

export type UserType = "O" | "S" | "C";

export type SupplierMediaType = {
  id: string | null;
  image: any;
};

export type CrewMemberProfileType = {
  bio: string | null;
  imdb_link: string | null;
  profession: Array<string | null>;
  working_days: Array<string | null>;
  role: Array<{ dep: string | null; role: Array<string | null> }>;
};
export type SupplierProfileType = {
  company_name: any;
  company_logo: string | null;
  bio: string | null;
  services: string | null;
  products: string | null;
  departments: Array<any>;
};

export enum USER_PRESS_ACTION_TYPE {
  TYPE_FLAG = "Flag",
  TYPE_WEBSITE = "Website",
  TYPE_MESSAGE = "Message",
  TYPE_IP = "invite_production",
  TYPE_ASIGN_JOB = "asign_job",
  TYPE_INACTIVE_USER = "inactive_user",
  TYPE_ACTIVE_USER = "active_user",
  TYPE_UPDATE_ROLE = "update_role",
  TYPE_COMPLETE = "complete",
  TYPE_UPDATE = "update",
  TYPE_DELETE = "delete",
}

export enum EXPLORE_TYPE {
  MY_PROD = "production",
  EX_CREW = "crew",
}

export enum PERMISSION_TYPE {
  TYPE_ADMIN = "A",
  TYPE_HOD = "H",
  TYPE_SUPERVISOR = "S",
  TYPE_CREW_MEMBER = "C",
  TYPE_PROD_OWNER = "P",
}

export enum SETTINGS_ITEMS_TYPE {
  TYPE_CHANGE_PASS = "CP",
  TYPE_PP = "PP",
  TYPE_TNC = "TNC",
  TYPE_FAQ = "FAQ",
  TYPE_CONTACT_ADMIN = "CA",
  TYPE_DELETE_PROFILE = "DELETE",
  TYPE_ARCHIVE_AND_DELETE = "ARCHIVE_DELETE",
  TYPE_CHANGE_OWNERSHIP = "CHANGE_OWNERSHIP",
  CANCEL_SUBSCRIPTION = "CANCEL",
}

export enum SERVICE_AND_PROD_TYPE {
  TYPE_PRODUCT = "p",
  TYPE_SERVICE = "S",
}

export enum NOTIFICATION_TYPE {
  TYPE_TNC_UPDATED = "TNC", //Terms and conditions updated
  TYPE_PAYMENT_TERMS_UPDATED = "PTU", // 'Payment terms updated'
  TYPE_PP_UPDATED = "PPU", // 'Privacy policy updated'
  TYPE_ADMIN = "ADMIN", // 'Sent by admin'
  TYPE_JOB_COMPLETED = "JC", //'Job completed'
  TYPE_JOB_ASSIGNED = "JA", //'Job assigned'
  TYPE_JOB_STATUS = "JS",
  TYPE_COMPANY_INVITE = "CI", // 'Company Invite'
  TYPE_REQUEST_UPDATED = "RU", // 'Request Updated'
  TYPE_CHAT_INVITE = "CR", //'Chat Invite'
  TYPE_CHAT = "C", //'Chat Message',
  TYPE_PROFILE_UPDATED = "pu",
  TYPE_EVENT_ASSIGNED = "eu", // 'Event Assigned'
  TYPE_EVENT_REMOVED = "ED",
}
export enum STATUS {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

export type JOB_STATUS = "Relevant" | "Invited" | "Accepted" | "Rejected";

export enum JOB_SCHEDULE_TYPE {
  TYPE_REST = "rest",
  TYPE_JOB = "job",
  TYPE_AVAILABLE = "available",
  TYPE_UN_AVAILABLE = "blocked",
  TYPE_EVENT = "event",
  TYPE_FLAT_DAY = "flat_day",
}
export interface Message {
  message: string;
  sent_by_id: string;
  attachment_url: string;
  attachment_type: string;
  created_at?: string;
  id: string;
  status?: string;
  receiver_id?: string;
  attachment_name?: string;
}

export enum SCHEDULE_TYPE {
  CREW_SCH = "Crew Schedule",
  EVENT_SCH = "Event Schedule",
}

export enum EVENT_TYPE {
  SHOOT = "s",
}

export enum EVENT_STATUS {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

export enum ACTION_TYPE {
  ADD = "add",
  EDIT = "edit",
}

export enum CREW_GROUP_STATUS {
  PUBLISHED_GROUP = "PG",
  MY_GROUP = "MG",
}
