import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ChatRequest from '../../components/modal/ChatRequest';
import { makeGetCall, makePostCall } from '../../effects/requests';
import Tabs from '../../global/ui/Tabs';
import UserBasicInfo from '../../global/ui/UserBasicInfo';
import { ApiResponseType, REQ_STATUS, ROLE_TYPE, USER_PRESS_ACTION_TYPE } from '../../types';
import { emptyFunction, getValidUrl, showToast } from '../../utils';
import { endPoints } from '../../utils/endpoints';
import AboutSupplier from './AboutSupplier';
import Services from './Services';
import SupplierMoreAddresses from '../../components/modal/SupplierMoreAddresses';
import { MyContext } from '../../context';

interface ViewSupplierDetailsProps {
    visible: any;
    onClose: () => void;
    onFlag: () => void;
    supplierDetails: any
    sideBarCss?: boolean;
    hideMessage?: boolean;
    getUpdatedData?: () => void;
}

const ViewSupplierDetails: React.FC<ViewSupplierDetailsProps> = (props) => {

    const navigate = useNavigate();
    const [showChatReqModal, setShowChatReqModal] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [showMoreAddress, setShowMoreAddress] = useState(false)
    const { updateUserData, userData: currentUserData, } = useContext(MyContext)

    const supplierProfile = props?.supplierDetails?.supplier_profile;

    const createRoom = (msg?: string, attachement_url?: string, attachement_type?: string) => {
        if (props?.supplierDetails?.chat_room?.chat_room_created) {
            checkRequestStatus();
            return
        }
        makePostCall(endPoints.ROOM_LIST, {
            'other_user': props?.supplierDetails?.id,
            'message': msg ? msg : '',
            'attachment_url': attachement_url ? attachement_url : '',
            'attachment_type': attachement_type ? attachement_type : ''
        }).then((res) => {
            setShowChatReqModal(false);
            showToast("Chat request sent successfully", "success", 23)
            if (props?.getUpdatedData) {
                props?.getUpdatedData?.();
            }
            // if (currentUserData?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
            //     getUserDetails();
            // }
        }).catch(emptyFunction)
    }

    const checkRequestStatus = () => {
        makeGetCall(endPoints.ROOM_LIST + props?.supplierDetails?.chat_room?.chat_id + '/')
            .then((response) => {
                if (response?.data?.status === REQ_STATUS.PENDING) {
                    showToast("Chat request is pending with user", "info", 323)
                }
                else if (response?.data?.status === REQ_STATUS.DELETED) {
                    showToast("User account is deleted", "error", 323)
                }
                else if (response?.data?.status === REQ_STATUS.REJECTED) {
                    showToast("Chat request is rejected by user", "info", 323)
                }
                else {
                    navigate(`/messages/?search=&user_type=${props?.supplierDetails?.user_type}&room=${props?.supplierDetails?.chat_room?.chat_id}`)
                }
            }).catch(emptyFunction)
    }

    const getUserDetails = () => {
        makeGetCall(endPoints.PROFILE, null, false, true).then((res: ApiResponseType<any>) => {
            if (res?.data?.crew_member_profile?.company_department?.length > 0) {
                res?.data?.crew_member_profile?.company_department?.map((item: any) => {
                    item.label = item?.dep;
                    item.value = item?.dep;
                })
            }
            updateUserData(res);
        }).catch(emptyFunction)
    }

    const flagUser = () => {
        const param = {
            flagged_user: props?.supplierDetails?.id
        }
        makePostCall(endPoints.FLAG_USER, param)
            .then((res) => {
                if (res?.status?.code === 200) {
                    showToast(res?.status?.message, "success")
                }
                else {
                    showToast(res?.message, "error")
                }
            })
            .catch((err) => {

            })
    }

    return (
        <div className={`right-side-popup-background ${props?.visible ? "" : "d-none"} ${props?.sideBarCss ? 'sideBarCss' : ''}`}>
            <div className="right-side-popup-fixed d-grid align-content-between gap-4">
                <div className="right-side-popup-main-top mh-100">
                    <UserBasicInfo
                        userData={{
                            name: supplierProfile?.company_name,
                            linkTitle: 'Website',
                            logo: supplierProfile?.company_logo,
                            dep: 'Supplier',
                            website: supplierProfile?.website,
                        }}
                        isSupplier={true}
                        onClose={() => {
                            props.onClose();
                            setSelectedIdx(0);
                        }}
                        onAction={(type) => {
                            switch (type) {
                                case USER_PRESS_ACTION_TYPE.TYPE_MESSAGE:

                                    { props?.supplierDetails?.chat_room?.chat_room_created ? createRoom() : setShowChatReqModal(true) }

                                    break;

                                case USER_PRESS_ACTION_TYPE.TYPE_WEBSITE:
                                    window.open(getValidUrl(supplierProfile?.website))
                                    break;

                                // case USER_PRESS_ACTION_TYPE.TYPE_FLAG:
                                //     flagUser()
                                //     break;

                                default:
                                    alert('To be implemented')
                                    break;
                            }
                        }}
                        getCrewMem={() => { }}
                        hideMessage={props?.hideMessage}
                        sideBarArrow={props.sideBarCss ? true : false}
                    />
                    <div className="bottom-tabs-wo">
                        <Tabs
                            data={['About', 'Services']}
                            selectedIdx={selectedIdx}
                            onClick={(item: any, idx: any) => setSelectedIdx(idx)}

                        />
                        <div className="tab-content pt-4 tab-overflow">

                            <AboutSupplier onMoreClick={() => setShowMoreAddress(true)} data={props?.supplierDetails} selectedIdx={selectedIdx} />

                            <Services data={props?.supplierDetails} selectedIdx={selectedIdx} />

                        </div>
                    </div>
                </div>
                <ChatRequest
                    show={showChatReqModal}
                    closePopup={() => setShowChatReqModal(false)}
                    onSubmit={createRoom}
                />
                {showMoreAddress &&
                    <SupplierMoreAddresses
                        isShow={showMoreAddress}
                        onClose={() => setShowMoreAddress(false)}
                        locations={props?.supplierDetails?.other_location}
                    />
                }
            </div>
        </div>
    )
}

export default ViewSupplierDetails