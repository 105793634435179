import React from 'react'
import { Modal } from 'react-bootstrap';

const DeleteUser = (props: any) => {
    return (
        <Modal
            centered
            scrollable
            className=" addProductionCrew modal-body-scroll-part"
            show={props?.show}
            onHide={props?.onClose}
        > <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={"/assets/images/delete-event.svg"} alt="" />
                <h5 style={{ fontWeight: '600', paddingTop: '2rem' }}>Are you sure you want to remove this user ?</h5>
            </div>
            <div>
                <button className={'btn btn-warning w-100 mt-4 with-after-shadow shadow-none'}
                    onClick={props?.handleAction}
                > Continue</button>
                <button className={'btn w-100 with-after-shadow shadow-none'} style={{ marginTop: '10px' }} onClick={props?.onClose}> Cancel</button>
            </div>
        </Modal>
    )
}

export default DeleteUser