// @ts-nocheck

import { compose, withProps } from 'recompose';
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle
} from 'react-google-maps';
interface IMyMapComponent {
    lat: any;
    lng: any;
    radius?: any;
    handleMarkerDragEnd?: (evt: any) => void;
    scrollwheel?: boolean
}

export const DEFAULT_LAT = 51.4303165
export const DEFAULT_LNG = -0.3386529

const MyMapComponent = compose(
    withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `350px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withGoogleMap,
)(({ scrollwheel = true, ...props }: IMyMapComponent) => {

    const position = {
        lat: props?.lat || DEFAULT_LAT,
        lng: props?.lng || DEFAULT_LNG
    }

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={position}
            center={position}
            options={{
                zoomControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                streetViewControl: false,
                mapTypeControl: false,
                scrollwheel: scrollwheel,
                // gestureHandling: 'none'
            }}
        >
            <Marker
                position={position}
                draggable={true}
                onDragEnd={props?.handleMarkerDragEnd}
            />
            <Circle
                radius={props?.radius * 800 || 30000}
                options={{
                    strokeColor: "#ffd800",
                    strokeWeight: 1
                }}
                defaultCenter={position}
                center={position}
            />
        </GoogleMap>
    )
});

export default MyMapComponent;