import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { timeSlots } from "../../data/data";
import DropDown from "../../global/ui/dropDown";
import { showToast } from "../../utils";
import { makeDeleteCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { MyContext } from "../../context";
import { ROLE_TYPE } from "../../types";
import Logo from "../../components/commonComponent/ProfileImage";

const SelectedMemberItem = (props: any) => {
  const [deleteIcon, setDeleteIcon] = useState<any>(true);
  const [schedule, setSchedule] = useState(false);
  const { userData } = useContext(MyContext)
  const isOwner = userData?.user_type === ROLE_TYPE.TYPE_OWNER || userData?.user_type === ROLE_TYPE.TYPE_CREW ? true : false;

  //const [start, setStart] = useState(props?.startTime ? moment(props?.startTime,'HH:mm').format('h:mm A') :setDropdownSelectedEventTime(timeSlots,props?.item?.start_time?.substring(11, 16)).value) 
  //const [end, setEnd] = useState(props?.endTime ? moment(props?.endTime,'HH:mm').format('h:mm A') : setDropdownSelectedEventTime(timeSlots,props?.item?.end_time?.substring(11, 16)).value)
  const [start, setStart] = useState('');
  // const [end, setEnd] = useState(props?.item?.event ? setDropdownSelectedEventTime(timeSlots, (props?.item?.end_time?.substring(11, 16)))?.value : props?.item?.end_time ? moment(props?.item?.end_time, 'HH:mm').format('h:mm A') : setDropdownSelectedEventTime(timeSlots, (props?.data?.end_datetime?.substring(11, 16))).value);
  const [end, setEnd] = useState('');
  //|| props?.data?.end_time?.substring(11, 16))).value
  const [saveButton, setSaveButton] = useState(false);

  useEffect(() => {
    // if (props?.item?.event) {
    //   setStart(setDropdownSelectedEventTime(timeSlots, (props?.item?.start_time?.substring(11, 16)))?.value)
    //   setEnd(setDropdownSelectedEventTime(timeSlots, (props?.item?.end_time?.substring(11, 16)))?.value)
    // } else {
    //   setStart(props?.startTime);
    //   setEnd(props?.endTime);
    // }
    setStart(props?.startTime);
    setEnd(props?.endTime);

    // setStart(props?.item?.event ? setDropdownSelectedEventTime(timeSlots, (props?.item?.start_time?.substring(11, 16)))?.value : props?.item?.start_time ? moment(props?.item?.start_time, 'HH:mm').format('h:mm A') : setDropdownSelectedEventTime(timeSlots, (props?.data?.start_datetime?.substring(11, 16))).value)
  }, [props?.startTime, props?.endTime])
  const optionForTime = (start: any, end: any, start_time: boolean) => {
    const filteredArray = timeSlots.filter((obj: any) => {
      const time = obj.startTime;
      const startTime = moment(props?.startTime, 'h:mm A').format('HH:mm')
      const endTime = moment(props?.endTime, 'h:mm A').format('HH:mm')

      if (start_time) {
        if (time >= startTime && time < endTime) {
          return obj;
        }
      }
      else {
        if (time > startTime && time <= endTime) {
          return obj;
        }
      }
      //return start_time ? time.isSameOrAfter(start) && time.isBefore(end) : time.isAfter(start) && time.isSameOrBefore(end);
    });
    return filteredArray;
  }
  const handleDelete = () => {
    setDeleteIcon(!deleteIcon);
    if (props?.item?.event_created_already) {
      makeDeleteCall(`${endPoints.CREATE_EVENT_ASSIGNEE}${props?.item?.id}`)
        .then((res) => {
          props?.getScheduleData()
        })
        .catch((err) => {

        })
    }
    props.deleteMember(props.item);

  };
  const handleScheduleChange = () => {
    setSchedule(!schedule);
  };

  const getUserName = (data: any) => {
    let name = `${data?.first_name || data?.assigned_member?.first_name} ${data?.last_name || data?.assigned_member?.last_name}`
    if (props?.selectedTab == 'Supplier' && data?.assigned_member?.supplier_profile?.company_name) {
      name = data?.assigned_member?.supplier_profile?.company_name
    }
    return name
  }
  return (
    <>
      {deleteIcon && (
        <div className="crew-member-row align-items-center">
          <div style={{ cursor: props?.onUserSelect ? 'pointer' : 'default' }} onClick={() => props?.onUserSelect?.(props?.item)} className="outer-container">
            {(props?.item?.assigned_member?.profile_image || props?.item?.profile_image) ? <img
              className="crew-img"
              src={props?.item?.assigned_member?.profile_image || props?.item?.profile_image}
              alt="" />
              : <Logo style={{ width: '25px', height: '25px' }} data={props?.item?.first_name?.[0] || props?.item?.assigned_member?.first_name?.[0] || props?.item?.last_name?.[0] || props?.item?.assigned_member?.last_name?.[0]} />
            }
            <div className="memeber-name other-event-title text-start lh-sm">
              {getUserName(props?.item)}
              {((props.item?.assigned_member?.verified && props.item?.assigned_member?.user_type === ROLE_TYPE.TYPE_CREW) || (props.item?.verified && props.item?.user_type === ROLE_TYPE.TYPE_CREW)) &&
                <img style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-1px' }} src={"/assets/images/yellow_check_mark.png"} alt="" />
              }
              <br />
              <span className="small fw-light ">
                {props?.item?.job_title}
              </span>
            </div>
          </div>

          <div className="outer-right-container d-flex gap-2 text-center w-100 justify-content-end">
            {props?.item?.available === false && props?.selectedTab !== 'Supplier' && <img src={"/assets/images/unavailable.svg"} />}
            {!schedule && (props?.item?.available || props?.selectedTab === 'Supplier') && (
              <div className="start-end-time bg-transparent fw-bolder" >
                {start} - {end}
              </div>
            )}
            {schedule && (
              <div className="d-flex gap-2 text-center align-items-center">
                <div className={`form_selcet w-100 arrow-fill ${props?.classNameFlag}`}>
                  <div className="input_box">
                    {/* <Select
                      name="country"
                      aria-label={'name'}
                      className="basic-multi-select shadow-none black-input"
                      classNamePrefix="select"
                      value={start}
                      onChange={(options: any) => { setStart(options.value) }}
                      options={optionForTime(start, end, true)}
                      placeholder={' '}
                      isSearchable={false}
                    /> */}
                    <DropDown
                      className="simple-dropdonw mb-0 with-label-setup"
                      // data={optionForTime(start, end, true)}
                      data={timeSlots}
                      onSelect={(options: any) => {
                        if (end > options.value) {
                          setStart(options.value)
                        } else {
                          setStart(start);
                          setSchedule(!schedule)
                          showToast('Time allocated must be within the event time. Please try again.', 'error')
                        }
                        //setStart(options.value)
                      }}
                      selectedItems={start}
                      placeholder={' '}
                      isSearchable={false}
                      label={start}
                    // errorMessage={formState.errors?.[apiConstants.eventEndTime]?.message}
                    //   selectedItems={selectedPermsn}
                    />
                  </div>
                </div>
                <b>-</b>
                <div className={`form_selcet w-100  arrow-fill ${props?.classNameFlag}`}>
                  <div className="input_box">
                    {/* <Select
                      name="country"
                      className="basic-multi-select shadow-none black-input"
                      classNamePrefix="select"
                      value={end}
                      onChange={(options: any) => setEnd(options.value)}
                      options={optionForTime(start, end, false)}
                      placeholder={' '}
                      isSearchable={false}
                    /> */}
                    <DropDown
                      className="simple-dropdonw mb-0 with-label-setup"
                      // data={optionForTime(start, end, false)}
                      data={timeSlots}
                      onSelect={(options: any) => {
                        if (start < options.value) {
                          setEnd(options.value)
                        } else {
                          setEnd(end);
                          setSchedule(!schedule)
                          showToast('Time allocated must be within the event time. Please try again.', 'error')
                        }
                      }}
                      selectedItems={end}
                      placeholder={' '}
                      isSearchable={false}
                      label={end}
                    // errorMessage={formState.errors?.[apiConstants.eventEndTime]?.message}
                    //   selectedItems={selectedPermsn}
                    />
                  </div>
                </div>
              </div>
            )}
            {!schedule && isOwner && (props?.item?.available || props?.selectedTab === 'Supplier') &&
              <img
                src={"/assets/images/delete-event.svg"}
                onClick={() => handleDelete()}
                alt=""
                className="cursor-pointer"
              />
            }
            {!schedule && isOwner && (props?.item?.available || props?.selectedTab === 'Supplier') &&
              <img
                src={"/assets/images/calander.svg"}
                onClick={() => handleScheduleChange()}
                alt=""
              />
            }
            {
              schedule && <img
                src={"/assets/images/save-button-green.svg"}
                onClick={() => { props?.updateObject(props?.item?.id, { start_time: start, end_time: end }, props?.item); setSchedule(!schedule) }}
                alt=""
              />
            }
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedMemberItem;