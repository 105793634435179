import React from 'react'
import { createFullName } from '../../utils';
import { DateUtils } from '../../utils/dateUtils';
import Logo from '../../components/commonComponent/ProfileImage';
interface ICrewGroupItem {
    onClick: (data: any) => void;
    item: any
}

const CrewGroupItem: React.FC<ICrewGroupItem> = (props) => {

    const { item } = props;

    const memberData = item?.member_data?.length > 0 ? item?.member_data : [];

    return (
        <div className="col-md-12 col-lg-6 col-xl-4">
            <div style={{ minHeight: '190px' }} className="card explore_crew_card">
                <div className="card-body d-flex align-items-start">
                    {item?.production?.company_logo
                        ?
                        <img src={item?.production?.company_logo} className="user-img rounded-circle" alt="" />
                        :
                        <Logo
                            style={{ width: '70px', height: '70px' }}
                            data={item?.production?.company_name?.[0] || ''}
                        />
                    }
                    <div className="cloud-data crew-group">
                        <h4 style={{ cursor: 'pointer', textTransform: 'capitalize' }} onClick={() => props?.onClick?.(item)}>{item?.name || ""}</h4>
                        <div className='d-flex justify-content-start align-items-center mb-2 mt-1'>
                            {memberData?.map((memItem: any, idx: any) => {
                                if (idx > 2) return null
                                return (
                                    <div style={{ zIndex: memberData.length - idx }}>
                                        {memItem?.member?.profile_image
                                            ?
                                            <img className='crews' src={memItem?.member?.profile_image} alt="crew image" />
                                            :
                                            <Logo
                                                className='crews'
                                                data={memItem?.member?.first_name?.[0] || ''}
                                            />
                                        }
                                    </div>
                                )
                            })}
                            {memberData?.length > 3 && <p style={{ marginLeft: '15px', textDecoration: 'underline' }} className='mb-0'>{`+${memberData?.length - 3} others`}</p>}
                        </div>
                        <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{item?.description || ''}</p>
                        <p className='mb-2'><img className='crew-group-icons' src={"/assets/images/user-icon-perple.svg"} alt="" />{`Created by ${createFullName(item?.created_by?.first_name || '', item?.created_by?.last_name || '')}`}</p>
                        <p className='mb-0'><img className='crew-group-icons' src={"/assets/images/Location-icon-perple.svg"} alt="" />{`Last Edited ${DateUtils.formatDateTime(item?.modified_at, 'DD/MM/YYYY HH:mm')}`}</p>
                    </div>
                </div>
                <p style={{ cursor: 'pointer' }} onClick={() => props?.onClick?.(item)} className="play-icon"><img src={"/assets/images/play-icon-dark.svg"} alt="" /></p>
            </div>
        </div>
    )
}

export default CrewGroupItem