import React, { useContext, useEffect, useState } from "react";
import SelectedMembers from "./SelectedMembers";
import Notes from "./Notes";
import { makeDeleteCall, makeGetCall, makePatchCall, makePostCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { setDropdownSelectedEventTime, showToast } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { EVENT_STATUS, ROLE_TYPE, USER_TYPE, USER_TYPE_NAME } from "../../types";
import { Modal } from "react-bootstrap";
import { DateUtils } from "../../utils/dateUtils";
import moment from "moment";
import { timeSlots } from "../../data/data";
import { MyContext } from "../../context";

const EventDetail = (props: any) => {
  const [selectedTab, setSelectedTab] = useState("Info");
  const [noteData, setNoteData] = useState<any>([]);
  const [noteText, setNoteText] = useState('');
  const [attachments, setAttachments] = useState<any>();
  const [nextURL, setNextURL] = useState('');
  const [selectedCrew, setSelectedCrew] = useState<any>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<any>([]);
  const [removeEvent, setRemoveEvent] = useState(false);
  const [haveEditPermission, setHaveEditPermission] = useState(true);

  const startLocalTime = DateUtils.removeUTCString(props?.eventDetail?.start_datetime);
  const endLocalTime = DateUtils.removeUTCString(props?.eventDetail?.end_datetime);

  const dateAndTime = `${DateUtils.formatDateTime(startLocalTime, 'DD MMM')} ${DateUtils.formatDateTime(startLocalTime, 'hh:mm A')} - ${DateUtils.formatDateTime(endLocalTime, 'hh:mm A')}`

  const extractStartTime = setDropdownSelectedEventTime(timeSlots, props?.eventDetail?.start_datetime.substring(11, 16))?.value
  // DateUtils.formatDateTime(props?.eventDetail?.start_datetime, 'hh:mm a');
  const extractEndTime = setDropdownSelectedEventTime(timeSlots, props?.eventDetail?.end_datetime.substring(11, 16))?.value

  //To know whether its a superuser or not
  const { userData } = useContext(MyContext)
  const getNoteData = () => {
    makeGetCall(`${endPoints.NOTE}`, { event: props?.eventDetail?.id }).then((res) => {
      setNoteData(res?.results)
      setNextURL(res?.next);
    });
  }

  const handleImageClick = () => {
    const inputElement = document.getElementById("fileInput");
    if (inputElement) {
      inputElement.click();
    }
  };
  //When user adds the file
  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setAttachments(selectedFile);
  };
  //To Display the files attached by user before sending - for only user is allowed to attach single file
  const renderAttachedFiles = () => {
    const fileType = attachments?.name?.slice(-3);
    let src: any;
    switch (fileType) {
      case 'png':
      case 'jpg':
        src = "/assets/images/image-example.svg";
        break;
      case 'mp4':
        src = "/assets/images/mp4-example.svg";
        break;
      case 'pdf':
        src = "/assets/images/pdf-example.svg";
        break;
      case 'xls':
        src = "/assets/images/xls-example.svg";
        break;
      default:
        src = "/assets/images/image-example.svg";
    }
    return <div className="add-file-inchat d-flex justify-content-between border px-3 mb-3">
      <div className="d-flex gap-2 align-items-center w-100">
        <img src={src} alt='' />
        <h6 className="mb-0 fw-bold">{attachments.name}</h6>
      </div>
      <img src={"/assets/images/delete-fill-inner-red.svg"} alt='' onClick={() => removeAttachment(attachments?.name)} />
    </div>
  }
  //remove attachement
  const removeAttachment = (name: any) => {
    // const updateattachment = attachments.filter((item:any) => item.name != name )
    setAttachments(null);
  }
  useEffect(() => {
    if (selectedTab === USER_TYPE_NAME.TYPE_CREW) {
      getSelectedAssignee(USER_TYPE.TYPE_CREW)
    }
    if (selectedTab === USER_TYPE_NAME.TYPE_SUPPLIER) {
      getSelectedAssignee(USER_TYPE.TYPE_SUPPLIER);
    }
  }, [selectedTab])
  const getSelectedAssignee = (type: any) => {
    makeGetCall(endPoints.CREATE_EVENT_ASSIGNEE, { user_type: type, event: props?.eventDetail?.id })
      .then((res) => {
        res?.results.forEach((obj: any) => {
          obj['event_created_already'] = true;
          obj['start_time'] = setDropdownSelectedEventTime(timeSlots, obj?.start_time.substring(11, 16)).value;
          obj['end_time'] = setDropdownSelectedEventTime(timeSlots, obj?.end_time.substring(11, 16)).value;
        });
        { type === USER_TYPE.TYPE_CREW ? setSelectedCrew(res?.results) : setSelectedSupplier(res?.results) }
      })
  }

  //Handle submitting the Note
  const handleNoteSubmit = () => {
    if (attachments || noteText) {
      const payload = {
        event_id: props?.eventDetail?.id,
        note: noteText
      }
      makePostCall(endPoints.NOTE, payload).then(res => {
        if (attachments) {
          const formData = new FormData();
          formData.append('note_id', res?.data?.id);
          formData.append('media', attachments)
          uploadMedia(formData)
        } else {
          showToast('Successfully added note', 'success')
          setNoteText('');
          getNoteData();
          setAttachments(null);
        }
      })
    } else {
      showToast('Please add notes or select attachments.', 'error')
    }
  }

  const uploadMedia = (payload: any) => {
    makePostCall(endPoints.MEDIA, payload).then(res => {
      showToast('Successfully added note', 'success')
      setNoteText('');
      getNoteData();
      setAttachments(null);
    })
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleNoteSubmit();
    }
  };
  //Handle delete Note
  const deleteNote = (note: any) => {
    makeDeleteCall(`${endPoints.NOTE}${note.id}`).then(res => {
      getNoteData()
      showToast('Deleted Successfully ', 'success')
    }
    )
  }
  //Handle Update Note
  const updateNote = (note: any, commentText: string, file: any) => {
    makePatchCall(`${endPoints.NOTE}${note?.id}/`, { note: commentText }).then(res => {
      let formData = new FormData();
      formData.append('note_id', res?.data?.id);
      formData.append('media', file)
      if (file) {
        makePostCall(endPoints.MEDIA, formData).then(res => {
          getNoteData();
          showToast('Successfully uploaded the file', 'success')
        })
      }
      getNoteData();
      showToast('Successfully updated the note', 'success')
    })
  }
  const fetchMoreData = () => {
    if (nextURL) {
      makeGetCall(nextURL).then((res) => {

        setNextURL(res.next);
        // setNoteData((prevState:any[]) =>prevState.push(res?.results) );
        setNoteData((prevState: any[]) => [
          ...prevState,
          ...res.results
        ]);
      });
    }
  }
  const updateStatus = (status: any) => {
    makePatchCall(endPoints.CREATE_EVENT_ASSIGNEE + `${props?.eventDetail?.id}/`, { status: status })
      .then((res) => {
        showToast('Event Updated Successfully', 'success')
        props?.onClose();
      })
      .catch((err) => {

      })
  }

  const deleteEvent = () => {
    makeDeleteCall(endPoints.CREATE_EVENT + `${props?.eventDetail?.id}/`)
      .then((res) => {
        showToast('Event Deleted Successfully', 'success')
        setRemoveEvent(false)
        props?.onClose();
        props?.getScheduleData()
      })
      .catch((err) => {

      })
  }
  //Set event type 
  const handleEventType = (type: string) => {
    switch (type) {
      case 's': return 'Shoot';
      case 'p': return 'Prep';
      case 'st': return 'Strike';
      case 'm': return 'Meeting';
      case 'ss': return 'Second Unit Shoot';
      case 'sp': return 'Splinter';
    }
  }

  //Handling Accept and Reject - For Supplier Flow
  const handleAcceptReject = (id: any, isReject?: boolean) => {
    makePatchCall(`${endPoints.ACCEPT_EVENT}${id}/`, { status: isReject ? '3' : '2' }).then((res) => {
      const toastMsg = `${isReject ? 'Invitation has been rejected' : 'Invitation has been accepted. This will be added to your calendar'}`
      showToast(toastMsg, 'success');
      props?.getScheduleData();
      props?.onClose();
    })
  }

  useEffect(() => {
    if ((userData?.permission_type == 'S' && userData?.user_type == "C") || (userData?.permission_type == 'H' && userData?.user_type == "C")) {
      const userTempData = { ...userData };
      let companyDepartment = userTempData?.crew_member_profile?.company_department
      if (companyDepartment && typeof companyDepartment == 'string') {
        companyDepartment = JSON.parse(companyDepartment)
      }
      let isHave = false;
      if (companyDepartment?.length > 0) {
        companyDepartment?.map((item: any) => {
          if (!isHave) {
            const idx = props?.eventDetail?.department?.findIndex((x: any) => x == item?.dep)
            if (idx != -1) {
              isHave = true
            }
          }
        })
      }
      setHaveEditPermission(isHave)
    }
  }, [JSON.stringify(userData)])

  return (
    <div
      className={`right-side-popup-background  ${props?.visible ? "" : "d-none"} `}>
      <div className="right-side-popup-fixed d-grid align-content-between gap-4">
        <div className="right-side-popup-main-top">
          <div className="popup-right-side-top" style={{ paddingBottom: "15px" }} >
            <div className="cross-and-dropdonw-selct d-flex justify-content-between align-items-center mb-0">
              <img
                src={"/assets/images/dismiss-cross-black.svg"}
                alt=""
                className="img-cross-icon"
                onClick={props.onClose}
              />
              <div className="edit-event-type">{handleEventType(props?.eventDetail?.type)}</div>
            </div>
            <h4
              className="h4 text-start mt-0 word-break"
              style={{ fontWeight: "700", paddingTop: "20px" }}
            >
              {props?.eventDetail?.name}
            </h4>
            <p className="sub-text">{props?.eventDetail?.sub_category}</p>
          </div>
          <div className="bottom-tabs-wo">
            <ul className="nav">
              <li className="nav-item" onClick={() => { setSelectedTab("Info"); }}>
                <a className={`nav-link ${selectedTab === "Info" ? "active" : ""}`}> Info</a>
              </li>
              <li className="nav-item" onClick={() => { setSelectedTab("Crew"); }}>
                <a className={`nav-link ${selectedTab === "Crew" ? "active" : ""}`}>Crew</a>
              </li>
              <li className="nav-item" onClick={() => { setSelectedTab("Supplier"); }} >
                <a className={`nav-link ${selectedTab === "Supplier" ? "active" : ""}`}> Supplier</a>
              </li>
              <li className="nav-item" onClick={() => { getNoteData(); setSelectedTab("Notes"); }}>
                <a className={`nav-link ${selectedTab === "Notes" ? "active" : ""}`}>Notes</a>
              </li>
            </ul>
          </div>
          {selectedTab === "Info" && (
            <div className="edit-info-tab">
              <div className="info-content">
                <img src={"/assets/images/calendar-purple.svg"} />
                <p>{dateAndTime}</p>
              </div>
              {props?.eventDetail?.address && <div className="info-content">
                <img src={"/assets/images/location-purple.svg"} style={{ paddingBottom: '6px' }} />
                <p>{props?.eventDetail?.address}</p>
              </div>}
              {props?.eventDetail?.description && <div className="info-content">
                <img src={"/assets/images/note-perple.svg"} />
                <p>{props?.eventDetail?.description}</p>
              </div>}
              {props?.eventDetail?.meeting_link && <div className="info-content">
                <img src={"/assets/images/meeting-purple.svg"} />
                <p>{props?.eventDetail?.meeting_link}</p>
                <a href={props?.eventDetail?.meeting_link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', marginTop: '3px' }}>Join</a>
              </div>}
            </div>
          )}
          {selectedTab === "Crew" &&
            (<div className="crew-scroll mx-2 p-0">
              <SelectedMembers
                tempData={selectedCrew}
                setSelectedList={setSelectedCrew}
                startTime={extractStartTime}
                endTime={extractEndTime}
                date={moment(props?.eventDetail?.start_datetime).format('YYYY-MM-DD')}
                editEventId={props?.eventDetail?.id}
                selectedTab='Supplier'
                getScheduleData={props?.getScheduleData}
                onUserSelect={(data: any) => props?.onUserSelect?.(data, ROLE_TYPE.TYPE_CREW)}
              />
            </div>
            )}
          {selectedTab === "Supplier" &&
            (<div style={{ padding: '5px', margin: '5px' }}>
              <SelectedMembers
                tempData={selectedSupplier}
                setSelectedList={setSelectedSupplier}
                startTime={extractStartTime}
                endTime={extractEndTime}
                date={moment(props?.eventDetail?.start_datetime).format('YYYY-MM-DD')}
                editEventId={props?.eventDetail?.id}
                selectedTab='Supplier'
                getScheduleData={props?.getScheduleData}
                onUserSelect={(data: any) => props?.onUserSelect?.(data, ROLE_TYPE.TYPE_SUPPLIER)}
              />
            </div>
            )}
          {selectedTab === "Notes" &&
            (<div className={`scrollAsdNotes ${attachments ? 'notes_with_attachment_scroll' : ''}`} id="scrollableDiv2">
              <InfiniteScroll
                dataLength={noteData.length}
                next={fetchMoreData}
                style={{ display: 'flex', flexDirection: 'column-reverse', overflowAnchor: 'none', contentVisibility: "auto" }} //To put endMessage and loader to the top.
                inverse={true}
                hasMore={nextURL ? true : false}
                loader={null}
                scrollableTarget="scrollableDiv2"
              >
                {noteData?.map((note: any, idx: any) => {
                  return <div style={{ margin: '2px 0' }} key={idx}>
                    {/* <div onClick={()=> deleteNote(note) }>{idx+1} Note:{note?.note}</div>
              <div> Media {note?.note_media && <p>{note?.note_media.length}</p>} </div>  */}
                    {/* Media:{note?.note_media && <p>Note media</p>} */}
                    <Notes
                      noteData={note}
                      deleteNote={() => deleteNote(note)}
                      updateNote={updateNote}
                      userData={userData}
                    />
                    {/* <NotesTest noteData={note} deleteNote={() => deleteNote(note)}/> */}
                  </div>
                })}
              </InfiniteScroll>
            </div>
            )}
        </div>
        <div className="right-side-popup-button" style={{ paddingBottom: '10px' }}>
          {selectedTab !== 'Notes' && props?.eventStatus != EVENT_STATUS.ACCEPTED && <ul className="d-flex list-unstyled gap-3 justify-content-between align-item-center w-100 mb-0">
            <li className="w-100">
              {(haveEditPermission && (props?.isProdSuperUser || props?.isSuperUser)) &&
                <button
                  type="button"
                  onClick={() => {
                    if (props?.eventStatus === EVENT_STATUS.PENDING) {
                      handleAcceptReject(props?.supplierID, true)
                    } else {
                      setRemoveEvent(true)
                    }
                  }}
                  className="btn btn-white w-100"
                >
                  {props?.isProdSuperUser ? `Remove Event` : `Reject Event`}
                </button>
              }
            </li>
            <li className="w-100">
              {(haveEditPermission && (props?.isProdSuperUser || props?.isSuperUser)) &&
                <button
                  type="button"
                  onClick={() => {
                    if (props?.eventStatus === EVENT_STATUS.PENDING) {
                      handleAcceptReject(props?.supplierID)
                    } else {
                      props.onAdd(props?.eventDetail?.id)
                    }
                  }
                  }
                  className="common_btn btn btn-warning w-100 with-after-shadow shadow-none"
                >
                  {props?.isProdSuperUser ? `Edit Event` : `Accept Event`}
                </button>
              }
            </li>
          </ul>}
          {selectedTab === 'Notes' &&
            <div>{attachments && <div>{renderAttachedFiles()}</div>}
              <form className="note-message">
                <input type='text' placeholder="Type message.." value={noteText} onChange={(e) => setNoteText(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
                <img src={"/assets/images/attach-file-icon.svg"} alt="" onClick={() => handleImageClick()} />
                <input
                  id="fileInput"
                  type="file"
                  accept=".jpg, .mp4, .pdf, .xls, .png"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <img src={"/assets/images/send-message.svg"} alt="" onClick={() => handleNoteSubmit()} />
              </form></div>}
        </div>
      </div>
      {removeEvent &&
        <Modal
          centered
          scrollable
          className=" addProductionCrew modal-body-scroll-part"
          show={removeEvent}
          onHide={close}
        > <div className="d-flex flex-column align-items-center justify-content-center">
            <img src={"/assets/images/delete-red.svg"} alt="" />
            <h5 style={{ fontWeight: '600', paddingTop: '2rem' }}>Remove Event</h5>
            <p style={{ textAlign: 'center', marginTop: '10px' }}>Are you sure you want to delete event? This will notify all associated members.</p>
            <button className={'btn btn-remove w-100 with-after-shadow shadow-none'} onClick={() => deleteEvent()}> Remove Event</button>
            <button className={'btn w-100 with-after-shadow shadow-none'} style={{ marginTop: '10px' }} onClick={() => setRemoveEvent(!removeEvent)}> Cancel</button>
          </div>
        </Modal>}
    </div>
  );
};

export default EventDetail;
