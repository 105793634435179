import { debounce } from 'lodash';
import React, { useCallback, useContext, useState } from 'react'
import { makeGetCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { USER_TYPE } from '../../types';
import { MyContext } from '../../context';
import InfiniteScroll from "react-infinite-scroll-component";
import SuggestedMembers from './SuggestedMembers';
import LoadingDots from '../../global/ui/LoadingDots';

interface IUserCustomSearch {
    onSelect: (data: any) => void;
    selectedMem: Array<any>;
}

const UserCustomSearch: React.FC<IUserCustomSearch> = (props) => {

    const { userData } = useContext(MyContext);

    const [searchValue, setSearchValue] = useState('');
    const [allData, setAllData] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onSearch = (value: any) => {
        const params = {
            user_type: USER_TYPE.TYPE_CREW,
            production_company_name: userData?.production_company_name || '',
            search: value
        }
        if (!value) {
            onClear();
            return
        }
        makeGetCall(endPoints.USERLIST, params, false, true)
            .then((res) => {
                setIsLoading(false)
                setAllData(res?.results);
                setNextPageUrl(res?.next || null);
            })
            .catch(() => setIsLoading(false))
    }

    const onChangeTextDebounce = useCallback(debounce(onSearch, 800), []);

    const fetchMoreData = () => { };

    const onClear = () => {
        setSearchValue('');
        setAllData([]);
        setNextPageUrl(null);
        setIsLoading(false)
    }

    const selectedIds = props?.selectedMem?.map(obj => obj.id);

    return (
        <div className="tab-content">
            <div className="tab-title px-0">Assign Crew</div>
            <div className="search-button d-flex align-items-center gap-3 justify-content-center newEvent-search">
                <input
                    type="text"
                    className="form-control py-2 px-2 shadow-none assign"
                    value={searchValue}
                    placeholder='Assign Crew members'
                    onChange={(evt) => {
                        setIsLoading(true);
                        setSearchValue(evt.target.value);
                        onChangeTextDebounce(evt.target.value);
                    }}
                    style={{ border: 'none' }}
                />
                {isLoading
                    ?
                    <LoadingDots style={{ marginRight: '5px' }} />
                    :
                    <img
                        src={searchValue ? "/assets/images/cross-grey-fill.svg" : '/assets/images/search-thin-blank.svg'}
                        onClick={() => {
                            if (!searchValue) return
                            onClear();
                        }}
                        alt="User"
                    />
                }
            </div>
            {allData?.length > 0 &&
                <div className="crew-member-list" style={{ position: 'absolute', zIndex: '1', width: '48%' }} >
                    <InfiniteScroll
                        dataLength={allData?.length}
                        next={fetchMoreData}
                        hasMore={nextPageUrl ? true : false}
                        loader={null}
                        scrollableTarget="scrollableUserCustomSearch"
                    >
                        <div>
                            {allData?.map((data: any, idx) => {
                                return (
                                    <SuggestedMembers
                                        item={data}
                                        key={idx}
                                        onSelect={() => props.onSelect(data)}
                                        isSelected={selectedIds.includes(data?.id)}
                                    />
                                )
                            })}
                        </div>
                    </InfiniteScroll>
                </div>
            }
        </div>

    )
}

export default UserCustomSearch