import React from 'react'

interface IAddPrepDiaryBtn {
    onClick: () => void;
    label?: any
}

const AddPrepDiaryBtn = (props: IAddPrepDiaryBtn) => {
    return (
        <div onClick={props.onClick} className="d-flex justify-content-between align-items-center cursor-pointer">
            <div className="d-grid bottom-prep">
                <div className="d-flex align-items-center justify-content-between">
                    {props?.label || 'Add Prep diary'}
                    <img className="cursor-pointer" src="/assets/images/add_prep.svg" alt="" />
                </div>
            </div>
        </div>
    )
}

export default AddPrepDiaryBtn