import React, { useEffect, useState } from 'react'
import { makeGetCall } from '../../effects/requests'
import SideBarLayout from '../../global/layout/SideBarLayout'
import SearchWithHeading from '../../global/ui/SearchWithHeading'
import { ApiResponseType, ROLE_TYPE } from '../../types'
import SupplierItem from './SupplierItem'
import SuppliersFilter from './SuppliersFilter'
import ViewSupplierDetails from './ViewSupplierDetails'
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom'
import CustomPagination from '../../global/ui/CustomPagination'
import NoData from '../../global/ui/NoData'
import { endPoints } from '../../utils/endpoints'
import { defaultSupplierFilter } from '../../utils/constants'

const Suppliers: React.FC<any> = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const parsed = queryString.parse(location.search);
    const queryParams = location?.state || defaultSupplierFilter;

    const [allData, setAllData] = useState([]);
    const [count, setCount] = useState();
    const [supplierDetails, setSupplierDetails] = useState<any>(null);

    useEffect(() => {
        if (parsed?.detail_id) {
            setTimeout(() => {
                fetchDetails(parsed?.detail_id)
            }, 1000);
        }
    }, [parsed?.detail_id])

    const getSuppliers = () => {
        const params: any = { ...queryParams };
        params.offset = Number(params.limit) - 12;
        delete params.limit
        makeGetCall(endPoints.USERLIST, { ...params, user_type: ROLE_TYPE.TYPE_SUPPLIER }).then((res: ApiResponseType<any>) => {
            setAllData(res.results);
            setCount(res?.count);
            setSupplierDetails(null)
        })
    }

    useEffect(getSuppliers, [JSON.stringify(queryParams)]);

    const updateUrls = (key: any, value: any) => {
        const params = queryParams;
        params[key] = value || '';
        navigate('/suppliers', { state: params })
    }

    const fetchDetails = (id: any) => {
        const urls = `${endPoints.USER}${id}`
        makeGetCall(urls).then((res: ApiResponseType<any>) => {
            setSupplierDetails(res?.data)
        })
    }

    const onLocationSearch = (data: any) => {
        const params = { ...queryParams, ...data };
        navigate('/suppliers', { state: params })
    }

    return (
        <SideBarLayout>
            <div className="right-content-full-screen">
                <SearchWithHeading
                    placeholder="Search by Name, Product or Services"
                    onSearch={(value) => updateUrls('search', value)}
                    value={queryParams?.search || ''}
                    heading='Suppliers'
                />
                <div className="bottom-content-full-screen">
                    <SuppliersFilter
                        count={count}
                        updateValue={updateUrls}
                        data={queryParams}
                        showing={allData?.length || 0}
                        onClear={() => navigate('/suppliers', { state: defaultSupplierFilter })}
                        onLocationSearch={onLocationSearch}
                    />
                    <div className="production-supplier-exploree">
                        {allData?.length > 0 ?
                            <div className="row gx-4">
                                {allData?.map((item: any, idx) => {
                                    return (
                                        <SupplierItem
                                            item={item}
                                            key={idx}
                                            onClick={() => fetchDetails(item?.id)}
                                        />
                                    )
                                })}
                            </div>
                            :
                            <NoData />
                        }
                    </div>
                    {Number(count) > 12 && <CustomPagination queryParams={queryParams} count={count} onUpdate={updateUrls} />}
                </div>
            </div>
            <ViewSupplierDetails
                visible={supplierDetails ? true : false}
                onClose={() => setSupplierDetails(null)}
                supplierDetails={supplierDetails}
                onFlag={() => alert('To be implemented')}
                getUpdatedData={() => fetchDetails(supplierDetails?.id)}
            />
        </SideBarLayout>
    )
}

export default Suppliers