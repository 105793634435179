
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { makePatchCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import moment from 'moment';
import { emptyFunction } from '../../utils';
import { useContext, useEffect, useState } from 'react';
import { MyContext } from '../../context';

const CalendarSync = () => {

    const { userData } = useContext(MyContext)

    const [haveAccess, setHaveAccess] = useState(false);

    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            const data = {
                google_synced: true,
                google_access_token: tokenResponse?.access_token,
                google_refresh_token: tokenResponse?.access_token,
                google_exp: `${moment().add(55, 'minutes').format('YYYY-MM-DD HH:mm:ss')}`
            }
            makePatchCall(endPoints.PROFILE, data).then((res) => {
                setHaveAccess(res?.data?.google_synced || false);
            }).catch(emptyFunction)
        },
        onError: emptyFunction,
        scope: 'https://www.googleapis.com/auth/calendar',
    });

    const remove = () => {
        makePatchCall(endPoints.PROFILE, { google_synced: false }).then((res) => {
            setHaveAccess(res?.data?.google_synced || false);
            googleLogout();
        }).catch(emptyFunction)
    }

    useEffect(() => {
        setHaveAccess(userData?.google_synced || false)
    }, [userData?.google_synced])

    return (
        <div style={{ marginTop: '-35px', }} className="w-100">
            <p style={{ fontSize: '16px', color: '#000000', fontWeight: 700, marginLeft: '40px' }}>{!haveAccess ? 'Sync New Calendar' : 'Connected Calendars'}</p>
            <div style={{ borderBottom: "1px solid #F2F2F2" }} />
            <div style={{ paddingLeft: '38px' }} className='d-flex align-items-center mb-2 mt-4'>
                <div style={{ marginRight: '30px', }} className='d-flex justify-content-center align-items-center'>
                    <img className='rounded-circle' style={{ width: '40px', height: '40px', marginRight: '10px', }} src="/assets/images/google_cal.svg" alt="" />
                    <span style={{ fontSize: '16px', color: '#000000', fontWeight: 700 }} className='ml-2 conflict-crew'>{'Google Calendar'}</span>
                </div>
                <div
                    onClick={() => {
                        if (haveAccess) {
                            remove()
                            return
                        }
                        login()
                    }}
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    style={{ backgroundColor: haveAccess ? "rgba(255, 51, 51, 0.12)" : 'rgba(122, 51, 255, 0.24)', padding: '4px 10px 4px 10px', borderRadius: '10px' }}
                >
                    <img style={{ marginRight: '10px', }} src={haveAccess ? "/assets/images/delete-fill-inner-red.svg" : "/assets/images/cal_sync.svg"} />
                    <span style={{ fontSize: '12px', color: haveAccess ? 'red' : 'rgba(122, 51, 255, 1)', fontWeight: 600, }}>{haveAccess ? "Remove" : "Sync"}</span>
                </div>
            </div>
        </div>
    )
}

export default CalendarSync