import React, { useState } from 'react'
import { Modal, ModalHeader, ModalTitle } from 'react-bootstrap';
import { ApiResponseType, NUMBER_STATUS } from '../../../types';
import Final from './Final';
import SelectMembers from './SelectMembers';
import AddBroadCastMsg from './AddBroadCastMsg';
import { useForm } from 'react-hook-form';
import { newBroadCastStepOneFormData, newBroadCastStepOneSchema, newBroadCastStepTwoFormData } from '../../../data/formData';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiConstants } from '../../../utils/apiConstants';
import { makePostCall } from '../../../effects/requests';
import { endPoints } from '../../../utils/endpoints';
import { generateFormData } from '../../../utils';

interface NewBroadCastProps {
    isShow: boolean;
    onClose: () => void;
}

const NewBroadCast: React.FC<NewBroadCastProps> = (props) => {

    const [activeStep, setActiveStep] = useState(NUMBER_STATUS.STATUS_1);
    const [isLoading, setIsLoading] = useState(false);

    const { ...stepOneFormData } = useForm({
        mode: 'all',
        defaultValues: { ...newBroadCastStepOneFormData() },
        reValidateMode: 'onBlur',
        resolver: yupResolver(newBroadCastStepOneSchema()),
    });

    const { ...stepTwoFormData } = useForm({
        mode: 'all',
        defaultValues: { ...newBroadCastStepTwoFormData() },
        reValidateMode: 'onBlur'
    });

    const onSubmit = (data: any) => {
        const fd = generateFormData(data);
        fd.append(apiConstants.user_ids, JSON.stringify(stepOneFormData.getValues(apiConstants.user_ids)))
        setIsLoading(true)
        makePostCall(endPoints.BROADCAST, fd).then((res: ApiResponseType<any>) => {
            setIsLoading(false)
            if (res.status?.code === 200) {
                setActiveStep((prevState) => prevState + 1)
            }
        }).catch(() => setIsLoading(false))
    }

    const handleClose = () => {
        stepOneFormData.reset();
        stepTwoFormData.reset();
        setActiveStep(NUMBER_STATUS.STATUS_1);
        props.onClose();
    }

    return (
        <Modal
            centered
            scrollable
            className='new_broadcast'
            show={props.isShow}
        // onHide={handleClose}
        >
            {(activeStep === NUMBER_STATUS.STATUS_1 || activeStep === NUMBER_STATUS.STATUS_2) &&
                <ModalHeader className="p-0 border-0 align-items-start">
                    <ModalTitle className="fw-bold me-auto text-start">
                        <h4 className='h4 text-start mb-0 mt-0'>New Broadcast</h4>
                    </ModalTitle>
                    <button className="close-btn-de shadow-none" onClick={handleClose}>
                        <img src={"/assets/images/cancel-button.svg"} alt="" />
                    </button>
                </ModalHeader>
            }
            {activeStep === NUMBER_STATUS.STATUS_1 &&
                <><SelectMembers
                    formData={stepOneFormData}
                    onSubmit={(data: any) => setActiveStep((prevState) => prevState + 1)}
                    activeStep={activeStep}
                />
                    <div className='mb-4'></div></>
            }
            {activeStep === NUMBER_STATUS.STATUS_2 &&
                <AddBroadCastMsg
                    formData={stepTwoFormData}
                    onSubmit={onSubmit}
                    activeStep={activeStep}
                    isLoading={isLoading}
                />
            }
            {activeStep === NUMBER_STATUS.STATUS_3 &&
                <Final
                    onFinal={() => {
                        setActiveStep(NUMBER_STATUS.STATUS_1);
                        handleClose()
                    }}
                />
            }
        </Modal>
    )
}

export default NewBroadCast