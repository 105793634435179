/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import DropDown from "../../global/ui/dropDown";
import { email_notifications_type_list } from "../../data/data";
import { labels, placeholder } from '../../utils/constants';
import InputField from '../../lib/input';
import { apiConstants } from '../../utils/apiConstants';
import { EMAIL_VALIDATION } from '../../lib/input/validation-rules';
import { sendInvite } from '../../data/formData';
import { FormProvider, useForm } from 'react-hook-form';
import { makeGetCall, makePatchCall, makePostCall, makeDeleteCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { ApiResponseType } from '../../types';
import { emptyFunction } from '../../utils';

interface ProfileInfoProps {
    userData?: any
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ userData }) => {
    const { ...methods } = useForm({
        mode: 'all',
        defaultValues: { ...sendInvite() },
        reValidateMode: 'onBlur'
    });

    const [notificationsInterval, setNotificationsInterval] = useState<any>(email_notifications_type_list[0]);
    const [emailLists, setEmailLists] = useState<any>([]);

    const getEmailNotificationsLists = () => {
        makeGetCall(endPoints.EMAIL_NOTIFICATION, null, false, true).then((res: ApiResponseType<any>) => {
            setEmailLists(res?.results);
        }).catch(emptyFunction)
    }

    useEffect(getEmailNotificationsLists, [])

    useEffect(() => {
        if (userData?.round_up_notification) {
            setNotificationsInterval(email_notifications_type_list[userData.round_up_notification - 1]);
        }
    }, userData);

    const handleDropDownChange = async (data: any) => {
        setNotificationsInterval(data);
        await makePatchCall(endPoints.PROFILE, { round_up_notification: data.value });
    }

    const handleAddField = async () => {
        if (methods.getValues()?.email) {
            await makePostCall(endPoints.EMAIL_NOTIFICATION, methods.getValues());
            getEmailNotificationsLists();
        }
        methods.reset();
    }

    const handleDeleteEmail = async (id: any) => {
        await makeDeleteCall(`${endPoints.EMAIL_NOTIFICATION}${id}`);
        getEmailNotificationsLists();
    }

    const emails = emailLists?.map((item: any) => item?.email)

    return (
        <>
            <div className='emails-wrap'>
                {emailLists?.length > 0 &&
                    <>
                        <div className='image-wrap'>
                            <img src={"/assets/images/Mail-icon-perple.svg"} alt="" />
                        </div>
                        <div className='emails-text-wrap'>{emails?.join(', ')}</div>
                    </>
                }
            </div>
            <FormProvider {...methods} >
                <div className='notifications-content-wrap'>
                    {emailLists.map((item: any) => {
                        return (
                            <div className='email-block'>{item?.email}
                                <img
                                    src={'/assets/images/cross.svg'}
                                    alt="Cross Image"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleDeleteEmail(item?.id)}
                                />
                            </div>
                        )
                    })}
                    {emailLists.length < 3
                        ?
                        <InputField
                            label={labels.email}
                            placeholder={placeholder.email}
                            name={apiConstants.email}
                            type='email'
                            rules={EMAIL_VALIDATION}
                            rightIcon={'/assets/images/plus-icon.svg'}
                            rightIconPress={() => handleAddField()}
                            className="black-input mb-4"
                        />
                        :
                        null
                    }
                    <DropDown
                        className="arrow-fill mb-0 w-100 drop-down-over-vis"
                        data={email_notifications_type_list}
                        placeholder={notificationsInterval?.label}
                        label="I would like a summary email"
                        selectedItems={notificationsInterval.value}
                        onSelect={(data) => handleDropDownChange(data)}
                    />
                </div>
            </FormProvider>
        </>
    )
}

export default ProfileInfo