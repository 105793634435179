
import React, { useEffect, useState } from "react"
import { makeDeleteCall, makePostCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { createFullName, getFileExtension, showToast, urlify } from "../../utils";
import Logo from "../../components/commonComponent/ProfileImage";
import { ROLE_TYPE } from "../../types";
let x: any;
const Notes = (props: any) => {
  const note = props?.noteData;
  const [editOpen, setEditOpen] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [commentText, setCommentText] = useState<any>()
  const [attachement, setAttachment] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<any>(null);

  useEffect(() => {
    setAttachment(note?.note_media);
    setCommentText(note?.note);
  }, [props?.noteData])

  function formatTime(time: any) {
    let hours = parseInt(time.substring(0, 2));
    let minutes = time.substring(3);
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ':' + minutes + ' ' + ampm;
  }

  const time = formatTime(note?.created_at.substring(11, 16))
  const date = new Date(note?.created_at);
  const day = date.getDate();
  const year = date.getFullYear();
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);

  // const formattedDate = `${day}${day > 3 && day < 21 ? 'th' : ['st', 'nd', 'rd'][day % 10 - 1] || 'th'} ${month}`;

  const renderAttachedFiles = () => {
    const src = handleFileTypeIcon(selectedFile.name);
    return <div className="d-flex" style={{ width: '420px', background: 'white', padding: '10px', borderRadius: '4px' }}>
      <div className="d-flex gap-2 align-items-center w-100">
        <img src={src} alt='' />
        <h6 className="mb-0">{selectedFile.name}</h6>
      </div>
      <img src={"/assets/images/delete-red.svg"} alt='' onClick={() => setSelectedFile(null)} />
    </div>
  }

  const deleteAttachment = (file: any) => {
    makeDeleteCall(`${endPoints.MEDIA}/${file?.id}}`).then((res) => {
      const updatedList = attachement.filter((item: any) => file?.id != item.id)
      setAttachment(updatedList)
      showToast('Attachment Deleted Succesfully', 'success');
      // setEditNote(!editNote)
    })
  }

  const handleImageClick = () => {
    const inputElement = document.getElementById("fileInputadd");
    if (inputElement) {
      inputElement.click();
    }
  };

  //Set Upload file image icon -
  const handleFileTypeIcon = (filename: any) => {
    // Get the file extension from the filename
    const fileType = filename.substring(filename.lastIndexOf('.') + 1);
    switch (fileType) {
      case 'png':
      case 'jpg':
        return "/assets/images/image-example.svg";
      case 'mp4':
        return "/assets/images/mp4-example.svg";
      case 'pdf':
        return "/assets/images/pdf-example.svg";
      case 'xls':
        return "/assets/images/xls-example.svg";
      default:
        return "/assets/images/image-example.svg";
    }
  }
  const handleInputChange = (e: any) => {
    const cursorPosition = e.target.selectionStart;
    setCommentText(e.target.value);
    requestAnimationFrame(() => {
      e.target.selectionStart = cursorPosition;
      e.target.selectionEnd = cursorPosition;
    });
  };


  const handleDownload = (fileURL: any, filename: any) => {
    const pdfAcceptType = ["pdf", 'PDF'];
    const imgAcceptType = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    const common = [...imgAcceptType, ...pdfAcceptType];
    const extension = getFileExtension(fileURL);

    if (common.includes(extension)) {
      const name = createFullName(props?.userData?.first_name, props?.userData?.last_name);
      const aws_url = fileURL;
      const url = pdfAcceptType.includes(extension) ? endPoints.WATER_MARK : endPoints.WATER_MARK_IMAGE;
      const payload = { name, aws_url };

      makePostCall(url, payload).then((res) => {
        const watermarkedURL = res?.data?.watermarked_url || fileURL;
        downloadFile(watermarkedURL, filename);
      });
    } else {
      downloadFile(fileURL, filename);
    }
  };


  const downloadFile = (fileURL: any, filename: any) => {
    // Create a virtual anchor element
    const anchor = document.createElement('a');
    anchor.href = fileURL;

    // Set the download attribute to specify the file name
    anchor.download = filename;

    // Append the anchor to the body
    document.body.appendChild(anchor);

    // Trigger a click on the anchor to initiate the download
    anchor.click();

    // Remove the anchor from the body
    document.body.removeChild(anchor);
  }

  return <div className="note-row">
    <div className="note-outer">
      {/* <img src={note?.created_by?.owner_profile?.company_logo} alt="" />  CREW HAVE A  DIFFERENT PROFILE CREATEDBY_> PROFILE_IMG*/}
      {note?.created_by?.profile_image ? <img src={note?.created_by?.profile_image} alt="" className="rounded-circle" width={'40px'} height='40px' />
        : <Logo style={{ width: '60px', height: '55px' }} data={note?.created_by?.first_name?.[0] || note?.created_by?.last_name?.[0]} />}
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between position-relative" >
          <p className="mb-0 fw-bold">{note?.created_by?.first_name} {note?.created_by?.last_name}
            {(note?.created_by?.verified && note?.created_by?.user_type === ROLE_TYPE.TYPE_CREW) && <img style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-1px' }} src={"/assets/images/yellow_check_mark.png"} alt="" />}
          </p>

          {!editNote && props?.userData?.user_type != ROLE_TYPE.TYPE_SUPPLIER && <h1 className="mb-0" onClick={() => setEditOpen(!editOpen)}>...</h1>}
          {editOpen &&
            <div className="note-edit-dropdown"  >
              <li onClick={() => { setEditNote(!editNote); setEditOpen(!editOpen) }} className="pb-2"> <img src={"/assets/images/edit-pencil.svg"} alt='' /></li>
              <li><img src={"/assets/images/delete-note.svg"} alt='' onClick={() => { props?.deleteNote(); setEditOpen(!editOpen) }} /></li>
            </div>}

        </div>
        <p >{day} {month} {year} {time}</p>
        {editNote
          ?
          <textarea className="form-control shadow-none w-100 p-2 bg-transparent mb-3 no-resize" rows={6} value={commentText} onChange={(e) => { handleInputChange(e) }} />
          :
          note?.note
            ?
            <p dangerouslySetInnerHTML={{ __html: urlify(note?.note) }} />
            :
            ''
        }
        {attachement?.map((file: any, idx: any) => {

          // Get the last part of the URL after the last '/'
          const filename = file?.media.substring(file?.media.lastIndexOf('/') + 1);

          const src = handleFileTypeIcon(filename);

          return <div className="image-example mb-3" key={idx}>
            <div className="d-flex gap-2 align-items-center" >
              <img src={src} alt='' />
              {/* <img style={{width:'20px', height:'20px'}} src={file?.media} alt='' /> */}
              <h6 className="mb-0">{filename}</h6>
            </div>
            {editNote ? <img src={"/assets/images/delete-red.svg"} onClick={() => deleteAttachment(file)} alt='' /> : <a onClick={() => handleDownload(file?.media, filename)} href='#'><img src={"/assets/images/download-black.svg"} alt='' /></a>}
          </div>
        })}

        {editNote && <div>
          {selectedFile && <div>{renderAttachedFiles()}</div>}
          <div className="update-note" >
            <img src={"/assets/images/attach-file-icon.svg"} alt='' onClick={() => handleImageClick()} />
            <input
              id="fileInputadd"
              type="file"
              accept=".jpg, .mp4, .pdf, .xls, .png"
              style={{ display: "none" }}
              onChange={(e: any) => { const selectedFile = e.target.files[0]; setSelectedFile(selectedFile) }}
            />
            <div style={{ display: 'flex' }}><p style={{ padding: '3px' }} onClick={() => { setEditNote(!editNote); setSelectedFile(null) }}>Cancel</p>
              <p className="update-note-button ms-3"
                onClick={() => {
                  setEditNote(!editNote);
                  props?.updateNote(note, commentText, selectedFile);
                  setSelectedFile(null);
                  setCommentText('')
                }} >
                Update Note
              </p></div>
          </div>
        </div>}

      </div>
    </div>
  </div>
}

export default Notes
