export const routes = {
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  onboarding: '/',
  signup: '/signup',
  signin: '/signin',
  legals: '/legals',
  verifyEmail: '/verify-email',
  subscription: '/subscription',
  expireSubscription: '/expire-subscription',
  viewOwnerProfile: '/view-profile-prod-owner',
  viewSupplierProfile: '/view-supplier-owner',
  createOwnerProfile: '/manage-profile/owner/:type/:step',
  createSupplierProfile: '/manage-profile/supplier/:type/:step',
  crewMembers: '/crew-members',
  viewProfile: '/view-profile',
  notifications: '/notifications',
  back_pages: '/back-pages',
  crew_groups: '/crew-groups',
  paymentComplete: '/payment-success',
  reports: '/reports',
  messages: '/messages',
  jobs: '/jobs',
  crew: '/crew',
  suppliers: '/suppliers',
  schedule: '/schedule',
  explorecrew: '/explore-crew',
  explore: '/explore',
  my_production: '/my-production',
}