import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import DatePicker from '../../../global/ui/DatePicker';
import { DateType, DateUtils } from '../../../utils/dateUtils';
import { makeGetCall, makePatchCall, makePostCall } from '../../../effects/requests';
import { endPoints } from '../../../utils/endpoints';
import { ApiResponseType, ROLE_TYPE } from '../../../types';
import { FormProvider, useForm } from 'react-hook-form';
import { addPrepDiarySchema, addPrepDiary } from '../../../data/formData';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFullName, emptyFunction, showToast } from '../../../utils';
import { apiConstants } from '../../../utils/apiConstants';
import InputField from '../../../lib/input';

interface IAddPrepDiaryModal {
    prepEditData: any;
    isShow: boolean;
    onClose: () => void;
    onAddEvt: (data: any) => void;
    userData: any
}

const AddPrepDiaryModal = (props: IAddPrepDiaryModal) => {

    const [selectedItems, setSelectedItems] = useState<any>([])
    const [isAllCrew, setIsAllCrew] = useState<any>(false)

    const { ...methods } = useForm({
        mode: "all",
        defaultValues: { ...addPrepDiary(props?.prepEditData) },
        reValidateMode: "onBlur",
        resolver: yupResolver(addPrepDiarySchema()),
    });

    const fetchCrews = (query: any) => {

        return makeGetCall(endPoints.USERLIST, { search: query, user_type: ROLE_TYPE.TYPE_CREW, production_company_name: props?.userData?.production_company_name || '', }, false, true).then((res: ApiResponseType<any>) => {
            return res?.results?.map((item: any) => {
                return { ...item, label: createFullName(item?.first_name || '', item?.last_name || ''), value: item?.id, crew_id: item?.id }
            })
        })
    }

    const onSubmit = (values: any) => {
        const payload: any = {
            [apiConstants.date]: DateUtils.formatDateTime(values?.date, 'YYYY-MM-DD') || '',
            [apiConstants.production_id]: props?.userData?.production_id || '',
            [apiConstants.event_title]: values?.[apiConstants.event_title] || ''
        }
        if (isAllCrew) {
            payload.all_crew = true
        }
        if (props?.prepEditData?.id) {
            callUpdateApi(payload)
        } else {
            callCreateApi(payload)
        }
        props?.onClose();
    }

    const callCreateApi = (payload: any) => {
        makePostCall(endPoints.PREP_DIARY, payload).then((res) => {
            if (res?.status?.message) {
                showToast(res?.status?.message, 'success');
            }
            if (selectedItems?.length > 0) {
                selectedItems?.map((item: any) => {
                    if (!item?.isEdit) {
                        mapCrew({ prep_id: res?.data?.id, crew_id: item?.crew_id })
                    }
                })
            }
        }).catch(emptyFunction)
    }

    const callUpdateApi = (payload: any) => {
        const url = `${endPoints.PREP_DIARY}${props?.prepEditData?.id}`
        makePatchCall(url, payload).then((res) => {
            if (res?.status?.message) {
                showToast(res?.status?.message, 'success');
            }
            if (selectedItems?.length > 0) {
                selectedItems?.map((item: any) => {
                    if (!item?.isEdit) {
                        mapCrew({ prep_id: res?.data?.id, crew_id: item?.crew_id })
                    }
                })
            }
        }).catch(emptyFunction)
    }

    const mapCrew = (apiPayload: any) => {
        makePostCall(endPoints.PREP_CREW, apiPayload).then((res) => { }).catch(emptyFunction)
    }

    useEffect(() => {
        if (props?.prepEditData?.all_crew) {
            setIsAllCrew(true);
            return
        }
        if (props?.prepEditData?.prep_crew_data?.length > 0) {
            setSelectedItems(props?.prepEditData?.prep_crew_data)
        }
    }, [JSON.stringify(props?.prepEditData)])

    return (
        <Modal
            centered
            scrollable
            className='new_broadcast'
            show={props.isShow}
        >
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex justify-content-center align-items-center'>
                    <div onClick={props.onClose} className='noti-back-btn'>
                        <img src={"/assets/images/black-arrow-left-side.svg"} alt="" />
                    </div>
                    <div className='add-prep-header'>
                        <span>Prep Diary</span>
                        <p>Add to prep diary</p>
                    </div>
                </div>
                <div className="theme-checkbox form-check">
                    <label className="form-check-label" htmlFor="all_crew">
                        All Crew
                    </label>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="all_crew"
                        checked={isAllCrew}
                        onChange={(evt) => {
                            setIsAllCrew(evt.target.checked);
                            setSelectedItems([])
                        }}
                    />
                </div>
            </div>
            <div className='add-prep-content'>
                <div className="row">
                    <FormProvider {...methods}>
                        <div className="col-md-6">
                            <div className="col-md-12" style={{ zIndex: '12' }} >
                                <DatePicker
                                    date={methods.getValues(apiConstants.date) || null}
                                    onDateChange={(date) => methods.setValue(apiConstants.date, date, { shouldValidate: true })}
                                    label="Select Date"
                                    displayFormat={DateType.NEWEVENT}
                                    openDirection='down'
                                    placeholder='Select Date'
                                    style={{ width: 'auto', marginBottom: '1.5rem' }}
                                    className="black-input"
                                    errorMessage={methods?.formState?.errors?.[apiConstants.date]?.message}
                                />
                            </div>
                            <div className='col-md-12'>
                                <InputField
                                    label='Event'
                                    placeholder='Type here...'
                                    name={apiConstants.event_title}
                                    type="text"
                                    className="black-input"
                                />
                            </div>
                            <div onClick={methods.handleSubmit(props?.onAddEvt)} className='col-md-12 d-flex justify-content-end cursor-pointer mt-2 mb-3'>
                                <img src={"/assets/images/add-evt.svg"} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='col-md-12'>
                                <div className={`form_selcet w-100 mb-4 col-md-12`}>
                                    <div className="input_box">
                                        <label htmlFor="country">Add Crew</label>
                                        <AsyncSelect
                                            className="basic-multi-select shadow-none remove-arrows-select"
                                            classNamePrefix="select"
                                            loadOptions={fetchCrews}
                                            placeholder='Assign crew members'
                                            value={selectedItems}
                                            onChange={(item) => setSelectedItems(item)}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            isDisabled={isAllCrew}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormProvider>
                </div>
                <button
                    style={{ minWidth: '110px', position: 'absolute', bottom: '30px', right: '25px' }}
                    className="btn btn-warning btn-sm"
                    onClick={methods.handleSubmit(onSubmit)}
                    type='submit'
                >
                    Save
                </button>
            </div>
        </Modal>
    )
}

export default AddPrepDiaryModal