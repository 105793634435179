import React, { useEffect } from 'react'
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

interface IGoogleSearchPlaceInput {
    className?: any;
    onSelect: (data: any) => void;
    selectedAddress?: any;
    onChange?: (address: any) => void;
    onDelete?: () => void;
    showDelete?: boolean
}

const GoogleSearchPlaceInput = (props: IGoogleSearchPlaceInput) => {

    const [searchValue, setSearchValue] = React.useState(props?.selectedAddress || '')

    const onSelect = async (address: string, placeId: string) => {
        const response = await geocodeByPlaceId(placeId);
        if (response?.length > 0) {
            const details: any = response[0];
            const { lat, lng } = await getLatLng(details);
            const obj = {
                address: address || '',
                coordinates: [lat, lng],
                country: '',
                city: '',
                post_code: '',
            }
            details?.address_components?.map((item: any) => {
                if (item?.types?.[0] === 'country') obj['country'] = item?.long_name || item?.short_name
                if (item?.types?.[0] === 'administrative_area_level_1') obj['city'] = item?.long_name || item?.short_name
                if (item?.types?.[0] === 'postal_code') obj['post_code'] = item?.long_name || item?.short_name
            })
            setSearchValue(address);
            props.onSelect(obj)
        };
    }

    useEffect(() => {
        setSearchValue(props?.selectedAddress)
    }, [props?.selectedAddress])

    return (
        <div className="location-input-container">
            <PlacesAutocomplete
                value={searchValue}
                onChange={(value) => {
                    setSearchValue(value);
                    props?.onChange?.(value);
                }}
                onSelect={onSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                        <input
                            id="location"
                            name="location"
                            placeholder="Search Location"
                            {...getInputProps({ className: "location-search-input" })}
                        />
                        {suggestions?.length > 0 && (
                            <div className={`autocomplete-dropdown-container ${props.className || ''}`}>
                                {suggestions?.map((item: any) => {
                                    return (
                                        <div style={{ cursor: 'pointer' }} {...getSuggestionItemProps(item, { className: "suggestion-item" })}>
                                            <span>{item?.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
            </PlacesAutocomplete>
            {props?.showDelete && <img onClick={props?.onDelete} className='cursor-pointer' src={"/assets/images/delete-fill-inner-red.svg"} alt="" />}
        </div>
    )
}

export default GoogleSearchPlaceInput
