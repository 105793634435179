import { useContext, useEffect, useState } from 'react';
import BasicInfo from './basicInfo';
import Address from './address';
import Rate from './rate';
import CreateProfileLayout from '../../../global/layout/createProfileLayout'
import { useNavigate, useParams } from 'react-router-dom';
import { makePatchCall } from '../../../effects/requests';
import { endPoints } from '../../../utils/endpoints';
import HeaderTab from '../../../global/ui/HearderTab';
import { ownerHeader } from '../../../data/data';
import { ACTION_TYPE, ApiResponseType, USER_ACTION_TYPE } from '../../../types';
import { MyContext } from '../../../context';
import ProfileTabs from '../../../global/ui/ProfileTabs';
import { showToast } from '../../../utils';
import { cookieService } from '../../../services';
import { cookieServiceParam } from '../../../utils/constants';
import React from 'react';
import Stripe from '../../../components/stripe';
import ConfirmSubscription from '../../../components/modal/confirmSubscription';

const CreateProductionOwnerProfile = (props: any) => {
  const navigate = useNavigate();
  const { updateUserData, userData } = useContext(MyContext);
  const [isShowCardModal, setIsShowCardModal] = useState(false);
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const params = useParams();
  const navigation = useNavigate()
  const currentStep = Number(params?.step);
  const actionType = params?.type || 'add'
  const [editedSuccessfully, setEditedSuccessfully] = useState(false);

  const updateUrls = (step: Number) => {
    const routeUrl = `/manage-profile/owner/${actionType}/${step}`;
    navigation(routeUrl);
  }

  const onSubmit = (value: any, isLast: boolean) => {
    makePatchCall(endPoints.PROFILE, value).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        updateUserData(res)
        if (actionType === ACTION_TYPE.ADD) {
          if (isLast && userData.user_type !== 'C') {
            setIsShowCardModal(true)
          }
          else if (isLast && userData.user_type === "C") {
            makePatchCall(endPoints.PROFILE, { account_completed: true })
              .then((res) => {
                updateUserData(res);
                cookieService.saveCookie(cookieServiceParam.account_completed, true);
                navigate('/schedule');
              })
              .catch((err) => { })
          }
          else {
            updateUrls(currentStep + 1)
          }
        }
        else {
          showToast(res?.status?.message, "success")
          setEditedSuccessfully(true);
        }
      }
    }).catch((error) => {
      setEditedSuccessfully(false);
    })
  }

  const renderMainContent = (value: number | any) => {

    switch (value) {
      case 2:
        return <Address
          onSubmit={(data) => { onSubmit(data, false) }}
          userData={userData}
          actionType={actionType}
        />

      case 3:
        return <Rate
          onSubmit={(data) => { onSubmit(data, true) }}
          userData={userData}
          actionType={actionType}
        />

      default:
        return <BasicInfo
          onSubmit={(data) => { onSubmit(data, false) }}
          userData={userData}
          actionType={actionType}
          editedSuccessfully={editedSuccessfully}
          setEditedSuccessfully={() => setEditedSuccessfully(false)}
        />
    }

  }

  const heading = currentStep == 2 ? 'Production Company Location' : currentStep == 3 ? 'Overtime Rates' : actionType === 'update' ? 'Edit Profile Information' : 'Welcome to Sixth Degree';

  return (
    <CreateProfileLayout onBack={() => navigation('/view-profile')} actionType={actionType}>
      <div className="top-part">
        {actionType === USER_ACTION_TYPE.TYPE_UPDATE &&
          <button onClick={() => navigation('/view-profile')} className="edit-profile-close-btn position-absolute top-0 start-100 translate-middle">
            <img src={"/assets/images/dismiss-cross-black.svg"} alt="" />
          </button>
        }
        <div className="form-steps pb-3 mb-3">
          {actionType === USER_ACTION_TYPE.TYPE_UPDATE
            ?
            <ProfileTabs
              data={ownerHeader}
              onClick={(idx) => updateUrls(idx + 1)}
              selectedIdx={currentStep - 1}
            />
            :
            <HeaderTab
              data={ownerHeader}
              onClick={(step) => {
                if (step <= currentStep) {
                  updateUrls(step)
                }
              }}
              selectedTab={currentStep}
            />
          }
        </div>
        <div className="mid-head text-center">
          <h3 className="fw-bold">{heading}</h3>
        </div>
        {renderMainContent(currentStep)}
      </div>
      <Stripe
        isShow={isShowCardModal}
        onClose={() => setIsShowCardModal(false)}
        onSubscribe={() => setIsShow(true)}
        redirectType='subs'
      />
      <ConfirmSubscription isShow={isShow} onClose={() => setIsShow(false)} />
    </CreateProfileLayout>
  )
}
export default CreateProductionOwnerProfile;