import React, { useContext, useEffect, useState } from 'react'
import SideBarLayout from '../../global/layout/SideBarLayout'
import SearchWithHeading from '../../global/ui/SearchWithHeading'
import queryString from 'query-string';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { emptyFunction, showToast } from '../../utils';
import BackPageFilter from './BackPageFilter';
import Items from './Items';
import { DateUtils } from '../../utils/dateUtils';
import { makeGetCall, makeNextPageCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../global/ui/Loader';
import { ApiResponseType } from '../../types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

const BackPages: React.FC<any> = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { userData, } = useContext(MyContext);

    const [selectedDate, setSelectedDate] = useState<Date | any>(new Date())
    const [allData, setAllData] = useState<Array<any>>([])
    const [nextUrl, setNextUrl] = useState<string | null>(null);
    const [totalCount, setTotalCount] = useState<number | null>(0);
    const [focused, setFocused] = useState(false);

    const parsed = queryString.parse(location.search);

    const updateUrls = (key: any, value: any) => {
        const params = { ...parsed };
        params[key] = value;
        navigate(`/back-pages/?${createSearchParams(params as never)}`);
    }

    const onClear = () => {
        navigate(`/back-pages/?search=&department=&role=&event_type=`);
        setSelectedDate(new Date());
    }

    const fetchBackPages = () => {
        const params = {
            ...parsed,
            production_company_name: userData?.production_company_name || '',
            date: DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD'),
        }
        makeGetCall(endPoints.BACK_PAGES, params).then((res: ApiResponseType<any>) => {
            setAllData(res?.results);
            setNextUrl(res?.next || null);
            setTotalCount(res?.count || null);
        }).catch(emptyFunction)
    }

    const fetchMoreData = () => {
        if (!nextUrl) return;
        makeNextPageCall(nextUrl, false, true).then((res: ApiResponseType<any>) => {
            const data = [...allData, ...res.results];
            setAllData(data);
            setNextUrl(res?.next);
            setTotalCount(res?.count || null);
        })
    }

    useEffect(() => {
        if (userData?.production_company_name) {
            fetchBackPages()
        }
    }, [userData?.production_company_name, selectedDate, JSON.stringify(parsed)]);

    const exportData = () => {
        const params = {
            production_company_name: userData?.production_company_name || '',
            date: DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD'),
        }
        makeGetCall(endPoints.BACK_PAGE_EXPORT, params).then((res: any) => {
            if (res) {
                const exportUrl = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement("a");
                link.href = exportUrl;
                link.setAttribute("download", "back-page-data.csv");
                document.body.appendChild(link);
                link.click();
                showToast("data exportation completed", "success");
            }
        }).catch(emptyFunction)
    }

    return (
        <SideBarLayout>
            <div className="right-content-full-screen">
                <SearchWithHeading
                    placeholder="Search by name, email or phone"
                    onSearch={(value) => updateUrls('search', value)}
                    value={parsed?.search || ''}
                    heading='Backpages'
                    onAdd={exportData}
                    addText='Export'
                    icon='/assets/images/export.svg'
                    actionComp={
                        <div className='back-page d-flex justify-content-center align-items-center'>
                            <img
                                style={{ cursor: "pointer", marginRight: '10px', transform: 'rotate(180deg)' }}
                                onClick={() => setSelectedDate(DateUtils.getPreviousDay(selectedDate))}
                                src="/assets/images/play-icon-dark.svg"
                                alt=""
                            />
                            <SingleDatePicker
                                date={selectedDate ? moment(selectedDate) : null} // momentPropTypes.momentObj or null
                                onDateChange={(date) => setSelectedDate(date)} // PropTypes.func.isRequired
                                focused={focused} // PropTypes.bool
                                onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                                id={'selected_date_id'} // PropTypes.string.isRequired,
                                numberOfMonths={1}
                                small
                                displayFormat='dddd, Do MMMM YYYY'
                                placeholder={'Date'}
                                firstDayOfWeek={1}
                                isOutsideRange={() => false}
                            />
                            {/* <span style={{ fontSize: '12px', fontWeight: 'bold' }} className='text-align-center'>{`${DateUtils.formatDateTime(selectedDate, "dddd, Do MMMM YYYY")}`}</span> */}
                            <img
                                style={{ cursor: "pointer", marginLeft: '10px' }}
                                onClick={() => setSelectedDate(DateUtils.getNextDay(selectedDate))}
                                src="/assets/images/play-icon-dark.svg"
                                alt=""
                            />
                        </div>
                    }
                />
                <div className="bottom-content-full-screen">
                    <BackPageFilter
                        count={totalCount || 0}
                        updateValue={updateUrls}
                        data={parsed}
                        showing={totalCount || 0}
                        onClear={onClear}
                    />
                </div>
                <div id='scrollableDiv' className="common-table">
                    <InfiniteScroll
                        dataLength={allData?.length}
                        next={fetchMoreData}
                        hasMore={nextUrl ? true : false}
                        loader={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loader imgStyle={{ width: '40px', height: '40px' }} /></div>}
                        scrollableTarget="scrollableDiv"
                    >
                        <Items
                            data={allData}
                        />
                    </InfiniteScroll>
                </div>
            </div>
        </SideBarLayout>
    )
}

export default BackPages