import React, { useRef } from 'react'
import { Table } from "react-bootstrap";
import NoData from '../../global/ui/NoData';
import { createFullName, getPrmsnText } from '../../utils';
import { tableHeader } from '../../utils/constants';
import Logo from '../../components/commonComponent/ProfileImage';
import { PERMISSION_TYPE } from '../../types';

interface ProductionCrewsProps {
    data: Array<any>;
    onClick?: (data: any) => void;
    onPrmsUpdate?: (data: any) => void;
    onSelect: (id: any, type: 'S' | 'M', checked?: any) => void;
    selectedIDS: Array<any>;
    userData?: any
}


const ProductionCrews: React.FC<ProductionCrewsProps> = (props) => {

    const isSuperUser = (props?.userData?.permission_type === PERMISSION_TYPE.TYPE_ADMIN || props?.userData?.permission_type === PERMISSION_TYPE.TYPE_HOD || props?.userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER || props?.userData?.permission_type === PERMISSION_TYPE.TYPE_SUPERVISOR) ? true : false;

    const onPrmsUpdate = (data: any) => {
        if (!isSuperUser) {
            return
        }
        props?.onPrmsUpdate?.(data);
    }

    return (
        <div className="common-table ovflow-height">
            {props.data?.length > 0
                ?
                <Table responsive className="">
                    <thead>
                        <tr>
                            <th style={{ minWidth: '80px', width: '10%' }}></th>
                            <th style={{ minWidth: '100px', width: '10%' }}>{tableHeader.name}</th>
                            <th style={{ minWidth: '206px', width: '10%' }}>{tableHeader.email}</th>
                            <th style={{ minWidth: '128px', width: '10%' }}>{tableHeader.phone}</th>
                            <th style={{ minWidth: '200px', width: '10%' }}>Contracted hours</th>
                            {/* <th style={{ minWidth: '200px', width: '10%' }}>{tableHeader.address}</th> */}
                            <th style={{ minWidth: '150px', width: '15%' }}>{tableHeader.department}</th>
                            <th style={{ minWidth: '163px', width: '10%' }}>{tableHeader.role}</th>
                            <th style={{ minWidth: '180px', width: '10%' }}>{tableHeader.type}</th>
                            {/* <th style={{ minWidth: '100px', width: '10%' }}>{tableHeader.status}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {props?.data?.map((item: any, idx: any) => {
                            let totalRoles: any = [];
                            if (Array.isArray(item?.crew_member_profile?.company_department) && item?.crew_member_profile?.company_department?.length > 0) {
                                item?.crew_member_profile?.company_department?.forEach((dept: any) => {
                                    if (dept?.roles?.length > 0) {
                                        totalRoles.push(...dept?.roles);
                                    }
                                    if (dept?.role?.length > 0) {
                                        totalRoles.push(...dept?.role);
                                    }
                                });
                            } else if (Array.isArray(item?.crew_member_profile?.role) && item?.crew_member_profile?.role?.length > 0) {
                                item?.crew_member_profile?.role?.forEach((dept: any) => {
                                    if (dept?.roles?.length > 0) {
                                        totalRoles.push(...dept?.roles);
                                    }
                                    if (dept?.role?.length > 0) {
                                        dept?.role?.map((role: any) => {
                                            if (typeof role === 'string') {
                                                totalRoles.push({ label: role, value: role });
                                            } else {
                                                totalRoles.push(role);
                                            }
                                        })
                                    }
                                });
                            }
                            return (
                                <tr key={idx}>
                                    <td style={{ minWidth: '80px', width: '10%' }}>
                                        <div className="d-flex align-items-center gap-4">
                                            {item?.profile_image ?
                                                <img style={{ width: '33px' }} src={item?.profile_image} alt="" className={`rounded-circle ${item?.profile_image ? '' : 'table-icon-img'}`} />
                                                :
                                                <Logo style={{ width: '33px', height: '33px' }} data={item?.first_name[0]} />}
                                        </div>
                                    </td>
                                    <td onClick={() => props?.onClick?.(item)} className='text-break' style={{ minWidth: '100px', width: '10%', cursor: 'pointer' }}><p style={{ textTransform: 'capitalize' }} className="mb-0">{createFullName(item?.first_name, item?.last_name)}</p></td>
                                    <td className='text-break' style={{ minWidth: '206px', width: '10%' }}>{item?.email}</td>
                                    <td style={{ minWidth: '128px', width: '10%' }}>{item?.mobile_number}</td>
                                    <td onClick={() => onPrmsUpdate?.(item)} className='text-break' style={{ minWidth: '200px', width: '10%', cursor: 'pointer' }}>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <div className='contract-hours-wrap d-flex align-items-center justify-content-center' >
                                                <span>{`${item?.crew_member_profile?.overtime_hours} hours`}</span>
                                            </div>
                                            {isSuperUser && <img style={{ marginLeft: '12px' }} src={"/assets/images/edit_hours.svg"} alt="" />}
                                        </div>
                                    </td>
                                    {/* <td className='text-break' style={{ minWidth: '200px', width: '10%' }}>{renderAddressDetail(item, true)}</td> */}
                                    {/* <td style={{ minWidth: '100px', width: '15%' }}>{item?.crew_member_profile?.role?.length > 0 && <button className="perple-button">{`${item?.crew_member_profile?.role[0]?.dep} ${(item?.crew_member_profile?.role?.length - 1) ? "+" + (item?.crew_member_profile?.role?.length - 1) : ''}`}</button>}</td> */}
                                    <td onClick={() => onPrmsUpdate?.(item)} style={{ minWidth: '150px', width: '15%', cursor: 'pointer' }}>
                                        {item?.crew_member_profile?.company_department?.length > 0
                                            ?
                                            <button className="perple-button text-break">
                                                {`${item?.crew_member_profile?.company_department[0]?.dep} ${(item?.crew_member_profile?.company_department?.length - 1) ? "+" + (item?.crew_member_profile?.company_department?.length - 1) : ''}`}
                                            </button>
                                            :
                                            <>
                                                {item?.crew_member_profile?.role?.length > 0 &&
                                                    <button className="perple-button text-break">
                                                        {`${item?.crew_member_profile?.role[0]?.dep} ${(item?.crew_member_profile?.role?.length - 1) ? "+" + (item?.crew_member_profile?.role?.length - 1) : ''}`}
                                                    </button>
                                                }
                                            </>
                                        }
                                    </td>
                                    <td onClick={() => onPrmsUpdate?.(item)} style={{ minWidth: '163px', width: '10%', cursor: 'pointer' }}>
                                        {totalRoles?.length > 0 &&
                                            <button className="perple-button text-break">
                                                {`${totalRoles[0]?.label} ${(totalRoles?.length - 1) ? "+" + (totalRoles?.length - 1) : ''}`}
                                            </button>
                                        }
                                    </td>
                                    <td style={{ minWidth: '180px', width: '10%' }}>{getPrmsnText(item?.permission_type)}</td>
                                    {/* <td style={{ minWidth: '100px', width: '10%' }}><button className={`btn rounded-pill witout-hover ${item?.active_at_company ? 'btn-success' : 'btn-danger'}`}>{item?.active_at_company ? 'Active' : 'Inactive'}</button></td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                :
                <NoData />
            }
        </div>
    )
}

export default ProductionCrews