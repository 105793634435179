import SideBarLayout from '../../global/layout/SideBarLayout';
import ProfileInfo from './ProfileInfo';
import Tabs from '../../global/ui/Tabs';
import { crewProfileTabs, profileTabs, supplierProfileTabs } from '../../data/data';
import queryString from 'query-string';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { apiConstants } from '../../utils/apiConstants';
import PersonalInformation from './personalInformation';
import Overtime from './overtime';
import Services from './services';
import Setting from './setting';
import Payment from './payment';
import Notifications from './Notifications';
import TransactionHistory from './transactionHistory';
import { viewProfileInfoTab } from '../../utils/constants';
import { useContext, useEffect, useState } from 'react';
import { PERMISSION_TYPE, ROLE_TYPE } from '../../types';
import { MyContext } from '../../context';
import SupplierMoreAddresses from '../../components/modal/SupplierMoreAddresses';
import { makeGetCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import CalendarSync from './CalendarSync';

const ViewProfile = (props: any) => {

  const { userData, updateCompanyData } = useContext(MyContext)
  const location = useLocation();
  const navigate = useNavigate();

  const parsed = queryString.parse(location.search);
  const [selectedIdx, setSelectedIdx] = useState(0)
  const [showMoreAddress, setShowMoreAddress] = useState(false)

  const updateUrls = (value: any) => {
    const params = parsed;
    params[apiConstants.view_type] = value;
    navigate(`/view-profile/?${createSearchParams(params as never)}`);
  }

  const renderMainView = (type: any) => {

    switch (type) {
      case viewProfileInfoTab.tab2:
        return <Overtime userData={userData} />
      case viewProfileInfoTab.tab5:
        return <Services userData={userData} />
      case viewProfileInfoTab.tab3:
        return <Setting userData={userData} />
      case viewProfileInfoTab.tab4:
        return <Payment />
      case viewProfileInfoTab.tab6:
        return <TransactionHistory />
      case viewProfileInfoTab.tab7:
        return <CalendarSync />
      case viewProfileInfoTab.notifications:
        return <Notifications userData={userData} />
      default:
        return <PersonalInformation
          onMoreClick={() => setShowMoreAddress(true)}
          userData={userData}
        />
    }

  }

  useEffect(() => {
    const idx = profileTabs.findIndex((item) => item === parsed?.[apiConstants.view_type]);
    if (idx > 0) {
      setSelectedIdx(idx)
    }
  }, [])


  const getTabData = (type: any, permission_type: any) => {
    switch (type) {
      case ROLE_TYPE.TYPE_SUPPLIER:
        return supplierProfileTabs;

      case ROLE_TYPE.TYPE_CREW:
        return (permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER || permission_type === PERMISSION_TYPE.TYPE_ADMIN) ? profileTabs : crewProfileTabs;

      default:
        return profileTabs;
    }
  }

  const getCompanyNames = () => {
    makeGetCall(endPoints.USER_PRODUCTION, null, false, true).then((res) => {
      if (res?.results?.length > 0) {
        const data = res?.results?.map((item: any) => {
          if (item?.company_department && typeof item?.company_department == 'string') {
            item.company_department = JSON.parse(item?.company_department)
          }
          return item
        })
        updateCompanyData(data);
      }
    }).catch((err) => {

    })
  }

  useEffect(() => {
    if (userData?.user_type != ROLE_TYPE.TYPE_SUPPLIER) {
      getCompanyNames();
    }
  }, [userData?.user_type])

  return (
    <SideBarLayout>
      <div className="right-content">
        <div className="viewprofile-right-cont">
          <div className="card">
            <ProfileInfo
              userData={userData}
              handleUpdateUrl={updateUrls}
              urlType={parsed?.[apiConstants.view_type]}
            />
            <div className="card-body p-0">
              <div className="bottom-tabs-wo">
                {parsed?.[apiConstants.view_type] !== 'notifications settings'
                  ?
                  <Tabs
                    data={getTabData(userData?.user_type, userData?.permission_type)}
                    onClick={updateUrls}
                    selectedIdx={selectedIdx}
                  />
                  :
                  ''
                }
                <div style={parsed?.[apiConstants.view_type] == 'calendar sync' ? { padding: '0px', paddingTop: '48px' } : {}} className={parsed?.[apiConstants.view_type] !== 'notifications settings' ? "tab-content" : ''}>
                  {renderMainView(parsed?.[apiConstants.view_type])}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMoreAddress &&
        <SupplierMoreAddresses
          isShow={showMoreAddress}
          onClose={() => setShowMoreAddress(false)}
          locations={userData?.other_location}
          showFullAddress
        />
      }
    </SideBarLayout>
  )
}
export default ViewProfile;