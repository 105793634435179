import React from 'react';
interface ProfileImageProps {
  data: any;
  style?: React.CSSProperties;
  className?: string;
}
const Logo = (props: ProfileImageProps) => {
  const initials = props?.data?.toUpperCase();
  return <div style={props?.style} className={`logoStyle ${props?.className || ''}`}>{initials}</div>;
};

export default Logo;
