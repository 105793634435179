import React, { useContext, useEffect, useState } from 'react'
import SideBarLayout from '../../global/layout/SideBarLayout'
import MessagingUsers from './MessagingUsers';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Welcome from '../messages/Welcome';
import { MyContext } from '../../context';
import ChatWindow from './ChatWindow';
import { makeGetCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { emptyFunction } from '../../utils';
import { ApiResponseType } from '../../types';

const Messages: React.FC<any> = () => {

    const location = useLocation();
    const parsed = queryString.parse(location?.search);
    const { userData } = useContext(MyContext);

    const [chatData, setChatData] = useState<any>(null)

    const fetchChatData = () => {
        const url = `${endPoints.ROOM_LIST}${parsed?.room}/`
        makeGetCall(url)
            .then((res: ApiResponseType<any>) => {
                setChatData(res?.data || null)
            })
            .catch(emptyFunction)
    }

    const acceptRejectRequest = (status: string) => {
        const apiUrl = `${endPoints.accept_chat_invite_url}${chatData?.id}`;
        const params = { status: status };
        makeGetCall(apiUrl, params).then((res: ApiResponseType<any>) => {
            fetchChatData()
        }).catch(emptyFunction)
    }

    useEffect(() => {
        if (parsed?.room) {
            fetchChatData();
        }
    }, [parsed?.room])

    return (
        <SideBarLayout>
            <div className="right-content-full-screen">
                <div className="full-message-part d-flex">
                    <MessagingUsers />
                    {parsed?.room
                        ?
                        <>
                            {chatData &&
                                <ChatWindow
                                    chatData={chatData}
                                    acceptRejectRequest={acceptRejectRequest}
                                />
                            }
                        </>
                        :
                        <Welcome
                            userFirstName={userData?.first_name || ""}
                        />
                    }
                </div>
            </div>
        </SideBarLayout>
    )
}

export default Messages