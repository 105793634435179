import React, { useEffect, useState } from 'react'
import { DateUtils } from '../../../utils/dateUtils';
import Button from '../../../global/ui/Button';
import { BUTTON_TYPE } from '../../../types';
import { makeGetCall } from '../../../effects/requests';
import { endPoints } from '../../../utils/endpoints';
import Logo from '../../../components/commonComponent/ProfileImage';
import { createFullName, emptyFunction } from '../../../utils';

interface IViewPrepDiary {
    selectedPrepDate: any;
    isShow: boolean;
    onClose: () => void;
    onAdd: () => void;
    onEdit: (data: any) => void;
    onAddEvent: (data: any) => void;
    userData: any
}

const ViewPrepDiary = (props: IViewPrepDiary) => {

    const [allData, setAllData] = useState([]);

    const [selectedDate, setSelectedDate] = useState(props?.selectedPrepDate)

    const getPrep = () => {
        const params = {
            date: DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD'),
            production_id: props?.userData?.production_id || '',
        }
        makeGetCall(endPoints.PREP_DIARY, params).then((res) => {
            setAllData(res?.data)
        }).catch(emptyFunction)
    }

    useEffect(() => {
        getPrep();
    }, [selectedDate]);

    useEffect(() => {
        setSelectedDate(props?.selectedPrepDate)
    }, [props?.selectedPrepDate])

    return (
        <div className={`right-side-popup-background ${props?.isShow ? "" : "d-none"}`}>
            <div className="right-side-popup-fixed d-grid align-content-between gap-4">
                <div className='prep-details justify-content-start align-items-center'>
                    <div onClick={props.onClose} className='noti-back-btn m-4'>
                        <img src={"/assets/images/dismiss-cross-black.svg"} alt="" />
                    </div>
                    <div style={{ marginLeft: '25px' }} className='add-prep-header'>
                        <span>Prep Diary</span>
                    </div>
                    <div className='view-prep-header d-flex justify-content-center align-items-center mt-3'>
                        <img
                            style={{ cursor: "pointer", marginRight: '10px', transform: 'rotate(180deg)' }}
                            onClick={() => setSelectedDate(DateUtils.getPreviousDay(selectedDate))}
                            src="/assets/images/play-icon-dark.svg"
                            alt=""
                        />
                        <div className='d-grid justify-content-center align-items-center '>
                            <span className="mb-0 extra-padding-top">{`${DateUtils.formatDateTime(selectedDate, "dddd, Do MMMM YYYY")}`}</span>
                            <div className='bar' />
                        </div>
                        <img
                            style={{ cursor: "pointer", marginLeft: '10px' }}
                            onClick={() => setSelectedDate(DateUtils.getNextDay(selectedDate))}
                            src="/assets/images/play-icon-dark.svg"
                            className="right-side-mo"
                            alt=""
                        />
                    </div>
                    <div className='prep-items-wrap'>
                        {allData?.map((item: any, idx) => {
                            return (
                                <div key={idx} className='prep-items d-flex justify-content-between align-items-center'>
                                    <span>{item?.event_title || ''}</span>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        {item?.all_crew
                                            ?
                                            <div className='all-crew d-flex justify-content-center align-content-center'>
                                                <span>All Crew</span>
                                            </div>
                                            :
                                            <>
                                                {item?.prep_crew_data?.length > 0 ?
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {item?.prep_crew_data?.map((crew: any, idx: any) => {

                                                            // preparing data for edit flow
                                                            crew.label = createFullName(crew?.crew_member?.first_name || '', crew?.crew_member?.last_name || '');
                                                            crew.value = crew?.crew_member?.id || '';
                                                            crew.crew_id = crew?.crew_member?.id || '';
                                                            crew.isEdit = true

                                                            if (idx > 2) return null
                                                            return (
                                                                <div style={{ zIndex: item?.prep_crew_data.length - idx }}>
                                                                    {crew?.crew_member?.profile_image
                                                                        ?
                                                                        <img className='user-img' src={crew?.crew_member?.profile_image} alt="crew image" />
                                                                        :
                                                                        <Logo style={{ width: '30px', height: '30px', borderRadius: '50px', marginRight: '-10px' }} data={crew?.crew_member?.first_name?.[0]} />
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                        {item?.prep_crew_data?.length > 3 ? <p>{`+${item?.prep_crew_data?.length - 3} others`}</p> : <div style={{ minHeight: '60px', marginLeft: '10px' }} />}
                                                    </div>
                                                    :
                                                    <div style={{ minHeight: '60px' }} />
                                                }
                                            </>
                                        }
                                        <div style={{ marginLeft: '30px' }} className='d-flex justify-content-start align-items-center'>
                                            <img className='cursor-pointer' onClick={() => props?.onEdit?.(item)} style={{ marginRight: '10px' }} src={"/assets/images/edit-prep.svg"} alt="" />
                                            <img className='cursor-pointer' onClick={() => props?.onAddEvent?.(item)} src={"/assets/images/add-evt.svg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='ms-3 me-3'>
                        <Button
                            type={BUTTON_TYPE.TYPE_BUTTON}
                            btnTxt='Add Item'
                            className={'btn btn-warning w-100 with-after-shadow shadow-none m-0 mt-2'}
                            onClick={props?.onAdd}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewPrepDiary