//error messages
export const errorMessage = {
  fieldRequired: (text: string) => `Please enter ${text}.`,
  selectFieldRequired: (text: string) => `Please select ${text}.`,
  phone_validation: "Phone number is not valid",
  email_validation: "Email is not valid.",
  empty_login_pin: "Please enter login pin.",
  server_error: "Server Error. Please try after some time.",
  pin_validation: "Verification pin is not valid",

  //Errors
  email_not_registered: "Email not registered",
  email_not_registered_desc: "Get started with Sixth Degree. Sign Up",
  email_registered: "Email registered",
  email_registered_desc: "Email already registered. Sign In",
  required_validation: (name: string, plural?: boolean) =>
    `${name} ${plural ? "are" : "is"} required.`,
  invalid_email_msg: "Please enter valid email address.",
  invalid_email_phone_msg: "Please enter valid email or phone number.",
  invalid_name: "Please enter valid name",
  invalid_mobile_msg: "Please enter valid mobile number.",
  invalid_pass_msg:
    "Password must contain at least eight characters including one number and one letter.",
  invalid_otp_msg: "Invalid otp, Otp must be 6 characters.",
  invalid_post_msg: (length: any) => `Code must be ${length} characters.`,
  invalid_imdb_link_msg: "Invalid link, Please enter valid link.",
  invalid_pass_special_msg:
    "Must contain minimum eight characters, at least one letter, one number and one special character",
  network_connection: "Network Connection",
  connection_offline: "Your connection seems Offline",
  connection_online: "You are back online",
  connection_offline_description:
    "Cannot proceed with the operation.\nPlease check your Internet connection.",
  unauthorized_access: "Sorry, you don’t have access to website.",
  subs_not_selected: "Please select subscription",
  upload_photo: "Photo is not uploaded",
  sel_country: "Country is required",
  invalid_bio: "Bio is required",
  sel_department: "Department is required",
  sel_services: "Service is required",
  sel_products: "Product is required",
  invalid_post_code: "Invalid Post Code",
  sel_services_products: "Service or Product is required",
  duplicate_product: "Duplicate product present",
  duplicate_service: "Duplicate service present",
  country_error: "Please select a valid location.",
  dept_error: "Please select department.",
};

export const signupText = {
  profile_type: "Choose Profile Type:",
  profile_type_selection: "Choose which profile type you wish to sign up as",
  welcome: "Welcome to Sixth Degree",
  details: "Enter your details to create your Sixth Degree account",
  pro_details:
    "Enter your Production Company details to create your Sixth Degree account",
  sup_details:
    "Enter your Supplier Company details to create your Sixth Degree account",
  verify_email: "Verify Email Address",
  send_verification_email: "We have sent a verification link to your email",
};
export const signinText = {
  welcome: "Welcome back to Sixth Degree",
  welcome_crew: "Create your Sixth Degree Production Account",
  signin_details:
    "Enter your details to sign back into your Sixth Degree account",
  signin_production_crew:
    "Enter your Sixth Degree details to log in and create your production",
  signin_production_crew_1:
    "Enter your Sixth Degree crew app details to login to create your new production profile",
  welcome_back: "Welcome back",
  companyLocation: "Production Company Location",
  basicInfo: "Let’s get some info about you!",
  location: "Where are you located",
  services: "What services do you offer?",
  logoutPopupText: "Are you sure you want to logout?",
};
export const buttonsText = {
  login_instead: "Log In Instead",
  continue: "Continue",
  skip: "Skip",
  signin: "Sign In",
  forgot_password: "Forgot Password",
  signup_instead: "Sign Up Instead",
  saveAndAdd: "Save & Add",
  saveAndUpdate: "Save & Update",
  logOut: "Log Out",
  addNewCard: "Add Card",
  next: "Next",
  addCrew: "Add Crew",
  newEvent: "New Event",
  editProfile: "Edit Profile",
  productionCompany: "Production Company",
  btnSupplier: "Supplier",
  update: "Update",
  go_back: "Go Back",
  back: "Back",
};
export const labels = {
  full_name: "Full Name",
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email Address",
  email_phone: "Email or Phone Number",
  password: "Password",
  companyName: "Production Company Name",
  contactName: "Contact Name",
  contactNumber: "Contact Number",
  addLogo: "Add Logo",
  address: "Address",
  city: "City",
  postCode: "Post Code",
  rate6: "6th Day Rates",
  rate7: "7th Day Rates",
  supplierName: "Supplier Name",
  departmentServing: "Departments servicing",
  supplierBio: "Supplier bio",
  supplierContactName: "Supplier Contact Name",
  supplier_website: "Supplier Website",
  supplierContactNumber: "Supplier contact number",
  addServices: "Add Services",
  addProduct: "Add Products",
  catalogueImg: "Catalogue Images",
  serviceProvided: "Service Provided",
  productProvided: "Product Provided",
  country: "Country",
  logout: "Logout",
  cardHolderName: "Card holder name",
  cardNumber: "Card number",
  expDate: "Exp. Date",
  cvv: "CVV",
  addNewCard: "Add New Card",
  cardDetails: "Card Details",
  saveCard: "Save card details",
  filterByRole: "Filter by role",
  filterByType: "Filter by permission type",
  filterByDepartment: "Filter by department",
  filterByStatus: "Filter by status",
  universalProduction: "Universal Production",
  mediaAccessSolutions: "Media Access Solutions",
  yearlyPlan: "Gold Yearly",
  monthlyPlan: "Silver Monthly",
  currency: {
    gbp: "GBP",
    usd: "USD",
    eur: "EUR",
  },
  dailyRate: "Daily Rate",
  noOfPosition: "Number of positions",
  location: "Location",
  jobTitle: "Job Title",
  old_password: "Old Password",
  new_password: "New Password",
  verify_pin: "Verification Code",
  event_name: "Event Name",
  event_sub_category: "Event Sub Category",
  meeting_link: "Meeting link",
  event_desc: "Event Description",
  roleSelect: "Select role",
  event_start_time: "Event Start Time",
  event_end_time: "Event End Time",
  department: "Department",
  timezone: "Timezone",
};
export const placeholder = {
  full_name: "Enter Full Name",
  email: "Enter Email",
  password: "Enter Password",
  companyName: "Enter Company Name",
  website_url: "Enter Website Url",
  contactName: "Enter Contact Name",
  contactNumber: "Enter Contact Number",
  companyAddress: "Enter Company Address",
  city: "Enter City",
  postCode: "Enter Post Code",
  rate: "Enter x times of daily rate",
  supplierName: "Enter Supplier Name",
  departmentSelect: "Select Departments",
  shortBio: "Enter short bio",
  addService: "Add Service",
  addProduct: "Add Product",
  enterService: "Enter Service",
  enterProduct: "Enter Product",
  selectCountry: "Select Country",
  cardHolderName: "Enter card holder name",
  cardNumber: "Enter card number",
  cvv: "Enter CVV Digit",
  expDate: "00/00",
  allRole: "All roles",
  allType: "All permission type",
  allDepartments: "All departments",
  allStatuses: "All statuses",
  dailyRate: "Enter daily rate",
  noOfPosition: "Enter number of positions",
  location: "Enter location",
  jobTitle: "Enter job title",
  enter_old_password: "Enter Old Password",
  enter_new_password: "Enter New Password",
  verify_pin: "Verification Code",
  event_name: "Enter event name",
  event_sub_category: "Enter event sub category",
  meeting_link: "Add meeting link",
  selectJobRole: "Job Role",
  sel_dept: "Select department",
  sel_start_time: "Select Start Time",
  sel_end_time: "Select End Time",
  sel_time_zone: "Select Timezone",
};
export const tabs = {
  tab1: { key: 1, name: "Information" },
  tab2: { key: 2, name: "Location" },
  tab3: { key: 3, name: "Rates" },
  tab4: { key: 1, name: "Info" },
  tab5: { key: 2, name: "Contact" },
  tab6: { key: 4, name: "Services" },
};
export const viewProfileTab = {
  tab1: "Schedule",
  tab2: "Suppliers",
  tab3: "Crew",
  tab4: "Jobs",
  tab5: "Messages",
  tab6: "Notifications",
  tab7: "Reports",
  tab8: "Profile",
  tab9: "Log Out",
};
export const viewProfileInfoTab = {
  tab1: "profile information",
  tab2: "overtime rates",
  tab3: "settings",
  tab4: "payment info",
  tab5: "services",
  tab6: "transaction history",
  tab7: "calendar sync",
  notifications: "notifications settings",
};
export const cookieServiceParam = {
  token: "token",
  user: "user",
  userType: "user_type",
  email: "email",
  refresh: "refresh",
  browserToken: "browser_token",
  payment_data: "payment_data",
  account_completed: "account_completed",
  subscription_id: "subs_id",
  is_payment_done: "is_payment_done",
};
export const card = {
  defaultCard: "Default Card",
  cardHolder: "Card holder",
  expDate: "Expire date",
  makeDefault: "Make Default",
};
export const tableHeader = {
  name: "Name",
  email: "Email",
  phone: "Phone",
  address: "Address",
  department: "Department",
  role: "Role",
  status: "Status",
  type: "Permission Type",
};
export const pageHeading = {
  chooseSubscriptionPackage: "Choose Subscription Package",
  chooseYourSubscriptionPackage: "Choose your subscription package",
  productionCrew: "Production Crew",
  resetPassword: "Reset Password",
  resetPasswordContent: "We have sent a reset link to your email",
  forgotPassword: "Reset Password",
  forgotPasswordContent:
    "Enter your email and we will send you a link to reset your password",
};
export const outerContent = {
  Day6thRate: "6th Day Rate",
  Day7thRate: "7th Day Rate",
};

export const routes = {
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  onboarding: "/",
  signup: "/signup",
  signin: "/signin",
  terms: "/terms",
  pp: "/privacy-policy",
  verifyEmail: "/verify-email",
  subscription: "/subscription",
  viewOwnerProfile: "/view-profile-prod-owner",
  viewSupplierProfile: "/view-supplier-owner",
  createOwnerProfile: "/manage-profile/owner/:type/:step",
  createSupplierProfile: "/manage-profile/supplier/:type/:step",
  crewMembers: "/crew-members",
  ExploreCrew: "/explorecrew",
  Suppliers: "/suppliers",
  Jobs: "/jobs",
};
export const timeout = 200;
export const toastTimeout = 3000;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const defaultISD = "+44";
export const defaultFlag = "🇬🇧";
export const defaultCountry = "GB";
export const defaultAdminMail = "info@sixthdegree.io";
export const default3DS2 = "3d_secure_2";

export const defaultCountryOptions = {
  label: "United Kingdom",
  flag: "🇬🇧",
  code: "GB",
  dial_code: "+44",
  value: "United Kingdom",
};

export const defaultSupplierFilter = {
  search: "",
  department: "",
  location: "",
  limit: 12,
  lat: "",
  lng: "",
};

export const defaultJobFilter = (companyName?: any) => ({
  search: "",
  limit: 10,
  job_company__company_name: companyName,
});
