import React from 'react'
import { ROLE_TYPE } from '../../../types';
import WeekEventCard from './WeekEventCard';
import PrepDiaryItem from '../prepDiaryManagement/PrepDiaryItem';
import { useDrop } from "react-dnd";
import { DateUtils } from '../../../utils/dateUtils';

interface ICustomTd {
    item: any;
    index: any;
    userData: any;
    onViewPrepDiary: (data: any) => void;
    isProdSuperUser?: any
    onEditEvt: (id: any) => void;
    onEventView: (id: any, eventStatus: any, supplierID?: any) => void;
    onDragEnd: (evtData: any) => void;
}

const CustomTd = (props: ICustomTd) => {

    const { item, index, userData } = props;

    const [{ isOver }, drop] = useDrop({
        accept: 'item',
        drop: (item: any, monitor) => {
            if (DateUtils.isDateTimeIsSame(item?.event_source_date, props?.item?.dateString, 'DD')) {
                return
            }
            props?.onDragEnd?.({ ...item, event_destination_date: props?.item?.dateString })
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <td
            ref={drop}
            className="tablehead-week"
            key={index}
            style={{ width: "12%", minWidth: "162px", backgroundColor: isOver ? 'rgba(255, 216, 0, 0.08)' : '' }}
        >
            {(userData?.user_type != ROLE_TYPE.TYPE_SUPPLIER && props?.isProdSuperUser) &&
                <PrepDiaryItem
                    onClick={() => props?.onViewPrepDiary?.(item)}
                />
            }
            {item?.hasEvent &&
                <>
                    {item?.shoot_events?.length > 0 && item?.shoot_events?.map((evt: any, evtIdx: any) => {
                        return (
                            <WeekEventCard
                                key={evtIdx}
                                data={evt}
                                onEventView={props?.onEventView}
                                onEditEvt={props?.onEditEvt}
                                isProdSuperUser={props?.isProdSuperUser}
                                sourceDate={item?.dateString}
                            />
                        )
                    })}
                    {item?.events?.length > 0 && item?.events?.map((evt: any, evtIdx: any) => {
                        return (
                            <WeekEventCard
                                key={evtIdx}
                                data={evt}
                                onEventView={props?.onEventView}
                                onEditEvt={props?.onEditEvt}
                                isProdSuperUser={props?.isProdSuperUser}
                                sourceDate={item?.dateString}
                            />
                        )
                    })}
                </>
            }
        </td>
    )
}

export default CustomTd