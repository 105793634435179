import Cookies from "js-cookie";
import { toast, TypeOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  CookiesKeysType,
  DateTypes,
  PERMISSION_TYPE,
  ROLE_TYPE,
  TimeTypes,
  UserDetailsType,
  USER_ACTION_TYPE,
} from "../types";
import moment from "moment";
import { cookieService } from "../services";
import {
  buttonsText,
  cookieServiceParam,
  defaultCountryOptions,
  defaultISD,
} from "./constants";
import {
  countryList,
  defaultDepRoles,
  preConfigDeptData,
  rolesList,
} from "../data/data";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import axios from "axios";
const _ = require("lodash");

export const emptyFunction = () => {};

export const generateFormData = (obj: any) => {
  let formData = new FormData();
  for (let [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      formData.append(`${key}`, `${value}`);
    } else {
      formData.append(`${key}`, `${value}`);
    }
  }
  return formData;
};

export const setCookie = (key: CookiesKeysType, value: string | any) =>
  Cookies.set(key, value);

export const getCookie = (key: CookiesKeysType) => Cookies.get(key);

export const removeCookie = (key: CookiesKeysType) => Cookies.remove(key);

export const isUserAuthenticated = () =>
  cookieService.getCookie(cookieServiceParam.token);

export const resetAllCookie = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  Cookies.remove("user");
};

export const showToast = (
  message: string | any,
  type: TypeOptions,
  toastId?: any
) => {
  toast(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    type,
    toastId: toastId || undefined,
  });
};

export const convertB64DataToByteArray = (b64Data: any) => {
  const encodedData = window.btoa(b64Data); // encode a string
  const byteCharacters = window.atob(encodedData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return byteArray;
};

export const createGuid = () => uuidv4();

export const userFullName = (
  firstName: string,
  midName?: string,
  lastName?: string
) => {
  const name = `${firstName ? firstName : ""} ${midName ? midName : ""} ${
    lastName ? lastName : ""
  }`;
  return name;
};

export const formatDateTime = (
  timeStamp: Date | any,
  formatTo?: DateTypes | TimeTypes
) => {
  return timeStamp
    ? moment(timeStamp).format(formatTo)
    : moment().format(formatTo);
};

export const getRoute = (data: any) => {
  let route = "/schedule";
  if (
    !data?.subscription_id &&
    data?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER
  ) {
    if (data?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
      return "/expire-subscription";
    } else if (
      data?.user_type === ROLE_TYPE.TYPE_CREW &&
      data?.permission_type !== PERMISSION_TYPE.TYPE_CREW_MEMBER &&
      data?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER
    ) {
      return "/schedule";
    }
    // else if (data?.user_type === ROLE_TYPE.TYPE_CREW && data.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER){
    //     return '/manage-profile/owner/add/1'
    // }
    else {
      return "/subscription";
    }
  }
  if (!data?.account_completed) {
    const supplierStep = 1;
    const supplierUrl = `/manage-profile/supplier/${USER_ACTION_TYPE.TYPE_ADD}/${supplierStep}`;
    const ownerStep = !data?.owner_profile?.company_name
      ? 1
      : !data?.country
      ? 2
      : 3;
    const ownerUrl = `/manage-profile/owner/${USER_ACTION_TYPE.TYPE_ADD}/${ownerStep}`;
    return data?.user_type === ROLE_TYPE.TYPE_CREW &&
      data?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER
      ? ownerUrl
      : supplierUrl;
  } else if (data?.user_type === ROLE_TYPE.TYPE_CREW) {
    if (data.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
      return "/schedule";
    } else {
      return "/manage-profile/owner/add/1";
    }
  }

  return route;
};

export const getInitialRouteName = (userData: UserDetailsType) => {
  let route = "";
  if (userData?.account_completed) {
    if (userData?.user_type === ROLE_TYPE.TYPE_CREW) {
      route = "/schedule";
    } else {
      if (!userData?.subscription_id) {
        route = "/expire-subscription";
      } else {
        route = "/schedule";
      }
    }
  } else {
    if (userData?.user_type === ROLE_TYPE.TYPE_CREW) {
      if (userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
        if (!userData?.owner_profile?.company_name) {
          route = `/manage-profile/owner/${USER_ACTION_TYPE.TYPE_ADD}/1`;
        } else if (!userData?.country) {
          route = `/manage-profile/owner/${USER_ACTION_TYPE.TYPE_ADD}/2`;
        } else {
          route = `/manage-profile/owner/${USER_ACTION_TYPE.TYPE_ADD}/3`;
        }
      } else {
        route = "/schedule";
      }
    } else {
      if (!userData?.is_email_verified) {
        route = "/verify-email";
      } else if (!userData?.subscription_id) {
        route = "/subscription";
      } else if (!userData?.supplier_profile?.company_name) {
        route = `/manage-profile/supplier/${USER_ACTION_TYPE.TYPE_ADD}/${1}`;
      } else if (!userData?.mobile_number) {
        route = `/manage-profile/supplier/${USER_ACTION_TYPE.TYPE_ADD}/${2}`;
      } else if (!userData?.address) {
        route = `/manage-profile/supplier/${USER_ACTION_TYPE.TYPE_ADD}/${3}`;
      } else {
        route = `/manage-profile/supplier/${USER_ACTION_TYPE.TYPE_ADD}/${4}`;
      }
    }
  }
  return route;
};

export const saveUserData = (payload: any) => {
  if (payload?.token?.access) {
    cookieService.saveCookie(
      cookieServiceParam.token,
      payload?.token?.access || ""
    );
  }
  if (payload?.token?.refresh) {
    cookieService.saveCookie(
      cookieServiceParam.refresh,
      payload?.token?.refresh || ""
    );
  }
  if (payload?.data) {
    cookieService.saveCookie(cookieServiceParam.user, payload?.data);
  }
};

export const getUserData = () => {
  const user = cookieService.getCookie(cookieServiceParam.user);
  return user || null;
};

export const createFullName = (firstName: any, lastName: any) => {
  if (firstName !== "" && lastName !== "") {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
};

export const breakFullname = (fullname: string): string[] => {
  let firstName: string;
  let lastName: any;
  const completeName = fullname.split(" ");

  if (completeName.length > 1) {
    lastName = completeName.pop();
    firstName = completeName.map((name) => name).join(" ");
  } else {
    firstName = completeName[0] || "";
  }

  return [firstName, lastName || ""];
};

export const setDropdownSelectedValue = (listArr: Array<any>, value: string) =>
  listArr.find((el) => el.value === value || el.label === value) || null;

export const setDropdownSelectedDate = (listArr: Array<any>, value: string) => {
  var diff = moment().diff(value, "days");
  listArr.find((el) => (el.difference === diff ? el.label : ""));
};

export const setDropdownSelectedEvent = (
  listArr: Array<any>,
  value: string
) => {
  let a = listArr.find((el) => el.value === value || null);
  return a;
};
export const setDropdownSelectedEventTime = (
  listArr: Array<any>,
  value: string
) => {
  let a = listArr.find((el) => el.startTime === value || null);
  return a;
};
export const onRoleSelect = (
  data: any,
  selectedDepartment: any,
  selectedDepIdx: any
) => {
  const dep = _.cloneDeep(selectedDepartment);
  const role: any = _.cloneDeep(dep?.[selectedDepIdx]?.selectedRole);
  const idx = role.findIndex((el: any) => el.value === data.value);
  if (idx === -1) {
    role.push(data);
  } else {
    role.splice(idx, 1);
  }
  dep[selectedDepIdx].selectedRole = role;
  return dep;
};

export const onDepSelect = (
  data: any,
  selectedDepartment: any,
  selectedDepIdx: any
) => {
  const tempDep = _.cloneDeep(selectedDepartment);
  let tempRole: any = [];
  const idx = tempDep.findIndex((el: any) => el.value === data.value);
  if (idx === -1) {
    tempDep[selectedDepIdx] = { ...data, selectedRole: [] };
    tempRole = data.roles;
  } else {
    tempDep.splice(idx, 1);
    if (tempDep?.length === 0) {
      tempDep.push(defaultDepRoles);
    }
  }
  return [tempDep, tempRole];
};

export const getMaskInputFormat = (num: any) => {
  const number = `( ${num.substring(0, 3)} )  0${num.substring(
    3,
    6
  )}  ${num.substring(6, num.length)}`;
  return number;
  // const code = mask(num, mastFormat || apiConstants.mast_format);
  // return code;
};
export const getExpiryFormat = (month: any, yr: any) => {
  const a = month;
  const b = yr?.substr(-2);
  const num = Number(a + b);
  var s = num + "";
  while (s.length < 4) s = "0" + s;
  return [s.slice(0, 2), "/", s.slice(2)].join("");
};

/** Phone Utils */
export const getNumberWithIsd = (mobileNum: any) => {
  if (!mobileNum) {
    return;
  }

  //this module is used to make country code separate from combined mobile number
  const parsedNumber = parsePhoneNumberFromString(mobileNum)
    ?.format("INTERNATIONAL")
    ?.split(" ");

  const isd = parsedNumber?.shift() || defaultISD;

  return [isd, mobileNum.substring(isd.length)];
};

export const validatePhoneNumber = (mobileNum: string | any) => {
  return isValidPhoneNumber(mobileNum);
};

export const getSelectedIsd = (isd: string) =>
  countryList.find((el) => el.dial_code == isd) || defaultCountryOptions;

export const getSelectedCountry = (country: string) =>
  countryList.find((el) => el.name == country) || null;

export const getPrmsnText = (permissionType: any) => {
  if (permissionType === PERMISSION_TYPE.TYPE_PROD_OWNER) {
    return buttonsText.productionCompany;
  }
  if (permissionType === PERMISSION_TYPE.TYPE_ADMIN) {
    return "Admin";
  }
  if (permissionType === PERMISSION_TYPE.TYPE_HOD) {
    return "HOD";
  }
  if (permissionType === PERMISSION_TYPE.TYPE_SUPERVISOR) {
    return "Supervisor";
  }
  if (permissionType === PERMISSION_TYPE.TYPE_CREW_MEMBER) {
    return "Crew";
  }
};

export const getRolesAsPerPrmsn = (prmsnType: any) => {
  const data = rolesList.filter((item) =>
    item?.permissions?.includes(prmsnType)
  );
  return data;
};
export function handleConvertToImageFile(
  base64String: string,
  filename: string,
  mimeType: string
) {
  // Extract the data and mime type from the base64 string
  const imageData = atob(base64String.split(",")[1]);
  mimeType = mimeType || base64String.split(";")[0].split(":")[1];

  // Convert the binary data to a Blob object
  let bytes = new Uint8Array(imageData.length);
  for (let i = 0; i < imageData.length; i++) {
    bytes[i] = imageData.charCodeAt(i);
  }
  let blob = new Blob([bytes.buffer], { type: mimeType });

  // Create a File object from the Blob
  return new File([blob], filename, { type: mimeType });
}
export const getAddressFromCoordinates = async (
  latitude: number,
  longitude: number
) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );

    if (response?.data?.results?.length > 0) {
      const result = response?.data?.results[0];
      const addressComponents = result?.address_components;
      const address = result?.formatted_address;

      const obj = {
        address: address || "",
        country: "",
        city: "",
        post_code: "",
      };

      addressComponents?.map((item: any) => {
        if (item?.types?.[0] === "country")
          obj["country"] = item?.long_name || item?.short_name;
        if (item?.types?.[0] === "administrative_area_level_1")
          obj["city"] = item?.long_name || item?.short_name;
        if (item?.types?.[0] === "postal_code")
          obj["post_code"] = item?.long_name || item?.short_name;
      });
      return obj;
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error retrieving address:", error);
    throw error;
  }
};

export const uniqueIdArray = (arr: any) => {
  const uniqueArray = arr.filter((obj: any, index: number, self: any) => {
    return index === self.findIndex((o: any) => o?.id === obj?.id);
  });
  return uniqueArray;
};

export const hasAddressData = (data: any) =>
  data?.city || data?.country || false;

export const renderAddressDetail = (
  data: any,
  isPostCode?: boolean,
  isFullAddress?: boolean
) => {
  const { address, city, country, postcode } = data;
  const list = isFullAddress
    ? [address, city, country]
    : isPostCode
    ? [city, country, postcode]
    : [city, country];
  const addressParts = list?.filter((part) => part); // Filter out falsy values
  return addressParts.join(", ");
};

export const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};

export const getFileExtension = (url: any) => url?.split(".")?.pop();

export const getRolesAndDept = (userData: any) => {
  let roles: any = [],
    dept: any = [];
  if (
    userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER ||
    userData?.permission_type === PERMISSION_TYPE.TYPE_ADMIN
  ) {
    roles = [...rolesList];
    dept = [...preConfigDeptData];
  } else {
    if (Array.isArray(userData?.crew_member_profile?.company_department)) {
      if (userData?.crew_member_profile?.company_department?.length > 0) {
        userData?.crew_member_profile?.company_department?.map((item: any) => {
          item.label = item?.dep;
          item.value = item?.dep;
        });
        dept = [...userData?.crew_member_profile?.company_department];
      }
    }
    const data = getRolesAsPerPrmsn(userData?.permission_type);
    roles = [...data];
  }
  return [roles, dept];
};

export const getUserRoleAndDept = (userData: any) => {
  let selectedPermission: any = [],
    selectedDept: any = [];
  if (Array.isArray(userData?.crew_member_profile?.company_department)) {
    if (userData?.crew_member_profile?.company_department?.length > 0) {
      userData?.crew_member_profile?.company_department?.map((item: any) => {
        item.label = item?.dep;
        item.value = item?.dep;
        item.selectedRole = item?.roles;
      });
      selectedDept = [...userData?.crew_member_profile?.company_department];
    }
  }
  const data = rolesList.filter(
    (item) => item?.value == userData?.permission_type
  );
  selectedPermission = [...data];
  return [selectedPermission, selectedDept];
};

export const urlify = (text: any) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text?.replace(urlRegex, (url: any) => {
    return (
      '<a style="text-decoration: underline;" target="_blank" href="' +
      url +
      '">' +
      url +
      "</a>"
    );
  });
};

export const isProdUser = (userData: any) => {
  let isProdUser = false;
  if (
    userData?.user_type === ROLE_TYPE.TYPE_CREW &&
    (userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER ||
      userData?.permission_type === PERMISSION_TYPE.TYPE_ADMIN)
  ) {
    isProdUser = true;
  }
  return isProdUser;
};

export const isSuperUser = (userData: any) => {
  let isSuperUser = false;
  if (
    userData?.user_type === ROLE_TYPE.TYPE_CREW &&
    (userData?.permission_type === PERMISSION_TYPE.TYPE_HOD ||
      userData?.permission_type === PERMISSION_TYPE.TYPE_SUPERVISOR)
  ) {
    isSuperUser = true;
  }
  return isSuperUser;
};
