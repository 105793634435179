import React, { useContext, useEffect } from 'react'
import RouteStack from './routes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from './global/ui/GlobalLoader';
import { cookieService } from './services';
import { cookieServiceParam } from './utils/constants';
import { makeGetCall } from './effects/requests';
import { ApiResponseType, ROLE_TYPE } from './types';
import { endPoints } from './utils/endpoints';
import { MyContext } from './context';
import { onMessageListener } from './../src/global/FCM/FCM'
import Notifications from './global/FCM/notificationToken';
import ReactNotificationComponent from './global/FCM/toast-notify'
import useWebSocket from './hooks/useWebSocket';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { GoogleOAuthProvider } from '@react-oauth/google';
import '../src/scss/commonstyle.scss';
import { emptyFunction } from './utils';

const App: React.FC = () => {

  const { updateUserData, userData, updateChatStatus, updateCompanyData } = useContext(MyContext)

  const [isRoomOpen, errorMessage, onSendMessage, onClose] = useWebSocket({
    roomID: userData?.id,
    initializeWebSocket: true,
    superWebSocket: true,
    onMessage: (data) => {
      updateChatStatus(true)
    }
  });

  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
    onClose()
  });

  useEffect(() => {
    if (!cookieService.getCookie(cookieServiceParam.token)) {
      onClose()
    }
  }, [cookieService.getCookie(cookieServiceParam.token)])

  onMessageListener()
    .then((payload: any) => {
      getUserDetails();
      ReactNotificationComponent(payload.notification.title, payload.notification.body)
    })
    .catch(emptyFunction);

  const getUserDetails = () => {
    makeGetCall(endPoints.PROFILE, null, false, true).then((res: ApiResponseType<any>) => {
      if (res?.data?.crew_member_profile?.company_department?.length > 0) {
        res?.data?.crew_member_profile?.company_department?.map((item: any) => {
          item.label = item?.dep;
          item.value = item?.dep;
        })
      }
      updateUserData(res);
      if (res?.data?.user_type != ROLE_TYPE.TYPE_SUPPLIER) {
        getCompanyNames();
      }
    }).catch(emptyFunction)
  }

  const getCompanyNames = () => {
    makeGetCall(endPoints.USER_PRODUCTION, null, false, true).then((res) => {
      if (res?.results?.length > 0) {
        const data = res?.results?.map((item: any) => {
          if (item?.company_department && typeof item?.company_department == 'string') {
            item.company_department = JSON.parse(item?.company_department)
          }
          return item
        })
        updateCompanyData(data);
      }
    }).catch((err) => {

    })
  }

  useEffect(() => {
    const accessToken: any = cookieService.getCookie(cookieServiceParam.token);
    if (!accessToken) return;
    getUserDetails();
  }, [])

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID as never}>
      <DndProvider backend={HTML5Backend}>
        <GlobalLoader />
        <RouteStack />
        <Notifications />
        <ToastContainer />
      </DndProvider>
    </GoogleOAuthProvider>
  )
}

export default App