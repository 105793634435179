import { cookieServiceParam, labels, placeholder } from "../../utils/constants";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { useMemo, useState } from "react";
import { stripeStyle } from "../../utils/UtilityFunctions";
import useResponsiveFontSize from "../../hooks/useResponsiveFontSize";
import ActionModal from "../modal/ActionModal";
import { makePatchCall, makePostCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { ApiResponseType } from "../../types";
import { cookieService } from "../../services";
import { useNavigate } from "react-router-dom";
interface AddNewCardDetailsProps {
  isShow: boolean;
  onClose: () => void;
  onDone?: (paymentId?: any) => void;
  onSubscribe: () => void;
  redirectType?: any
}

const AddNewCardDetails = (props: AddNewCardDetailsProps) => {
  const navigate = useNavigate();
  const subscriptionSelected = cookieService.getCookie(cookieServiceParam.subscription_id)
  const [cardHolderName, setCardHolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setError] = useState({
    cardNo: '',
    cardExp: '',
    cardCvv: '',
    cardHolderName: '',
  });

  const stripe: any = useStripe();
  const elements: any = useElements();

  const updatePlan = (paymentId: any) => {
    makePostCall(endPoints.SUBSCRIPTION, { price_id: subscriptionSelected, payment_id: paymentId }, true).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        cookieService.saveCookie(cookieServiceParam.is_payment_done, true);
        props?.onClose();
        props?.onSubscribe();
      }
    })
  }

  const updateUserData = (paymentId: any) => {
    makePatchCall(endPoints.UPDATEPROFILE, {
      subscription_tier: subscriptionSelected?.recurring?.interval?.substring(0, 1)?.toUpperCase(),
      subscription_currency: subscriptionSelected?.currency?.toUpperCase()
    }).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        if (props?.redirectType == 'subs' && subscriptionSelected)
          updatePlan(paymentId)
      }
    })
  }
  const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(() => ({
      style: { ...stripeStyle }
    }), [fontSize]);
    return options;
  };

  const onSubmit = () => {
    let isCardValid = elements.getElement(CardNumberElement)._complete,
      isExpiryValid = elements.getElement(CardExpiryElement)._complete,
      isCvcValid = elements.getElement(CardCvcElement)._complete,
      isCardEmpty = elements.getElement(CardNumberElement)._empty,
      isExpiryEmpty = elements.getElement(CardExpiryElement)._empty,
      isCvcEmpty = elements.getElement(CardCvcElement)._empty;
    if (isCardValid && isExpiryValid && isCvcValid && cardHolderName) {
      setIsLoading(true);
      makePostCall(endPoints.PAYMENTDATA).then((res) => {
        addCardDetails(res?.data);
      }).catch(() => setIsLoading(false))
    } else {
      if (!isCardValid) {
        setError((prvState) => ({
          ...prvState,
          cardNo: isCardEmpty ? 'Card number is required' : 'Please enter valid card number',
        }))
      }
      if (!isExpiryValid) {
        setError((prvState) => ({
          ...prvState,
          cardExp: isExpiryEmpty ? 'Card expiry date is required' : 'Please enter valid expiry date',
        }))
      }
      if (!isCvcValid) {
        setError((prvState) => ({
          ...prvState,
          cardCvv: isCvcEmpty ? 'Card CVV is required' : 'Please enter valid card cvv',
        }))
      }
      if (!cardHolderName) {
        setError((prvState) => ({
          ...prvState,
          cardHolderName: 'Card holder name is required'
        }))
      }
    }
  }

  const addCardDetails = (stripeId: any) => {
    stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardHolderName,
      },
    }).then((res: any) => confirmSetup(stripeId, res?.paymentMethod?.id)).catch(() => setIsLoading(false))
  }

  const confirmSetup = (stripeId: any, paymentId: any) => {
    stripe.confirmSetupIntent(stripeId,
      {
        payment_method: paymentId,
        return_url: process.env.REACT_APP_PAYMENT_REDIRECT_URL
      }
    ).then((res: any) => {
      setIsLoading(false)
      if (res?.setupIntent?.status === 'requires_action') {
        if (res?.setupIntent?.next_action?.redirect_to_url && res?.setupIntent?.next_action?.type === 'redirect_to_url') {
          const subsData = {
            paymentId: paymentId,
            stripeId: stripeId,
            selectedSubs: subscriptionSelected,
            redirectType: props?.redirectType,
          }
          cookieService.saveCookie(cookieServiceParam.payment_data, subsData);
          const iframe = document.createElement('iframe');
          const myContainer = document.getElementById('my_container');
          iframe.src = res?.setupIntent?.next_action?.redirect_to_url?.url;
          iframe.width = '600px';
          iframe.height = '400px';
          myContainer!.style!.display = "flex";
          myContainer!.appendChild(iframe);
          props?.onClose?.()
        }
      } else {
        props.onDone?.(paymentId)
        updateUserData(paymentId)
      }
    }).catch(() => setIsLoading(false))
  }

  const clearError = (key: any) => {
    setError((prvState) => ({
      ...prvState,
      [key]: '',
    }))
  }

  window.addEventListener('message', function (evt) {
    const data = evt?.data;
    if (data?.type === 'payment') {
      cookieService.removeCookie(cookieServiceParam.payment_data);
      const myContainer: any = document.getElementById('my_container');
      myContainer!.innerHTML = '';
      myContainer!.style!.display = "none";
      if (data?.isCardAdded) navigate('/schedule');
    }
  }, false);

  return (
    <>
      <ActionModal
        isShow={props.isShow}
        onClose={props.onClose}
        onSubmit={onSubmit}
        title={labels.cardDetails}
        isLoading={isLoading}
        isStripeModal={true}
      >
        <div className={`form_group mb-4 black-input input-value-pleacehoder-opecity`}>
          <div className="input_box">
            <label htmlFor='card_holder'>{labels.cardHolderName}</label>
            <input
              placeholder={placeholder.cardHolderName}
              id='card_holder'
              onChange={(evt) => {
                setCardHolderName(evt.target.value);
                clearError('cardHolderName')
              }}
              type={'text'}
              value={cardHolderName}
            />
          </div>
          <span style={{ color: 'red' }}>{errors?.cardHolderName || ""}</span>
        </div>
        <div className="card-label mb-4" id="456">
          <CardNumberElement
            options={{ ...useOptions, placeholder: 'Enter card number' }}
            className="card-field"
            onChange={() => clearError('cardNo')}
          />
          <label htmlFor="cardnumber">{labels.cardNumber}</label>
          <span style={{ color: 'red' }}>{errors?.cardNo || ""}</span>
        </div>
        <div className="columns-wrap d-flex gap-4" style={{ marginBottom: '130px' }}>
          <div className="card-label mb-4">
            <CardExpiryElement
              options={{ ...useOptions, placeholder: 'MM/YY' }}
              onChange={() => clearError('cardExp')}
            />
            <label htmlFor="cardnumber">{labels.expDate}</label>
            <span style={{ color: 'red' }}>{errors?.cardExp || ""}</span>
          </div>
          <div className="card-label mb-4">
            <CardCvcElement
              options={{ ...useOptions, placeholder: 'Enter CVV Digit' }}
              onChange={() => clearError('cardCvv')}
            />
            <label htmlFor="cardnumber">{labels.cvv}</label>
            <span style={{ color: 'red' }}>{errors?.cardCvv || ""}</span>
          </div>
        </div>
      </ActionModal>

    </>
  )
}
export default AddNewCardDetails; 