import { Table } from "react-bootstrap";
import { ROLE_TYPE } from "../../../types";
import AddPrepDiaryBtn from "../prepDiaryManagement/AddPrepDiaryBtn";
import { useContext } from "react";
import { MyContext } from "../../../context";
import { DateUtils } from "../../../utils/dateUtils";

interface IWeekViewFooter {
    data: any;
    isProdSuperUser?: boolean
    showPrep?: boolean;
    onAddPrepDiary?: (date: Date) => void;
    onAddEvent?: () => void
}

const WeekViewFooter = (props: IWeekViewFooter) => {

    const { userData } = useContext(MyContext);

    const dateData = props?.data?.footerData

    return (
        <Table responsive borderless className="event-scadule-bottom-fisx" cellSpacing={"0"} >
            <tbody>
                <tr>
                    {props?.data?.evtData?.map((item: any, idx: any) => {
                        const key: any = DateUtils.formatDateTime(item?.dateString, 'YYYY-MM-DD')
                        return (
                            <th key={idx}>
                                <div className="bottom-wwekse">
                                    {(userData?.user_type != ROLE_TYPE.TYPE_SUPPLIER && props?.showPrep) &&
                                        <AddPrepDiaryBtn
                                            onClick={() => props?.onAddPrepDiary?.(item)}
                                        />
                                    }
                                    {(props?.isProdSuperUser || userData?.user_type == ROLE_TYPE.TYPE_SUPPLIER) &&
                                        <div
                                            onClick={props?.onAddEvent}
                                            className="top-part-collender d-flex justify-content-between align-items-center cursor-pointer"
                                        >
                                            <div className="d-grid bottom-week">
                                                <div className="add-schedule d-flex align-items-center justify-content-between">
                                                    Add Event
                                                    <img src="/assets/images/schedule-plus.svg" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="d-flex justify-content-between align-items-center gap-2 fw-normal">
                                        <div className="fw-bold d-flex align-items-center gap-1">
                                            <img src="/assets/images/crew-member.svg" alt="" />
                                            Crew
                                        </div>
                                        {dateData[key]?.total_crew}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center gap-2 fw-normal">
                                        <div className="fw-bold d-flex align-items-center gap-1">
                                            <img src="/assets/images/supplier-mem.svg" alt="" />
                                            Supplier
                                        </div>
                                        {dateData[key]?.total_supplier}
                                    </div>
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </tbody>
        </Table>
    )
}

export default WeekViewFooter