import React, { useContext, useState } from 'react'
import { ROLE_TYPE, USER_PRESS_ACTION_TYPE } from '../../types';
import { showToast } from '../../utils';
import FlagConfirm from '../../components/modal/FlagConfirm';
import Logo from '../../components/commonComponent/ProfileImage';
import DeleteUser from '../../components/modal/DeleteUser';
import { makeDeleteCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';
import MessageLimitExceed from '../../pages/crewMembers/MessageLimitExceed';

interface UserBasicInfoProps {
    userData: any;
    onClose: () => void;
    onAction: (type: any) => void;
    showUpdateRoleOption?: any;
    isActive?: boolean;
    isSupplier?: boolean;
    isCrew?: boolean;
    getCrewMem: any;
    sideBarArrow?: boolean;
    showDeleteOption?: boolean;
    hideMessage?: boolean;
}

const UserBasicInfo: React.FC<UserBasicInfoProps> = (props) => {

    const [showMoreOption, setShowMoreOption] = useState(false);
    const [confirmFlag, setConfirmFlag] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showExceedMessageModal, setShowExceedMessageModal] = useState(false);

    const { userData: currentUserData, } = useContext(MyContext)

    const onAction = (type: any) => {
        setShowMoreOption(false);
        props?.onAction?.(type)
    }

    const onRemoveAction = () => {
        makeDeleteCall(`${endPoints.COMPANY_INVITE}${props?.userData?.id}`)
            .then((res) => {
                setShowMoreOption(false);
                setConfirmDelete(!confirmDelete)
                props?.onClose()
                props?.getCrewMem()
            })
            .catch((err) => {
                showToast(err, "error")
            })
    }

    const messageDisabled = (props?.isCrew && !props?.hideMessage && currentUserData?.user_type === ROLE_TYPE.TYPE_SUPPLIER && currentUserData?.message_credit == 0) ? true : false

    return (
        <div className="popup-right-side-top">
            <div className='cross-and-dropdonw-selct d-flex justify-content-between align-items-center mb-0'>
                <img
                    src={props.sideBarArrow ? "/assets/images/arrow-donw-black.svg" : "/assets/images/dismiss-cross-black.svg"}
                    alt=""
                    className={props.sideBarArrow ? "img-cross-icon sidebarArrow" : "img-cross-icon"}
                    onClick={() => {
                        props?.onClose?.();
                        setShowMoreOption(false)
                    }}
                />
                <div className='filter-select'>
                    {!props?.isSupplier &&
                        <img
                            onClick={() => setShowMoreOption((prevState) => !prevState)}
                            src={"/assets/images/more-three-dots.svg"}
                            className="main-drop-donwe"
                            alt=""
                        />
                    }
                    <div className={`drop-down-dtost  ${showMoreOption ? "d-block" : "d-none"} `}>
                        <ul className="d-grid list-unstyled pl-0 mb-0">
                            {props?.showUpdateRoleOption &&
                                <li
                                    onClick={() => {
                                        if (props?.userData?.account_completed) {
                                            onAction?.(USER_PRESS_ACTION_TYPE.TYPE_UPDATE_ROLE)
                                        } else {
                                            showToast("Able to update the role once the profile is created completely", "info")
                                        }
                                    }}
                                >
                                    <img src={"/assets/images/update-role.svg"} alt="" />
                                    Update Role
                                </li>
                            }
                            <li
                                onClick={() => setConfirmFlag(!confirmFlag)}
                            >
                                <img src={"/assets/images/Flag-red.svg"} alt="" />
                                Flag User
                            </li>
                            {props?.showDeleteOption &&
                                <li onClick={() => setConfirmDelete(!confirmDelete)} ><img src={"/assets/images/delete-event.svg"} alt="" />Remove User</li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="profile-show-part text-center">
                {props?.userData?.logo
                    ?
                    <img className="profile-img" src={props?.userData?.logo} width="87" height="87" alt="" />
                    :
                    <Logo style={{ width: '70px', height: '70px' }} data={props?.userData?.name?.[0]} />
                }
                <h5>
                    {props.userData?.name}
                    {props.userData?.verified &&
                        <img style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-3px' }} src={"/assets/images/yellow_check_mark.png"} alt="" />
                    }
                </h5>
                {props.userData?.dep &&
                    <button className="perple-button mb-3">{props.userData?.dep}</button>
                }
                <ul style={props?.isSupplier ? { marginBottom: '0px' } : {}} className="list-unstyled d-flex flex-wrap align-items-center justify-content-center">
                    {props.userData?.website?.length > 0 &&
                        <li>
                            <button type='button' onClick={() => onAction?.(USER_PRESS_ACTION_TYPE.TYPE_WEBSITE)} className="btn btn-light-with-icon shadow-none">
                                <img src={"/assets/images/link-icon.svg"} alt="" />{props.userData?.linkTitle}
                            </button>
                        </li>
                    }
                    {!props?.hideMessage &&
                        <li>
                            <button
                                type='button'
                                onClick={() => {
                                    if (messageDisabled) {
                                        setShowExceedMessageModal(true)
                                        return;
                                    }
                                    onAction?.(USER_PRESS_ACTION_TYPE.TYPE_MESSAGE)
                                }}
                                className="btn btn-dark-button shadow-none"
                            >
                                <img src={"/assets/images/chat-message-icon.svg"} alt="" />
                                Message
                            </button>
                        </li>
                    }
                </ul>
                {(props?.isCrew && !props?.hideMessage && currentUserData?.user_type === ROLE_TYPE.TYPE_SUPPLIER) &&
                    <span className='message-limit'>{`You have ${currentUserData?.message_credit || 0} out of 10 messages remaining`}</span>
                }
            </div>
            <FlagConfirm
                show={confirmFlag}
                onClose={() => setConfirmFlag(!confirmFlag)}
                handleAction={() => {
                    onAction?.(USER_PRESS_ACTION_TYPE.TYPE_FLAG);
                    setConfirmFlag(!confirmFlag);
                }}
            />
            <DeleteUser
                show={confirmDelete}
                onClose={() => setConfirmDelete(!confirmDelete)}
                handleAction={onRemoveAction}
            />
            <MessageLimitExceed
                onClose={() => setShowExceedMessageModal(false)}
                show={showExceedMessageModal}
            />
        </div>
    )
}

export default UserBasicInfo