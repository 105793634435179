import React, { useState } from 'react'
import { JOB_SCHEDULE_TYPE, ROLE_TYPE } from '../../types';
import { createFullName } from '../../utils';
import { DateUtils } from '../../utils/dateUtils';
import MultipleEvent from './MultipleEvent';
import AssignEvent from './AssignEvent';
import Logo from '../../components/commonComponent/ProfileImage';
import CustomToggle from '../../global/ui/CustomToggle';

interface ScheduleItemProps {
    item: any;
    onClick: (status: any, data: any) => void;
    calHeaderData: Array<any>;
    index: number;
    onEventView: any;
    markFlatDay: (data: any, date: any, status?: any) => void;
}

const ScheduleItem: React.FC<ScheduleItemProps> = (props) => {

    const dateData = props?.item?.date_data;
    const [popEventData, setPopEventData] = useState<any>(null);
    const [assignEvent, setAssignEvent] = useState<any>(null);

    const renderMainData = (data: any, key: string, nameData: any) => {

        switch (data?.status) {

            case JOB_SCHEDULE_TYPE.TYPE_AVAILABLE:
                return <div
                    onClick={() => {
                        props.onClick(data?.status, { ...data, ...props.item });
                    }}
                    className='fill-card-assign available d-flex align-items-center justify-content-center'
                >
                    <p className='mb-0 d-flex align-items-center justify-content-center'><img src="/assets/images/checkmark-green.svg" alt="" /> Available</p>
                    <div className='plus-available d-flex align-items-center justify-content-center'>
                        <img src="/assets/images/plus-icon-white.svg" alt="" />
                    </div>
                </div>

            case JOB_SCHEDULE_TYPE.TYPE_FLAT_DAY:
                return <div
                    onClick={() => {
                        props.onClick(data?.status, { ...data, ...props.item });
                    }}
                    className='fill-card-assign available d-flex align-items-center justify-content-center'
                    style={{ backgroundColor: 'rgba(227, 255, 204, 0.5)', paddingLeft: '25px', paddingRight: '25px', }}
                >
                    <p
                        style={{ color: '#00923D', textAlign: 'center' }}
                        className='mb-0'
                    >
                        Flat day - No events Scheduled
                    </p>
                </div>

            case JOB_SCHEDULE_TYPE.TYPE_REST:
                return <div
                    onClick={() => props.onClick(data?.status, { ...data, ...props.item, key })}
                    className='fill-card-assign rest d-flex align-items-center justify-content-center'
                >
                    <p className='mb-0 d-flex align-items-center justify-content-center'><img src="/assets/images/rest.svg" alt="" />Rest</p>
                    <div className='plus-addin d-flex align-items-center justify-content-center'>
                        <img src="/assets/images/plus-icon-white.svg" alt="" />
                    </div>
                </div>

            case JOB_SCHEDULE_TYPE.TYPE_JOB:
                return <div
                    onClick={() => props.onClick(data?.status, { ...data, ...props.item })}
                    className='fill-card-assign'
                >
                    <ul className='list-unstyled ps-0 ms-0 mb-0 d-grid '>
                        <li>{data?.job_data?.job_title || ''}</li>
                        <li>{data?.job_data?.job_location || ''}</li>
                        <li>{`${DateUtils.formatDateTime(data?.job_data?.job_start_date, 'DD MMM, YY')} - ${DateUtils.formatDateTime(data?.job_data?.job_end_date, 'DD MMM, YY')}`}</li>
                    </ul>
                </div>

            case JOB_SCHEDULE_TYPE.TYPE_UN_AVAILABLE:
                return <div
                    onClick={() => props.onClick(data?.status, { ...data, ...props.item })}
                    className='fill-card-assign unavailable d-flex align-items-center justify-content-center'
                >
                    <p className='mb-0 d-flex align-items-center justify-content-center'><img src="/assets/images/cross-icon-red.svg" alt="" /> Unavailable</p>
                </div>

            case JOB_SCHEDULE_TYPE.TYPE_EVENT:
                return <div>
                    {data?.event_data.length > 1
                        ?
                        <div onClick={() => setPopEventData({ nameData: nameData, eventData: data?.event_data })} style={{ backgroundColor: '#F2f2f2', borderRadius: '12px', padding: '0.75rem 0.5rem' }}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <p className="mb-0 fw-bold">{data?.event_data.length} Events Scheduled</p>
                                <img
                                    style={{ cursor: "pointer" }}
                                    // onClick={() => setSelectedDate(DateUtils.getNextWeek(selectedDate))}
                                    src="/assets/images/play-icon-dark.svg"
                                    className="right-side-mo"
                                    alt=""
                                />
                            </div>
                            <p className="mb-0">Tap to view all</p>
                        </div>
                        :
                        <div className="crew-event" onClick={() => props.onEventView(data?.event_data[0]?.event_id)}>
                            <div className="d-flex justify-content-between align-items-center mb-2" >
                                <p className="mb-0 fw-bold other-event-title" >{data?.event_data[0]?.event_title}</p>
                                <img style={{ cursor: "pointer" }}
                                    src="/assets/images/play-icon-dark.svg"
                                    className="right-side-mo" alt="" />
                            </div>
                            <div className="d-flex justify-content-between ">
                                <p className="mb-0">{data?.event_data[0]?.event_start_date.slice(11, 16)} - {data?.event_data[0]?.event_end_date.slice(11, 16)}</p>
                                {data?.event_data[0]?.is_seventh_day ? <img src="/assets/images/7th-icon.svg" alt="" className='icon-6th-7th' /> :
                                    data?.event_data[0]?.is_sixth_day && <img src="/assets/images/6th-icon.svg" alt="" className='icon-6th-7th' />}
                            </div>
                        </div>}
                </div>
        }
    }

    return (<>
        <tr style={{ width: "10%", minWidth: "187px" }}></tr>
        <tr>
            <>
                <td style={{ width: '10%', minWidth: '187px' }}>
                    <div className='profile_table_scadule d-flex gap-2 align-items-center' style={{ wordBreak: 'break-word' }}>
                        {props?.item?.profile_image ?
                            <img className='align-self-baseline' src={props.item?.profile_image} alt="Image" />
                            :
                            <Logo style={{ width: '30px', height: '30px', borderRadius: '0', padding: '10px' }} data={props.item?.first_name?.[0]} />}
                        <div>
                            {createFullName(props.item?.first_name, props.item?.last_name) || ''}
                            {(props.item?.verified && props.item?.user_type === ROLE_TYPE.TYPE_CREW) && <img style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-1px' }} src={"/assets/images/yellow_check_mark.png"} alt="" />}
                            <p className='word-brack mb-0' style={{ fontWeight: '400', fontSize: '12px' }}>{props?.item?.job_title ? props?.item?.job_title : <p className='mb-0' style={{ color: '#BDBDBD' }}>No Job Assigned</p>}</p>
                        </div>
                    </div>
                </td>
                {props.calHeaderData.map((dateObj, idx) => {
                    const key: any = DateUtils.formatDateTime(dateObj, 'YYYY-MM-DD');
                    const status = dateData?.[key]?.status || '';
                    const isDisabled = (status === JOB_SCHEDULE_TYPE.TYPE_EVENT || status === JOB_SCHEDULE_TYPE.TYPE_UN_AVAILABLE) ? true : false;
                    return (
                        <td style={{ width: '12%', minWidth: '162px' }} key={idx}>
                            {renderMainData(dateData?.[key], key, props.item)}
                            <div className='d-flex justify-content-end p-2'>
                                <CustomToggle
                                    onChange={() => {
                                        if (isDisabled) {
                                            return
                                        }
                                        props?.markFlatDay(props.item, dateObj, status)
                                    }}
                                    checked={(isDisabled || status === JOB_SCHEDULE_TYPE.TYPE_FLAT_DAY) ? true : false}
                                    disabled={isDisabled}
                                />
                            </div>
                        </td>
                    )
                })}
            </>
        </tr>
        {popEventData &&
            <MultipleEvent
                isShow={popEventData ? true : false}
                onClose={() => setPopEventData(null)}
                nameData={popEventData?.nameData}
                eventData={popEventData?.eventData}
                onEventView={(id: any) => {
                    setPopEventData(null)
                    props.onEventView(id)
                }} />}
        {assignEvent &&
            <AssignEvent
                isShow={assignEvent ? true : false}
                onClose={() => setAssignEvent(null)}
                nameData={assignEvent?.nameData}
                dateSelected={assignEvent?.key}
                eventData={assignEvent?.eventData}
            />
        }
    </>
    )
}

export default ScheduleItem
