import { useContext } from "react";
import { DateUtils } from "../../../utils/dateUtils";
import { MyContext } from "../../../context";
import { EVENT_STATUS, EVENT_TYPE, ROLE_TYPE } from "../../../types";
import { useDrag } from "react-dnd";

interface IWeekEventCard {
    data: any;
    onEventView: (id: any, eventStatus?: any, supplierID?: any) => void;
    onEditEvt: (id: any) => void;
    isProdSuperUser?: boolean;
    sourceDate: any
}

const WeekEventCard = (props: IWeekEventCard) => {

    const { data } = props;

    const { userData } = useContext(MyContext);

    const isSupplier = userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? true : false;

    let event = isSupplier ? data?.event : data;

    const isShootEvt = event?.type === EVENT_TYPE.SHOOT ? true : false;

    const isPending = data?.status === EVENT_STATUS.PENDING ? true : false

    const handleViewEvt = () => {
        if (isSupplier) {
            props.onEventView(event?.id, data?.status, isPending ? event?.id : null)
        } else {
            props?.onEventView(event?.id)
        }
    }

    const [{ isDragging }, drag] = useDrag({
        type: 'item',
        item: { ...props?.data, event_source_date: props?.sourceDate },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const renderMainView = () => {
        if (isShootEvt) {
            return (
                <div
                    ref={isSupplier ? null : drag}
                    className={isPending ? 'imp-event event-request' : 'imp-event'}
                    style={{ height: '60px', opacity: isDragging ? 0.5 : 1 }}
                >
                    <div className='outer'>
                        <div
                            className={isPending ? 'event-name event-request-text' : 'event-name'}
                            onClick={handleViewEvt}
                        >
                            {event?.name}
                        </div>
                        {props?.isProdSuperUser &&
                            <img src="/assets/images/schedule-white.svg" alt="" onClick={() => props?.onEditEvt(event?.id)} />
                        }
                    </div>
                    <div className={isPending ? 'event-address event-request-sub-text text-break' : 'event-address'} onClick={handleViewEvt}>{event?.address}</div>
                </div>
            )
        } else {
            return (
                <div
                    ref={isSupplier ? null : drag}
                    onClick={handleViewEvt}
                    className={isPending ? 'imp-event other-event-request ' : "imp-event event-scadu"}
                    style={{ padding: '0.5rem 1rem', opacity: isDragging ? 0.5 : 1 }}
                >
                    <div className="d-flex justify-content-between align-items-center" >
                        <p className="mb-0 fw-bold other-event-title">{event?.name}</p>
                        <img
                            style={{ cursor: "pointer" }}
                            src="/assets/images/play-icon-dark.svg"
                            className="right-side-mo" alt=""
                        />
                    </div>
                    {isSupplier
                        ?
                        <div
                            className={isPending ? 'event-address event-request-sub-text' : 'event-address'}
                            onClick={handleViewEvt}
                        >
                            {event?.address}
                        </div>
                        :
                        <p
                            className="mb-0"
                            style={{ fontWeight: '500' }}
                        >
                            {`${DateUtils.formatDateTime(event?.start_datetime, 'HH:mm')} - ${DateUtils.formatDateTime(event?.end_datetime, 'HH:mm')}`}
                        </p>
                    }
                </div>
            )
        }
    }

    return (
        <>
            {renderMainView()}
        </>
    )
}

export default WeekEventCard;