import { useContext, useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { signInFormData } from '../../data/formData';
import { makePatchCall, makePostCall, makePutCall } from '../../effects/requests';
import { ApiResponseType, BUTTON_TYPE, PERMISSION_TYPE, ROLE_TYPE } from '../../types';
import { apiConstants } from '../../utils/apiConstants';
import { emptyFunction, getRoute, showToast } from '../../utils';
import { buttonsText, cookieServiceParam, errorMessage, labels, placeholder, signinText } from '../../utils/constants';
import AuthSection from '../../global/layout/authSection';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import InputField from '../../lib/input';
import { EMAIL_VALIDATION, REQUIRED_VALIDATION } from '../../lib/input/validation-rules';
import Button from '../../global/ui/Button';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';
import ChangePassword from "../../components/modal/changePassword";
import { cookieService } from '../../services';
import { setToken } from '../../global/FCM/FCM';

const SignIn = () => {

  const { updateUserData } = useContext(MyContext)

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...signInFormData() },
    reValidateMode: 'onBlur'
  });

  const navigate = useNavigate();
  const userInfo = useRef<any>(null)

  const [isEmailExists, setIsEmailExists] = useState<Boolean>(false);
  const [userName, setUserName] = useState(null);
  const [isSecure, setIsSecure] = useState<any>(true);
  const [showPassChangeModal, setShowPassChangeModal] = useState<any>(false);
  const userType = cookieService.getCookie(cookieServiceParam.userType);
  const isProductionSignUp = userType === ROLE_TYPE.TYPE_CREW
  const [existUserType, setExistUserType] = useState(null);

  useEffect(() => {
    if (isProductionSignUp) {
      setIsEmailExists(true)
    }
  }, [])
  const onSubmit: SubmitHandler<any> = (data: any) => {
    if (isEmailExists) {
      onSignIn(methods.getValues());
    } else (
      checkEmailExists()
    )
  };

  const onSignIn = (data: any) => {
    makePostCall(endPoints.LOGIN, data).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        if (!res?.data?.is_password_changed) {
          userInfo.current = res;
          cookieService.saveCookie(cookieServiceParam.token, res?.token?.access || "");
          setShowPassChangeModal(true)
          return
        }
        cookieService.saveCookie(cookieServiceParam.token, res?.token?.access || "");
        cookieService.saveCookie(cookieServiceParam.userType, res?.data?.user_type)
        cookieService.saveCookie(cookieServiceParam.account_completed, res?.data?.account_completed);
        cookieService.saveCookie(cookieServiceParam.subscription_id, res?.data?.subscription_id)
        regDeviceToken()
        userNavigate(res)
      }
    }).catch(emptyFunction)
  }

  const regDeviceToken = async () => {
    const params: any = { device_type: 'WEB' }
    if (cookieService.getCookie(cookieServiceParam.browserToken)) {
      params.token = cookieService.getCookie(cookieServiceParam.browserToken)
    } else {
      const fcmToken = await setToken(emptyFunction);
      params.token = fcmToken
    }
    if (!params?.token) return
    makePostCall(endPoints.DEVICE_TOKEN, params).then(emptyFunction).catch(emptyFunction)
  }

  const userNavigate = (res: any) => {
    if (Array.isArray(res?.data?.crew_member_profile?.company_department) && res?.data?.crew_member_profile?.company_department?.length > 0) {
      res?.data?.crew_member_profile?.company_department?.map((item: any) => {
        item.label = item?.dep;
        item.value = item?.dep;
      })
    }
    updateUserData(res);
    let routeUrl = null;
    if (res?.data?.user_type === ROLE_TYPE.TYPE_CREW) {
      if (res?.data?.permission_type === PERMISSION_TYPE.TYPE_CREW_MEMBER) {
        makePatchCall(endPoints.PROFILE, { permission_type: PERMISSION_TYPE.TYPE_PROD_OWNER })
          .then((res) => {
            routeUrl = '/manage-profile/owner/add/1'
            navigate(routeUrl)
            return;
          })
          .catch((err) => { })

      }
      else if (res?.data?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
        routeUrl = getRoute(res.data);
      }
      else {
        routeUrl = '/schedule';
      }
    }
    else {
      routeUrl = getRoute(res.data);
    }
    navigate(routeUrl as never);
  }

  const checkEmailExists = () => {
    const payload = { email: methods.getValues(apiConstants.email) }
    makePostCall(endPoints.CHECKEMAIL, payload).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        // if (res.data?.user_type == ROLE_TYPE.TYPE_CREW && res.data?.permission_type == ROLE_TYPE.TYPE_CREW) {
        //   showToast(errorMessage.unauthorized_access, "error");
        //   return
        // }
        setUserName(res.data?.full_name);
        setIsEmailExists(!res.data?.available);
        setExistUserType(res?.data?.user_type || "")
        setTimeout(() => methods.setValue(apiConstants.password, ""), 100)
        if (res.data?.available) {
          showToast(errorMessage.email_not_registered_desc, "error")
          setUserName(null);
          methods.setValue(apiConstants.email, "")
        }
      }
    }).catch(emptyFunction)
  }

  const handleUpdatePass = (data: any) => {
    setShowPassChangeModal(false)
    makePutCall(endPoints.PASSWORD, data).then((res: ApiResponseType<any>) => {
      if (res.status?.code === 200) {
        userNavigate(userInfo?.current);
        updateProfile();
      }
    })
  }

  const updateProfile = () => {
    makePatchCall(endPoints.PROFILE, { is_password_changed: true }).then((res: ApiResponseType<any>) => {
      cookieService.saveCookie(cookieServiceParam.userType, res?.data?.user_type)
    }).catch(emptyFunction)
  }

  return (
    <AuthSection isProductionSignUp={isProductionSignUp}>
      <div className='inner-content signup-page text-center ms-auto'>
        <div className='top-content'>
          {userName ? <h3>{signinText.welcome_back} <span>{UtilityFunctions.capitalize(userName)}!</span></h3> : isProductionSignUp ? <h3>{signinText.welcome_crew}</h3> : <h3>{signinText.welcome}</h3>}
          <p>{isProductionSignUp ? signinText.signin_production_crew_1 : existUserType === ROLE_TYPE.TYPE_CREW ? signinText.signin_production_crew : signinText.signin_details}</p>
        </div>
        <FormProvider {...methods} >
          <form className='text-start' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <InputField
              label={labels.email}
              placeholder={isProductionSignUp ? 'Enter your crew app email address' : placeholder.email}
              name={apiConstants.email}
              type='email'
              rules={EMAIL_VALIDATION}
              containerStyle={{ marginTop: '25px', }}
            />
            {(isEmailExists || isProductionSignUp) &&
              <InputField
                label={labels.password}
                placeholder={isProductionSignUp ? 'Enter your crew app password' : placeholder.password}
                name={apiConstants.password}
                type={isSecure ? 'password' : 'text'}
                rules={REQUIRED_VALIDATION(labels.password)}
                rightIconPress={() => setIsSecure((prvState: any) => !prvState)}
                rightIcon={isSecure ? '/assets/images/eye-on.svg' : '/assets/images/eye-off.svg'}
                containerStyle={{ marginTop: '25px', }}
              />
            }
            <Button
              type={BUTTON_TYPE.TYPE_SUBMIT}
              btnTxt={isProductionSignUp ? 'Sign Up' : buttonsText.signin}
              style={{ marginTop: "20px", width: "100%" }}
              className={'btn btn-warning w-100 with-after-shadow shadow-none'}
              disabled={!methods?.formState?.isValid}
            />
          </form>
        </FormProvider>
        {(isEmailExists && !isProductionSignUp) &&
          <div className='move-login-signup' onClick={() => navigate('/forgot-password', { state: { email: methods.getValues(apiConstants.email) } })}>
            <p className='mb-0'>{buttonsText.forgot_password}</p>
          </div>
        }
        {!isProductionSignUp &&
          <div className='move-login-signup' onClick={() => navigate('/')}>
            <p className='mb-0 mt-4'>{buttonsText.signup_instead}</p>
          </div>
        }
        <div className='move-login-signup' onClick={() => { navigate('/'); cookieService.removeCookie(cookieServiceParam.userType); }}>
          <p className='mt-4'>{buttonsText.go_back}</p>
        </div>
      </div>
      <ChangePassword
        isShow={showPassChangeModal}
        onClose={emptyFunction}
        onSubmit={handleUpdatePass}
        hideClose
      />
    </AuthSection>
  )
}

export default SignIn;
