import React from 'react'
import { CREW_GROUP_STATUS } from '../../types';

interface ICrewGroupFilter {
    selectedType: string | any;
    onSelect: (value: string) => void;
}

const CrewGroupFilter: React.FC<ICrewGroupFilter> = (props) => {
    return (
        <div className="table-filter-setup mb-3 mb-lg-0">
            <div className="row align-items-center d-flex justify-content-between">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 order-1 order-xxl-1">
                    <div className="liket-active-tab mb-4">
                        <ul className="list-unstyled d-flex align-item-center mb-0">
                            <li
                                onClick={() => props?.onSelect(CREW_GROUP_STATUS.PUBLISHED_GROUP)}
                                className={props?.selectedType === CREW_GROUP_STATUS.PUBLISHED_GROUP ? "active" : ''}
                            >
                                Published Groups
                            </li>
                            <li
                                onClick={() => props?.onSelect(CREW_GROUP_STATUS.MY_GROUP)}
                                className={props?.selectedType === CREW_GROUP_STATUS.MY_GROUP ? "active" : ''}
                            >
                                My Groups
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrewGroupFilter