import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import Logo from '../../components/commonComponent/ProfileImage';
import DropDown from '../../global/ui/dropDown';
import { preConfigDeptData, rolesList } from '../../data/data';
import Button from '../../global/ui/Button';
import { ApiResponseType, BUTTON_TYPE, PERMISSION_TYPE } from '../../types';
import { createFullName, generateFormData, getRolesAndDept, getUserRoleAndDept, isProdUser, showToast } from '../../utils';
import { MyContext } from '../../context';
import { makePatchCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import DeptWithRoles from '../../global/ui/DeptWithRoles';

interface IUpdateCrewRoleAndPrmsn {
    isShow: boolean;
    onClose: () => void;
    onSave: (data: any) => void;
    selectedCrewData: any;
    onDelete: () => void
}

const UpdateCrewRoleAndPrmsn = (props: IUpdateCrewRoleAndPrmsn) => {

    const { userData } = useContext(MyContext)

    const [selectedDept, setSelectedDept] = useState<any>([]);
    const [selectedPermission, setSelectedPermission] = useState<any>(null);
    const [allPermission, setAllPermission] = useState(rolesList);
    const [allDept, setAllDept] = useState(preConfigDeptData);
    const [overtimeHours, setOvertimeHours] = useState('');

    const preFillUserData = (selectedUserData: any) => {
        const [selectedPermission, selectedDept] = getUserRoleAndDept(selectedUserData);
        const [permissions, dept] = getRolesAndDept(userData);
        setAllPermission(permissions);
        setAllDept(dept)
        setOvertimeHours(selectedUserData?.crew_member_profile?.overtime_hours || '');
        setSelectedDept(selectedDept);
        setSelectedPermission(selectedPermission?.[0]);
    }

    useEffect(() => {
        if (props?.isShow) {
            preFillUserData(props?.selectedCrewData)
        }
    }, [props?.isShow, JSON.stringify(userData)]);

    const onSubmit = () => {
        if (!selectedPermission?.value) {
            showToast('Please select permission', 'error');
            return
        }
        if (!overtimeHours) {
            showToast('Please enter overtime hours', 'error');
            return
        }
        if (selectedDept.some((item: any) => !item?.label)) {
            showToast('Please select department', 'error');
            return
        }
        if (selectedDept.some((item: any) => item?.selectedRole?.length === 0)) {
            showToast('Please select roles', 'error');
            return
        }
        const dept: any = [];
        selectedDept?.map((item: any) => {
            dept.push({ dep: item?.label, roles: item?.selectedRole })
        })
        const payload: any = {
            permission_type: selectedPermission?.value,
            company_department: JSON.stringify(dept),
            overtime_hours: overtimeHours
        }
        handleUpdateRole(payload)
    }

    const handleUpdateRole = (apiPayload: any) => {
        const fd = generateFormData(apiPayload);
        makePatchCall(`${endPoints.USER}${props?.selectedCrewData?.id}/`, fd, true).then((res: ApiResponseType<any>) => {
            props?.onSave(res)
        })
    }

    const isSuperUser = (selectedPermission?.value == PERMISSION_TYPE.TYPE_ADMIN || selectedPermission?.value === PERMISSION_TYPE.TYPE_HOD) ? true : false;

    const imgStyle = {
        width: '90px',
        height: '90px',
        borderRadius: '50px',
        border: '1px solid rgba(122, 51, 255, 1)'
    }

    return (
        <Modal
            centered
            scrollable
            show={props.isShow}
            className='new_broadcast'
        >
            <div className='d-flex justify-content-between align-items-start' style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: '5px' }}>
                <div onClick={props.onClose} className='noti-back-btn'>
                    <img src={"/assets/images/dismiss-cross-black.svg"} alt="" />
                </div>
                <div className='d-grid justify-content-center align-items-center align-content-center'>
                    {props?.selectedCrewData?.profile_image
                        ?
                        <img
                            style={imgStyle}
                            src={props?.selectedCrewData?.profile_image}
                            alt='user Image'
                        />
                        :
                        <Logo style={imgStyle} data={props?.selectedCrewData?.first_name?.[0]} />
                    }
                    <span className='role-user'>{createFullName(props?.selectedCrewData?.first_name, props?.selectedCrewData?.last_name)}</span>
                </div>
                {isProdUser(userData) ? <img className='cursor-pointer' onClick={props?.onDelete} src={"/assets/images/delete-fill-inner-red.svg"} alt="" /> : <div />}
            </div>
            <ModalBody className="p-0 text-start height-402 overflow-scroll">
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{ backgroundColor: '#6FCF97', marginTop: '30px' }} className='contract-hours-wrap'>
                        <span>{props?.selectedCrewData?.active_at_company ? 'Active' : 'InActive'}</span>
                    </div>
                </div>
                <div className='m-5'>
                    <DropDown
                        className="arrow-fill new-brodcast"
                        data={allPermission}
                        onSelect={(data) => {
                            setSelectedDept([null]);
                            setSelectedPermission(data)
                        }}
                        placeholder='Select Permission'
                        label='Permission Type'
                        selectedItems={selectedPermission}
                    />
                    <div className={`form_group mb-4 black-input input-value-pleacehoder-opecity`}>
                        <div className="input_box">
                            <label htmlFor='overtime_hours'>Update Contracted Hours</label>
                            <input
                                placeholder='Enter contracted hours'
                                id='overtime_hours'
                                onChange={(evt) => {
                                    setOvertimeHours(evt.target.value?.replace(/[^0-9]/g, ''));
                                }}
                                type='number'
                                value={overtimeHours}
                            />
                        </div>
                    </div>
                    <DeptWithRoles
                        depts={allDept}
                        onDeptSelect={(data: any, idx: any) => {
                            const temp: Array<any> = [...selectedDept];
                            const index = temp.findIndex((item) => item?.label == data?.label);
                            if (index === -1) {
                                const obj = { ...data };
                                obj.selectedRole = []
                                temp[idx] = obj;
                                setSelectedDept(temp)
                            } else {
                                showToast('Department has already been picked.', 'error');
                            }
                        }}
                        selectedDept={selectedDept}
                        onRoleSelect={(roles: any, idx: any) => {
                            const temp: any = [...selectedDept];
                            temp[idx].selectedRole = roles;
                            setSelectedDept(temp)
                        }}
                    />
                    {isSuperUser &&
                        <div className='d-flex align-items-center justify-content-center mt-2 mb-3'>
                            <button
                                className="btn btn-warning btn-sm shadow-none"
                                type='button'
                                style={{ padding: '12px 14px', alignSelf: 'center' }}
                                onClick={() => {
                                    const temp: any = [...selectedDept];
                                    temp.push(null)
                                    setSelectedDept(temp)
                                }}
                            >
                                <img style={{ marginRight: '20px' }} src={"/assets/images/plus-icon.svg"} alt="" />Add Department
                            </button>
                        </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter className="p-0 border-0" style={{ marginTop: '0px' }}>
                <div className='d-flex justify-content-end align-items-center'>
                    <Button
                        type={BUTTON_TYPE.TYPE_BUTTON}
                        btnTxt='Cancel'
                        style={{ marginTop: "0px", minWidth: "160px", marginRight: '15px', backgroundColor: 'rgba(242, 242, 242, 1)', }}
                        className={'btn btn-warning w-100 with-after-shadow shadow-none border-0'}
                        onClick={props.onClose}
                    />
                    <Button
                        type={BUTTON_TYPE.TYPE_BUTTON}
                        btnTxt='Save Changes'
                        style={{ marginTop: "0px", minWidth: "160px" }}
                        className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                        onClick={onSubmit}
                    />
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default UpdateCrewRoleAndPrmsn