import React from 'react'
import { createFullName, getValidUrl, hasAddressData, renderAddressDetail } from '../../utils'
import { DateUtils } from '../../utils/dateUtils'
import NoData from '../../global/ui/NoData'
import Logo from '../../components/commonComponent/ProfileImage';

interface CrewsProps {
    data: Array<any>;
    onClick?: (data: any) => void;
}

const Crews: React.FC<CrewsProps> = (props) => {

    return (
        <div className="production-crow-exploree">
            {props.data?.length > 0
                ?
                <div className="row gx-4">
                    {props.data?.map((item, idx) => {
                        return (
                            <div key={idx} className="col-md-12 col-lg-6 col-xl-4">
                                <div className="card explore_crew_card">
                                    <div className="card-body d-flex align-items-start">
                                        {item?.profile_image ?
                                            <img style={{ cursor: 'pointer' }} onClick={() => props?.onClick?.(item)} src={item?.profile_image || "/assets/images/user-icon-perple.svg"} className="user-img rounded-circle" alt="" />
                                            : <Logo style={{ width: '70px', height: '70px' }} data={item?.first_name[0]} />}
                                        <div className="cloud-data">
                                            <h4 style={{ cursor: 'pointer', textTransform: 'capitalize' }} onClick={() => props?.onClick?.(item)}>{createFullName(item?.first_name, item?.last_name)}
                                                {item?.verified && <img style={{ width: '15px', height: '15px', marginLeft: '5px' }} src={"/assets/images/yellow_check_mark.png"} alt="" />}
                                            </h4>
                                            {hasAddressData(item) &&
                                                <p className={`${item?.crew_member_profile?.imdb_link ? 'mb-0 pb-2' : ''}`}>
                                                    {renderAddressDetail(item, true)}
                                                </p>
                                            }
                                            {item?.crew_member_profile?.imdb_link && <a target='_blank' className='text-decoration-none' href={getValidUrl(item?.crew_member_profile?.imdb_link)}><p style={{ color: 'blue' }}>IMDb Profile</p></a>}
                                            {item?.crew_member_profile?.bio && <p className='ellipsis'>{item?.crew_member_profile?.bio || ''}</p>}
                                            <ul className="profile-information-list">
                                                <li className="align-items-center">
                                                    <img src={"/assets/images/dimand-icon-perpel.svg"} alt="" />
                                                    {` Member since ${DateUtils.formatDateTime(item?.date_joined, 'DD MMMM YYYY')}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p style={{ cursor: 'pointer' }} onClick={() => props?.onClick?.(item)} className="play-icon"><img src={"/assets/images/play-icon-dark.svg"} alt="" /></p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                <NoData />
            }
        </div >
    )
}

export default Crews