import React from 'react';
interface WelcomeProps {
  userFirstName: string;
}

const Welcome: React.FC<WelcomeProps> = ({ userFirstName }) => {
  return (
    <div className="detalt-chet text-center w-100 d-flex mh-100 align-items-center justify-content-center">
      <div>
        <h1 className="fw-bold">
          Welcome<span>{userFirstName ? `, ${userFirstName}` : ''}!</span>
        </h1>
        <h3>Please select a chat to start messaging.</h3>
      </div>
    </div>
  );
};

export default Welcome;
