import Button from '../../global/ui/Button';
import { cookieServiceParam, defaultCountryOptions, errorMessage, labels, placeholder } from '../../utils/constants';
import { ApiResponseType, BUTTON_TYPE, ROLE_TYPE } from '../../types';
import { signupText, buttonsText } from '../../utils/constants';
import { Link, useNavigate } from "react-router-dom";
import AuthSection from '../../global/layout/authSection';
import { useForm, FormProvider } from "react-hook-form";
import InputField from '../../lib/input';
import { EMAIL_VALIDATION, SUPPLIER_NAME_VALIDATION, PASS_VALIDATION, MOBILE_VALIDATION } from '../../lib/input/validation-rules';
import { signUpFormData } from '../../data/formData';
import React, { useContext, useState } from 'react';
import { apiConstants } from '../../utils/apiConstants'
import { emptyFunction, showToast, validatePhoneNumber } from '../../utils';
import { routes } from '../../utils/routesConstants';
import { makeGetCall, makePostCall } from '../../effects/requests';
import { cookieService } from '../../services';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';
import { setToken } from '../../global/FCM/FCM';
import MobileNumber from '../../global/ui/mobileNumber';

const Signup: React.FC = () => {

  const navigate = useNavigate();
  const { updateUserData } = useContext(MyContext)

  const userType = cookieService.getCookie(cookieServiceParam.userType) || ROLE_TYPE.TYPE_SUPPLIER

  // useForm hook and set default behavior/values
  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...signUpFormData() },
    reValidateMode: 'onBlur',
  });

  const [isSecure, setIsSecure] = useState<any>(true);
  const [countryOptions, setCountryOptions] = useState(defaultCountryOptions);

  const onSubmit = (values: any) => {
    const payload: any = {
      email: values?.[apiConstants.email],
      password: values?.[apiConstants.password],
      first_name: values?.[apiConstants?.full_name]?.substring(0, values?.[apiConstants?.full_name]?.indexOf(' ')),
      last_name: values?.[apiConstants?.full_name]?.substring(values?.[apiConstants?.full_name]?.indexOf(' ') + 1),
      mobile_number: `${countryOptions?.dial_code}${values?.[apiConstants.mobile_number]}`,
      user_type: userType,
      is_pp_accepted: true,
      is_tnc_accepted: true,
      is_password_changed: true,
    }
    if (!validatePhoneNumber(payload?.[apiConstants.mobile_number])) {
      showToast(errorMessage.invalid_mobile_msg, 'error', 'mobile')
      return
    }
    checkEmailExists(payload);
  }

  const checkEmailExists = (apiPayload: any) => {
    makePostCall(endPoints.CHECKEMAIL, { email: apiPayload?.[apiConstants.email] }).then((res: ApiResponseType<any>) => {
      if (res.status.code == 200) {
        if (res?.data?.available) {
          doSignup(apiPayload);
        } else {
          showToast("Email already registered. Sign In", "error");
          navigate('/sign-in');
        }
      }
    })
  }

  const doSignup = (payload: any) => {
    makePostCall(endPoints.SIGNUP, payload).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        updateUserData(res);
        saveDeviceToken();
        sendVerificationMail(payload?.[apiConstants.email]);
      }
    }).catch(emptyFunction)
  }

  const sendVerificationMail = (email: any) => {
    makeGetCall(endPoints.VERIFYEMAIL, { email: email }).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        cookieService.saveCookie(cookieServiceParam.userType, 'S')
        navigate('/verify-email');
      }
    }).catch(emptyFunction)
  }

  const saveDeviceToken = async () => {
    const params: any = { device_type: 'WEB' }
    if (cookieService.getCookie(cookieServiceParam.browserToken)) {
      params.token = cookieService.getCookie(cookieServiceParam.browserToken)
    } else {
      const fcmToken = await setToken(emptyFunction);
      params.token = fcmToken
    }
    if (!params?.token) return
    makePostCall(endPoints.DEVICE_TOKEN, params).then(emptyFunction).catch(emptyFunction)
  }

  console.log(!methods?.formState?.isValid, "isValid")

  return (
    <AuthSection >
      <div>
        <div className='inner-content signup-page text-center ms-auto'>
          <div className='top-content'>
            <h3>{signupText.welcome}</h3>
            <p>{userType === 'O' ? signupText.pro_details : signupText.sup_details}</p>
          </div>
          <FormProvider {...methods} >
            <form className='text-start' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
              <InputField
                label={labels.full_name}
                placeholder={placeholder.full_name}
                name={apiConstants.full_name}
                type='text'
                rules={SUPPLIER_NAME_VALIDATION(labels.full_name)}
                containerStyle={{ marginTop: '25px', }}
              />
              <InputField
                label={labels.email}
                placeholder={placeholder.email}
                name={apiConstants.email}
                type='email'
                rules={EMAIL_VALIDATION}
                containerStyle={{ marginTop: '25px', }}
              />
              <MobileNumber
                onSelect={(data: any) => setCountryOptions(data)}
                selectedOption={countryOptions}
                name={apiConstants.mobile_number}
                label={labels.contactNumber}
                placeholder={placeholder.contactNumber}
                type='number'
                rules={MOBILE_VALIDATION}
                containerStyle={{ marginTop: '25px', }}
                selectLabel="Select"
                isTransparent
              />
              <InputField
                label={labels.password}
                placeholder={placeholder.password}
                name={apiConstants.password}
                type={isSecure ? 'password' : 'text'}
                rules={PASS_VALIDATION('')}
                rightIconPress={() => setIsSecure((prvState: any) => !prvState)}
                rightIcon={isSecure ? '/assets/images/eye-on.svg' : '/assets/images/eye-off.svg'}
                containerStyle={{ marginTop: userType === ROLE_TYPE.TYPE_SUPPLIER ? '5px' : '25px', }}
              />
              <div style={{ marginTop: '25px' }} className='form-bottom-pp-tc'>
                <p className='mb-0'>By pressing sign up you agree to our <span><Link to={`${routes.legals}/tnc`}>Terms & Conditions</Link> & <Link to={`${routes.legals}/pp`}>Privacy Policy</Link></span></p>
              </div>
              <Button
                type={BUTTON_TYPE.TYPE_SUBMIT}
                btnTxt={buttonsText.continue}
                style={{ marginTop: "24px" }}
                disabled={!methods?.formState?.isValid}
                className={'btn btn-warning w-100 with-after-shadow shadow-none'}
              />
            </form>
          </FormProvider>
          <div className='move-login-signup' onClick={() => { navigate('/sign-in'); cookieService.removeCookie(cookieServiceParam.userType); }}>
            <p className='mb-0'>{buttonsText.login_instead}</p>
          </div>
        </div>
      </div>
    </AuthSection>
  )
}
export default Signup;