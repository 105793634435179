import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
  makePostCall,
} from "../../effects/requests";
import SideBarLayout from "../../global/layout/SideBarLayout";
import NoData from "../../global/ui/NoData";
import SearchWithHeading from "../../global/ui/SearchWithHeading";
import { ApiResponseType, JOB_SCHEDULE_TYPE, ROLE_TYPE, SCHEDULE_TYPE } from "../../types";
import { apiConstants } from "../../utils/apiConstants";
import { DateUtils } from "../../utils/dateUtils";
import { endPoints } from "../../utils/endpoints";
import ExportData from "./ExportData";
import ScheduleDetails from "./ScheduleDetails";
import ScheduleFilters from "./ScheduleFilters";
import ScheduleItem from "./ScheduleItem";
import TableHeaderData from "./TableHeaderData";
import { buttonsText, cookieServiceParam } from "../../utils/constants";
import NewEvent from "../../components/modal/NewEvent";
import EventScheduleDay from "./dayView/EventScheduleDay";
import EventDetail from "../newEvent/EventDetail";
import { MyContext } from "../../context";
import { getWeekHeaderData, manipulatedDayData, manipulatedMonthData, manipulatedWeekData } from "../../utils/UtilityFunctions";
import { cookieService } from "../../services";
import EventMonthView from "./monthView/EventMonthView";
import { Space, Switch, ConfigProvider } from 'antd';
import DraftEvtModal from "../../components/modal/DraftEvtModal";
import AddPrepDiaryModal from "./prepDiaryManagement/AddPrepDiaryModal";
import ViewPrepDiary from "./prepDiaryManagement/ViewPrepDiary";
import { emptyFunction, isProdUser, isSuperUser, showToast } from "../../utils";
import WeekView from "./weekView";
import DragEventInfo from "../../components/modal/DragEventInfo";
import DragEventConflictCrew from "../../components/modal/DragEventConflictCrew";
import ActionModal from "../../components/modal/ActionModal";
import CustomGooglePlaceSearch from "../../components/customGooglePlaceSearch";

const Schedule = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const parsed = queryString.parse(location.search);
  const { userData, updateUserData, updateCompanyData } = useContext(MyContext)

  const [calData, setCalData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(DateUtils.getCurrentDate().toDate());
  const [nextUrl, setNextUrl] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [calNextUrl, setCalNextUrl] = useState("");
  const [isExport, setIsExport] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [needUpdateAddress, setNeedUpdateAddress] = useState(false);

  const [locationData, setLocationData] = useState<any>({
    lat: '',
    lng: '',
    city: '',
    country: '',
    post_code: '',
    location: userData?.address || '',
  });

  const [noOfHoursInDay, setNoOfHoursInDay] = useState<Array<any>>([]);
  const [noOfDatesInWeek, setNoOfDatesInWeek] = useState<any>({
    evtData: [],
    footerData: []
  });
  const [weekDatesInMonth, setWeekDatesInMonth] = useState<Array<any>>([]);
  const [shootEventsInDay, setShootEventsInDay] = useState<Array<any>>([]);

  const [isShowDraftModal, setIsShowDraftModal] = useState(false);
  const [isAddPrepDiary, setIsAddPrepDiary] = useState(false);
  const [isViewPrepDiary, setIsViewPrepDiary] = useState(false);
  const [selectedPrepDate, setSelectedPrepDate] = useState(null);
  const [prepDiaryEvtData, setPrepDiaryEvtData] = useState<any>(null);
  const [prepEditData, setPrepEditData] = useState(null);

  const [impEventData, setImpEventData] = useState([]);
  const [eventDetail, setEventDetail] = useState(null)
  const [eventStatus, setEventStatus] = useState(null);
  const [supplierID, setSupplierID] = useState(null);
  const [isEvtDetail, setIsEvtDetail] = useState(false);
  const [isEditEvent, setIsEditEvent] = useState(false);
  const [scheduleType, setScheduleType] = useState(isProdUser(userData) ? SCHEDULE_TYPE.CREW_SCH : SCHEDULE_TYPE.EVENT_SCH)
  const [calendarType, setCalendarType] = useState('Week');
  const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
  const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());
  const [dragEventDetail, setDragEventDetail] = useState<any>(null)
  const [dragEventConflict, setDragEventConflict] = useState<any>(false)
  const [conflictedCrews, setConflictedCrews] = useState<any>([])

  useEffect(() => {
    if (location?.state?.eventId) {
      setTimeout(() => {
        fetchEventDetail(false, location?.state?.eventId)
      }, 1000)
    }
  }, [location])

  const getScheduleData = () => {
    const payLoad: any = {
      start_date: DateUtils.startOfWeek(selectedDate, 'DD-MM-YYYY'),
      end_date: DateUtils.endOfWeek(selectedDate, 'DD-MM-YYYY'),
      production_company_name: userData?.production_company_name || '',
      ...parsed,
    };
    if (userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
      const payLoad = {
        start_date_gte: calendarType === 'Day' ? DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD') : calendarType === 'Month' ? DateUtils.startOfMonth(selectedDate, 'YYYY-MM-DD') : DateUtils.startOfWeek(selectedDate, 'YYYY-MM-DD'),
        start_date_lte: calendarType === 'Day' ? DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD') : calendarType === 'Month' ? DateUtils.endOfMonth(selectedDate, 'YYYY-MM-DD') : DateUtils.endOfWeek(selectedDate, 'YYYY-MM-DD'),
        ...parsed,
      }
      makeGetCall(endPoints.SCHEDULE, payLoad).then((res => {
        setDragEventDetail(null)
        if (calendarType === 'Day') {
          updateDayHoursList(res)
        } else if (calendarType === 'Month') {
          updateWeekDatesInMonth(res)
        } else {
          updateDayInWeek(res)
        }
      }))
    } else {
      if (scheduleType === SCHEDULE_TYPE.CREW_SCH) {
        makeGetCall(endPoints.USER_SCHEDULE, payLoad).then(
          (res: ApiResponseType<any>) => {
            setDragEventDetail(null)
            setImpEventData(res?.shoot_data)
            setCalData(res?.results);
            setCalNextUrl(res?.next || "");
          }
        );
      } else if (scheduleType === SCHEDULE_TYPE.EVENT_SCH) {
        if (calendarType === 'Month') {
          payLoad.start_date = DateUtils.startOfMonth(selectedDate, 'DD-MM-YYYY');
          payLoad.end_date = DateUtils.endOfMonth(selectedDate, 'DD-MM-YYYY');
        } else if (calendarType === 'Day') {
          payLoad.start_date = DateUtils.formatDateTime(selectedDate, 'DD-MM-YYYY');
          payLoad.end_date = DateUtils.formatDateTime(selectedDate, 'DD-MM-YYYY');
        }
        makeGetCall(endPoints.EVENT, payLoad).then(
          (res: any) => {
            setDragEventDetail(null)
            if (calendarType === 'Day') {
              updateDayHoursList(res)
            } else if (calendarType === 'Month') {
              updateWeekDatesInMonth(res)
            } else {
              updateDayInWeek(res)
            }
          }
        )
      }
    }
  };

  const updateUrls = (key: any, value: any) => {
    const params = parsed;
    params[key] = value;
    navigate(`/schedule/?${createSearchParams(params as never)}`);
  };

  const getUserList = (query?: any) => {
    const params = {
      user_type: ROLE_TYPE.TYPE_CREW,
      search: query || '',
      production_company_name: userData?.production_company_name || ''
    }
    makeGetCall(endPoints.USERLIST, params).then((res: ApiResponseType<any>) => {
      setUserListData(res?.results);
      setNextUrl(res?.next || '')
    })
  }

  const fetchMore = () => {
    if (nextUrl) {
      setIsLoading(true)
      makeNextPageCall(nextUrl, false, true).then((res: ApiResponseType<any>) => {
        if (res?.next) {
          setIsLoading(false)
          const tempUser: any = [...userListData, ...res?.results];
          setUserListData(tempUser)
          setNextUrl(res?.next)
        }
      }).catch(() => setIsLoading(false))
    }
  };

  const onCardClick = (status: any, data: any) => {
    if (!isProdUser(userData)) {
      return
    }
    switch (status) {
      case JOB_SCHEDULE_TYPE.TYPE_AVAILABLE:
      case JOB_SCHEDULE_TYPE.TYPE_REST:
        if (DateUtils.getDayOfWeek(data?.key) === 0 || DateUtils.getDayOfWeek(data?.key) === 6) {
          setScheduleDetails(data)
          setIsShow(true) //to show New Event pop up
        }
        else {
          setScheduleDetails(data)
          setIsShow(true)  //to show New Event pop up
        }
        break;

      case JOB_SCHEDULE_TYPE.TYPE_JOB:
        setScheduleDetails(data);
        setIsShowDetail(true);
        break;
    }
  };

  const fetchMoreData = () => {
    if (!calNextUrl) return;
    makeNextPageCall(calNextUrl).then((res: ApiResponseType<any>) => {
      const data = [...calData, ...res.results];
      setCalNextUrl(res?.next);
      setCalData(data as never);
    });
  };

  useEffect(() => {
    if (userData?.id) {
      getScheduleData();
    }
  }, [selectedDate, JSON.stringify(parsed), scheduleType, calendarType, userData]);

  useEffect(() => {
    setTimeout(() => {
      if (!cookieService.getCookie(cookieServiceParam.subscription_id) && cookieService.getCookie(cookieServiceParam.userType) !== 'C') {
        navigate(cookieService.getCookie(cookieServiceParam.account_completed) ? '/expire-subscription' : '/subscription')
      }
    }, 1000)
  }, [])

  const updateDayHoursList = (res: any) => {
    const selectedDateKey: any = DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD')
    const noOfHoursInDay = DateUtils.getNoOfHoursInDay(selectedDate);
    const [dayFinalData, shootEvents] = manipulatedDayData(noOfHoursInDay, res?.data || [], res?.date_data?.[selectedDateKey]);
    setNoOfHoursInDay(dayFinalData);
    setShootEventsInDay(shootEvents);
  }

  const updateWeekDatesInMonth = (res: any) => {
    const weekDatesInMonth = DateUtils.getWeekDatesInMonth(selectedDate)
    const finalData = manipulatedMonthData(weekDatesInMonth, res?.data || [], res?.date_data || []);
    setWeekDatesInMonth(finalData);
  }

  const updateDayInWeek = (res: any) => {
    const [evtData, evtFooterData] = manipulatedWeekData(getWeekHeaderData(selectedDate), res, userData);
    setNoOfDatesInWeek({
      evtData: evtData,
      footerData: evtFooterData
    })
  }

  useEffect(getUserList, [userData]);

  const fetchEventDetail = (req: boolean, id: any, eventStatus?: any, supplierID?: any) => {
    setEventStatus(eventStatus);
    setSupplierID(supplierID);
    const url = `${endPoints.EVENT}${id}/`
    makeGetCall(url).then((res) => {
      setEventDetail(res?.data);
      setIsEvtDetail(!isEvtDetail)
    })
  }

  const evtDetails = (id: any) => {
    const url = `${endPoints.EVENT}/${id}`
    makeGetCall(url).then((res) => {
      setEventDetail(res?.data);
      setIsShow(!isShow);
      setIsEditEvent(true)
    })
  }
  const resetEventDetail = () => {
    setIsShow(!isShow)
    setEventDetail(null)
  }
  const clearAll = () => {
    navigate(`/schedule/?${createSearchParams({
      department: '',
      role: '',
      id: ''
    } as never)}`);
  }

  const sendEvtNotification = () => {
    const payload = {
      start_date: DateUtils.startOfWeek(selectedDate, "YYYY-MM-DD"),
      end_date: DateUtils.endOfWeek(selectedDate, "YYYY-MM-DD"),
    }
    makePostCall(endPoints.SEND_NOTIFICATION, payload, false).then((res) => {
      makeGetCall(endPoints.PROFILE)
        .then((res) => {
          updateUserData(res)
        })
        .catch((err) => { })
    }).catch(emptyFunction)
  }

  const updateUserCalNotification = () => {
    makePatchCall(endPoints.PROFILE, { calender_notification_sent: false }, false)
      .then((res) => {
        updateUserData(res)
      })
      .catch((err) => { })
  }

  useEffect(() => {
    if (userData?.id && userData?.user_type != ROLE_TYPE.TYPE_SUPPLIER) {
      getCompanyNames();
    }
  }, [userData?.id])

  const getCompanyNames = () => {
    makeGetCall(endPoints.USER_PRODUCTION, null, false, true).then((res) => {
      if (res?.results?.length > 0) {
        const data = res?.results?.map((item: any) => {
          if (item?.company_department && typeof item?.company_department == 'string') {
            item.company_department = JSON.parse(item?.company_department)
          }
          return item
        })
        updateCompanyData(data);
      }
    }).catch((err) => {

    })
  }

  const checkEventAvailability = () => {
    const params = {
      event_id: dragEventDetail?.id,
      date: `${DateUtils.formatDateTime(dragEventDetail?.event_destination_date, 'YYYY-MM-DD')}`
    }
    makeGetCall(endPoints.CHECK_CONFLICT, params).then((res) => {
      if (res?.status?.code === 200) {
        if (res?.data?.conflict) {
          setConflictedCrews(res?.data?.user_data || [])
          setDragEventConflict(true);
          setDragEventDetail(null)
          return
        }
        updateDragEvt();
      }
    }).catch(() => {
    })
  }

  const updateDragEvt = () => {
    const fd = {
      start_datetime: `${DateUtils.formatDateTime(dragEventDetail?.event_destination_date, 'YYYY-MM-DD')} ${DateUtils.formatDateTime(dragEventDetail?.start_datetime, 'HH:mm:ss')}`,
      end_datetime: `${DateUtils.formatDateTime(dragEventDetail?.event_destination_date, 'YYYY-MM-DD')} ${DateUtils.formatDateTime(dragEventDetail?.end_datetime, 'HH:mm:ss')}`,
    }
    makePatchCall(`${endPoints.CREATE_EVENT}${(dragEventDetail?.id).trim()}/`, fd, true).then((res) => {
      if (res?.status?.code === 200) {
        updateUserCalNotification();
        getScheduleData();
      }
    }).catch(() => {
    })
  }

  const updateAddress = () => {
    if (!locationData?.location || !locationData?.lat || !locationData?.lng) {
      showToast('Please select address', 'error');
      return;
    }
    const fd = new FormData();
    fd.append('address', locationData?.location);
    fd.append('city', locationData?.city || '');
    fd.append('country', locationData?.country || '');
    fd.append('post_code', locationData?.post_code || '');
    fd.append('coordinates', JSON.stringify({ "type": "Point", "coordinates": [locationData?.lat, locationData?.lng] }));
    makePatchCall(endPoints.PROFILE, fd)
      .then((res) => {
        updateUserData(res);
        setNeedUpdateAddress(false)
      }).catch(emptyFunction)
  }

  const markFlatDay = (data: any, date: any, status?: any) => {
    const fd = new FormData();
    fd.append('user_id', data?.id || '');
    fd.append('date', `${DateUtils.formatDateTime(date, 'YYYY-MM-DD')}`);
    fd.append('production_company_name', userData?.production_company_name);
    if (status === JOB_SCHEDULE_TYPE.TYPE_FLAT_DAY) {
      makeDeleteCall(endPoints.FLAT_DAY, fd).then((res: ApiResponseType<any>) => {
        if (res?.status?.code === 200) {
          getScheduleData()
        }
      }).catch(emptyFunction)
    } else {
      makePostCall(endPoints.FLAT_DAY, fd).then((res: ApiResponseType<any>) => {
        if (res?.status?.code === 200) {
          getScheduleData()
        }
      }).catch(emptyFunction)
    }
  }

  useEffect(() => {
    if (userData?.id) {
      setTimeout(() => {
        if (!(userData?.city && userData?.country && userData?.coordinates?.coordinates?.length > 0)) {
          setLocationData((prevState: any) => ({
            ...prevState,
            location: userData?.address,
          }))
          setNeedUpdateAddress(true)
        }
      }, 2000);
    }
  }, [userData?.id])

  const draftEvtText = userData?.calender_notification_sent ? 'Your calendar is currently live - Crew will be notified by any changes you make immediately.' : 'Your calendar is currently in draft mode - Crew will not receive any notifications until you publish changes.'

  return (
    <SideBarLayout>
      <div className="right-content-full-screen">
        <SearchWithHeading
          placeholder="Search by name, email or phone"
          onSearch={(value) => updateUrls(apiConstants.search, value)}
          value={parsed?.[apiConstants.search]}
          heading="Schedule"
          addText={buttonsText.newEvent}
          onAdd={() => { resetEventDetail(); }}
        />
        <div className="bottom-content-full-screen">
          <ScheduleFilters
            value={parsed}
            onChange={updateUrls}
            clearAll={clearAll}
            onExport={() => setIsExport(true)}
            type={scheduleType}
            changeScheduleType={(type: any) => {
              setScheduleType(type)
              setCalendarType('Week');
            }}
            userFilterData={{
              next: nextUrl,
              fetchMore: fetchMore,
              userListData: userListData,
              value: parsed?.[apiConstants.user] || '',
              onChange: (value: any) => updateUrls(apiConstants.id, value),
              isLoading: isLoading,
              onSearch: (query) => {
                setSearchValue(query)
                getUserList(query)
              },
              showSearch: true,
              searchValue: searchValue
            }}
            scheduleType={scheduleType}
            isOwner={isProdUser(userData)}
          />
          <div className="date-part-top-design d-flex align-item-center justify-content-between" >
            <div className="d-flex align-item-center" style={{ width: '400px' }}>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (calendarType === 'Day')
                    setSelectedDate(DateUtils.getPreviousDay(selectedDate))
                  if (calendarType === 'Week')
                    setSelectedDate(DateUtils.getPreviousWeek(selectedDate))
                  if (calendarType === 'Month') {
                    setSelectedDate(DateUtils.getPreviousMonth(selectedDate))
                    if (currentMonth === 0) {
                      setCurrentMonth(11);
                      setCurrentYear(currentYear - 1);
                    } else {
                      setCurrentMonth(currentMonth - 1);
                    }
                  }
                }
                }
                src="/assets/images/play-icon-dark.svg"
                className="left-side-mo"
                alt=""
              />
              {calendarType === 'Day' && <p className="mb-0 extra-padding-top">{`${DateUtils.startdate(
                selectedDate, "DD MMMM")}`}</p>}
              {calendarType === 'Week' && <p className="mb-0 extra-padding-top">{`${DateUtils.startOfWeek(
                selectedDate,
                "DD MMM, YY"
              )} - ${DateUtils.endOfWeek(selectedDate, "DD MMM, YY")}`}</p>}
              {calendarType === 'Month' &&
                <p className="mb-0 extra-padding-top">
                  {`${DateUtils.formatDateTime(selectedDate, "MMMM YYYY")}`}
                </p>
              }
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (calendarType === 'Day')
                    setSelectedDate(DateUtils.getNextDay(selectedDate))
                  if (calendarType === 'Week')
                    setSelectedDate(DateUtils.getNextWeek(selectedDate))
                  if (calendarType === 'Month') {
                    setSelectedDate(DateUtils.getNextMonth(selectedDate))
                    if (currentMonth === 11) {
                      setCurrentMonth(0);
                      setCurrentYear(currentYear + 1);
                    } else {
                      setCurrentMonth(currentMonth + 1);
                    }
                  }
                }
                }
                src="/assets/images/play-icon-dark.svg"
                className="right-side-mo"
                alt=""
              /></div>
            {(scheduleType === SCHEDULE_TYPE.EVENT_SCH && userData?.user_type != ROLE_TYPE.TYPE_SUPPLIER && isProdUser(userData)) &&
              <div className="draft d-flex justify-content-between align-items-center">
                <div className='draft-cal' style={{ backgroundColor: userData?.calender_notification_sent ? '#BFF495' : '#FF3F3F' }} >
                  <span>{draftEvtText}</span>
                </div>
                <ConfigProvider
                  theme={{
                    components: {
                      Switch: {
                        handleBg: '#FFD800',
                        colorPrimaryText: '#FFD800',
                        fontFamily: "Greycliff CF",
                        fontSize: 14,
                      },
                    },
                  }}
                >
                  <Space>
                    <Switch
                      checkedChildren="Live"
                      unCheckedChildren="Draft"
                      checked={userData?.calender_notification_sent}
                      onChange={(checked, evt) => {
                        if (checked) {
                          setIsShowDraftModal(true)
                          return
                        }
                      }}
                      style={{ marginLeft: '20px', backgroundColor: '#D9D9D9' }}
                    />
                  </Space>
                </ConfigProvider>
              </div>
            }
            {/* {scheduleType === SCHEDULE_TYPE.EVENT_SCH &&
              <div>
                <button className={`calendar-type ${calendarType === 'Day' ? 'active-calendar-type' : ''}`} onClick={() => setCalendarType('Day')}>Day</button>
                <button className={`calendar-type ${calendarType === 'Week' ? 'active-calendar-type' : ''}`} onClick={() => setCalendarType('Week')}>Week</button>
                <button className={`calendar-type ${calendarType === 'Month' ? 'active-calendar-type' : ''}`} onClick={() => setCalendarType('Month')}>Month</button>
              </div>
            } */}
            {isEvtDetail &&
              <EventDetail
                visible={isEvtDetail}
                onClose={() => setIsEvtDetail(!isEvtDetail)}
                onAdd={(id: any) => {
                  if (!isProdUser(userData)) {
                    return
                  }
                  setIsShow(!isShow);
                  setIsEvtDetail(!isEvtDetail);
                  evtDetails(id)
                }}
                eventStatus={eventStatus}
                supplierID={supplierID}
                eventDetail={eventDetail}
                getScheduleData={() => {
                  updateUserCalNotification()
                  getScheduleData()
                }}
                onUserSelect={(data: any, type: any) => {
                  if (type == ROLE_TYPE.TYPE_CREW) navigate('/explore', { state: { userId: data?.assigned_member?.id } });
                  else navigate(`/suppliers/?detail_id=${data?.assigned_member?.id}`)
                }}
                isProdSuperUser={isProdUser(userData)}
                isSuperUser={isSuperUser(userData)}
              />
            }
          </div>
          {scheduleType === SCHEDULE_TYPE.CREW_SCH &&
            <div className="main-collender">
              {calData?.length > 0 ? (
                <Table responsive borderless className="" cellSpacing={"0"}>
                  <TableHeaderData
                    data={getWeekHeaderData(selectedDate)}
                    impEventData={impEventData}
                    editFlow={isEvtDetail}
                    onAdd={(id: any) => { evtDetails(id); }}
                    onEventView={(id: any) => { fetchEventDetail(false, id) }}
                    userData={userData}
                  />
                  <tbody id="scrollableDiv">
                    <InfiniteScroll
                      dataLength={calData?.length}
                      next={fetchMoreData}
                      hasMore={nextUrl ? true : false}
                      loader={null}
                      scrollableTarget="scrollableDiv"
                    >
                      {calData?.map((item, idx) => {
                        return (
                          <ScheduleItem
                            onClick={onCardClick}
                            item={item}
                            key={idx}
                            calHeaderData={getWeekHeaderData(selectedDate)}
                            index={idx}
                            onEventView={(id: any) => { fetchEventDetail(false, id) }}
                            markFlatDay={markFlatDay}
                          />
                        );
                      })}
                    </InfiniteScroll>
                  </tbody>
                </Table>
              ) : (
                <NoData />
              )}
            </div>}
          {scheduleType === SCHEDULE_TYPE.EVENT_SCH &&
            <div>
              {calendarType === 'Day' &&
                <EventScheduleDay
                  onView={(evt) => fetchEventDetail(false, evt?.id)}
                  allData={noOfHoursInDay}
                  shootEvents={shootEventsInDay}
                  onEdit={(evt) => evtDetails(evt?.id)}
                />
              }
              {calendarType === 'Week' &&
                <WeekView
                  data={noOfDatesInWeek}
                  onAddPrepDiary={(data: any) => {
                    setIsAddPrepDiary(true);
                    setSelectedPrepDate(data)
                  }}
                  onViewPrepDiary={(data: any) => {
                    setIsViewPrepDiary(true)
                    setSelectedPrepDate(data)
                  }}
                  isProdSuperUser={isSuperUser(userData) || isProdUser(userData)}
                  onEditEvt={(id) => evtDetails(id)}
                  onEventView={(id: any, eventStatus: any, supplierID?: any) => fetchEventDetail(false, id, eventStatus, supplierID)}
                  onAddEvent={() => {
                    setEventDetail(null);
                    setIsShow(!isShow)
                  }}
                  onDragEnd={setDragEventDetail}
                />
              }
              {calendarType === 'Month' &&
                <EventMonthView
                  selectedDate={selectedDate}
                  allData={weekDatesInMonth}
                  onView={(evt) => fetchEventDetail(false, evt?.id)}
                  onDaySelect={(date: Date) => {
                    setSelectedDate(date);
                    setCalendarType('Day')
                  }}
                />
              }
            </div>
          }
        </div>
      </div>
      {isExport &&
        <ExportData
          isShow={isExport}
          onClose={() => setIsExport(false)}
          type={scheduleType}
        />
      }
      {isShowDetail &&
        <ScheduleDetails
          isShow={isShowDetail}
          onClose={() => {
            setIsShowDetail(false);
            setScheduleDetails(null);
          }}
          scheduleDetails={scheduleDetails}
        />
      }
      {isShow &&
        <NewEvent
          isShow={isShow}
          onClose={() => {
            setIsShow(false);
            setIsEditEvent(false)
            getScheduleData();
          }}
          getScheduleData={() => {
            updateUserCalNotification()
            getScheduleData()
          }}
          editFlow={isEvtDetail || isEditEvent}
          data={eventDetail}
          setEventDetail={setEventDetail}
          prepDiaryEvtData={prepDiaryEvtData}
        />
      }
      {isShowDraftModal &&
        <DraftEvtModal
          show={isShowDraftModal}
          onClose={() => setIsShowDraftModal(false)}
          onPublish={() => {
            setIsShowDraftModal(false);
            sendEvtNotification()
          }}
        />
      }

      {dragEventDetail &&
        <DragEventInfo
          show={dragEventDetail ? true : false}
          onClose={() => setDragEventDetail(null)}
          onPublish={checkEventAvailability}
          data={dragEventDetail}
        />
      }

      {dragEventConflict &&
        <DragEventConflictCrew
          show={dragEventConflict}
          onClose={() => setDragEventConflict(null)}
          data={conflictedCrews}
          onView={(id) => {
            setDragEventConflict(false);
            setDragEventDetail(null);
            setScheduleType(SCHEDULE_TYPE.CREW_SCH);
            updateUrls(apiConstants.id, id)
          }}
        />
      }

      {isAddPrepDiary &&
        <AddPrepDiaryModal
          isShow={isAddPrepDiary}
          onClose={() => {
            setIsAddPrepDiary(false)
            setSelectedPrepDate(null)
            setPrepEditData(null)
          }}
          onAddEvt={(data: any) => {
            setIsAddPrepDiary(false);
            setEventDetail(null);
            setTimeout(() => {
              setPrepDiaryEvtData(data)
              setIsShow(true);
            }, 200);
          }}
          userData={userData}
          prepEditData={prepEditData}
        />
      }

      {isViewPrepDiary &&
        <ViewPrepDiary
          selectedPrepDate={selectedPrepDate}
          isShow={isViewPrepDiary}
          onClose={() => {
            setIsViewPrepDiary(false)
            setSelectedPrepDate(null)
          }}
          userData={userData}
          onAdd={() => {
            setIsViewPrepDiary(false);
            setSelectedPrepDate(null);
            setTimeout(() => {
              setIsAddPrepDiary(true);
            }, 200);
          }}
          onEdit={(data: any) => {
            setIsViewPrepDiary(false);
            setSelectedPrepDate(null);
            setPrepEditData(data)
            setTimeout(() => {
              setIsAddPrepDiary(true);
            }, 200);
          }}
          onAddEvent={(data: any) => {
            setIsViewPrepDiary(false);
            setSelectedPrepDate(null);
            setTimeout(() => {
              setPrepDiaryEvtData({
                [apiConstants.date]: data?.[apiConstants.date],
                [apiConstants.event_title]: data?.[apiConstants.event_title],
              })
              setIsShow(true);
            }, 200);
          }}
        />
      }

      {needUpdateAddress &&
        <ActionModal
          isShow={needUpdateAddress}
          onClose={() => setNeedUpdateAddress(false)}
          onSubmit={updateAddress}
          title='Update locations'
          children={
            <CustomGooglePlaceSearch
              onApply={(data: any) => setLocationData(data)}
              data={locationData}
              hideRadius
              placeholder='Select Location'
              heading='Select Location'
              scrollwheel={false}
              needExtraData
              contentStyle={{ paddingBottom: '80px' }}
            />
          }
          btnTxt='Submit'
          bodyStyle={{ minHeight: '350px', overflow: 'hidden' }}
        />
      }

    </SideBarLayout>
  );
};

export default Schedule;