import React, { ReactNode, useContext } from 'react'
import Search from './Search';
import { MyContext } from '../../context';
import './style.css'
import { isProdUser, isSuperUser } from '../../utils';
interface SearchWithHeadingProps {
    heading?: string;
    placeholder?: string;
    onSearch: (value: any) => void;
    value?: any;
    onAdd?: () => void;
    newEvent?: () => void;
    addText?: any
    typeView?: any;
    icon?: string;
    actionComp?: ReactNode
}

const SearchWithHeading: React.FC<SearchWithHeadingProps> = (props) => {

    const { userData } = useContext(MyContext);

    return (
        <div className={props?.typeView ? "py-4 d-md-flex align-items-center justify-content-center justify-content-md-between gap-3" : "main-top-bar-heading px-4  d-md-flex align-items-center justify-content-center justify-content-md-between gap-3"}>
            {!props?.typeView && <h3 className="fw-bold text-center text-md-start mb-3 mb-md-0">{props?.heading || ''}</h3>}
            {props?.actionComp || null}
            <div className={props?.typeView ? "typeView-box search-button d-flex align-items-center gap-3 justify-content-center" : "search-button d-flex align-items-center gap-3 justify-content-center"}>
                <Search
                    placeholder={props?.placeholder}
                    value={props?.value}
                    onChange={props.onSearch}
                    typeView={props?.typeView}
                />
                {(props.addText && (isProdUser(userData) || isSuperUser(userData))) && <button className="btn btn-warning btn-sm shadow-none" onClick={props.onAdd}><img src={props?.icon || "/assets/images/plus-icon.svg"} alt="" /> {props.addText}</button>}
            </div>
        </div>
    )
}

export default SearchWithHeading