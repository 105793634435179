import React, { useEffect, useState } from "react";
import AuthSection from "../../global/layout/authSection";
import { currencyData } from "../../data/data";
import { makeGetCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import { ApiResponseType, BUTTON_TYPE, CURRENCY_TYPE, INTERVAL_TYPE, ROLE_TYPE } from "../../types";
import { showToast } from "../../utils";
import { buttonsText, cookieServiceParam, errorMessage } from "../../utils/constants";
import CurrencyItem from "./CurrencyItem";
import SubscriptionItem from "./SubscriptionItem";
import { cookieService } from '../../services';
import { useNavigate } from "react-router-dom";
import NoData from "../../global/ui/NoData";
import { endPoints } from "../../utils/endpoints";

const Subscription: React.FC = () => {

    const naviagte = useNavigate();
    const [subscriptionListData, setSubscriptionListData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_TYPE.TYPE_GBP);
    const [selectedSubs, setSelectedSubs] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(false);

    const userType = cookieService.getCookie(cookieServiceParam.userType)

    const getSubsData = () => {
        setIsLoading(true)
        makeGetCall(endPoints.SUBSCRIPTION).then((res: ApiResponseType<any>) => {
            setIsLoading(false)
            if (res.status?.code == 200) {
                const filterArr = filterListAsPerCurrency(res.data?.data?.length > 0 ? res.data?.data : [], selectedCurrency);
                setAllData(res.data?.data)
                setSubscriptionListData(filterArr as never)
            }
        }).catch((err) => setIsLoading(false))
    }

    const filterListAsPerCurrency = (data: Array<any>, currentCurrency: any) => data.filter((item) => item?.currency === currentCurrency)

    useEffect(getSubsData, []);

    const getSymbol = () => {
        let sym = '£';
        if (selectedCurrency === CURRENCY_TYPE.TYPE_EUR) {
            sym = '€'
        }
        if (selectedCurrency === CURRENCY_TYPE.TYPE_USD) {
            sym = '$'
        }
        return sym;
    }

    return (
        <AuthSection>
            <div>
                <div className='inner-content text-center ms-auto'>
                    <div className='top-content'>
                        <h3>Subscription Package</h3>
                        <p>Choose your preferred currency</p>
                    </div>
                    <form>
                        <div className='slect-radio-like-tab dark-mode ms-auto'>
                            {currencyData.map((item, index) => <CurrencyItem
                                key={index}
                                item={item}
                                selectedCurrency={selectedCurrency}
                                onClick={(value: any) => {
                                    const temp = filterListAsPerCurrency(allData, value);
                                    setSubscriptionListData(temp as never);
                                    setSelectedCurrency(value);
                                    setSelectedSubs(null)
                                }}
                            />)}
                        </div>
                        <div className='full-radio-buttons mb-5 pb-3'>
                            {subscriptionListData?.length > 0 ?
                                subscriptionListData.map((item: any, index) => {
                                    if (userType === ROLE_TYPE.TYPE_SUPPLIER && item?.recurring?.interval !== INTERVAL_TYPE.TYPE_YEAR) return null
                                    if (userType === ROLE_TYPE.TYPE_CREW && item?.recurring?.interval !== INTERVAL_TYPE.TYPE_WEEK) return null
                                    return (
                                        <SubscriptionItem
                                            item={item}
                                            key={index}
                                            selectedId={selectedSubs?.id}
                                            onClick={(data: any) => {
                                                cookieService.saveCookie(cookieServiceParam.subscription_id, data?.id);
                                                setSelectedSubs(data)
                                            }}
                                            currencyIcon={getSymbol()}
                                            userType={userType}
                                        />
                                    )
                                })
                                :
                                <>
                                    {isLoading ? 'Loading...' : <NoData />}
                                </>
                            }
                        </div>
                        <Button
                            type={BUTTON_TYPE.TYPE_BUTTON}
                            btnTxt={buttonsText.continue}
                            style={{ marginTop: "20px", width: "100%" }}
                            onClick={() => {
                                if (!selectedSubs?.id) {
                                    showToast(errorMessage.subs_not_selected, 'error');
                                    return
                                }
                                if (userType === ROLE_TYPE.TYPE_CREW) {
                                    naviagte(`/manage-profile/owner/add/1`)
                                    return
                                }
                                if (userType === ROLE_TYPE.TYPE_SUPPLIER) {
                                    naviagte(`/manage-profile/supplier/add/1`)
                                    return
                                }
                            }}
                            className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                        />
                    </form>
                </div>
            </div>
        </AuthSection>
    )
}
export default Subscription;