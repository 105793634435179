import React, { useState } from 'react'
import Logo from '../../components/commonComponent/ProfileImage'
import { ROLE_TYPE } from '../../types'

const CrewListItems = (props: any) => {
  const [showtick, setShowTick] = useState<any>(false)
  const handleAdd = () => {
    setShowTick(!showtick)
    props.selectCrewMembers(props.item)
  }
  const handleRemove = () => {
    setShowTick(!showtick)
    props.deleteMember(props.item)
  }
  return (
    <div className="crew-member-row" style={{ backgroundColor: '#f2f2f2' }}>
      <div className="outer-container">
        {props?.item?.profile_image ? <img className="crew-img" src={props?.item?.profile_image} alt="" style={{ opacity: props?.item?.user_type === 'C' ? (props?.item?.available ? '1' : '0.5') : '1' }} />
          : <Logo style={{ width: '25px', height: '25px', padding: '10px' }} data={props?.item?.first_name?.[0] || props.item.last_name?.[0]} />}
        <div>
          <div className="memeber-name d-grid lh-sm" style={{ textAlign: 'left', opacity: props?.item?.user_type === 'C' ? (props?.item?.available ? '1' : '0.5') : '1', wordBreak: 'break-word' }}>
            {props?.type === "Supplier" ? props?.item?.supplier_profile?.company_name : `${props.item.first_name} ${props.item.last_name}`}
            <span className='small fw-light '>{props?.item?.job_title}</span>
          </div>
          {/* <div className="memeber-name memeber-role " style={{opacity: props?.item?.user_type === 'C'? (props?.item?.available? '1': '0.5'): '1'  }}>{props?.item?.job_title}</div> */}
        </div>
      </div>
      {props?.item?.available && props?.item?.user_type === 'C' && <div>
        {props?.item?.sixth_day && props?.item?.seventh_day && <img src={"/assets/images/7th-icon.svg"} style={{ margin: '0 6px' }} />}
        {props?.item?.sixth_day && !props?.item?.seventh_day && <img src={"/assets/images/6th-icon.svg"} style={{ margin: '0 6px' }} />}
        {/* {props?.item?.sixth_day && <img src={"/assets/images/6th-icon.svg"} style={{margin:'0 6px'}}/>} */}
        {/* {props?.item?.seventh_day && <img src={"/assets/images/7th-icon.svg"} style={{margin:'0 6px'}}/>} */}
        {showtick ? <img src={"/assets/images/green-tick-white.svg"} onClick={() => handleRemove()} alt="" /> : <img src={"/assets/images/plus-white.svg"} onClick={() => handleAdd()} alt="" />}
      </div>}
      {props?.item?.user_type === 'S' && <div>
        {props?.item?.sixth_day && <img src={"/assets/images/6th-icon.svg"} style={{ margin: '0 6px' }} />}
        {props?.item?.seventh_day && <img src={"/assets/images/7th-icon.svg"} style={{ margin: '0 6px' }} />}
        {showtick ? <img src={"/assets/images/green-tick-white.svg"} onClick={() => handleRemove()} alt="" /> : <img src={"/assets/images/plus-white.svg"} onClick={() => handleAdd()} alt="" />}
      </div>}
      {props?.item?.available === false && props?.item?.user_type === 'C' && <img src={"/assets/images/unavailable.svg"} />}
    </div>
  )
}

export default CrewListItems
