import React from 'react'
import Logo from '../commonComponent/ProfileImage'

interface ISuggestedMembers {
    item: any
    onSelect: () => void;
    isSelected: boolean
}

const SuggestedMembers: React.FC<ISuggestedMembers> = (props) => {
    return (
        <div onClick={props?.onSelect} className="crew-member-row cursor-pointer" style={{ backgroundColor: '#f2f2f2' }}>
            <div className="outer-container">
                {props?.item?.profile_image
                    ?
                    <img
                        className="crew-img"
                        src={props?.item?.profile_image}
                        alt=""
                    />
                    :
                    <Logo
                        style={{ width: '25px', height: '25px', padding: '10px' }}
                        data={props?.item?.first_name?.[0] || props.item.last_name?.[0]}
                    />
                }
                <div
                    style={{ textAlign: 'left' }}
                    className="memeber-name d-grid lh-sm"
                >
                    {`${props.item.first_name} ${props.item.last_name}`}
                </div>
            </div>
            <img src={props?.isSelected ? '/assets/images/green-tick-white.svg' : "/assets/images/plus-white.svg"} alt="add" />
        </div>
    )
}

export default SuggestedMembers