import React from 'react'

interface IPrepDiaryItem {
    onClick: () => void;
    label?: any;
    style?: any
}

const PrepDiaryItem = (props: IPrepDiaryItem) => {
    return (
        <div onClick={props.onClick} className="d-flex justify-content-between align-items-center cursor-pointer">
            <div style={props?.style} className="d-grid view-prep-dairy">
                <div className="d-flex align-items-center justify-content-between">
                    <span>{props?.label || 'Prep Diary'}</span>
                    <img className="cursor-pointer" src="/assets/images/play-icon-dark.svg" alt="" />
                </div>
            </div>
        </div>
    )
}

export default PrepDiaryItem