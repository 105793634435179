import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import DropDown from '../../global/ui/dropDown';
import Button from '../../global/ui/Button';
import { BUTTON_TYPE } from '../../types';

interface UpdateRoleProps {
    isShow: boolean;
    onClose: () => void;
    onUpdate: () => void;
    data: Array<any>;
    selectedEl: any;
    onSelect: (el: any) => void;
}

const UpdateRole = ({ isShow, onClose, onUpdate, selectedEl, data, onSelect }: UpdateRoleProps) => {
    return (
        <Modal
            centered
            scrollable
            className='new_broadcast'
            show={isShow}
        >
            <ModalHeader className="p-0 border-0 align-items-start">
                <ModalTitle className="fw-bold me-auto text-start">
                    <h4 className='h4 text-start mb-0 mt-0'>Change Ownership</h4>
                </ModalTitle>
                <button className="close-btn-de shadow-none" onClick={onClose}>
                    <img src={"/assets/images/cancel-button.svg"} alt="" />
                </button>
            </ModalHeader>
            <ModalBody style={{ minHeight: '200px' }} className="p-0 text-start pb-4">
                <DropDown
                    className="arrow-fill new-brodcast"
                    isMultiSelect={false}
                    data={data}
                    placeholder='Select person to shift ownership to'
                    label='Change Ownership'
                    onSelect={onSelect}
                    selectedItems={selectedEl}
                />
            </ModalBody>
            <ModalFooter className="p-0 border-0 row align-items-center justify-content-center" style={{ marginTop: '45px', }}>
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt={'Update'}
                    style={{ marginTop: "0px", width: "auto", alignSelf: 'center' }}
                    className={'btn btn-warning w-75 with-after-shadow shadow-none m-0 center'}
                    onClick={onUpdate}
                />
            </ModalFooter>
        </Modal>
    )
}

export default UpdateRole