import React from 'react'
import { ROLE_TYPE } from '../../types'

interface IUserMiniProfile {
    chatData: any
    onToggle: () => void
}

const UserMiniProfile: React.FC<IUserMiniProfile> = (props) => {
    return (
        <div className="minimized-sidebar-wrap">
            <div className="minimized-sidebar">
                <div>
                    <img
                        src="/assets/images/arrow-donw-black.svg"
                        className="left-arrow"
                        alt=""
                        onClick={props.onToggle}
                    />
                </div>
                <div
                    className="userName"
                    style={{
                        transform: 'rotate(-90deg)',
                        whiteSpace: 'nowrap'
                    }}
                    onClick={props.onToggle}
                >
                    {props?.chatData?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? props?.chatData?.other_user?.supplier_profile?.company_name : props?.chatData?.other_user?.first_name + " " + props?.chatData?.other_user?.last_name}
                    {(props?.chatData?.other_user?.verified && props?.chatData?.other_user?.user_type === ROLE_TYPE.TYPE_CREW) &&
                        <img
                            style={{ width: '15px', height: '15px', marginLeft: '5px', marginTop: '-3px' }}
                            src={"/assets/images/yellow_check_mark.png"}
                            alt="Verified User"
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default UserMiniProfile