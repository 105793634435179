import React from 'react'
import Logo from '../../components/commonComponent/ProfileImage';
import { createFullName } from '../../utils';

interface IAssignedCrews {
    memberData: Array<any>;
    containerStyle?: React.CSSProperties;
}

const AssignedCrews: React.FC<IAssignedCrews> = (props) => {

    return (
        <div style={props?.containerStyle}>
            {props?.memberData?.map((memItem: any, idx: number) => {
                return (
                    <div key={idx} className='d-flex justify-content-start align-items-center mt-3'>
                        {memItem?.member?.profile_image
                            ?
                            <img className='assigned-user-img' src={memItem?.member?.profile_image} alt="crew image" />
                            :
                            <Logo
                                className='assigned-user-img'
                                data={memItem?.member?.first_name?.[0] || ''}
                            />
                        }
                        <span className='assigned-user-name'>{createFullName(memItem?.member?.first_name || '', memItem?.member?.last_name || '')}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default AssignedCrews