import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { createJob, createJobSchema } from '../../data/formData';
import Button from '../../global/ui/Button';
import DropDown from '../../global/ui/dropDown';
import InputField from '../../lib/input';
import { ApiResponseType, BUTTON_TYPE, USER_ACTION_TYPE } from '../../types';
import { labels, placeholder } from '../../utils/constants';
import { emptyFunction, generateFormData, setDropdownSelectedValue, showToast } from '../../utils';
import { apiConstants } from '../../utils/apiConstants';
import DatePicker from '../../global/ui/DatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import ImageCrop from '../../global/ui/ImageCrop';
import { DateUtils } from '../../utils/dateUtils';
import { makePatchCall, makePostCall } from '../../effects/requests';
import { preConfigDeptData } from '../../data/data';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';
import CustomGooglePlaceSearch from '../../components/customGooglePlaceSearch';

interface CreateJobProps {
    isShow: boolean;
    onClose: () => void;
    onDone?: () => void;
    data: any;
    type?: any
}

const CreateJob: React.FC<CreateJobProps> = (props) => {

    const { ...methods } = useForm({
        mode: 'all',
        defaultValues: { ...createJob(props?.data) },
        reValidateMode: 'onBlur',
        resolver: yupResolver(createJobSchema()),
    });
    const [roles, setRoles] = useState([])
    const [allDept, setAllDept] = useState(preConfigDeptData)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedLoc, setSelectedLoc] = useState<any>(null)

    const { userData } = useContext(MyContext)

    const onSubmit = (data: any) => {
        if (DateUtils.isDateTimeIsBefore(new Date(data?.[apiConstants.end_date]), new Date(data?.[apiConstants.start_date]), 'YYYY-MM-DD')) {
            showToast('End date can not be equal or lesser than start date', 'error')
            return
        }
        setIsLoading(true)
        const payload = { ...data }
        payload[apiConstants.start_date] = DateUtils.formatDateTime(data?.[apiConstants.start_date], 'YYYY-MM-DD');
        payload[apiConstants.end_date] = DateUtils.formatDateTime(data?.[apiConstants.end_date], 'YYYY-MM-DD');
        if (payload[apiConstants.image] === props?.data?.image) {
            delete payload[apiConstants.image]
        }

        const rawData = {
            ...payload,
            company: userData?.production_company_name || ''
        };

        const fd = generateFormData(rawData)

        if (selectedLoc?.lat && selectedLoc?.lng) {
            fd.append('coordinates', JSON.stringify({ "type": "Point", "coordinates": [selectedLoc?.lat, selectedLoc?.lng] }));
        }

        if (props?.type === USER_ACTION_TYPE.TYPE_UPDATE) {
            makePatchCall(`${endPoints.JOBLIST}${props?.data?.id}`, fd, true).then(onDone).catch(() => setIsLoading(false))
        } else {
            makePostCall(endPoints.JOBLIST, fd, true).then(onDone).catch(() => setIsLoading(false))
        }
    }

    const onDone = (res: ApiResponseType<any>) => {
        setIsLoading(false)
        if (res.status.code == 200) {
            props.onDone?.();
            props.onClose();
        }
    }

    // useEffect(() => {
    //     if (userData?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER && userData?.crew_member_profile?.company_department?.length > 0) {
    //         if(Array.isArray(userData?.crew_member_profile?.company_department)){
    //             userData?.crew_member_profile?.company_department?.map((item: any) => {
    //                 item.label = item?.dep;
    //                 item.value = item?.dep;
    //             })
    //             setAllDept(userData?.crew_member_profile?.company_department)
    //         }
    //     }
    // }, [userData])

    useEffect(() => {
        if (props.isShow) {
            const data = createJob(props.type === USER_ACTION_TYPE.TYPE_UPDATE ? props.data : null);
            if (props.type === USER_ACTION_TYPE.TYPE_UPDATE) {
                const dept = setDropdownSelectedValue(allDept, props.data?.department);
                setRoles(dept?.roles?.length > 0 ? dept?.roles : [])
            }
            methods.reset(data)
        }
    }, [props.isShow, JSON.stringify(allDept)])

    const { formState } = methods;
    const handleInputChange = (e: any) => {
        const cursorPosition = e.target.selectionStart;
        methods.setValue(apiConstants.description, e.target.value, { shouldValidate: true })
        requestAnimationFrame(() => {
            e.target.selectionStart = cursorPosition;
            e.target.selectionEnd = cursorPosition;
        });
    };

    useEffect(() => {
        if (props?.data?.coordinates) {
            setSelectedLoc({
                location: props?.data?.location || '',
                lat: props?.data?.coordinates?.coordinates[0] || '',
                lng: props?.data?.coordinates?.coordinates[1] || '',
            })
        }
    }, [props?.data?.coordinates]);

    return (
        <Modal
            centered
            scrollable
            className='max-width-496 addProductionCrew modal-body-scroll-part'
            show={props.isShow}
            onHide={emptyFunction}>
            <ModalHeader className="p-0 border-0 mb-3 align-items-start">
                <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/black-arrow-left-side.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3 text-start">
                    <h4 className='h4 text-start mb-0 mt-0'>{props?.type === USER_ACTION_TYPE.TYPE_UPDATE ? "Update Job" : 'Create Job'}</h4>
                    <p className="mb-0 text-start">Create a job listing to hire crew members for your production</p>
                </ModalTitle>
            </ModalHeader>
            <FormProvider {...methods} >
                <form className='text-start' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <ModalBody className="p-0 text-start">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="input-type-file-secend-part">
                                    <div className="form_group file-image-icon black-input">
                                        <div className="input_box text-center">
                                            <label htmlFor="image_id" className="update-image">
                                                <span className="img-wrapper">
                                                    <img src={methods.getValues(apiConstants.image) || "/assets/images/Image-icon-light-white.svg"} className="center-img" alt="" />
                                                    <img
                                                        style={{ zIndex: 9999 }}
                                                        onClick={(evt) => {
                                                            if (methods.getValues(apiConstants.image)) {
                                                                evt.preventDefault();
                                                                methods.setValue(apiConstants.image, '', { shouldValidate: true });
                                                            }
                                                        }}
                                                        src={methods.getValues(apiConstants.image) ? "/assets/images/delete-btn-fill-red.svg" : "/assets/images/add-plus-yellow.png"}
                                                        className="bottom-puls-icon" alt=""
                                                    />
                                                </span>
                                            </label>
                                            {formState.errors?.[apiConstants.image]?.message &&
                                                <span style={{ color: 'red' }}>{formState.errors?.[apiConstants.image]?.message as never}</span>
                                            }
                                        </div>
                                        <ImageCrop
                                            ratio={1 / 1}
                                            croppedImage={(imgSrc: any, file: any) => {
                                                methods.setValue(apiConstants.image, imgSrc, { shouldValidate: true });
                                            }}
                                            size={15}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <DropDown
                                    className="arrow-fill drop-down-over-vis"
                                    data={allDept}
                                    onSelect={(data) => {
                                        methods.setValue(apiConstants.department, data.label, { shouldValidate: true });
                                        methods.setValue(apiConstants.title, '');
                                        setRoles(data?.roles);
                                    }}
                                    placeholder={placeholder.allDepartments}
                                    label={labels.filterByDepartment}
                                    selectedItems={setDropdownSelectedValue(allDept, methods.getValues(apiConstants.department))}
                                    errorMessage={formState.errors?.[apiConstants.department]?.message}
                                />
                            </div>
                            <div className='col-md-6'>
                                <DropDown
                                    className="arrow-fill drop-down-over-vis"
                                    data={roles}
                                    onSelect={(data) => {
                                        methods.setValue(apiConstants.title, data.label, { shouldValidate: true });
                                    }}
                                    placeholder={placeholder.selectJobRole}
                                    label={labels.roleSelect}
                                    selectedItems={setDropdownSelectedValue(roles, methods.getValues(apiConstants.title))}
                                    errorMessage={formState.errors?.[apiConstants.title]?.message}
                                />
                            </div>
                            <div className='col-md-6'>
                                <InputField
                                    label={labels.dailyRate}
                                    className="black-input mb-4"
                                    placeholder={placeholder.dailyRate}
                                    name={apiConstants.daily_rate}
                                    type='number'
                                />
                            </div>
                            <div className='col-md-6'>
                                <InputField
                                    label={labels.noOfPosition}
                                    className="black-input mb-4"
                                    placeholder={placeholder.noOfPosition}
                                    name={apiConstants.number_of_positions}
                                    type='number'
                                />
                            </div>
                            <div className='col-md-12 mb-4'>
                                <CustomGooglePlaceSearch
                                    onApply={(data: any) => {
                                        methods.setValue(apiConstants.location, data?.location, { shouldValidate: true });
                                        setSelectedLoc(data);
                                    }}
                                    data={selectedLoc}
                                    hideRadius
                                    placeholder={placeholder.location}
                                    label={labels.location}
                                    scrollwheel={false}
                                />
                            </div>
                            <div className='col-md-6'>
                                <DatePicker
                                    date={methods.getValues(apiConstants.start_date)}
                                    onDateChange={(date) => { methods.setValue(apiConstants.start_date, date, { shouldValidate: true }); methods.setValue(apiConstants.end_date, date, { shouldValidate: true }) }}
                                    label='Start Date'
                                    displayFormat='DD/MM/YYYY'
                                    errorMessage={formState.errors?.[apiConstants.start_date]?.message}
                                    className="black-input mb-4"
                                />

                            </div>
                            <div className='col-md-6'>
                                <DatePicker
                                    date={methods.getValues(apiConstants.end_date)}
                                    onDateChange={(date) => methods.setValue(apiConstants.end_date, date, { shouldValidate: true })}
                                    label='End Date'
                                    displayFormat='DD/MM/YYYY'
                                    //allowPastDate = {false}
                                    isEndDate={true}
                                    errorMessage={formState.errors?.[apiConstants.end_date]?.message}
                                    className="black-input mb-4 date-colender-top-right-0"
                                />
                            </div>
                            <div className='col-md-12'>
                                <div className="form_group black-input mb-4">
                                    <div className="input_box">
                                        <label htmlFor="supplier_bio">Description</label>
                                        <textarea
                                            name="supplier_bio"
                                            id="supplier_bio"
                                            placeholder="Enter description"
                                            value={methods.getValues(apiConstants.description)}
                                            onChange={(evt) => handleInputChange(evt)}
                                            className="no-resize"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <Button
                                    type={BUTTON_TYPE.TYPE_SUBMIT}
                                    btnTxt={props?.type === USER_ACTION_TYPE.TYPE_UPDATE ? "Update Job" : 'Create Job'}
                                    style={{ marginTop: "0px", }}
                                    className={'btn btn-warning w-100 with-after-shadow shadow-none m-0 mb-2'}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateJob