import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ChatRequest from '../../components/modal/ChatRequest';
import { makeGetCall, makePostCall } from '../../effects/requests';
import Tabs from '../../global/ui/Tabs';
import UserBasicInfo from '../../global/ui/UserBasicInfo';
import { ApiResponseType, EXPLORE_TYPE, REQ_STATUS, ROLE_TYPE, USER_PRESS_ACTION_TYPE, } from '../../types';
import { createFullName, emptyFunction, getValidUrl, isProdUser, isSuperUser, showToast } from '../../utils';
import { endPoints } from '../../utils/endpoints';
import AboutCrew from './AboutCrew';
import CrewWork from './CrewWork';
import References from './References';
import { MyContext } from '../../context';

interface ViewCrewDetailsProps {
    crewDetails: any;
    visible: any;
    onClose: () => void;
    onAction: (type: any) => void;
    type?: any;
    onRefView?: (value: any) => void;
    hideBtn?: boolean
    getCrewMem: any;
    sideBarCss?: boolean;
    hideMessage?: boolean;
    getUpdatedData?: () => void;
}

const ViewCrewDetails: React.FC<ViewCrewDetailsProps> = (props) => {

    const crewProfile = props?.crewDetails?.crew_member_profile;
    const [showChatReqModal, setShowChatReqModal] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [selectedDeptIdx, setSelectedDeptIdx] = useState(null);

    const { userData, updateUserData } = useContext(MyContext);

    const createRoom = (msg?: string, attachement_url?: string, attachement_type?: string) => {

        if (props?.crewDetails?.chat_room?.chat_room_created) {
            checkRequestStatus();
            return
        }

        makePostCall(endPoints.ROOM_LIST, {
            'other_user': props?.crewDetails?.id,
            'message': msg ? msg : '',
            'attachment_url': attachement_url ? attachement_url : '',
            'attachment_type': attachement_type ? attachement_type : ''
        })
            .then((res) => {
                setShowChatReqModal(false);
                showToast("Chat request sent successfully", "success")
                if (props?.getUpdatedData) {
                    props?.getUpdatedData?.();
                }
                if (userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
                    getUserDetails();
                }
            })
            .catch(emptyFunction)
    }

    const checkRequestStatus = () => {
        makeGetCall(endPoints.ROOM_LIST + props?.crewDetails?.chat_room?.chat_id + '/')
            .then((response) => {
                if (response?.data?.status === REQ_STATUS.PENDING) {
                    showToast("Chat request is pending with user", "info")
                }
                else if (response?.data?.status === REQ_STATUS.DELETED) {
                    showToast("User account is deleted", "error")
                }
                else if (response?.data?.status === REQ_STATUS.REJECTED) {
                    showToast("Chat request is rejected by user", "info")
                }
                else {
                    navigate(`/messages/?search=&user_type=${props?.crewDetails?.user_type}&room=${props?.crewDetails?.chat_room?.chat_id}`)
                }
            })
            .catch(emptyFunction)
    }

    const getUserDetails = () => {
        makeGetCall(endPoints.PROFILE, null, false, true).then((res: ApiResponseType<any>) => {
            if (res?.data?.crew_member_profile?.company_department?.length > 0) {
                res?.data?.crew_member_profile?.company_department?.map((item: any) => {
                    item.label = item?.dep;
                    item.value = item?.dep;
                })
            }
            updateUserData(res);
        }).catch(emptyFunction)
    }

    const flagUser = () => {
        const param = {
            flagged_user: props?.crewDetails?.id
        }
        makePostCall(endPoints.FLAG_USER, param)
            .then((res) => {
                if (res?.status?.code === 200) {
                    showToast(res?.status?.message, "success")
                }
                else {
                    showToast(res?.message, "error")
                }
                props?.onClose();
            })
            .catch((err) => {
                props?.onClose();
            })
    }

    const navigate = useNavigate();

    let companyDepartment: any = [];

    if (Array.isArray(crewProfile?.company_department) && crewProfile?.company_department?.length > 0) {
        crewProfile?.company_department?.map((item: any) => {
            const dept: any = { dep: item?.dep, role: [] }
            if (Array.isArray(item?.roles) && item?.roles?.length > 0) {
                item?.roles?.map((innerRole: any) => {
                    dept.role.push(innerRole.label)
                })
            }
            companyDepartment.push(dept)
        })
    }

    return (
        <div className={`right-side-popup-background ${props?.visible ? "" : "d-none"} ${props?.sideBarCss ? 'sideBarCss' : ''}`}>
            <div className="right-side-popup-fixed d-grid align-content-between gap-4">
                <div className="right-side-popup-main-top">
                    <UserBasicInfo
                        userData={{
                            name: createFullName(props.crewDetails?.first_name, props.crewDetails?.last_name),
                            linkTitle: 'IMDb Profile',
                            logo: props.crewDetails?.profile_image,
                            dep: null,
                            account_completed: props?.crewDetails?.account_completed,
                            website: crewProfile?.imdb_link,
                            id: props.crewDetails?.id,
                            verified: (props?.crewDetails?.verified && props?.crewDetails?.user_type === 'C') ? true : false,
                        }}
                        onClose={() => {
                            props.onClose();
                            setSelectedIdx(0);
                            setSelectedDeptIdx(null);
                        }}
                        onAction={(type) => {
                            switch (type) {
                                case USER_PRESS_ACTION_TYPE.TYPE_MESSAGE:
                                    { props?.crewDetails?.chat_room?.chat_room_created ? createRoom() : setShowChatReqModal(true) }
                                    break;

                                case USER_PRESS_ACTION_TYPE.TYPE_WEBSITE:
                                    window.open(getValidUrl(crewProfile?.imdb_link))
                                    break;

                                case USER_PRESS_ACTION_TYPE.TYPE_FLAG:
                                    flagUser()
                                    break;

                                default:
                                    props.onAction(type)
                                    break;
                            }
                        }}
                        showUpdateRoleOption={(props.type === EXPLORE_TYPE.MY_PROD && (isProdUser(userData) || isSuperUser(userData))) ? true : false}
                        isActive={props?.crewDetails?.active_at_company}
                        getCrewMem={props?.getCrewMem}
                        sideBarArrow={props.sideBarCss ? true : false}
                        showDeleteOption={isProdUser(userData) || isSuperUser(userData) && props.type === EXPLORE_TYPE.MY_PROD}
                        hideMessage={props?.hideMessage}
                        isCrew={true}
                    />
                    <div className="bottom-tabs-wo">
                        <Tabs
                            data={['About', 'Work', 'References']}
                            selectedIdx={selectedIdx}
                            onClick={(item: any, idx: any) => setSelectedIdx(idx)}
                        />
                        <div className="tab-content pt-4 tab-overflow crew-tab">
                            <AboutCrew
                                dept={(Array.isArray(crewProfile?.role) && crewProfile?.role?.length > 0) ? crewProfile?.role : (Array.isArray(companyDepartment) && companyDepartment?.length > 0) ? companyDepartment : []}
                                type={props?.type}
                                data={props?.crewDetails}
                                selectedIdx={selectedIdx}
                                selectedDeptIdx={selectedDeptIdx}
                                setSelectedDeptIdx={setSelectedDeptIdx}
                            />
                            <CrewWork
                                name={props.crewDetails?.first_name}
                                data={props?.crewDetails?.job_request_data?.length > 0 ? props?.crewDetails?.job_request_data : []}
                                selectedIdx={selectedIdx}
                            />
                            <References
                                data={props?.crewDetails?.reference_data?.length > 0 ? props?.crewDetails?.reference_data : []}
                                onClick={props?.onRefView}
                                selectedIdx={selectedIdx}
                                changeIndex={(idx: any) => setSelectedIdx(idx)}
                            />
                        </div>
                    </div>
                </div>
                {!props.hideBtn &&
                    <div className="right-side-popup-button">
                        <ul className="d-flex list-unstyled gap-3 justify-content-between align-item-center w-100 mb-0">
                            <li className="w-100">
                                <button type='button' onClick={() => navigate(`/schedule/?search=&id=${props?.crewDetails?.id}`)} className={`btn btn-white w-100 ${!props?.crewDetails?.my_production ? 'disabled-button' : ''}`} disabled={!props?.crewDetails?.my_production}>View Schedule</button>
                            </li>
                            <li className="w-100">
                                <button
                                    type='button'
                                    onClick={() => props.onAction(props?.type === EXPLORE_TYPE.MY_PROD ? USER_PRESS_ACTION_TYPE.TYPE_ASIGN_JOB : USER_PRESS_ACTION_TYPE.TYPE_IP)}
                                    className={`common_btn btn btn-warning w-100 with-after-shadow shadow-none ${!isProdUser(userData) || isSuperUser(userData) ? 'disabled-button' : ''}`} disabled={!isProdUser(userData) || isSuperUser(userData)}>{props?.type === EXPLORE_TYPE.MY_PROD ? 'Assign Job' : 'Invite to Production'}</button>
                            </li>
                        </ul>
                    </div>
                }
                {showChatReqModal &&
                    <ChatRequest
                        show={showChatReqModal}
                        closePopup={() => setShowChatReqModal(false)}
                        onSubmit={createRoom}
                    />
                }
            </div>
        </div>
    )
}

export default ViewCrewDetails