import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import Button from '../../global/ui/Button';
import { ApiResponseType, BUTTON_TYPE } from '../../types';
import { createFullName, emptyFunction, showToast } from '../../utils';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import InputField from '../../lib/input';
import { apiConstants } from '../../utils/apiConstants';
import UserCustomSearch from '../../components/userCustomSearch';
import NoData from '../../global/ui/NoData';
import { crewGroupFormData, crewGroupSchema } from '../../data/formData';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeDeleteCall, makePatchCall, makePostCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';
import Logo from '../../components/commonComponent/ProfileImage';

interface ICreateCrewGroup {
    isShow: boolean
    type: 'ADD' | "EDIT"
    onClose: () => void;
    onDone: () => void;
    crewGroupDetails: any
}

const CreateCrewGroup: React.FC<ICreateCrewGroup> = (props) => {

    const { userData } = useContext(MyContext);

    const [selectedMem, setSelectedMem] = useState([])
    const [published, setPublished] = useState(false)

    const { ...methods } = useForm({
        mode: "all",
        defaultValues: { ...crewGroupFormData() },
        reValidateMode: "onBlur",
        resolver: yupResolver(crewGroupSchema()),
    });

    const preFillData = (data: any) => {
        methods.setValue(apiConstants.name, data?.name || '', { shouldValidate: true });
        methods.setValue(apiConstants.description, data?.description || '', { shouldValidate: true });
        setPublished(data?.published || false);
        if (data?.member_data?.length > 0) {
            const members = data?.member_data?.map((item: any) => ({ ...item?.member, member_id: item?.id }));
            setSelectedMem(members)
        }
    }

    useEffect(() => {
        if (props?.isShow) {
            methods.clearErrors();
        }
        if (props?.type === 'ADD') {
            methods.reset();
            setSelectedMem([]);
            setPublished(false);
        }
        if (props?.type === 'EDIT') {
            preFillData(props?.crewGroupDetails);
        }
    }, [props?.isShow]);

    const onSelect = (data: any) => {
        let selectedData: any = [...selectedMem];
        if (selectedData.some((obj: any) => obj?.id === data?.id)) {
            // If item with the same id exists, remove it
            selectedData = selectedData.filter((obj: any) => obj?.id !== data?.id);
        } else {
            // If item with the same id doesn't exist, push it
            selectedData.push({ ...data, is_adding: true });
        }
        setSelectedMem(selectedData);
    };

    const onCrewMemRemove = (mem: any, idx: any) => {
        const tempSelectedMem = [...selectedMem];
        if (mem?.is_adding) {
            tempSelectedMem.splice(idx, 1);
            setSelectedMem(tempSelectedMem);
        } else {
            const url = `${endPoints.GROUP_MEMBER}${mem?.member_id}/`
            makeDeleteCall(url).then((res: ApiResponseType<any>) => {
                if (res?.status?.code == 200) {
                    showToast(res?.status?.message, 'success');
                    setSelectedMem(tempSelectedMem);
                    tempSelectedMem.splice(idx, 1);
                    setSelectedMem(tempSelectedMem);
                }
            })
        }
    }

    const onSubmit: SubmitHandler<any> = (data: any) => {
        const fd = new FormData();
        fd.append('name', data.name);
        fd.append('production_company_name', userData.production_company_name || '');
        fd.append('description', data.description);
        fd.append('published', published ? 'true' : 'false');
        if (props?.type == 'ADD') {
            makePostCall(endPoints.CREW_GROUP, fd).then((res: ApiResponseType<any>) => {
                if (res?.status?.code == 200) {
                    showToast(res?.status?.message, 'success');
                    addGroupMembers(res?.data)
                }
            }).catch(emptyFunction)
        } else {
            const url = `${endPoints.CREW_GROUP}${props?.crewGroupDetails?.id}/`
            makePatchCall(url, fd).then((res: ApiResponseType<any>) => {
                if (res?.status?.code == 200) {
                    showToast(res?.status?.message, 'success');
                    addGroupMembers(res?.data)
                }
            }).catch(emptyFunction)
        }
    };

    const deleteCrewGroup = () => {
        const url = `${endPoints.CREW_GROUP}${props?.crewGroupDetails?.id}/`
        makeDeleteCall(url).then((res: ApiResponseType<any>) => {
            if (res?.status?.code == 200) {
                showToast(res?.status?.message, 'success');
                props.onDone();
            }
        })
    }

    const addGroupMembers = (data: any) => {
        if (selectedMem?.length > 0) {
            let count = 0;
            selectedMem.forEach((item: any, idx: number) => {
                const fd = new FormData();
                fd.append('group', data?.id);
                fd.append('member_id', item?.id);
                if (item?.is_adding) {
                    makePostCall(endPoints.GROUP_MEMBER, fd).then((res: ApiResponseType<any>) => {
                        count++;
                        if (count === selectedMem.length) {
                            props.onDone();
                        }
                    }).catch((error: any) => {
                        // Handle error if needed
                    });
                } else {
                    count++;
                    if (count === selectedMem.length) {
                        props.onDone();
                    }
                }
            });
        } else {
            props.onDone();
        }
    }

    return (
        <Modal
            centered
            scrollable
            className="max-width-1033 addProductionCrew modal-body-scroll-part"
            show={props.isShow}
            onHide={emptyFunction}
        >
            <ModalHeader className="p-0 border-0 mb-4 align-items-start">
                <button className="close-btn-de shadow-none" onClick={props?.onClose}>
                    <img src={"/assets/images/dismiss-cross-black.svg"} alt="" />
                </button>
                <ModalTitle className="fw-bold me-auto ps-3 text-start">
                    <h4 className="h4 text-start mb-0 mt-1">{props.type === 'EDIT' ? 'Edit Crew Group' : 'Create Crew Group'} </h4>
                </ModalTitle>
                <div className='d-flex justify-content-start align-items-center'>
                    <Button
                        type={BUTTON_TYPE.TYPE_BUTTON}
                        btnTxt='Delete Group'
                        className={'btn btn-warning btn-sm shadow-none'}
                        style={{ marginTop: "0px", width: "auto", background: "rgba(255, 63, 63, 1)", padding: "10px", marginRight: "10px", opacity: props.type === 'ADD' ? 0 : 1 }}
                        onClick={deleteCrewGroup}
                        isLoading={false}
                    />
                    <Button
                        type={BUTTON_TYPE.TYPE_BUTTON}
                        btnTxt={props.type === 'ADD' ? 'Save Group' : 'Update Group'}
                        className={'btn btn-warning btn-sm shadow-none'}
                        style={{ marginTop: "0px", width: "auto", background: "#FFD800", padding: "10px" }}
                        onClick={methods.handleSubmit(onSubmit)}
                        isLoading={false}
                    />
                </div>
            </ModalHeader>
            <ModalBody className="p-0 text-start  height-402">
                <div className="row">
                    <div className="col-md-6">
                        <h6 style={{ fontWeight: "600" }}>Event Details</h6>
                        <div className="row">
                            <FormProvider {...methods}>
                                <div className="col-md-12">
                                    <InputField
                                        label='Group Name'
                                        placeholder='Group Name'
                                        name={apiConstants.name}
                                        type="text"
                                        className="black-input mb-4"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <InputField
                                        label="Group Description"
                                        placeholder="Add description"
                                        name={apiConstants.description}
                                        type="text"
                                        className="black-input mb-4"
                                        isTextArea
                                        hideErrorColor
                                        maxLength={250}
                                        textAreaStyle={{ minHeight: "150px" }}
                                    />
                                </div>
                            </FormProvider>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h6 style={{ fontWeight: "600" }}>Members</h6>
                        <div className="bottom-tabs-wo">
                            <ul style={{ paddingLeft: '10px', paddingRight: '0px' }} className="d-flex justify-content-between align-items-end nav">
                                <li className="nav-item" >
                                    <a className={`nav-link active`}>
                                        Crews
                                    </a>
                                </li>
                                <div className="theme-checkbox form-check">
                                    <label className="form-check-label" htmlFor="publish_crew">Publish group to production</label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value='publish'
                                        id="publish_crew"
                                        checked={published}
                                        onChange={(evt) => {
                                            setPublished(evt.target.checked)
                                        }}
                                    />
                                </div>
                            </ul>
                        </div>
                        <UserCustomSearch
                            onSelect={onSelect}
                            selectedMem={selectedMem}
                        />
                        {selectedMem?.length > 0
                            ?
                            <div style={{ maxHeight: '270px', overflowY: 'scroll' }}>
                                {selectedMem?.map((memItem: any, idx) => {

                                    let dept = '-';
                                    const departments = memItem?.crew_member_profile?.company_department || []
                                    if (departments?.length > 0) {
                                        dept = `${departments?.[0]?.dep || ''} ${departments?.length - 1 > 0 ? ` + ${departments?.length - 1} more` : ''}`;
                                    }

                                    return (
                                        <div key={idx} className='create-gp-container d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-start align-items-center mt-3'>
                                                <img src='/assets/images/green-tick-white.svg' alt='check' />
                                                {memItem?.profile_image
                                                    ?
                                                    <img
                                                        style={{ width: '33px', height: '33px', borderRadius: '50px', marginLeft: '10px', marginRight: '20px' }}
                                                        src={memItem?.profile_image}
                                                        alt='User'
                                                    />
                                                    :
                                                    <Logo
                                                        style={{ width: '33px', height: '33px', marginLeft: '10px', marginRight: '20px' }}
                                                        data={memItem?.first_name?.[0] || ''}
                                                    />
                                                }
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{createFullName(memItem?.first_name || '', memItem?.last_name || '')}</span>
                                            </div>
                                            <div className='d-flex justify-content-end align-items-center'>
                                                <div className='create-gp-dept'>
                                                    <span style={{ fontSize: '12px', fontWeight: '500', color: '#7A33FF' }}>{dept}</span>
                                                </div>
                                                <img
                                                    src={"/assets/images/cross-grey-fill.svg"}
                                                    onClick={() => onCrewMemRemove(memItem, idx)}
                                                    alt="clear"
                                                    className='remove-selected-user cursor-pointer'
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                            :
                            <NoData
                                imgStyle={{ maxWidth: '150px' }}
                                style={{ minHeight: '120px', paddingTop: '20px' }}
                                textStyle={{ fontSize: '14px', fontWeight: '400', marginTop: '-15px' }}
                            />
                        }
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default CreateCrewGroup