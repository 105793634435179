import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { BUTTON_TYPE, SCHEDULE_TYPE } from '../../types'
import Button from "../../global/ui/Button";
import { useState } from 'react';
import DatePicker from '../../global/ui/DatePicker';
import moment from 'moment';
import { DateUtils } from '../../utils/dateUtils';
import { makePostCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { generateFormData, showToast } from '../../utils';

interface ExportDataProps {
    isShow: boolean;
    onClose: () => void
    type: any;
}


//This component use only for ui presentation so all details enter here is static will remove once api is available and also maintain reusability
const ExportData = (props: ExportDataProps) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    function checkDateDifference(start_date: any, end_date: any) {
        const startDate = moment(start_date, 'DD-MM-YYYY');
        const endDate = moment(end_date, 'DD-MM-YYYY');
        const duration = moment.duration(endDate.diff(startDate));
        const days = duration.asDays();
        return {
            days, payload: {
                "start_date": startDate.format("DD-MM-YYYY"), "end_date": endDate.format("DD-MM-YYYY")
            }
        };
    }
    const exportdata = () => {
        const { days, payload } = checkDateDifference(startDate, endDate)
        const fd = generateFormData(payload);
        if (days > 5) {
            makePostCall(props?.type === SCHEDULE_TYPE.CREW_SCH ? endPoints.USER_SCHEDULE : endPoints.EVENT_EXPORT, payload)
                .then((res) => {
                    if (res.status && res.status.code != 200) {
                    }
                    else {
                        const exportUrl = window.URL.createObjectURL(new Blob([res]));
                        const link = document.createElement("a");
                        link.href = exportUrl;
                        link.setAttribute("download", "data.csv");
                        document.body.appendChild(link);
                        link.click();
                        showToast("File downloaded", "success");
                    }
                })
                .catch((err) => {

                })

            props.onClose();
            setStartDate('');
            setEndDate('');
        }
        else {
            showToast('Difference between dates is less than 6', 'error')
            setStartDate('');
            setEndDate('');
        }

    }
    return (
        <Modal
            centered
            scrollable
            className='exportdata'
            show={props.isShow}
            onHide={props.onClose}>
            <ModalHeader className="p-0 border-0 mb-3">
                <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3">Export Data</ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start overflow-hidden">
                <p className="mb-5 text-start">Filter the date you want to export. This will export all crew contact information.</p>
                <div className='datepicker-height-316'>
                    <div className='row pb-2 '>
                        <div className='col-md-6'>
                            <DatePicker
                                date={startDate}
                                onDateChange={(date) => setStartDate(date)}
                                label='Start Date'
                                allowPastDate={true}
                                displayFormat='DD-MM-YYYY'
                                className="black-input mb-4 date-colender-bottom-left-0"
                            />

                        </div>
                        <div className='col-md-6'>
                            <DatePicker
                                date={endDate}
                                onDateChange={(date) => {
                                    if (DateUtils.isDateTimeIsBefore(date, startDate, 'YYYY-MM-DD')) {
                                        showToast('End date can not be lesser than from start date.', 'error', Math.random())
                                        return
                                    }
                                    setEndDate(date)
                                }}
                                label='End Date'
                                allowPastDate={true}
                                displayFormat='DD-MM-YYYY'
                                className="black-input mb-4 date-colender-bottom-right-0"
                            />
                        </div>
                    </div>
                </div>
                <div className='right-side-inputs ms-auto pt-3 mt3'>
                    <Button
                        type={BUTTON_TYPE.TYPE_BUTTON}
                        btnTxt='Export Data'
                        style={{ marginTop: "0px", width: "auto" }}
                        onClick={exportdata}
                        className={'btn btn-warning w-100 with-after-shadow mb-3  shadow-none'}
                    />
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ExportData
