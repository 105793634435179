import React from 'react'
interface CustomPaginationProps {
    count: any;
    onUpdate: (key: any, value: any) => void;
    queryParams: any
}

const CustomPagination: React.FC<CustomPaginationProps> = (props) => {

    const perPage = 12

    const totalPage = Math.ceil(Number(props.count / perPage));
    const currentPage = Math.ceil(Number(props?.queryParams?.limit as never / perPage));

    return (
        <div className='self-pagination d-flex gap-3 justify-content-center align-item-center mb-5 mt-4'>
            <span
                className='left-side'
                onClick={() => {
                    if (currentPage > 1) {
                        props.onUpdate('limit', (currentPage - 1) * 12)
                    }
                }}
            >
                <img src="/assets/images/play-icon-dark.svg" alt="" />
            </span>
            <ul className='d-flex gap-1 list-unstyled pl-0 mb-0'>
                <li>{currentPage}</li>
                <li>of</li>
                <li>{totalPage}</li>
            </ul>
            <span
                className='right-side'
                onClick={() => {
                    if (currentPage < totalPage) {
                        props.onUpdate('limit', (currentPage + 1) * 12)
                    }
                }}
            >
                <img src="/assets/images/play-icon-dark.svg" alt="" />
            </span>
        </div>
    )
}

export default CustomPagination