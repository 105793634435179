import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import Button from '../../global/ui/Button';
import { BUTTON_TYPE } from '../../types';
import { createFullName, emptyFunction } from '../../utils';
import PrepDiaryItem from '../../pages/scheduleManagement/prepDiaryManagement/PrepDiaryItem';
import Logo from '../commonComponent/ProfileImage';


interface IDragEventConflictCrew {
    show: boolean;
    onClose: () => void;
    data: any;
    onView: (id: any) => void;
}

const DragEventConflictCrew = (props: IDragEventConflictCrew) => {
    return (
        <Modal
            centered
            scrollable
            show={props?.show}
            onHide={emptyFunction}>
            <ModalHeader className="p-0 border-0 text-center mb-3">
                <ModalTitle className="fw-bold mx-auto">
                    <p><img src={"/assets/images/red_flag.svg"} alt="" /></p>Conflict
                    <p style={{ lineHeight: '24px' }} className="mb-3">The following crew are already assigned to events at this time adjust their schedule to move this event.</p>
                </ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-center">
                <div style={{ maxHeight: '200px', paddingLeft: '50px', paddingRight: '50px' }}>
                    {props?.data?.map((item: any, idx: any) => {
                        return (
                            <div key={idx} className='d-flex justify-content-between align-items-center mb-2'>
                                <div style={{ marginRight: '30px', }} className='d-flex justify-content-center align-items-center'>
                                    {item?.profile_image
                                        ?
                                        <img className='rounded-circle' style={{ width: '40px', height: '40px', marginRight: '10px', }} src={item?.profile_image || ''} alt="" />
                                        :
                                        <Logo style={{ width: '40px', height: '40px', marginRight: '10px', }} data={createFullName(item?.first_name || '', item?.last_name || '')?.[0] || ''} />
                                    }
                                    <span style={{ fontSize: '12px', color: '#000000', fontWeight: 700 }} className='ml-2 conflict-crew'>{createFullName(item?.first_name || '', item?.last_name || '') || ''}</span>
                                </div>
                                <PrepDiaryItem
                                    onClick={() => props?.onView?.(item?.id)}
                                    label={'View Schedule'}
                                    style={{ borderRadius: '50px', }}
                                />
                            </div>
                        )
                    })}
                </div>
            </ModalBody>
            <ModalFooter className="p-0 border-0">
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Cancel'
                    style={{ marginTop: "0px", width: "auto", textDecoration: 'underline' }}
                    onClick={props.onClose}
                    className={'btn btn-light w-100 with-after-shadow shadow-none fw-normal bg-transparent m-0 border-0 mt-4 p-0'}
                />
            </ModalFooter>
        </Modal>
    )
}

export default DragEventConflictCrew