import React from 'react';

interface ICustomToggle {
    style?: React.CSSProperties;
    checked: boolean;
    onChange?: (evt: any) => void;
    disabled?: boolean
}

const CustomToggle: React.FC<ICustomToggle> = (props) => {

    return (
        <label style={props?.style} className="custom-toggle">
            <input
                type="checkbox"
                checked={props?.checked || false}
                onChange={props?.onChange}
                disabled={props?.disabled}
            />
            <span className={`slider round ${props?.disabled ? 'disabled' : ''}`}></span>
        </label>
    );
};

export default CustomToggle;
