// @ts-nocheck

import React, { useEffect, useState } from 'react'
import { radiusList } from '../../data/data';
import MyMapComponent from './MyMapComponent';
import { Select } from 'antd';
import { getAddressFromCoordinates } from '../../utils';
import GoogleSearchPlaceInput from './GoogleSearchPlaceInput';

const DEFAULT_RADIUS = '50'

const CustomGooglePlaceSearch: React.FC<any> = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const [locationData, setLocationData] = useState<any>(null)
    const [selectedRadius, setSelectedRadius] = useState<any>(DEFAULT_RADIUS)

    const handleApply = () => {
        const obj = {
            location: locationData?.address || '',
            lat: locationData?.lat || '',
            lng: locationData?.lng || '',
            radius: selectedRadius || ''
        }
        if (props?.needExtraData) {
            obj['city'] = locationData?.city || '';
            obj['country'] = locationData?.country || '';
            obj['post_code'] = locationData?.post_code || '';
        }
        props?.onApply?.(obj);
        setIsOpen(false)
    }

    useEffect(() => {
        const obj = {
            address: props?.data?.location || '',
            lat: props?.data?.lat || '',
            lng: props?.data?.lng || '',
        }
        setLocationData(obj)
        setSelectedRadius(props?.data?.radius || DEFAULT_RADIUS)
    }, [JSON.stringify(props?.data)]);

    const handleMarkerDragEnd = event => {
        const { latLng } = event;
        const lat = latLng?.lat();
        const lng = latLng?.lng();
        getAddressFromCoordinates(lat, lng).then((response) => {
            setLocationData({
                address: response?.address || '',
                lat: lat || '',
                lng: lng || '',
                city: response?.city || '',
                country: response?.country || '',
                post_code: response?.post_code || ''
            })
        })
    };

    return (
        <div className='custom-search' style={{ position: 'relative', marginTop: props?.isSupplier ? '-20px' : '0px' }}>
            <div onClick={() => setIsOpen((prevState) => !prevState)} className='google-custom-search'>
                <label>{props?.label || 'Location'}</label>
                <span>{props?.data?.location ? props?.data?.location : props?.placeholder ? props?.placeholder : 'City or Postcode'}</span>
                {props?.showDelete &&
                    <div
                        className='address-delete'
                        onClick={(evt) => {
                            evt.stopPropagation();
                            props?.onDelete();
                        }}
                    >
                        <img className='cursor-pointer' src={"/assets/images/delete-fill-inner-red.svg"} alt="" />
                    </div>
                }
            </div>
            <div style={{ display: isOpen ? 'block' : 'none', width: props?.hideRadius ? '445px' : '800px' }} className='custom-search-dropdown'>
                <div className='d-flex justify-content-between w-100'>
                    <span></span>
                    <span>{props?.heading || 'Change Location'}</span>
                    <button type='button' onClick={() => setIsOpen((prevState) => !prevState)} className="close-btn shadow-none"><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                </div>
                <div style={props?.contentStyle || { paddingBottom: '20px' }}>
                    <div className={props?.hideRadius ? 'align-content-center justify-content-between' : 'd-flex align-content-center justify-content-between'}>
                        <div>
                            <p>Search by city neighbourhood or zip code</p>
                            <div className="form_group black-input mb-4">
                                <div className="input_box">
                                    <label htmlFor="location">Location</label>
                                    <GoogleSearchPlaceInput
                                        onSelect={(data) => {
                                            setLocationData((prevState) => ({
                                                ...prevState,
                                                address: data?.address || '',
                                                lat: data?.coordinates?.[0] || '',
                                                lng: data?.coordinates?.[1] || '',
                                                city: data?.city || '',
                                                country: data?.country || '',
                                                post_code: data?.post_code || ''
                                            }))
                                        }}
                                        selectedAddress={locationData?.address || ''}
                                    />
                                </div>
                            </div>
                            {!props?.hideRadius &&
                                <Select
                                    onChange={(data) => { setSelectedRadius(data) }}
                                    options={radiusList}
                                    style={{ width: '100%', height: '45px' }}
                                    placeholder='Select radius'
                                    value={selectedRadius || 'Select radius'}
                                />
                            }
                        </div>
                        <div style={{ width: props?.hideRadius ? '100%' : '60%', marginLeft: '10px', marginTop: '20px' }}>
                            <MyMapComponent
                                lat={locationData?.lat ? Number(locationData?.lat) : ''}
                                lng={locationData?.lng ? Number(locationData?.lng) : ''}
                                radius={selectedRadius || null}
                                handleMarkerDragEnd={handleMarkerDragEnd}
                                scrollwheel={props?.scrollwheel}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', justifyItems: 'flex-end' }}>
                        <button
                            className="btn btn-warning btn-sm shadow-none"
                            type='button'
                            style={{ marginTop: '20px', marginLeft: 'auto', marginRight: '0px', width: '100px', padding: '0px' }}
                            onClick={handleApply}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomGooglePlaceSearch