import moment from "moment";
import { CURRENCY_TYPE, PERMISSION_TYPE, SETTINGS_ITEMS_TYPE } from "../types";
import { labels, viewProfileInfoTab } from "../utils/constants";

export const countryList = [
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "GB",
    dial_code: "+44",
    value: "+44",
    label: "United Kingdom",
  },
  {
    name: "United States",
    flag: "🇺🇸",
    code: "US",
    dial_code: "+1",
    value: "+1",
    label: "United States",
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    code: "AU",
    dial_code: "+61",
    value: "+61",
    label: "Australia",
  },
];

export const DAYS_OF_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const currencyData = [
  { label: labels.currency.gbp, value: CURRENCY_TYPE.TYPE_GBP },
  { label: labels.currency.eur, value: CURRENCY_TYPE.TYPE_EUR },
  { label: labels.currency.usd, value: CURRENCY_TYPE.TYPE_USD },
];

export const paginationData = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
  { label: 40, value: 40 },
  { label: 50, value: 50 },
  { label: 60, value: 60 },
  { label: 70, value: 70 },
  { label: 80, value: 80 },
  { label: 90, value: 90 },
  { label: 100, value: 100 },
];

export const statusList = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "Invited", value: "Invited" },
];
export const yearGap = [
  {
    label: "One year",
    value: "One year",
    date: moment().subtract(1, "year").format("YYYY-MM-DD"),
    difference: 1,
  },
  {
    label: "Two year",
    value: "Two years",
    date: moment().subtract(2, "year").format("YYYY-MM-DD"),
    difference: 2,
  },
  {
    label: "Three year",
    value: "Three years",
    date: moment().subtract(3, "year").format("YYYY-MM-DD"),
    difference: 3,
  },
  {
    label: "Four year",
    value: "Four years",
    date: moment().subtract(4, "year").format("YYYY-MM-DD"),
    difference: 4,
  },
  {
    label: "Five year",
    value: "Five years",
    date: moment().subtract(5, "year").format("YYYY-MM-DD"),
    difference: 5,
  },
  {
    label: "Six year",
    value: "Six years",
    date: moment().subtract(6, "year").format("YYYY-MM-DD"),
    difference: 6,
  },
  {
    label: "Seven year",
    value: "Seven years",
    date: moment().subtract(7, "year").format("YYYY-MM-DD"),
    difference: 7,
  },
  {
    label: "Eight year",
    value: "Eight years",
    date: moment().subtract(8, "year").format("YYYY-MM-DD"),
    difference: 8,
  },
];
export const timezone = [
  { label: "British Summer Time (GMT+1)", value: "London" },
  { label: "Greenwich Mean Time (GMT +0)", value: "Europe" },
];
export const defaultDepRoles = {
  label: "",
  value: "",
  roles: [],
  selectedRole: [],
};

export const country: Array<any> = [
  {
    label: "United Kingdom",
    value: "United Kingdom",
    name: "United Kingdom",
    id: "",
  },
  {
    label: "United States",
    name: "United States",
    value: "United States",
    id: "",
  },
  { label: "Australia", name: "Australia", value: "Australia", id: "" },
];

export const rolesList: Array<any> = [
  {
    label: "Admin",
    value: PERMISSION_TYPE.TYPE_ADMIN,
    name: "Admin",
    id: "",
    permissions: [],
  },
  {
    label: "HOD",
    value: PERMISSION_TYPE.TYPE_HOD,
    name: "HOD",
    id: "",
    permissions: [PERMISSION_TYPE.TYPE_ADMIN],
  },
  {
    label: "Supervisor",
    value: PERMISSION_TYPE.TYPE_SUPERVISOR,
    name: "Supervisor",
    id: "",
    permissions: [PERMISSION_TYPE.TYPE_ADMIN, PERMISSION_TYPE.TYPE_HOD],
  },
  {
    label: "Crew Member",
    value: PERMISSION_TYPE.TYPE_CREW_MEMBER,
    name: "Crew Member",
    id: "",
    permissions: [
      PERMISSION_TYPE.TYPE_ADMIN,
      PERMISSION_TYPE.TYPE_HOD,
      PERMISSION_TYPE.TYPE_SUPERVISOR,
    ],
  },
];

export const eventTypeList: Array<any> = [
  { label: "Prep", value: "p", name: "p", id: "" },
  { label: "Shoot", value: "s", name: "s", id: "" },
  { label: "Strike", value: "st", name: "st", id: "" },
  { label: "Meeting", value: "m", name: "m", id: "" },
  { label: "Second Unit Shoot", value: "ss", name: "ss", id: "" },
  { label: "Splinter", value: "sp", name: "sp", id: "" },
];

export const email_notifications_type_list: Array<any> = [
  { label: "Daily", value: 1, name: "daily", id: "" },
  { label: "Weekly", value: 2, name: "weekly", id: "" },
  { label: "Never", value: 3, name: "never", id: "" },
];

export const profileTabs: Array<any> = [
  viewProfileInfoTab.tab1,
  viewProfileInfoTab.tab2,
  viewProfileInfoTab.tab3,
  viewProfileInfoTab.tab7,
];
// export const profileTabs: Array<any> = [viewProfileInfoTab.tab1, viewProfileInfoTab.tab2, viewProfileInfoTab.tab3, viewProfileInfoTab.tab4, viewProfileInfoTab.tab6]
export const supplierProfileTabs: Array<any> = [
  viewProfileInfoTab.tab1,
  viewProfileInfoTab.tab5,
  viewProfileInfoTab.tab3,
  viewProfileInfoTab.tab4,
  viewProfileInfoTab.tab6,
  viewProfileInfoTab.tab7,
];
export const crewProfileTabs: Array<any> = [viewProfileInfoTab.tab1];

export const settingsData: Array<any> = [
  {
    label: "Change Password",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_CHANGE_PASS,
    icon: "/assets/images/TC-icon.svg",
  },
  {
    label: "Terms & Conditions",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_TNC,
    icon: "/assets/images/TC-icon.svg",
  },
  {
    label: "Privacy Policy",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_PP,
    icon: "/assets/images/shield-pp.svg",
  },
  {
    label: "FAQ",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_FAQ,
    icon: "/assets/images/shield-pp.svg",
  },
  {
    label: "Contact Admin",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_CONTACT_ADMIN,
    icon: "/assets/images/mail-fill-dark.svg",
  },
];

export const suppSettingData = [
  {
    label: "Remove Profile",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_DELETE_PROFILE,
    icon: "/assets/images/delete-user-red.svg",
  },
  {
    label: "Cancel Subscription",
    value: "",
    type: SETTINGS_ITEMS_TYPE.CANCEL_SUBSCRIPTION,
    icon: "/assets/images/cross-cercle-fill-red.svg",
  },
];

export const ownerSettingData = [
  {
    label: "Remove Production",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_DELETE_PROFILE,
    icon: "/assets/images/delete-user-red.svg",
  },
  {
    label: "Archive/Duplicate Production",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_ARCHIVE_AND_DELETE,
    icon: "/assets/images/archive.png",
  },
  {
    label: "Change Ownership",
    value: "",
    type: SETTINGS_ITEMS_TYPE.TYPE_CHANGE_OWNERSHIP,
    icon: "/assets/images/change_owner.png",
  },
];

const startTime = new Date();
startTime.setHours(0, 0, 0); // set start time to 11:00 AM

const endTime = new Date();
endTime.setHours(24, 0, 0); // set end time to 11:30 PM

const timeSlots: any = [];

while (startTime < endTime) {
  const slotStart = startTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  startTime.setMinutes(startTime.getMinutes() + 15);
  const slotEnd = startTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  let value = {
    label: `${slotStart}`,
    value: `${slotStart}`,
    startTime: `${moment(slotStart, ["h:mm A"]).format("HH:mm")}`,
    endTime: `${moment(slotEnd, ["h:mm A"]).format("HH:mm")}`,
    name: `${slotStart} - ${slotEnd}`,
    id: "",
  };
  timeSlots.push(value);
}

export { timeSlots };
export const allTimeSlot: Array<any> = [
  { label: "prep", value: "prep", name: "prep", id: "" },
  { label: "shoot", value: "shoot", name: "shoot", id: "" },
  { label: "strike", value: "strike", name: "strike", id: "" },
];
export const preConfigDeptData = [
  {
    value: "1",
    label: "Directors Department",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Director",
      },
      {
        value: "2",
        label: "Assistant to Director",
      },
      {
        value: "3",
        label: "2nd Unit Director",
      },
      {
        value: "4",
        label: "1st Assistant Director ",
      },
      {
        value: "5",
        label: "Key 2nd Assistant Director",
      },
      {
        value: "6",
        label: "2nd Assistant Director",
      },
      {
        value: "7",
        label: "Floor 2nd Assistant Director",
      },
      {
        value: "8",
        label: "3rd Assistant Director",
      },
      {
        value: "9",
        label: "Crowd 2nd Assistant Director",
      },
      {
        value: "10",
        label: "Crowd 3rd Assistant Director",
      },
      {
        value: "11",
        label: "Assistant Director PA / Runner",
      },
      {
        value: "12",
        label: "Floor Runner",
      },
      {
        value: "13",
        label: "Base Runner",
      },
      {
        value: "14",
        label: "Misc",
      },
    ],
  },
  {
    value: "2",
    label: "Producers",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Show Runner",
      },
      {
        value: "2",
        label: "Executive Producer",
      },
      {
        value: "3",
        label: "Line Producer",
      },
      {
        value: "4",
        label: "Production Executive",
      },
      {
        value: "5",
        label: "Associate Producer",
      },
      {
        value: "6",
        label: "Assistant to the producer",
      },
      {
        value: "7",
        label: "Co Producer",
      },
      {
        value: "8",
        label: "Co Executive Producer",
      },
      {
        value: "9",
        label: "Producer",
      },
      {
        value: "10",
        label: "Consulting Producer",
      },
      {
        value: "11",
        label: "Misc",
      },
    ],
  },
  {
    value: "3",
    label: "Writers",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Writer",
      },
      {
        value: "2",
        label: "Story Editor",
      },
      {
        value: "3",
        label: "Misc",
      },
    ],
  },
  {
    value: "4",
    label: "COVID",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Infection Control Officer",
      },
      {
        value: "2",
        label: "Covid Supervisor",
      },
      {
        value: "3",
        label: "Covid Co-ordinator",
      },
      {
        value: "4",
        label: "Covid Unit Manager",
      },
      {
        value: "5",
        label: "Covid Assistant Co-ordinator",
      },
      {
        value: "6",
        label: "Covid Secretary",
      },
      {
        value: "7",
        label: "Covid Assistant",
      },
      {
        value: "8",
        label: "Covid PA",
      },
      {
        value: "9",
        label: "Misc",
      },
    ],
  },
  {
    value: "5",
    label: "Production",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Unit Production Manager",
      },
      {
        value: "2",
        label: "Production Manager",
      },
      {
        value: "3",
        label: "Production Supervisor",
      },
      {
        value: "4",
        label: "Production Co-ordinator",
      },
      {
        value: "5",
        label: "Assistant Production Co-ordinator",
      },
      {
        value: "6",
        label: "Production Secretary",
      },
      {
        value: "7",
        label: "Production Assistant",
      },
      {
        value: "8",
        label: "PA / Runner",
      },
      {
        value: "9",
        label: "Misc",
      },
    ],
  },
  {
    value: "6",
    label: "Accounts",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Financial Controller",
      },
      {
        value: "2",
        label: "Production Accountant",
      },
      {
        value: "3",
        label: "1st Assistant Accountant",
      },
      {
        value: "4",
        label: "Crew Payroll",
      },
      {
        value: "5",
        label: "Lead AP",
      },
      {
        value: "6",
        label: "Dailies Payroll Accountant",
      },
      {
        value: "7",
        label: "Petty Cash Accountant",
      },
      {
        value: "8",
        label: "Accounts Junior",
      },
      {
        value: "9",
        label: "Accounts Payable",
      },
      {
        value: "10",
        label: "Cashier",
      },
      {
        value: "11",
        label: "Misc",
      },
    ],
  },
  {
    value: "7",
    label: "Armoury",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "HOD Armourer",
      },
      {
        value: "2",
        label: "Buyer",
      },
      {
        value: "3",
        label: "Administrator",
      },
      {
        value: "4",
        label: "Armourer",
      },
      {
        value: "5",
        label: "Misc",
      },
    ],
  },
  {
    value: "8",
    label: "Art Department",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Designer",
      },
      {
        value: "2",
        label: "Supervising Art Director",
      },
      {
        value: "3",
        label: "Senior Art Director",
      },
      {
        value: "4",
        label: "Art Director",
      },
      {
        value: "5",
        label: "Stand By Art Director",
      },
      {
        value: "6",
        label: "VFX Art Director",
      },
      {
        value: "7",
        label: "Assistant Art Director",
      },
      {
        value: "8",
        label: "Graphic Designer",
      },
      {
        value: "9",
        label: "Graphics Art Director",
      },
      {
        value: "10",
        label: "Draughtsperson",
      },
      {
        value: "11",
        label: "Art Department Co-ordinator",
      },
      {
        value: "12",
        label: "Art Department Assistant",
      },
      {
        value: "13",
        label: "Art Department PA/ Runner",
      },
      {
        value: "14",
        label: "Misc",
      },
    ],
  },
  {
    value: "9",
    label: "Camera",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Director of Photography",
      },
      {
        value: "2",
        label: "Camera Operator",
      },
      {
        value: "3",
        label: "First Assistant Camera",
      },
      {
        value: "4",
        label: "Steady Cam",
      },
      {
        value: "5",
        label: "Drone Operator",
      },
      {
        value: "6",
        label: "Drone First Assistant Camera",
      },
      {
        value: "7",
        label: "Second Assistant Camera",
      },
      {
        value: "8",
        label: "Central Loader",
      },
      {
        value: "9",
        label: "Camera Trainee",
      },
      {
        value: "10",
        label: "DIT",
      },
      {
        value: "11",
        label: "DIT Assistant",
      },
      {
        value: "12",
        label: "Data Wrangler",
      },
    ],
  },
  {
    value: "10",
    label: "Construction",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Construction Manager",
      },
      {
        value: "2",
        label: "Structural Engineer",
      },
      {
        value: "3",
        label: "Assistant Construction Manager",
      },
      {
        value: "4",
        label: "Construction Buyer",
      },
      {
        value: "5",
        label: "HOD Carpenter",
      },
      {
        value: "6",
        label: "Carpenter",
      },
      {
        value: "7",
        label: "HOD Painter",
      },
      {
        value: "8",
        label: "Painter",
      },
      {
        value: "9",
        label: "HOD Rigger",
      },
      {
        value: "10",
        label: "Rigger",
      },
      {
        value: "11",
        label: "HOD Plasterer",
      },
      {
        value: "12",
        label: "Plasterer",
      },
      {
        value: "13",
        label: "HOD Sculptor",
      },
      {
        value: "14",
        label: "Sculptor",
      },
      {
        value: "15",
        label: "HOD Stage Hand",
      },
      {
        value: "16",
        label: "Stage Hand",
      },
      {
        value: "17",
        label: "Wet Trades Buyer",
      },
      {
        value: "18",
        label: "Construction Supervisor",
      },
      {
        value: "19",
        label: "Construction Assistant",
      },
      {
        value: "20",
        label: "Construction Junior",
      },
      {
        value: "21",
        label: "Misc",
      },
    ],
  },
  {
    value: "11",
    label: "Costume",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Costume Designer",
      },
      {
        value: "2",
        label: "Assistant Costume Designer",
      },
      {
        value: "3",
        label: "Costume Supervisor",
      },
      {
        value: "4",
        label: "Crowd Supervisor ",
      },
      {
        value: "5",
        label: "Armourer Supervisor",
      },
      {
        value: "6",
        label: "Principal Stand By Costume",
      },
      {
        value: "7",
        label: "Principal Wadrobe Mistress",
      },
      {
        value: "8",
        label: "Principal Trainee",
      },
      {
        value: "9",
        label: "Costumier",
      },
      {
        value: "10",
        label: "Breakdown Artist",
      },
      {
        value: "11",
        label: "Crowd Stand by",
      },
      {
        value: "12",
        label: "Seastress",
      },
      {
        value: "13",
        label: "Cutter",
      },
      {
        value: "14",
        label: "Sculptor",
      },
      {
        value: "15",
        label: "Leatherworker",
      },
      {
        value: "16",
        label: "Costume Trainee",
      },
      {
        value: "17",
        label: "Daily",
      },
      {
        value: "18",
        label: "Misc",
      },
    ],
  },
  {
    value: "12",
    label: "Editorial",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Editor",
      },
      {
        value: "2",
        label: "VFX Editor",
      },
      {
        value: "3",
        label: "1st Assistant Editor",
      },
      {
        value: "4",
        label: "Editorial PA",
      },
      {
        value: "5",
        label: "Rushes Runner",
      },
      {
        value: "6",
        label: "Misc",
      },
    ],
  },
  {
    value: "13",
    label: "Electrical",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Gaffer",
      },
      {
        value: "2",
        label: "Best Boy",
      },
      {
        value: "3",
        label: "Desk Op",
      },
      {
        value: "4",
        label: "Electrician",
      },
      {
        value: "5",
        label: "Generator Op",
      },
      {
        value: "6",
        label: "Rigging Gaffer",
      },
      {
        value: "7",
        label: "HOD Electrical Rigger",
      },
      {
        value: "8",
        label: "Rigging Best Boy",
      },
      {
        value: "9",
        label: "Rigging Desk Op",
      },
      {
        value: "10",
        label: "Rigging Electrician",
      },
      {
        value: "11",
        label: "Rigging Chargehand",
      },
      {
        value: "12",
        label: "Stand By Rigger",
      },
      {
        value: "13",
        label: "Rigger",
      },
    ],
  },
  {
    value: "14",
    label: "Greens",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "HOD Greensperson",
      },
      {
        value: "2",
        label: "Greens Supervisor",
      },
      {
        value: "3",
        label: "Greens Buyer",
      },
      {
        value: "4",
        label: "Lead Greensman",
      },
      {
        value: "5",
        label: "Greensman",
      },
    ],
  },
  {
    value: "15",
    label: "Grips",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Key Grip",
      },
      {
        value: "2",
        label: "Best Boy Grip",
      },
      {
        value: "3",
        label: "B Cam Grip",
      },
      {
        value: "4",
        label: "Head Technician",
      },
      {
        value: "5",
        label: "Crane Technician",
      },
      {
        value: "6",
        label: "Grips Trainee",
      },
    ],
  },
  {
    value: "16",
    label: "Hair Make Up",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Hair Make Up Designer",
      },
      {
        value: "2",
        label: "Hair Make Up Supervisor",
      },
      {
        value: "3",
        label: "Crowd Supervisor",
      },
      {
        value: "4",
        label: "Hair Make Up Artist",
      },
      {
        value: "5",
        label: "Hair Make Up Trainee",
      },
    ],
  },
  {
    value: "17",
    label: "Health and Safety",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Health and Safety Advisor",
      },
    ],
  },
  {
    value: "18",
    label: "Locations",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Supervising Location Manager",
      },
      {
        value: "2",
        label: "Location Manager",
      },
      {
        value: "3",
        label: "Location Scout",
      },
      {
        value: "4",
        label: "Unit Location Manager",
      },
      {
        value: "5",
        label: "Unit Manager",
      },
      {
        value: "6",
        label: "Assistant Unit Manager",
      },
      {
        value: "7",
        label: "Unit Assistant",
      },
      {
        value: "8",
        label: "Key Location Marshall",
      },
      {
        value: "9",
        label: "Location Marshall",
      },
      {
        value: "10",
        label: "Greens PA",
      },
      {
        value: "11",
        label: "Location Co-ordinator",
      },
      {
        value: "12",
        label: "Locations Assistant",
      },
      {
        value: "13",
        label: "Assistant Location Manager",
      },
    ],
  },
  {
    value: "19",
    label: "Medical",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Unit Medic",
      },
      {
        value: "2",
        label: "Studio Medic",
      },
    ],
  },
  {
    value: "20",
    label: "Props",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Propmaster",
      },
      {
        value: "2",
        label: "Assistant Propmaster",
      },
      {
        value: "3",
        label: "Location Propmaster",
      },
      {
        value: "4",
        label: "Prop Storeman",
      },
      {
        value: "5",
        label: "Assistant Prop Storeman",
      },
      {
        value: "6",
        label: "Props Co-ordinator",
      },
      {
        value: "7",
        label: "Buyer",
      },
      {
        value: "8",
        label: "Chargehand Dressing Props",
      },
      {
        value: "9",
        label: "Dressing Prophand",
      },
      {
        value: "10",
        label: "Props Carpenter",
      },
      {
        value: "11",
        label: "Lead Props Painter",
      },
      {
        value: "12",
        label: "Props Painter",
      },
      {
        value: "13",
        label: "Lead Props Standby",
      },
      {
        value: "14",
        label: "Props Standby",
      },
      {
        value: "15",
        label: "Props Trainee",
      },
    ],
  },
  {
    value: "21",
    label: "Propmaking",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "HOD Propmaker",
      },
      {
        value: "2",
        label: "Assistant Promaker",
      },
      {
        value: "3",
        label: "Propmaker",
      },
      {
        value: "4",
        label: "Propmaking Trainee",
      },
      {
        value: "5",
        label: "2nd Propmaker",
      },
      {
        value: "6",
        label: "Senior Propmaker",
      },
      {
        value: "7",
        label: "Junior Propmaker",
      },
    ],
  },
  {
    value: "22",
    label: "Prosthetics",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Prosthetics Designer",
      },
      {
        value: "2",
        label: "Prosthetics HOD",
      },
      {
        value: "3",
        label: "Prosthetics Assistant",
      },
    ],
  },
  {
    value: "23",
    label: "Publicity",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Unit Publicist",
      },
      {
        value: "2",
        label: "Stills Photographer",
      },
      {
        value: "3",
        label: "Publicist",
      },
      {
        value: "4",
        label: "BTS Videographer",
      },
      {
        value: "5",
        label: "BTS Photographer",
      },
    ],
  },
  {
    value: "24",
    label: "Set Decoration",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Set Decorator",
      },
      {
        value: "2",
        label: "Production Buyer",
      },
      {
        value: "3",
        label: "Assistant Set Decorator",
      },
      {
        value: "4",
        label: "Set Decoration Art Director",
      },
      {
        value: "5",
        label: "Junior Set Decorator",
      },
      {
        value: "6",
        label: "Assistant Buyer",
      },
      {
        value: "7",
        label: "Set Decoration Co-ordinator",
      },
      {
        value: "8",
        label: "Set Decoration Draughtsperson",
      },
      {
        value: "9",
        label: "Petty Cash Buyer",
      },
      {
        value: "10",
        label: "Set Decoration Assistant",
      },
      {
        value: "11",
        label: "Drapesmaster",
      },
      {
        value: "12",
        label: "Drapesperson",
      },
    ],
  },
  {
    value: "25",
    label: "Script",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Script Supervisor",
      },
      {
        value: "2",
        label: "Assistant Script Supervisor",
      },
      {
        value: "3",
        label: "Script Co-ordinator",
      },
      {
        value: "4",
        label: "Assistant Script Co-ordinator",
      },
    ],
  },
  {
    value: "26",
    label: "Security",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Security Co-ordinator",
      },
      {
        value: "2",
        label: "Lead Operative",
      },
      {
        value: "3",
        label: "Close Protection",
      },
      {
        value: "4",
        label: "Day Operative",
      },
      {
        value: "5",
        label: "Night Operative",
      },
      {
        value: "6",
        label: "Coneperson",
      },
    ],
  },
  {
    value: "27",
    label: "Standbys",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Standby Carpenter",
      },
      {
        value: "2",
        label: "Standby Rigger",
      },
      {
        value: "3",
        label: "Standby Stagehand",
      },
      {
        value: "4",
        label: "Standby Painter",
      },
    ],
  },
  {
    value: "28",
    label: "Sound",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Sound Recordist",
      },
      {
        value: "2",
        label: "First Assistant Sound",
      },
      {
        value: "3",
        label: "Second Assistant Sound",
      },
      {
        value: "4",
        label: "Third Assistant Sound",
      },
      {
        value: "5",
        label: "Boom Op",
      },
      {
        value: "6",
        label: "Mic Op",
      },
    ],
  },
  {
    value: "29",
    label: "Studio",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Studio Manager",
      },
      {
        value: "2",
        label: "Assistant Studio Manager",
      },
      {
        value: "3",
        label: "Studio Assistant",
      },
      {
        value: "4",
        label: "Studio PA",
      },
    ],
  },
  {
    value: "30",
    label: "Stunts",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Stunt Co-ordinator",
      },
      {
        value: "2",
        label: "Stunt Department Co-ordinator",
      },
      {
        value: "3",
        label: "Assistant Stunt Co-ordinator",
      },
      {
        value: "4",
        label: "Double",
      },
      {
        value: "5",
        label: "Key Rigger",
      },
      {
        value: "6",
        label: "Rigger",
      },
      {
        value: "7",
        label: "Core stunts",
      },
      {
        value: "8",
        label: "Utility Stunts",
      },
    ],
  },
  {
    value: "31",
    label: "SFX",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "SFX Supervisor",
      },
      {
        value: "2",
        label: "Workshop Supervisor",
      },
      {
        value: "3",
        label: "Buyer",
      },
      {
        value: "4",
        label: "SFX Co-ordinator",
      },
      {
        value: "5",
        label: "Floor Supervisor",
      },
      {
        value: "6",
        label: "Lead Workshop Technician",
      },
      {
        value: "7",
        label: "Senior Floor Technician",
      },
      {
        value: "8",
        label: "SFX Rigger",
      },
      {
        value: "9",
        label: "Floor Technician",
      },
      {
        value: "10",
        label: "Workshop Technician",
      },
      {
        value: "11",
        label: "Technician",
      },
      {
        value: "12",
        label: "Assistant Workshop Technician",
      },
      {
        value: "13",
        label: "Assistant Modeller",
      },
      {
        value: "14",
        label: "Trainee Floor Technician",
      },
      {
        value: "15",
        label: "SFX Trainee",
      },
    ],
  },
  {
    value: "32",
    label: "Specialist Advisor",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Military Advisior",
      },
      {
        value: "2",
        label: "Linguist",
      },
      {
        value: "3",
        label: "Intamacy Advisor",
      },
      {
        value: "4",
        label: "Choreographer",
      },
      {
        value: "5",
        label: "Misc",
      },
      {
        value: "6",
        label: "Marine Coordinator",
      },
      {
        value: "7",
        label: "Access Coordinator",
      },
    ],
  },
  {
    value: "33",
    label: "Transport",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Transport Captain",
      },
      {
        value: "2",
        label: "Assistant & Transport Captain",
      },
      {
        value: "3",
        label: "Transport Assistant",
      },
      {
        value: "4",
        label: "Unit Driver",
      },
      {
        value: "5",
        label: "Minibus Driver",
      },
    ],
  },
  {
    value: "34",
    label: "Video",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Video Op",
      },
      {
        value: "2",
        label: "Video Assistant",
      },
    ],
  },
  {
    value: "35",
    label: "DIT",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "DIT",
      },
      {
        value: "2",
        label: "Assistant DIT",
      },
    ],
  },
  {
    value: "36",
    label: "VFX",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "VFX Designer",
      },
      {
        value: "2",
        label: "VFX Supervisor",
      },
      {
        value: "3",
        label: "Concept Artist",
      },
      {
        value: "4",
        label: "VFX Producer",
      },
      {
        value: "5",
        label: "VFX Production Manager",
      },
      {
        value: "6",
        label: "VFX Co-ordinator",
      },
      {
        value: "7",
        label: "VFX Artist",
      },
      {
        value: "8",
        label: "Multimedia Artist",
      },
      {
        value: "9",
        label: "Animator",
      },
      {
        value: "10",
        label: "VFX PA",
      },
    ],
  },
  {
    value: "37",
    label: "Graphics Department",
    selectedRole: [],
    isDisabled: false,
    roles: [
      {
        value: "1",
        label: "Lead Graphic Designer",
      },
      {
        value: "2",
        label: "Graphic Designer",
      },
      {
        value: "3",
        label: "Assistant Graphic Designer",
      },
      {
        value: "4",
        label: "Graphics Assistant",
      },
      {
        value: "5",
        label: "Graphics Trainee",
      },
      {
        value: "6",
        label: "Misc",
      },
    ],
  },
];

export const ownerHeader = ["Information", "Location", "Rates"];
export const supplierHeader = ["Info", "Contact", "Location", "Services"];
export const supplierUpdateHeader = [
  "Profile Information",
  "Contact",
  "Location",
  "Services",
];
export const jobTab = ["Relevant", "Invited", "Accepted", "Rejected"];
export const jobTab1 = ["Accepted"];
export const supplierSubsData = [
  "Create your profile on Sixth Degree",
  "Tag all your products and services to become infinitely searchable",
  "Manage upcoming jobs, deliveries, or services with the integrated calendar",
  "Showcase your work with photos on your profile",
  "Increase your reach in the industry",
  "Monitor all communications with one central inbox",
];
export const prodSubsData = [
  "Centrally manage your crew and events on one dashboard",
  "Upload existing crew easily and invite them to use Sixth Degree",
  "Find available crew to fill positions on your production",
  "Easily communicate with crew and suppliers on and off your production",
  "Find suppliers for your needs local to where you are filming",
  "Send broadcast messages to your crew delivered directly to their phones.",
];

export const radiusList = [
  { label: "50 Kilometers", value: "50" },
  { label: "100 Kilometers", value: "100" },
  { label: "200 Kilometers", value: "200" },
  { label: "300 Kilometers", value: "300" },
  { label: "400 Kilometers", value: "400" },
  { label: "500 Kilometers", value: "500" },
];
