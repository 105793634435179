import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { cookieService } from '../../services';
import { cookieServiceParam } from '../../utils/constants';
import { makeGetCall, makePatchCall, makePostCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { ApiResponseType } from '../../types';
import { MyContext } from '../../context';
import Stripe from '.';
import queryString from 'query-string';

const PaymentComplete: React.FC<any> = (props) => {

    const { updateUserData } = useContext(MyContext)
    const paymentData = cookieService.getCookie(cookieServiceParam.payment_data)
    const navigate = useNavigate();
    const location = useLocation();
    const parsed = queryString.parse(location?.search);
    const isFromSubs = paymentData?.redirectType === 'subs';
    const [isCardAdded, setIsCardAdded] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowCardModal, setIsShowCardModal] = useState(false);


    const handleClick = () => {
        const data = { setupIntent: parsed?.setup_intent, type: 'payment', ...paymentData, isCardAdded: isCardAdded }
        window!.top!.postMessage(data || '');
        if (!isCardAdded && parsed?.payment_status == 'error') {
            // need to show payment modal
            cookieService.removeCookie(cookieServiceParam.payment_data);
            setIsShowCardModal(true)
            return
        }
    }

    const navigateToRespectiveScreen = () => {
        let route = ''
        cookieService.removeCookie(cookieServiceParam.payment_data);
        // cookieService.removeCookie('subs_id')
        if (paymentData && isFromSubs) {
            route = '/schedule'
        } else {
            route = '/view-profile'
        }
        if (route) navigate(route);
    }

    const createSubs = () => {
        makePostCall(endPoints.SUBSCRIPTION, { price_id: paymentData?.selectedSubs, payment_id: paymentData?.paymentId }, false).then((res: ApiResponseType<any>) => {
            if (res.status?.code == 200) {
                makePatchCall(endPoints.PROFILE, { account_completed: true }).then((res) => {
                    updateUserData(res)
                }).catch((err) => { setIsLoading(false) })
            }
        })
    }

    const checkIsCardAdded = () => {
        const urls = `${endPoints.SETUP_INTENT}${parsed?.setup_intent}/`
        makeGetCall(urls, null, false, true).then((res: ApiResponseType<any>) => {
            setIsLoading(false);
            if (res?.status?.code === 200) {
                setIsCardAdded(res?.data?.confirmed);
                if (paymentData && isFromSubs && res?.data?.confirmed) createSubs()
            } else {
                setIsCardAdded(false);
            }
        }).catch((err) => {
            setIsLoading(false);
            setIsCardAdded(false);
        })
    }

    useEffect(checkIsCardAdded, []);

    const getTitleAndDesc = () => {
        let title = 'Card Added!';
        let desc = 'Your card added successfully';
        if (!isCardAdded) {
            title = 'Oops!';
            desc = 'It seems that your card was not added due to some technical error or it was interrupted in between, Please try again.'
        } else if (isFromSubs) {
            title = 'Subscription Active!';
            desc = 'Your Sixth Degree subscription is now active'
        }
        return [title, desc]
    }

    const [title, desc] = getTitleAndDesc();

    return (
        <div id='payment_success'>
            <div className='payment-succ'>
                <div className="card bg-white z_index_99 min-ht-auto">
                    <div className="card-body">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img src={isCardAdded ? "/assets/images/success-upgrade.svg" : '/assets/images/payment_error.png'} alt="" />
                            <h5 style={{ fontWeight: '600', paddingTop: '2rem' }}>{isLoading ? 'Loading...' : title}</h5>
                            <p style={{ textAlign: 'center', marginTop: '10px' }}>{isLoading ? 'Fetching data please wait...' : desc}</p>
                            {!isLoading && <button className={'btn btn-warning w-100 with-after-shadow shadow-none'} onClick={handleClick}>{!isCardAdded ? 'Dismiss' : 'Activate your profile'}</button>}
                        </div>
                    </div>
                </div>
            </div>
            {isShowCardModal &&
                <Stripe
                    isShow={isShowCardModal}
                    onClose={() => setIsShowCardModal(false)}
                    onDone={navigateToRespectiveScreen}
                    onSubscribe={navigateToRespectiveScreen}
                    redirectType={paymentData?.redirectType}
                />
            }
        </div>
    )
}

export default PaymentComplete