import moment from "moment";
import Dept from "../../global/ui/Dept";
import { ROLE_TYPE } from "../../types";
import { getMaskInputFormat, hasAddressData, renderAddressDetail } from "../../utils";
import { apiConstants } from "../../utils/apiConstants";
import Logo from "../../components/commonComponent/ProfileImage";

interface Props {
  userData?: any;
  onMoreClick?: () => void;
}

interface ItemProps {
  className?: any;
  label: any;
  lebel2?: any;
  icon?: any;
  moreAddressCount?: any;
  onMoreClick?: () => void
}

const Items = (itemProps: ItemProps) => {
  return (
    <li className={itemProps?.className}>
      <img src={itemProps?.icon || "/assets/images/Mail-icon-perple.svg"} alt="image" />
      {itemProps.label}
      {itemProps.lebel2 && itemProps.lebel2 != 'null' ?
        <>
          <br />{itemProps?.lebel2 || ""}
        </> : ''
      }
      {itemProps?.moreAddressCount && <span className="cursor-pointer address-label" onClick={itemProps?.onMoreClick}>{`+${itemProps?.moreAddressCount} More`}</span>}
    </li>
  )
}

const PersonalInformation = ({ userData, onMoreClick }: Props) => {
  return (
    <div className="tab-pane active" id="profile_information">
      <ul className="profile-information-list">
        <li className='align-items-center text-capitalize'>
          {userData?.[apiConstants.profile_image] ? <img src={userData?.[apiConstants.profile_image] || "/assets/images/user-icon-perple.svg"} className={userData?.[apiConstants.profile_image] ? "final-profile" : ''} alt="" />
            :
            <Logo style={{ width: '34px', height: '34px' }} data={userData?.first_name?.[0] || userData?.last_name?.[0]} />}
          {(userData?.first_name || userData?.last_name) ? `${userData?.first_name || ''} ${userData?.last_name || ''}` : userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? userData?.supplier_profile?.contact_person : ''}
        </li>
        <Items
          label={userData?.email}
          className='align-items-center'
          lebel2={userData?.mobile_number ? getMaskInputFormat(userData?.mobile_number) : ''}
          icon='/assets/images/Mail-icon-perple.svg'
        />

        {hasAddressData(userData) &&
          <Items
            label={renderAddressDetail(userData, false, true)}
            lebel2={userData?.postcode || ''}
            icon="/assets/images/Location-icon-perple.svg"
            className='align-items-center'
            moreAddressCount={userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER && userData?.other_location?.length > 0 ? userData?.other_location?.length : null}
            onMoreClick={onMoreClick}
          />
        }
        <Items
          label={`Member since ${moment(userData?.date_joined).format('LL')}`}
          icon="/assets/images/Calendar-empty-icon-perple.svg"
          className='align-items-center'
        />
        {userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER &&
          <>
            {userData?.supplier_profile?.bio &&
              <li style={{ wordBreak: 'break-word', textAlign: 'justify' }}>
                <img src={"/assets/images/user-icon-perple.svg"} alt="" />
                <p dangerouslySetInnerHTML={{ __html: userData?.supplier_profile?.bio.replace(/\n/g, "<br/>") || '' }} >
                </p>
              </li>
            }
            {userData?.supplier_profile?.departments?.length > 0 &&
              <Dept
                data={userData?.supplier_profile?.departments}
              />
            }
          </>
        }
      </ul>
    </div>
  )
}
export default PersonalInformation;