import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { makeDeleteCall, makeGetCall, makePatchCall } from '../../effects/requests';
import SideBarLayout from '../../global/layout/SideBarLayout'
import CustomPagination from '../../global/ui/CustomPagination';
import NoData from '../../global/ui/NoData';
import SearchWithHeading from '../../global/ui/SearchWithHeading'
import { ApiResponseType, USER_ACTION_TYPE, USER_PRESS_ACTION_TYPE } from '../../types';
import { generateFormData, showToast } from '../../utils';
import { endPoints } from '../../utils/endpoints';
import CreateJob from './CreateJob';
import JobFilter from './JobFilter';
import JobsData from './JobsData';
import ViewJobDetail from './ViewJobDetail';
import { defaultJobFilter } from '../../utils/constants';
import { MyContext } from '../../context';

const JobList: React.FC<any> = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const { userData } = useContext(MyContext)

    var queryParams = location?.state || defaultJobFilter(userData?.production_company_name || '')

    const [allData, setAllData] = useState([]);
    const [count, setCount] = useState();
    const [jobDetails, setJobDetails] = useState<any>(null);
    const [showAddModal, setShowAddModal] = useState<any>(null);

    useEffect(() => {
        if (location?.state?.jobId) {
            setTimeout(() => {
                fetchDetails(location?.state?.jobId)
            }, 1000)
        }
    }, [location])

    const getJobs = () => {
        updateQueryParams(userData?.production_company_name)
        const params: any = { ...queryParams };
        params.offset = params?.search ? 0 : Number(params.limit) - 10;
        delete params.limit
        makeGetCall(endPoints.JOBLIST, params).then((res: ApiResponseType<any>) => {
            setAllData(res.results);
            setCount(res?.count);
            setJobDetails(null)
        })
    }

    useEffect(getJobs, [JSON.stringify(queryParams), userData]);

    const updateQueryParams = (newCompanyName: string) => {
        // Create a new object with updated values
        const updatedParams = {
            ...queryParams,
            job_company__company_name: newCompanyName,
        };

        // Update queryParams with the new object
        queryParams = updatedParams;
    };

    const updateUrls = (key: any, value: any) => {
        const params = queryParams;
        params[key] = value;
        navigate('/jobs', { state: params })
    }

    const fetchDetails = (id: any) => {
        const urls = `${endPoints.JOBLIST}${id}`
        makeGetCall(urls).then((res: ApiResponseType<any>) => {
            if (res?.status?.code == 200) {
                setJobDetails(res?.data)
            }
            else {
                showToast(res?.status?.message, "error")
            }
        })
    }

    const deleteJob = (id: any) => {
        setJobDetails(null);
        const urls = `${endPoints.JOBLIST}${id}/`
        makeDeleteCall(urls, null, true).then((res: ApiResponseType<any>) => {
            getJobs();
        })
    }

    const completeJob = (id: any) => {
        const urls = `${endPoints.JOBLIST}${id}/`
        setJobDetails(null);
        const fd = generateFormData({ completed: true })
        makePatchCall(urls, fd).then((res) => { getJobs(); })
    }

    return (
        <SideBarLayout>
            <div className="right-content-full-screen">
                <SearchWithHeading
                    placeholder="Search by job title"
                    onSearch={(value) => updateUrls('search', value)}
                    value={queryParams?.search || ''}
                    heading='Jobs'
                    addText={'Add Job'}
                    onAdd={() => setShowAddModal(USER_ACTION_TYPE.TYPE_CREATE)}
                />
                <div className="bottom-content-full-screen">
                    {/* {isOwner && (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 order-1 order-xxl-1">
                                <div className="liket-active-tab mb-4">
                                    <ul className="list-unstyled d-flex align-item-center mb-0">
                                        <li
                                            // onClick={() => {}
                                            //     props?.changeScheduleType(SCHEDULE_TYPE.CREW_SCH)
                                            // }
                                            // className={
                                            //     props?.type === SCHEDULE_TYPE.CREW_SCH ? "active" : ""
                                            // }
                                            className='active'
                                        >
                                           Active
                                        </li>
                                        <li
                                            // onClick={() =>
                                            //     props?.changeScheduleType(SCHEDULE_TYPE.EVENT_SCH)
                                            // }
                                            // className={
                                            //     props?.type === SCHEDULE_TYPE.EVENT_SCH ? "active" : ""
                                            // }
                                        >
                                           Unavailable
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )} */}
                    <JobFilter
                        count={count}
                        updateValue={updateUrls}
                        data={queryParams}
                        showing={allData?.length}
                    />
                    {allData?.length > 0
                        ?
                        <>
                            <JobsData
                                data={allData}
                                onClick={(item) => fetchDetails(item?.id)}
                            />
                            {Number(count) > 10 && <CustomPagination queryParams={queryParams} count={count} onUpdate={updateUrls} />}
                        </>
                        :
                        <NoData />
                    }
                </div>
                <ViewJobDetail
                    visible={jobDetails ? true : false}
                    onClose={() => setJobDetails(null)}
                    jobDetails={jobDetails}
                    onAction={(type) => {
                        if (type === USER_PRESS_ACTION_TYPE.TYPE_COMPLETE) {
                            completeJob(jobDetails?.id);
                            return
                        }
                        if (type === USER_PRESS_ACTION_TYPE.TYPE_DELETE) {
                            deleteJob(jobDetails?.id);
                            return
                        }
                        setShowAddModal(USER_ACTION_TYPE.TYPE_UPDATE)
                    }}
                    onDone={getJobs}
                />
                <CreateJob
                    isShow={showAddModal ? true : false}
                    onClose={() => setShowAddModal(null)}
                    data={jobDetails}
                    type={showAddModal}
                    onDone={getJobs}
                />
            </div>
        </SideBarLayout>
    )
}

export default JobList